import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_All_CAMPAIGN,
  GET_CAMPAIGNS_USERS,
  GET_DASHBOARD,
  GET_ALL_CAMPAIGN_TRAININGS
} from "../../constants/ActionTypes";
import {
  getAllCampaignSuccess,
  getCampaignsUsersSuccess
  // getAllCampaign
} from "../actions/Campaign";
import {
  queryDashBoardSuccess,
  queryAllCampaignTrainingsSuccess,
  showMessage
  // getAllCampaign
} from "../actions/Dashboard";
import { api, apiUrl } from "util/config";

const {
  campaignsUsers,
  campaignsAnalytics,
  queryDashboard,
  queryAllCampaignTrainings
} = api;

// if ( typeof localStorage.getItem('accessToken') !== undefined) {
//   axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
// }

// const updateCampaignRequest = async (campaign) =>
// await axios.post(apiUrl + queryUpdateCampaign, campaign)
// .then(response => response)
//   .catch(error => error);

// const getCampaignsUsersRequest = async (campaignId) =>
// await axios.get(apiUrl + `api/campaigns/${campaignId}/users`,
//   {
//     headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('accessToken')
//     }
//   }
// )
// .then(response => response)
//   .catch(error => error);

// const getAllCampaignRequest = async (obj) =>
// await axios.get(apiUrl + campaignsAnalytics,
//   {
//     headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('accessToken')
//     },
//     params: obj
//   }
// )
// .then(response => response)
//   .catch(error => error);

const queryDashBoard = async obj =>
  await axios
    .get(apiUrl + queryDashboard, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: obj
    })
    .then(response => response)
    .catch(error => error);

const queryCampaignTrainings = async obj =>
  await axios
    .get(apiUrl + queryAllCampaignTrainings, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: obj
    })
    .then(response => response)
    .catch(error => error);

// function* getCampaignsUsers({ payload }) {
//   const campaignId = payload.campaignId;
//   // console.log("payload",payload)
//   try {
//     const responseData = yield call(getCampaignsUsersRequest, campaignId);
//     if (responseData.error) {
//       yield put(showMessage(responseData.data.userMessage));
//     } else {
//       if(responseData.data.data) {
//         const campaignInfo = responseData.data.data;
//         // console.log("campaignInfo",campaignInfo)
//         yield put(getCampaignsUsersSuccess(campaignInfo));
//       }
//     }
//   } catch (error) {
//     yield put(showMessage(error));
//   }
// }

// function* getAllCampaign({ payload }) {
//   const { live, page }  = payload;
//   const obj = {};
//   obj['live'] =  Number(live);
//   obj['page'] = page ? Number(page) : 1;
//   try {
//     const responseData = yield call(getAllCampaignRequest, obj);
//     if (responseData.message) {
//       yield put(showMessage(responseData.response.data.userMessage));
//     } else {
//       if(responseData.data.data) {
//         var campaignInfo = responseData.data.data;
//         campaignInfo['page'] = obj['page'];
//         campaignInfo['live'] = obj['live'];
//         yield put(getAllCampaignSuccess(campaignInfo));
//       }
//     }
//   } catch (error) {
//     yield put(showMessage(error));
//   }
// }
function* getdasboard({ payload }) {
  const { live, page } = payload;
  const obj = {};
  // obj['live'] =  Number(live);
  // obj['page'] = page ? Number(page) : 1;
  try {
    const responseData = yield call(queryDashBoard, obj);
    // console.log({responseData})
    if (responseData.message) {
      yield put(showMessage(responseData.response.data.userMessage));
    } else {
      if (responseData.data.data) {
        var dashboard = responseData.data.data;

        yield put(queryDashBoardSuccess(dashboard));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}
function* getAllCampaignTrainingsFunc({ payload }) {
  const { live, page, searchText } = payload;
  const obj = {};
  if (live || live === 0) {
    obj["live"] = Number(live);
  }
  obj["page"] = page ? Number(page) - 1 : 0;
  obj["searchText"] = searchText;
  try {
    const responseData = yield call(queryCampaignTrainings, obj);
    // console.log({responseData})
    if (responseData.message) {
      yield put(showMessage(responseData.response.data.userMessage));
    } else {
      if (responseData.data.data) {
        var camaignTrainings = responseData.data.data;
        camaignTrainings["page"] = obj["page"] + 1;
        camaignTrainings["live"] = obj["live"];
        yield put(queryAllCampaignTrainingsSuccess(camaignTrainings));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

// export function* campaignsUsersGet(){
//   yield takeEvery( GET_CAMPAIGNS_USERS, getCampaignsUsers)
// }

// export function* campaignAllGet(){
//   yield takeEvery( GET_All_CAMPAIGN, getAllCampaign)
// }
export function* getDashboard() {
  yield takeEvery(GET_DASHBOARD, getdasboard);
}
export function* getAllCampaignTrainings() {
  yield takeEvery(GET_ALL_CAMPAIGN_TRAININGS, getAllCampaignTrainingsFunc);
}

export default function* rootSaga() {
  if (typeof localStorage.getItem("accessToken") !== undefined) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
  }
  yield all([
    // fork(campaignAllGet),
    // fork(campaignsUsersGet),
    fork(getDashboard),
    fork(getAllCampaignTrainings)
  ]);
}
