import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ImportUsers from "components/UserInfo/ImportUsers";
import Widget from "../../../components/Widget";
import CircularProgress from "components/CircularProgress/index";

import {
  Button,
  Card,
  Form,
  Icon,
  InputNumber,
  Modal,
  Table,
  Popconfirm,
  Input,
  Row,
  Select,
  Col
} from "antd";

import { moment } from "moment";
import { func } from "prop-types";
const { Option } = Select;
var Model = require("./model");
var _ = require("lodash");
const { confirm } = Modal;
const EditableContext = React.createContext();

console.log("API", process.env.NODE_ENV);
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  //  const WrappedNormalLoginForm = Form.create()(this.renderCell);

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class Trainee extends PureComponent {
  state = {
    loading: true,
    importedUsers: [],
    selectedRowKeys: [],
    data: {},
    editingKey: "",
    userImport: {},
    totalRecord: 0
  };
  componentDidMount() {
    var self = this
    const user = JSON.parse(localStorage.getItem("user"));
    var userImport = user.organisation.userImport;
    this.setState({ userImport },self.initialApiCall());
   
  }
initialApiCall (){
  var self = this
  Model.default.getTrainee({}).then(response => {
    console.log({ response });
    if (!response.error) {
      var { importedUsers } = this.state;
      importedUsers = response.data.trainees.concat(importedUsers);
      this.setState({
        importedUsers,
        loading: false,
        totalRecord: response.data.totalRecords,
        branches: response.data.branches,
        designations: response.data.designations,
        departments: response.data.departments
      },self.forceUpdate());
    }
  });
}

  handlePagination(page) {
    var query = {
      page:page-1
    }
    const state = this.state
    if(state.branch){
      query.branch = state.branch
    }
    if(state.designation){
      query.designation = state.designation
    }
    if(state.department){
      query.department = state.department
    }

    Model.default
      .getTrainee(query)
      .then(response => {
        console.log({ response });
        if (!response.error) {
          var { importedUsers } = this.state;
          importedUsers = response.data.trainees;
          this.setState({
            importedUsers,
            loading: false,
            totalRecord: response.data.totalRecords
          });
        }
      });
  }
  formatUserToTrainee = User => {
    var formatted = {
      employeeId: User.EmployeeId,
      userName: User.EmployeeName,
      email: User.EmployeeMail,
      organisationId: "",
      department: User.Department,
      designation: User.Designation,
      branch: User.Branch
    };
    return formatted;
  };
  showConfirm(content) {
    return new Promise(function(resolve, reject) {
      confirm({
        title: "Do you want to proceed?",
        content: content,
        onOk() {
          resolve(null);
        },
        onCancel() {
          reject(null);
        }
      });
    });
  }
  importedTrainees = (data) => {
    console.log({ data },this.state.userImport);
    var self = this;
    if (this.state.userImport && this.state.userImport[data.importType] && this.state.importedUsers) {
      var newUsers = data.importedUsers.map(function(user) {
        return self.formatUserToTrainee(user);
      });
      const numberOfnewRecord = newUsers.length;
      const numberOfOldUsers = this.state.importedUsers.length;

      var diff = _.differenceBy(this.state.importedUsers, newUsers, "email");

      const newUserFound = diff.length + numberOfnewRecord - numberOfOldUsers;
      console.log(
        { numberOfnewRecord },
        { numberOfOldUsers },
        { diff },
        { newUserFound }
      );
      var content = (
        <div>
          Previous Users: {numberOfOldUsers}
          <br></br>
          {/* Newly Fetched Users  */}
         New User Added: {numberOfnewRecord}
          <br></br>
          Affected Users:{" "}
          {data.importType === "manual" ? numberOfnewRecord : diff.length}
          <br></br>
          New Users: {newUserFound}
          <br></br>
        </div>
      );
      //  const saveTrainee = Model.default.saveTrainee(data.importedUsers,data.importType,this.state.userImport)

      self.showConfirm(content).then(resp => {
        var modifiedUsers = data.importedUsers.map(function(user){
          user.importType = data.importType
          return user
        })
        console.log("ok clicked");
        Model.default
          .saveTrainee(
            modifiedUsers,
            data.importType,
            this.state.userImport
          )
          .then(response => {
            console.log({ response });
            if (!response.error) {
              var { importedUsers } = this.state;
              // if (data.importType === "manual") {
              //   // importedUsers = response.trainees.concat(importedUsers);
              //   // importedUsers = response.trainees;
                
              // } else {
              //   importedUsers = response.trainees;
              // }

              this.setState({ importedUsers:[] },function(){
                self.initialApiCall()
              });
              
            }
          });
      });
    } else {
      Model.default
        .saveTrainee(data.importedUsers, data.importType, this.state.userImport)
        .then(response => {
          console.log({ response },response.trainees);
          if (!response.error) {
            var user = response.trainees
            var { importedUsers } = this.state;
            console.log({importedUsers})
            importedUsers = user.concat(importedUsers); 
            this.setState({ importedUsers });
          }
        });
    }
  };
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  onSelectAll = (selected, selectedRows, changeRows) => {
    if (!selected) {
      this.setState({ selectedRowKeys: [] });
    } else {
      this.setState({
        selectedRowKeys: [...Array(this.state.importedUsers.length).keys()]
      });
    }
  };
  onSelect = (record, selected, selectedRows, nativeEvent) => {
    console.log({ record, selected, selectedRows, nativeEvent });
    let index = this.state.importedUsers.findIndex(x => x._id === record._id);
    console.log({ index });
    var { selectedRowKeys } = this.state;
    if (selectedRowKeys.includes(index)) {
      selectedRowKeys = selectedRowKeys.filter(function(value, i, arr) {
        return value != index;
      });
    } else {
      console.log("pushing");
      Array.prototype.insert = function(index, item) {
        this.splice(index, 0, item);
      };
      selectedRowKeys.insert(index, index);
      console.log({ selectedRowKeys });
      // this.setState({ selectedRowKeys,importedUsers })
    }
    console.log({ selectedRowKeys });
    this.setState({ selectedRowKeys: selectedRowKeys });
    this.forceUpdate();
  };
  deleteSelected = () => {
    var self = this;
    const { selectedRowKeys, importedUsers } = this.state;
    var ids = importedUsers.map(function(user, index) {
      if (selectedRowKeys.includes(index)) {
        return user._id;
      } else {
        return null;
      }
    });
    ids = ids.filter(id => id != null);
    console.log({ ids });

    Model.default.deleteTrainee(ids).then(function(response) {
      console.log({ response });
      if (!response.error) {
        // const updatedTrainee = importedUsers.filter(
        //   user => !ids.includes(user._id)
        // );
        self.setState({ importedUsers:[], selectedRowKeys: [],loading:true },self.initialApiCall());
        // self.forceUpdate();
        
        // self.setState({selectedRowKeys:[]})
      }
    });
  };
  onChangeTable = selectedRowKeys => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };
  isEditing = record => record._id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };
  edit(key) {
    console.log(key);
    this.setState({ editingKey: key });
  }
  save(form, key) {
    var self = this;
    console.log({ form }, { key });

    form.validateFields((error, row) => {
      console.log({ error }, { row });
      if (error) {
        return;
      }
      console.log({row})
      
      self.setState({ loading: true });
      Model.default.updatreTrainee(key, row).then(response => {
        if (!response.error) {
          var updatedUsers = self.state.importedUsers.map(function(user) {
            console.log({user})
            
            if (user._id == key) {
              user = {
                _id: key,
                ...row,
                importType:user.importType

              };
            }

            return user;
          });

          self.setState({
            editingKey: "",
            importedUsers: updatedUsers,
            loading: false
          });
        }
      });
    });
  }
  onChange(value, type) {
    console.log(`selected ${value}`);
    this.setState({ dataLoading: true });
    this.setState({ [type]: value }, function() {
      Model.default
        .getTrainee({
          branch: this.state.branch,
          designation: this.state.designation,
          department: this.state.department,
          search: this.state.searchText
        })
        .then(response => {
          this.setState({ dataLoading: false });
          console.log({ response });
          if (!response.error) {
            var { importedUsers } = this.state;
            importedUsers = response.data.trainees;
            this.setState({
              importedUsers,
              loading: false,
              totalRecord: response.data.totalRecords
            });
          }
        });
    });
  }

  onBlur() {
    console.log("blur");
  }

  onFocus() {
    console.log("focus");
  }
  onSearch(val) {
    console.log("search:", val);
  }
  filterBox(type) {
    var self = this;
    return (
      <Select
        showSearch
        style={{ width: "100%" , fontSize:"14px !important"}}
        placeholder={`Select ${type}`}
        optionFilterProp="children"
        onChange={function(value) {
          self.onChange(value, type);
        }}
        // onFocus={this.onFocus}
        // onBlur={this.onBlur}
        // onSearch={this.onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="">ALL</Option>
        {this.state[
          type == "branch"
            ? "branches"
            : type == "designation"
            ? "designations"
            : "departments"
        ].map(function(val) {
          return <Option value={val}>{val}</Option>;
        })}
      </Select>
    );
  }
  getSearchData = e => {
    this.onChange(e.target.value, "searchText");
  };

  render() {
    var columns = [
      // {
      //     title: 'Employee Id',
      //     key: 'employeeId',
      //     dataIndex: 'employeeId',
      //     // editable:true,
      // },
      {
        title: "Name",
        key: "userName",
        dataIndex: "userName",
        editable: true
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        editable: true
      },
      {
        title: "Designation",
        key: "designation",
        dataIndex: "designation",
        editable: true
      },
      {
        title: "Department",
        key: "department",
        dataIndex: "department",
        editable: true
      },
      {
        title: "Branch",
        key: "branch",
        dataIndex: "branch",
        editable: true
      },
      {
        title: "Import Type",
        key: "importType",
        dataIndex: "importType",
        render:  (text, record)  =>{ console.log({record, text}, record.importType); 
         return <span>{text ? (text).charAt(0).toUpperCase()+''+(text).substring(1, (text).length):""}</span>}
        // editable: true,
        // disabled:true
        // (importType).charAt(0).toUpperCase()+''+(importType).substring(1, (importType).length))
      },
      {
        title: "operation",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a
                    onClick={() => this.save(form, record._id)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record._id)}
              >
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <a
              disabled={editingKey !== ""}
              onClick={() => this.edit(record._id)}
            >
              Edit
            </a>
          );
        }
      }

      // sorter: (a, b) => { return moment(a.publishedAt).unix() - moment(b.publishedAt).unix()}
    ];
    var { importedUsers: trainees, selectedRowKeys, loading } = this.state;
    if (loading) {
      return <CircularProgress></CircularProgress>;
    }
    console.log({trainees},"render")
    var self = this;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onChangeTable
      // onSelectAll: this.onSelectAll,
      // onSelect: this.onSelect,
      // hideDefaultSelections: true,
      // selections: [
      //   {
      //     key: "all-data",
      //     text: "Select All Data",
      //     onSelect: () => {
      //       this.setState({
      //         selectedRowKeys: [
      //           ...Array(this.state.importedUsers.length).keys()
      //         ] // 0...45
      //       });
      //     }
      //   },
      //   {
      //     key: "odd",
      //     text: "Select Odd Row",
      //     onSelect: changableRowKeys => {
      //       let newSelectedRowKeys = [];
      //       newSelectedRowKeys = changableRowKeys.filter((key, index) => {
      //         if (index % 2 !== 0) {
      //           return false;
      //         }
      //         return true;
      //       });
      //       this.setState({ selectedRowKeys: newSelectedRowKeys });
      //     }
      //   },
      //   {
      //     key: "even",
      //     text: "Select Even Row",
      //     onSelect: changableRowKeys => {
      //       let newSelectedRowKeys = [];
      //       newSelectedRowKeys = changableRowKeys.filter((key, index) => {
      //         if (index % 2 !== 0) {
      //           return true;
      //         }
      //         return false;
      //       });
      //       this.setState({ selectedRowKeys: newSelectedRowKeys });
      //     }
      //   }
      // ]
    };
    const WrappedAdvancedSearchForm = Form.create()(EditableCell);
    var EditableCellNew = Form.create()(EditableCell);
    const components = {
      body: {
        cell: EditableCell
      }
    };

    columns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    trainees = trainees.sort(this.dynamicSort("-createdAt"));
    return (
      <Auxiliary>
        <ImportUsers
          importedTrainees={this.importedTrainees}
          userImport={this.state.userImport}
        />
        <Widget >
          <Row className="gx-p-2">
            <Col xs={6}>{this.filterBox("designation")}</Col>
            <Col xs={6}>{this.filterBox("department")}</Col>
            <Col xs={6}>{this.filterBox("branch")}</Col>

            <Col xs={6}>
              <Input
                type="search"
                placeholder="Search"
                onChange={this.getSearchData}
              />
            </Col>
          </Row> 
      
          <EditableContext.Provider value={this.props.form}>
            <Table
              loading={this.state.dataLoading}
              components={components}
              className="gx-table-responsive"
              columns={columns}
              dataSource={trainees}
              bordered={false}
              rowSelection={rowSelection}
              pagination={{
                total: this.state.totalRecord,
                showTotal: total => `Total ${this.state.totalRecord} Items`,

                onChange: page => {
                  console.log(page);

                  this.handlePagination(page);
                }
              }}
              // rowKey={record => record.EmployeeId}
              // scroll={{ x: 1000 }}
            />
          </EditableContext.Provider>

          {selectedRowKeys.length > 0 ? (
            <Button type="danger" onClick={this.deleteSelected}>Delete selected</Button>
          ) : null}
        </Widget>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  const {
    loader,
    alertMessage,
    showMessage,
    training,
    allTraining
  } = trainingInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    accessToken,
    training,
    allTraining
  };
};
// const WrappedNormalLoginForm = Form.create()(importUsers);

//  export default Form.create()(Trainee)

export default withRouter(connect(mapStateToProps, {})(Form.create()(Trainee)));
