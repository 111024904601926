import React from "react";

export const description = `
<h3> About this Course</h3>
<p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim   laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
<p> Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequa mod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
<p>Magna  consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat </p>
<p>consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci </p>
<b> Interdum et malesuada fames ipsum </b>
<p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim   laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad n ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
<h4> Elementum tellus id mauris faucibus</h4>
<ul className="uk-list uk-list-bullet">
    <li>At vero eos et accusam et justo .</li>
    <li>Consetetur sadipscing elitr, eirmod tempor invidunt .</li>
    <li>Lorem ipsum dolor sit amet .</li>                         
</ul>
<h4>Uismod tincidunt ut laoreet dolore </h4>
<p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim   laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>

`;

export const trainerDescription = `<p className="uk-margin-remove-top uk-margin-small-bottom">
        OhPhish is the subsidiary of EC-Council, the world’s leading cybersecurity certification body. We offer the most comprehensive portfolio of solutions and products specially tailored to your business needs.
    </p> 
    <p>    
        Our cutting-edge solutions leverage EC-Council’s expertise in the cyber space, combining technology, intelligence and services in one package, enabling enterprises to minimize their exposure to constantly evolving cybersecurity threats and risks. 
    </p>
    <p>  
        OhPhish is OhPhish’s flagship solution. It includes phishing, smishing and vishing in a single revolutionary platform, all integrated with e-Learning and Gamifiction modules in a learning manangement system (LMS) to help organizations strengthen their most vulnerable asset, their people.   
    </p>
    `;

export const trainerInfo = {
  name: "OhPhish",
  specialization: "",
  description: trainerDescription,
  imageUrl: require("assets/images/ic_logo.png")
  // rating:'4.6',
  // reviews:'89,072',
  // totelCourse:'5',
  // totelTrainee:'485,420',
};

export const arrBookmarks = [
  {
    _id: "Bookmarks",
    category: "Favorite Training",
    categoryNav: "Favorite Training",
    categoryCoverImageUrl: require("assets/images/courses/baner_my_favourite_training.jpg")
  }
];
export const arrCategoryTraining = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "10000 +",
    totalNumberOfDiscussion: "7961",
    categoryCoverImageUrl: require("assets/images/courses/baner_assigned_training.jpg")
  },
  {
    _id: "Practice",
    category: "Practice Quizes",
    categoryNav: "Practice Quizes",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "150 +",
    totalNumberOfStudents: "200 +",
    totalNumberOfDiscussion: "754",
    categoryCoverImageUrl: require("assets/images/courses/baner_practice_training.jpg") //baner_practice_training.jpg
  }
];

export const arrCategory = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "10000 +",
    totalNumberOfDiscussion: "7961",
    categoryCoverImageUrl: require("assets/images/courses/baner_assigned_training.jpg")
  },
  {
    _id: "Premium", //Assigned / Premium / Standard/ Practice/CSCU
    category: "Explore Training", //Assigned Training, Standard Training, Premium Training, Practice Quizes, CSCU Video
    categoryNav: "Explore Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "57",
    totalNumberOfStudents: "100 +",
    totalNumberOfDiscussion: "60",
    categoryCoverImageUrl: require("assets/images/courses/baner_premium_training.jpg")
  },
  // {
  //     _id: 'Standard',
  //     category:'Standard Package',
  //     categoryNav:'Standard Package',
  //     // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
  //     totalNumberOfCourse: '83',
  //     totalNumberOfStudents: '100 +',
  //     totalNumberOfDiscussion: '543',
  //     categoryCoverImageUrl:require('assets/images/courses/baner_standard_training.jpg'),
  // },
  {
    _id: "Practice",
    category: "Practice Quizes",
    categoryNav: "Practice Quizes",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "150 +",
    totalNumberOfStudents: "200 +",
    totalNumberOfDiscussion: "754",
    categoryCoverImageUrl: require("assets/images/courses/baner_practice_training.jpg") //baner_practice_training.jpg
  },
  {
    _id: "CSCU",
    category: "CSCU Training",
    categoryNav: "CSCU Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "30",
    totalNumberOfStudents: "99",
    totalNumberOfDiscussion: "60",
    categoryCoverImageUrl: require("assets/images/courses/baner_cscu_videos_training.jpg")
  }
];

export const arrCategoryAll = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "35000+",
    totalNumberOfDiscussion: "98",
    categoryCoverImageUrl: require("assets/images/courses/baner_assigned_training.jpg")
  },
  {
    _id: "premium", //Assigned / Premium / Standard/ Practice/CSCU
    category: "Explore Training", //Assigned Training, Standard Training, Premium Training, Practice Quizes, CSCU Video
    categoryNav: "Explore Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "35000+",
    totalNumberOfDiscussion: "98",
    categoryCoverImageUrl: require("assets/images/courses/baner_premium_training.jpg")
  },
  // {
  //     _id: 'Standard',
  //     category:'Standard Package',
  //     categoryNav:'Standard Package',
  //     // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
  //     totalNumberOfCourse: '83',
  //     totalNumberOfStudents: '100 +',
  //     totalNumberOfDiscussion: '543',
  //     categoryCoverImageUrl:require('assets/images/courses/baner_standard_training.jpg'),
  // },
  {
    _id: "practice",
    category: "Practice Quizes",
    categoryNav: "Practice Quizes",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "35000+",
    totalNumberOfDiscussion: "98",
    categoryCoverImageUrl: require("assets/images/courses/baner_practice_training.jpg") //baner_practice_training.jpg
  },
  {
    _id: "cscu",
    category: "CSCU Training",
    categoryNav: "CSCU Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "10",
    totalNumberOfStudents: "99",
    totalNumberOfDiscussion: "60",
    categoryCoverImageUrl: require("assets/images/courses/baner_cscu_videos_training.jpg")
  }
];

export const arrGiedeaCategoryAll = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "35000+",
    totalNumberOfDiscussion: "98",
    categoryCoverImageUrl: require("assets/images/courses/trainingBannerImage.png")
  },
  {
    _id: "premium", //Assigned / Premium / Standard/ Practice/CSCU
    category: "Explore Training", //Assigned Training, Standard Training, Premium Training, Practice Quizes, CSCU Video
    categoryNav: "Explore Training",
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "35000+",
    totalNumberOfDiscussion: "98",
    categoryCoverImageUrl: require("assets/images/courses/trainingBannerImage.png")
  }
];

export const arrCategoryPremium = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "10000 +",
    totalNumberOfDiscussion: "7961",
    categoryCoverImageUrl: require("assets/images/courses/baner_assigned_training.jpg")
  },
  {
    _id: "Premium", //Assigned / Premium / Standard/ Practice/CSCU
    category: "Explore Training", //Assigned Training, Standard Training, Premium Training, Practice Quizes, CSCU Video
    categoryNav: "Explore Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "57",
    totalNumberOfStudents: "100 +",
    totalNumberOfDiscussion: "60",
    categoryCoverImageUrl: require("assets/images/courses/baner_premium_training.jpg")
  },
  {
    _id: "Practice",
    category: "Practice Quizes",
    categoryNav: "Practice Quizes",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "150 +",
    totalNumberOfStudents: "200 +",
    totalNumberOfDiscussion: "754",
    categoryCoverImageUrl: require("assets/images/courses/baner_practice_training.jpg") //baner_practice_training.jpg
  }
];

export const arrCategoryCSCU = [
  {
    _id: "Assigned",
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus',
    totalNumberOfCourse: "100 +",
    totalNumberOfStudents: "10000 +",
    totalNumberOfDiscussion: "7961",
    categoryCoverImageUrl: require("assets/images/courses/baner_assigned_training.jpg")
  },
  {
    _id: "Practice",
    category: "Practice Quizes",
    categoryNav: "Practice Quizes",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "150 +",
    totalNumberOfStudents: "200 +",
    totalNumberOfDiscussion: "754",
    categoryCoverImageUrl: require("assets/images/courses/baner_practice_training.jpg") //baner_practice_training.jpg
  },
  {
    _id: "CSCU",
    category: "CSCU Training",
    categoryNav: "CSCU Training",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: "30",
    totalNumberOfStudents: "99",
    totalNumberOfDiscussion: "60",
    categoryCoverImageUrl: require("assets/images/courses/baner_cscu_videos_training.jpg")
  }
];

export const arrCategory1 = [
  {
    _id: 0,
    category: "Assigned Training",
    categoryNav: "Assigned Training",
    categoryDescription:
      "Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus",
    totalNumberOfCourse: 100,
    totalNumberOfStudents: 10000,
    totalNumberOfDiscussion: 79561,
    categoryCoverImageUrl: require("assets/images/courses/course-catagory-hero.jpg"),
    trainingInfo: [
      {
        _id: 1,
        title: "Vue.js The Complete Guide",
        shortDescription:
          "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ...",
        description: description,
        imageUrl: require("assets/images/courses/course-10.jpg"),
        category: {
          type: "JavaScript",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt"
        },
        level: {
          type: "Beginner",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing .."
        },
        trainerInfo: trainerInfo
      }
    ]
  },
  {
    _id: 1,
    category: "Web Development",
    categoryNav: "Web Development",
    categoryDescription:
      "Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus",
    totalNumberOfCourse: 17,
    totalNumberOfStudents: 99,
    totalNumberOfDiscussion: 60,
    categoryCoverImageUrl: require("assets/images/courses/course-catagory-hero.jpg"),
    trainingInfo: [
      {
        _id: 1,
        title: "Vue.js The Complete Guide",
        shortDescription:
          "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ...",
        description: description,
        imageUrl: require("assets/images/courses/course-10.jpg"),
        category: {
          type: "JavaScript",
          subType: "",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt"
        },
        level: {
          type: "Beginner",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing .."
        },
        assignTraining: "12",
        trainerInfo: trainerInfo
      }
    ]
  },
  {
    _id: 2,
    category: "Mobile Apps",
    categoryNav: "Mobile Apps",
    categoryDescription:
      "Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. Suspendisse pharetra risus ut metus",
    totalNumberOfCourse: 17,
    totalNumberOfStudents: 99,
    totalNumberOfDiscussion: 60,
    categoryCoverImageUrl: require("assets/images/courses/course-catagory-hero.jpg"),
    trainingInfo: [
      {
        _id: 1,
        title: "Vue.js The Complete Guide",
        shortDescription:
          "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ...",
        description: description,
        imageUrl: require("assets/images/courses/course-10.jpg"),
        category: {
          type: "JavaScript",
          subType: "",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt"
        },
        level: {
          type: "Beginner",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing .."
        },
        trainerInfo: trainerInfo
      },

      {
        _id: 1,
        title: "Vue.js The Complete Guide",
        shortDescription:
          "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ...",
        description: description,
        imageUrl: require("assets/images/courses/course-10.jpg"),
        category: {
          type: "JavaScript",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt"
        },
        level: {
          type: "Beginner",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing .."
        },
        trainerInfo: trainerInfo
      },

      {
        _id: 1,
        title: "Vue.js The Complete Guide",
        shortDescription:
          "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ...",
        description: description,
        imageUrl: require("assets/images/courses/course-10.jpg"),
        category: {
          type: "JavaScript",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt"
        },
        level: {
          type: "Beginner",
          description:
            "Lorem ipsum dolor sit amet tempor  consectetur adipiscing .."
        },
        trainerInfo: trainerInfo
      }
    ]
  },
  {
    _id: 3,
    category: "Game Development",
    categoryNav: "Game Development",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: 30,
    totalNumberOfStudents: 100
    // totalNumberOfDiscussion: 60,
    // categoryCoverImageUrl:'./../../../assets/images/courses/course-catagory-hero.jpg',
  },
  {
    _id: 4,
    category: "Software Training",
    categoryNav: "Software",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: 30,
    totalNumberOfStudents: 99
    // totalNumberOfDiscussion: 60,
    // categoryCoverImageUrl:'./../../../assets/images/courses/course-catagory-hero.jpg',
  },
  {
    _id: 5,
    category: "Development Tools",
    categoryNav: "Development",
    // categoryDescription:'Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum.',
    totalNumberOfCourse: 30,
    totalNumberOfStudents: 45
    // totalNumberOfDiscussion: 60,
    // categoryCoverImageUrl:'./../../../assets/images/courses/course-catagory-hero.jpg',
  }
];
