import { api, apiUrl } from "../../../util/config";
import { message } from "antd";
import $ from "jquery";
var Actions = {
  saveTrainee: (trainees, importType, userImport) => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("trainees", trainees);
      // form.append("campaignType", "email");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee",
        method: "POST",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: { trainees, importType, userImport },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            var user = JSON.parse(localStorage.getItem("user"));
            user.organisation = response.data;
            localStorage.setItem("user", JSON.stringify(user));

            message.success(response.userMessage);
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  updatreTrainee: (id, trainee) => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("trainee", trainee);
      // form.append("campaignType", "email");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/trainee?id=" + id,
        method: "PUT",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: { trainee },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  deleteTrainee: trainees => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("trainees", trainees);
      // form.append("campaignType", "email");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee",
        method: "DELETE",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: { trainees },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            // location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  getTrainee: query => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      // form.append("trainees", tt);
      // form.append("campaignType", "email");
      console.log(query, "query");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee",
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: query,
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  }
};

export default Actions;
