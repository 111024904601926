import axios from 'axios'
import io from 'socket.io-client'
import { apiUrl, socketURL } from './config'

console.log('socketURL', socketURL)
let socket = {}

socket.connect = function() {
  // socket.io = io(socketURL)
  socket.io = {};
  return this
}

socket.getConnection = function() {
  console.log(socketURL)
  if (socket) {
    return this.io
  } else {
    socket = io(socketURL)
    return this.io
  }
}

socket.on = function(eventName, callback) {
  console.log("eventName", eventName)
  if(eventName == 'SECRET_KEY'){
    setTimeout(()=>{
      callback(localStorage.getItem(eventName));
    },2000);
    return this;
  }
  // socket.io.on(eventName, callback)
  axios.get(apiUrl + 'api/' + (eventName == 'CLIENT_PRIVATE_KEY' ? "INIT" : "INIT_ACK"))
  .then(response => {
    callback(response.data.data);
  })
  
  return this
}

socket.emit = function(eventName, data) {
  // socket.io.emit(eventName, data)
  axios.post(apiUrl + 'api/' + (eventName == 'CLIENT_PRIVATE_KEY' ? "INIT" : "INIT_ACK"), {data})
  .then(response => {
    localStorage.setItem(eventName, response.data.data);
  })
}

export default socket
