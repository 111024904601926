import React from "react";
import { Route, Switch } from "react-router-dom";
import Category from "./main1";
import CourseIntro from "./courseIntro";
import CourseDhashboard from "./courseDhashboard";
import CourseLesson from "./CourseLesson";
import TrainingPlayer from "./play/$id";
import UserProfile from "./profile";
// import Settings from "./profile/settings";
import Bookmarks from "./bookmarks";
import Calendar from "./Calendar";
import Result from "./result/$trainingId";
import YourTrainings from "./yourTrainings";
import MyCart from "./myCart";
import PaymentCancelled from "./PaymentCancelled/index";
import PaymentSuccess from "./PaymentSuccess/index";
import LeaderBoard from "./leaderBoard/index";

const Main = ({ match, location, i18n }) => {
  console.log("locationsss", { location }, i18n);
  let style = {};
  if (
    location.pathname === "/trainee/profile" ||
    location.pathname === "/trainee/settings" ||
    location.pathname === "/trainee/calendar" ||
    location.pathname === "/trainee/your/training" ||
    location.pathname === "/trainee/cart"
  ) {
    style = {
      paddingTop: "70px",
      minHeight: "90vh",
      paddingBottom: "150px"
    };
  }
  return (
    <div style={style}>
      {/* // <div className="app" style={{backgroundColor: '#edeff0'}} > Calendar */}
      {/* <SideNavbar/> */}
      <Switch>
        <Route path={`${match.url}/cart`} component={MyCart} />
        <Route path={`${match.url}/your/training`} component={YourTrainings} />
        <Route path={`${match.url}/calendar`} component={Calendar} />
        <Route path={`${match.url}/result/:trainingId`} component={Result} />
        <Route path={`${match.url}/favorite`} component={Bookmarks} />
        <Route path={`${match.url}/profile`} component={UserProfile} />
        {/* <Route path={`${match.url}/settings`} component={Settings} /> */}
        <Route path={`${match.url}/course-lesson/:trainingId/:trainingType`} component={CourseLesson} />
        <Route path={`${match.url}/course-dhashboard/:trainingId/:trainingType`} component={CourseDhashboard} />
        <Route path={`${match.url}/course-intro/:trainingId/:trainingType`} component={CourseIntro} />
        <Route path={`${match.url}/play/:trainingId/:trainingType`} component={TrainingPlayer} />
        <Route path={`${match.url}/payment-success`} exact component={PaymentSuccess} />
        <Route path={`${match.url}/payment-cancelled`} exact component={PaymentCancelled} />
        <Route path={`${match.url}/leader-board/:campaignId`} exact component={LeaderBoard} />
        <Route path={`${match.url}/:trainingType`} component={Category} />
        <Route path={`${match.url}`} component={Category} />
      </Switch>
      {/* <Footer/> */}
    </div>
  );
};

export default Main;
