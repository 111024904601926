import React from 'react';
import moment from 'moment';
import Aux from 'util/Auxiliary';

const QuizItem = ({ data }) => {
	const formatTime = (date) => {
		return moment(date).format('DD MMM YYYY,  hh:mm A');
	};
	return (
		<Aux>
			<div key={data._id} className="gx-media gx-task-list-item gx-flex-nowrap">
				<div className="gx-media-body gx-task-item-content">
					<div className="gx-task-item-content-left">
						<h5 className="gx-text-truncate gx-task-item-title gx-text-hover">
							{data.quizId.title}
						</h5>
						<p key={data._id} className="gx-text-grey gx-fs-sm gx-mb-0">
							{formatTime(data.createdAt)}
						</p>
					</div>
					<div className="gx-task-item-content-right">
						<div className="gx-task-item-date">
							<p>Players : 20</p>
							<div style={{ marginLeft: '8px' }} />
							<p>Question : 8</p>
						</div>
					</div>
				</div>
			</div>
		</Aux>
	);
};

export default QuizItem;
