import {
  HIDE_MESSAGE,
  SHOW_MESSAGE, 
  GET_TRAINEE_TRAINING,
  GET_TRAINEE_TRAINING_SUCCESS,
  GET_TRAINEE_ALL_TRAINING_SUCCESS, 
  SHOW_MODAL,
  HIDE_MODAL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER, 
} from "constants/ActionTypes";
 
 
export const getTraineeTraining = (trainingId) => {  
  return {
    type: GET_TRAINEE_TRAINING,  
    payload:{trainingId}
  }
} 
export const getTraineeAllTrainingSuccess = (training) => {
  return {
    type: GET_TRAINEE_ALL_TRAINING_SUCCESS,
    payload: training
  }
}
 
export const getTraineeTrainingSuccess = (training) => {
  return {
    type: GET_TRAINEE_TRAINING_SUCCESS,
    payload: training
  }
}
 
 
export const showModal = (modal) => { 
  return {
    type: SHOW_MODAL,
    payload: modal
  };
};
export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
 
  
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};



 