import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Training from "./Training";
import Campaign from "./Campaign";
import Dashboard from "./Dashboard";
import Trainee from "./Trainee";
import DemoReducer from "./Demo";
import TrialReducer from "./Trial";

const reducers = combineReducers({
  trial: TrialReducer,
  demo: DemoReducer,
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  trainingInfo: Training,
  campaignInfo: Campaign,
  dashboardInfo: Dashboard,
  traineeInfo: Trainee
});

export default reducers;
