import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  GET_CAMPAIGNS_USERS_SUCCESS,
  GET_All_CAMPAIGN_SUCCESS,
  GET_CAMPAIGNS_ANALYTICS_SUCCESS,
  CLEAN_All_CAMPAIGNS,
  GET_All_CAMPAIGNS_INFO_SUCCESS,
  GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  CAMPAIGN_USER,
  SHOW_MODAL,
  HIDE_MODAL,
  CL
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  campaign: {},
  allCampaigns: [],
  pagination: {},
  userPagination: {},
  steps: 0,
  count: 0,
  userCampaignInfo: {},
  campaignsUsers: [],
  allCampaignsInfo: [],
  campaignsAnalytics: {},
  campaignsReports: [],
  noData: true,
  modalVisible: false,
  reportModalVisible: false,
  browserModalVisible: false,
  credentialsModalVisible: false,
  trainingModalVisible: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_ANALYTICS_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignsAnalytics: action.payload.campaignsAnalytics
      };
    }
    case GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignsReports: action.payload,
        noData: true
      };
    }
    //GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS
    case GET_CAMPAIGNS_USERS_SUCCESS: {
      console.log("====GET_CAMPAIGNS_USERS_SUCCESS", action.payload);
      return {
        ...state,
        loader: false,
        campaignsUsers: action.payload.campaignsUsers,
        userPagination: {
          page: Number(action.payload.page),
          total: action.payload.totalRecords
        }
      };
    }
    case GET_All_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loader: false,
        pagination: {
          current: Number(action.payload.page) || 1,
          pageSize: Number(action.payload.limit) || 10,
          total: action.payload.totalRecords,
          live: Number(action.payload.live)
        },
        campType: Number(action.payload.live),
        allCampaigns: action.payload.campaigns,
        campaign: {}
      };
    }

    case GET_All_CAMPAIGNS_INFO_SUCCESS: {
      return {
        ...state,
        loader: false,
        allCampaignsInfo: action.payload.campaigns,
        count: action.payload.count
      };
    }
    case CLEAN_All_CAMPAIGNS: {
      return {
        ...state,
        allCampaignsInfo: [],
        allCampaigns: [],
        loader: true
      };
    }
    case CAMPAIGN_USER: {
      return {
        ...state,
        userCampaignInfo: action.payload
      };
    }

    case SHOW_MODAL: {
      return {
        ...state,
        modalVisible: action.payload === "modal" ? true : false,
        reportModalVisible: action.payload === "reportModal" ? true : false,
        browserModalVisible: action.payload === "browserModal" ? true : false,
        credentialsModalVisible:
          action.payload === "credentialsModal" ? true : false,
        trainingModalVisible: action.payload === "trainingModal" ? true : false
      };
    }
    case HIDE_MODAL: {
      return {
        ...state,
        modalVisible: false,
        reportModalVisible: false,
        browserModalVisible: false,
        credentialsModalVisible: false,
        trainingModalVisible: false
      };
    }
    // case SHOW_MESSAGE: {
    //   return {
    //     ...state,
    //     alertMessage: action.payload,
    //     showMessage: true,
    //     loader: false
    //   }
    // }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.error ? action.payload.error : "",
        successMessage: action.payload.success ? action.payload.success : "",
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false
      };
    }
    case ON_SHOW_LOADER: {
      // console.log("ON_SHOW_LOADER")
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      // console.log("ON_HIDE_LOADER")
      return {
        ...state,
        loader: false
      };
    }
    default:
      return state;
  }
};
