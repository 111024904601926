import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  INIT_URL,
  GET_TRAINING_SUCCESS,
  GET_All_TRAINING_SUCCESS,
  ON_ADD_TRAINING_SUCCESS,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_ON_TRAINING_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MODAL,
  HIDE_MODAL,
  GET_TRAINEE_TRAINING_SUCCESS, 
  GET_TRAINEE_ALL_TRAINING_SUCCESS, 
  DELETE_TRAINING_SUCCESS, 
  ACTION_BOOKMARKS_TRAINING_SUCCESS, 
  GET_BOOKMARKS_TRAINING_SUCCESS,
  UPDATE_MY_CART_TRAINING
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  modalVisible: false,
  deleteModalVisible: false, 
  initURL: '', 
  training:{},
  allTraining:[],
  allTraineeTraining:[],
  traineeTraining:{},
  bookmarkInfo:{},
  bookmarksTraining:[],
  pagination:{},
  myCartTraining:[],
  steps: 0,
  status:false,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case UPDATE_MY_CART_TRAINING: { 
      console.log("action.payload",action.payload)  
      return {
        ...state,
        loader: false, 
        myCartTraining: action.payload
      }
    }  
    case ACTION_BOOKMARKS_TRAINING_SUCCESS: {   
      return {
        ...state,
        loader: false, 
        bookmarkInfo: action.payload
      }
    }  
    case GET_BOOKMARKS_TRAINING_SUCCESS: {  
      console.log("GET_BOOKMARKS_TRAINING_SUCCESS",action.payload)
      return {
        ...state,
        loader: false, 
        bookmarksTraining: action.payload.data,
        bookmarkInfo:{},
        pagination:action.payload.pagination
      }
    } 

    case GET_TRAINEE_ALL_TRAINING_SUCCESS: {   
      console.log("GET_TRAINEE_ALL_TRAINING_SUCCESS---", action.payload)
      return {
        ...state,
        loader: false, 
        allTraineeTraining: action.payload.data,
        pagination:action.payload.pagination,
        totalTraining: action.payload.totalTraining,
        totalUserCount: action.payload.totalUserCount,
        totalDiscussion: action.payload.totalDiscussion
      }
    }  
    case GET_TRAINEE_TRAINING_SUCCESS: {   
      return {
        ...state,
        loader: false, 
        traineeTraining: action.payload
      }
    }  
    case GET_TRAINING_SUCCESS: { 
      return {
        ...state,
        loader: false,
        training: {},
        training: action.payload
      }
    }
    case GET_All_TRAINING_SUCCESS: { 
      console.log("GET_All_TRAINING_SUCCESS---", action.payload)
      return {
        ...state,
        loader: false,
        allTraining: action.payload.data,
        training: {},
        pagination:action.payload.pagination
      }
    }
    case ON_ADD_TRAINING_SUCCESS: {
      console.log("ON_ADD_TRAINING_SUCCESS---", action.payload)
      return {
        ...state,
        loader: false,
        allTraining: action.payload.allTraining,
        steps: action.payload.steps,
        training: action.payload.training,
        pagination:action.payload.pagination
      }
    }
    case UPDATE_TRAINING_SUCCESS: { 
      return {
        ...state,
        loader: false,
        training: action.payload,
        steps: action.payload.steps,
      }
    }
    case UPDATE_ON_TRAINING_SUCCESS: { 
      return {
        ...state,
        loader: false,
        training: action.payload,
        steps: action.payload.steps,
      }
    }
    case DELETE_TRAINING_SUCCESS: {   
      return {
        ...state,
        loader: false,
        allTraining: [],
        allTraining: action.payload,
        training: {},
      }
    }

    case SHOW_MODAL: {  
      return {
        ...state, 
        modalVisible: (action.payload === "modal")?true:false, 
        deleteModalVisible: (action.payload === "deleteModal")?true:false, 
        showMessage: false,  
      }
    }
    case HIDE_MODAL : { 
      return {
        ...state,  
        modalVisible: false,
        deleteModalVisible: false, 
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    } 
    
    case SHOW_MESSAGE: { 
      return {
        ...state,
        alertMessage: (action.payload.error)? action.payload.error:'',
        successMessage: (action.payload.success)? action.payload.success:'',
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        successMessage:'',
        showMessage: false, 
      }
    } 
    
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        allTraining:[],
        allTraineeTraining:[],
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    // case CLEAR_USER_DATA: {
    //   return {
    //     ...state,
    //     userData: null,
    //     loader: false
    //   }
    // }
    default:
      return state;
  }
}
