import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import UserTable from "./components/userTable";
import ViewModal from "./components/modal";
import ModalBrowserInfo from "./components/modalBrowserInfo";
import ModalCredentials from "./components/modalCredentials";
import ModalTrainingDetails from "./components/modalTrainingDetails";
import { Col, Row, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  hideMessage,
  showMessage,
  getAllCampaign,
  getCampaignsUsers,
  downloadCertificate,
  campaignUser,
  showLoader,
  showModal,
  hideModal
} from "../../../appRedux/actions/Campaign";

const convertToObject = url => {
  const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
  let params = {};
  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i],
      value = arr[i + 1];
    params[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }
  return params;
};

class Campaigns extends PureComponent {
  state = {
    campaignType: "email"
  };

  componentDidMount() {
    const { match } = this.props;
    const id = match.params._id;
    console.log("match--------", id);
    this.props.showLoader();
    this.props.getCampaignsUsers(id);
  }
  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const id = match.params._id;
    console.log("match---", id);
    // this.props.getCampaignsUsers(id)
    // const prevObj = convertToObject(prevProps.location.search);
    // const obj = convertToObject(location.search);
    // if (obj.live !== prevObj.live) {
    //     this.props.showLoader();
    //     this.props.getAllCampaign(obj)
    //     this.setState({campType: (typeof obj.live !== 'undefined') ? Number(obj.live): this.state.campType})
    // }
  }

  render() {
    const {
      loader,
      alertMessage,
      showMessage,
      pagination,
      campaignsUsers,
      userCampaignInfo,
      modalVisible,
      browserModalVisible,
      credentialsModalVisible,
      trainingModalVisible
    } = this.props;

    var campaign =
      campaignsUsers.length > 1 ? campaignsUsers[0].campaignId : "";

    console.log("props", this.props);
    const campaignProps = {
      campaign: campaign,
      pagination: pagination,
      data: campaignsUsers,
      loader: loader,
      showModal: (viewModal, record) => {
        console.log({ record });
        this.props.campaignUser(record);
        this.props.showModal(viewModal);
      },
      hideModal: () => {
        this.props.hideModal();
      }
      // handleChange: (e) => {
      //     var newQuery = {};
      //     newQuery['live'] = e.target.value
      //     this.setState({campType: newQuery['live']})
      //     this.props.showLoader();
      //     this.props.getAllCampaign(newQuery)
      // },
      // handlePagination: (newQuery) => {
      //     this.props.showLoader();
      //     this.props.getAllCampaign(newQuery)
      // }
    };
    const viewModal = {
      info: {},
      modalVisible: modalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const browserModal = {
      loader: loader,
      campaign: campaign,
      info: userCampaignInfo,
      modalVisible: browserModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const credentialsModal = {
      loader: loader,
      campaign: campaign,
      info: userCampaignInfo,
      modalVisible: credentialsModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const trainingModal = {
      campaign: campaign,
      loader: loader,
      trainingDetails: userCampaignInfo,
      modalVisible: trainingModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      },
      downloadCertificate: (campaignId, traineeId, trainingName) => {
        this.props.showLoader();
        var paramsObj = {};
        paramsObj["traineeId"] = traineeId;
        var obj = {};
        obj["downloadUrl"] = `api/trainings/${campaignId}/download`;
        obj["name"] = trainingName;
        obj["paramsObj"] = paramsObj;
        obj["fileType"] = ".pdf"; //fileType
        this.props.downloadCertificate(obj);
      }
    };
    return (
      <Auxiliary>
        {showMessage
          ? alertMessage
            ? message.error(alertMessage.toString())
            : null
          : null}

        <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {/* <h1>Phishing Campaign Users</h1> */}
            <div className="gx-campaign-space" />
          </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <UserTable {...campaignProps} />
          </Col>
        </Row>
        {modalVisible && <ViewModal {...viewModal} />}
        {browserModalVisible && <ModalBrowserInfo {...browserModal} />}
        {credentialsModalVisible && <ModalCredentials {...credentialsModal} />}
        {trainingModalVisible && <ModalTrainingDetails {...trainingModal} />}
      </Auxiliary>
    );
  }
}

// export default Campaigns;

const mapStateToProps = ({ campaignInfo }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    userCampaignInfo,
    campaignsUsers,
    pagination,
    modalVisible,
    browserModalVisible,
    credentialsModalVisible,
    trainingModalVisible
  } = campaignInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    userCampaignInfo,
    campaignsUsers,
    pagination,
    modalVisible,
    browserModalVisible,
    credentialsModalVisible,
    trainingModalVisible
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideMessage,
    showMessage,
    getAllCampaign,
    getCampaignsUsers,
    downloadCertificate,
    campaignUser,
    showLoader,
    showModal,
    hideModal
  })(Campaigns)
);
