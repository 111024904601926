import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Table } from 'antd'
import CircularProgress from 'components/CircularProgress';
// import {DropOption} from '../../../components'

 
const { confirm } = Modal

const CredentialsModal = (campaignProps) => {
 
   
  const { loader, modalVisible, handleCancel , info} = campaignProps 
  return (

    <Modal
      onCancel={handleCancel}
      title="Credentials Details"
      visible={modalVisible}  
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Close</Button> 
      ]}
    >
      
      {loader ? (
            <div className="gx-loader-view" >
                <CircularProgress />
            </div>
        ) : null}
    {(info.credentials || []) 
    .filter(key => info.credentials[key])
    .map(key => {
                  return ( 
                      <div style={{ margin:'10px'}}>
                        <h4>{key}</h4>
                        <p>{info.credentials[key]}</p>
                      </div>
                  );
              })}
      {/* {Object.keys(info.credentials || {})
        .filter(key => info.credentials[key])
        .map(key => {
            return (
                <div className="pad-btm">
                    <strong className="text-main">{key}</strong>
                    <br/>
                    {info.credentials[key]}
                </div>
            );
        })} */}
    
    </Modal> 
  ) 
}

export default CredentialsModal
