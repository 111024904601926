import { Rate, Card, Icon, DatePicker } from "antd";
import React from "react";
// import {Bar, BarChart, ResponsiveContainer, Tooltip} from "recharts";
// import Widget from "components/Widget/index";

import { imagePrefixUrl } from "util/config";
import styles from "./index.less";
import store from "store";

const TrainingsCard = info => {
  const { data, index, editTraining, viewTraining, showModal } = info;

  const displayTime = d => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? m + ":" : "00:";
    var sDisplay = s > 0 ? s : "00";
    return hDisplay + mDisplay + sDisplay;
  };
  const displayPrice = price => {
    return parseFloat(price).toFixed(2);
  };
  return (
    <div
      className="uk-scrollspy-inview uk-animation-slide-bottom-small"
      style={{ visibility: "unset !important" }}
    >
      <div
        className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
        tabIndex={index}
        style={{ visibility: "unset",border:"2px solid #fefefe" }}
      >
        {data.price && data.price !== 0 ? (
          <div
            onClick={() => viewTraining(index, data._id)}
            className=" uk-position-top-left uk-padding-small uk-padding-remove-left uk-position-z-index"
            style={{ width: "100px", cursor: "pointer" }}
          >
            <img src={require("assets/images/training/price_tag_image.png")} />
            <strong
              style={{
                position: "absolute",
                left: "15px",
                bottom: "16px",
                fontSize: "18px",
                fontWeight: "500"
              }}
            >
              {data.price && data.price !== 0
                ? "$" + displayPrice(data.price)
                : " "}
            </strong>
          </div>
        ) : (
          ""
        )}
        <div className="uk-link-reset">
          <img
            onClick={() => viewTraining(index, data._id)}
            src={
              data.coverImage
                ? `${imagePrefixUrl}${data.coverImage}`
                : require("assets/images/courses/cover_image.jpg")
            }
            style={{
              width: "100%",
              height: "190px",
              objectFit: "cover",
              cursor: "pointer"
            }}
            className="course-img"
          />

          <div
            className="uk-clearfix"
            style={{ position: "absolute", right: "10px" }}
          >
            <div
              className="uk-float-right"
              style={{ position: "relative", bottom: "40px" }}
            >
              {data.trainingType !== 4 && (
                <span
                  className="Course-tags "
                  style={{
                    borderRadius: "27px",
                    background: "#fdfdfd",
                    padding: "5px 18px",
                    opacity: "0.8",
                    fontWeight: "600",
                    color: "rgb(255, 255, 255)",
                    border: "1px solid rgb(0, 0, 0)",
                    background: "rgb(0, 0, 0)",
                    boxShadow: "0 2px 15px rgba(0, 0, 0, 0.14)"
                  }}
                >
                  {data.duration ? displayTime(data.duration) : "00:00"}
                </span>
              )}
            </div>
          </div>

          <div className="uk-card-body">
            <h4
              onClick={() => viewTraining(index, data._id)}
              style={{ cursor: "pointer" }}
            >
              {data.name ? data.name : " "}
            </h4>
            <div className="uk-clearfix">
              <div
                className="uk-float-left"
                onClick={() => viewTraining(index, data._id)}
                style={{ cursor: "pointer" }}
              >
                <p style={{marginBottom: "12px"}}>{`${"By "}${
                  data.authorName ? data.authorName : ""
                }`}</p>
              </div>

              {(data.organisationId === store.get("user").organisationId || store.get("user").accessLevel===0)&& (
                <div className="uk-float-right ">
                  <div
                    style={{
                      position: "absolute",
                      right: "70px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      onClick={() => editTraining(data)}
                      src={require("assets/images/training/edit_icon.png")}
                    ></img>
                  </div>
                  <div style={{ position: "relative", cursor: "pointer" }}>
                    <img
                      onClick={() => showModal(data, index)}
                      src={require("assets/images/training/delete_icon.png")}
                    ></img>
                  </div>
                </div>
              )}
            </div>

            <p
              onClick={() => viewTraining(index, data._id)}
              style={{ cursor: "pointer",marginTop: "0px", textAlign:"justify" }}
            >
              {data.shortDescription ? data.shortDescription : ""}
            </p>

            <hr className="uk-margin-remove" />
            <div className="uk-clearfix " style={{ padding: "10px" }}>
              {data.hasAssessment && (
                <div className="uk-float-left uk-margin-right">
                  <span
                    className="Course-tags"
                    style={{
                      borderRadius: "4px",
                      background: "rgb(237, 239, 240)",
                      padding: "4px 10px"
                    }}
                  >
                    <img
                      src={require("assets/images/training/quiz_icon.png")}
                      style={{ width: "20px", padding: "0 5px 5px 0" }}
                    />
                    Quiz
                  </span>
                </div>
              )}
              <div className="uk-float-left uk-margin-right">
                <span
                  className="Course-tags uk-margin-small-right"
                  style={{
                    borderRadius: "4px",
                    background: "rgb(237, 239, 240)",
                    padding: "4px 10px"
                  }}
                >
                  <img
                    src={require("assets/images/training/bookmark_icon.png")}
                    style={{ width: "20px", padding: "0 8px 5px 0" }}
                  />
                  {data.totalBookmarks ? data.totalBookmarks : 0}
                </span>
              </div>
            </div>

            <hr className="uk-margin-remove-top" />
            <div className="uk-clearfix">
              <div className="uk-float-left">
                {data.trainingSubType && data.trainingSubType != "" && (
                  <a
                    className="Course-tags uk-margin-small-right"
                    style={{
                      borderRadius: "27px",
                      background: "#fdfdfd",
                      padding: "8px 18px",
                      color: "#464748",
                      border: "1px solid rgb(232, 229, 229)"
                    }}
                  >
                    {" "}
                    {data.trainingSubType}{" "}
                  </a>
                )}
                {/* {data.level &&
                            <a className="Course-tags" 
                                style={{
                                    borderRadius: '27px',
                                    background: '#fdfdfd',
                                    padding: '8px 18px',
                                    color: '#464748',
                                    border: '1px solid rgb(232, 229, 229)'
                                }} 
                            > { data.level} </a> 
                        } */}
              </div>

              <div className="uk-float-right">
                {data.trainingType && (
                  <span
                    className="Course-tags uk-text-white"
                    style={{
                      borderRadius: "27px",
                      background:
                        data.trainingType == "1" || data.trainingType == "3"
                          ? "rgba(224, 153, 66, 0.64)"
                          : "rgba(78, 72, 64, 0.64)",
                      padding: "8px 18px",
                      color: "white !important"
                      // border: '1px solid rgb(232, 229, 229)'
                    }}
                  >
                    {data.trainingType == "1" || data.trainingType == "3"
                      ? "Premium"
                      : "Basic"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingsCard;
