import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Table } from 'antd'
import CircularProgress from 'components/CircularProgress';
// import {DropOption} from '../../../components'

 
const { confirm } = Modal

const BrowserInfoModal = (campaignProps) => {
 
   
  const { loader, modalVisible, handleCancel , info} = campaignProps 
  return (

    <Modal
      onCancel={handleCancel}
      title="Browser Info"
      visible={modalVisible}  
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Close</Button> 
      ]}
    >

      {loader ? (
          <div className="gx-loader-view" >
              <CircularProgress />
          </div>
      ) : null}
      {(info.browserDetails || [])
                                .map(key => {
                                    return ( 
                                        <div style={{ margin:'10px'}}>
                                            <h4>{key ? key.split(":")[0].charAt(0).toUpperCase() + key.split(":")[0].slice(1)  + " : " + key.split(":")[1] :"" }</h4>
                                        </div>
                                    );
                                })}
    
    </Modal> 
  ) 
}

export default BrowserInfoModal
