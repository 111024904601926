import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget";
import CircularProgress from 'components/CircularProgress'
import { Row, Col, Card, List, Progress, Divider } from 'antd'
import Model from "./model";
var moment = require('moment')

class Result extends PureComponent {

    state = {
        data: {},
        loading: true
      };
    componentWillMount(){
        const { match } = this.props;
    console.log({ match });
    const trainingId = match.params.trainingId;
    Model.getTrainingDetails({ trainingId: trainingId }).then(response => {
        console.log({ response });
        this.setState({ data: response.data, loading: false,trainingId });
      });
    }
    formateDateTime(date) {
        return moment(date).format('DD MMM YYYY,  hh:mm A')
      }
      calculatePercent = (max, score) => {
        return ((score / max) * 100).toFixed(0)
      }

    render(){
        console.log("in result")
        var { loading, data } = this.state;
    if (loading) {
      return <CircularProgress></CircularProgress>;
    }
        return <Auxiliary>
          
           <Row >
          <Card
            bordered={true}
            style={{width:"100%"}}
            title={
              <div>
                <div style={{ fontSize: 22, fontWeight: 800 }}>
                  {data.campaignId.trainingId.name}
                </div>
                <div>Assigned On: {this.formateDateTime(data.assignedAt)}</div>
                <div>
                  Completed On:{' '}
                  {data.submittedAt
                    ? this.formateDateTime(data.submittedAt)
                    : 'Not completed yet'}
                </div>
              </div>
            }
            className=""
          >
              <Row>
              <Col
              span={12}
              style={{
                textAlign: 'center',
                borderRight: '1px solid lightgray',
              }}
            >
              <Progress
                style={{ fontSize: '20px' }}
                type="circle"
                status={data.attempted ? '' : 'exception'}
                percent={data.attempted ? 100 : 0}
                strokeWidth={17}
                width={150}
                strokeColor="#5bd775"
              />
              <div style={{ paddingTop: 20, fontSize: 24 }}>
                Training Completed
              </div>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <Progress
                style={{ fontSize: '20px' }}
                type="circle"
                status={data.successStatus == 'passed' ? '' : 'exception'}
                percent={this.calculatePercent(data.scoreMax, data.scoreRaw)}
                strokeWidth={17}
                width={150}
                strokeColor="#5f76e8"
              />
              <div style={{ paddingTop: 20, fontSize: 24 }}>Result</div>
            </Col>
              </Row>
            
          </Card>
          

         
            <Card bordered={false} style={{ minHeight: 150, fontSize: 40, width:"100%" }}>
                <Row 
                style={{    flex: 1,
                    alignContent: 'center',
                    textAlign: "center",
                    alignItems: "center",
                    padding: 42}}
                >
                <Col span={12}>
             <span style={{textAlign:"center"}}>
                 Score
             </span>
             </Col>
             <Col span={12}>
             <span style={{textAlign:"center"}}>
                 {data.scoreRaw}
             </span>
             </Col>
                </Row>
             
            
             
            </Card>
        
        </Row>
          
        </Auxiliary>



    }
}
export default Result