import {
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  INIT_URL,
  GET_CAMPAIGNS_USERS, // campaign CAMPAIGN
  GET_CAMPAIGNS_USERS_SUCCESS,
  CAMPAIGNS_DOWNLOAD_CERTIFICATE,
  GET_All_CAMPAIGN,
  GET_All_CAMPAIGN_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  CAMPAIGN_USER,
  CLEAN_All_CAMPAIGNS,
  GET_CAMPAIGNS_ANALYTICS,
  GET_CAMPAIGNS_ANALYTICS_SUCCESS,
  GET_All_CAMPAIGNS_INFO,
  GET_All_CAMPAIGNS_INFO_SUCCESS,
  GET_All_CAMPAIGNS_REPORTS_INFO,
  GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS,
  DOWNLOAD_XLSX_FILE,
  DOWNLOAD_PDF_FILE,
  SHOW_MODAL,
  HIDE_MODAL
} from "../../constants/ActionTypes";

export const downloadCertificate = info => {
  return {
    type: CAMPAIGNS_DOWNLOAD_CERTIFICATE,
    payload: info
  };
};
export const downloadXlsxFile = info => {
  return {
    type: DOWNLOAD_XLSX_FILE,
    payload: info
  };
};
export const downloadPdfFile = info => {
  return {
    type: DOWNLOAD_PDF_FILE,
    payload: info
  };
};

export const getCampaignsAnalytics = campaignId => {
  return {
    type: GET_CAMPAIGNS_ANALYTICS,
    payload: {
      campaignId: campaignId
    }
  };
};
export const getCampaignsAnalyticsSuccess = info => {
  return {
    type: GET_CAMPAIGNS_ANALYTICS_SUCCESS,
    payload: {
      campaignsAnalytics: info
    }
  };
};

export const getCampaignsUsers = (campaignId, type, page) => {
  return {
    type: GET_CAMPAIGNS_USERS,
    payload: {
      campaignId: campaignId,
      page: page,
      type: type
    }
  };
};
export const getCampaignsUsersSuccess = (
  campaignsUsers,
  totalRecords,
  page
) => {
  console.log({ campaignsUsers, totalRecords }, "getCampaignsUsersSuccess");
  return {
    type: GET_CAMPAIGNS_USERS_SUCCESS,
    payload: {
      campaignsUsers: campaignsUsers,
      totalRecords: totalRecords,
      page: page
    }
  };
};
export const getAllCampaign = obj => {
  return {
    type: GET_All_CAMPAIGN,
    payload: obj
  };
};
export const getAllCampaignSuccess = campaign => {
  return {
    type: GET_All_CAMPAIGN_SUCCESS,
    payload: campaign
  };
};

export const getAllCampaignsInfo = obj => {
  return {
    type: GET_All_CAMPAIGNS_INFO,
    payload: obj
  };
};
export const getAllCampaignsInfoSuccess = campaigns => {
  return {
    type: GET_All_CAMPAIGNS_INFO_SUCCESS,
    payload: campaigns
  };
};
export const getAllCampaignsReportsInfo = obj => {
  return {
    type: GET_All_CAMPAIGNS_REPORTS_INFO,
    payload: obj
  };
};
export const getAllCampaignsReportsInfoSuccess = campaignsReports => {
  return {
    type: GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS,
    payload: campaignsReports
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const campaignUser = user => {
  return {
    type: CAMPAIGN_USER,
    payload: user
  };
};

export const cleanAllCampaigns = () => {
  return {
    type: CLEAN_All_CAMPAIGNS
  };
};
export const showModal = modal => {
  return {
    type: SHOW_MODAL,
    payload: modal
  };
};
export const hideModal = () => {
  return {
    type: HIDE_MODAL
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
