import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { Col, Row, message, Card, Icon, List,Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Model from "./model";
import { Link } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
class TrainingPlayer extends PureComponent {
  state = {
    data: {},
    loading: true
  };
  componentWillMount() {
    const { match } = this.props;
    console.log({ match });
    const trainingId = match.params.trainingId;
    Model.getTrainingDetails({ trainingId: trainingId }).then(response => {
      console.log({ response });
      this.setState({ data: response.data, loading: false,trainingId });
    });
  }
  onSubmit = () => {
    const { match, history } = this.props
    var self = this

    const training_id = match.params.trainingId
    this.iframe.contentWindow.document.addEventListener(
      'submit',
      function(e) {
        console.log('onsubmit', e)
        if (e.detail) {
          const failed =
            e.detail.data && e.detail.data.successStatus == 'failed'
        

          failed
            ? message.info(
                'You have failed this assessment training and you have been re-assigned a training, Please check your email.',
                10,
                function() {
                    self.props.history.push('/trainings/result/' + training_id)
                }
              )
            : message.success(
                'You have completed your training successfully, Thank you.',
                10,
                function() {
                    self.props.history.push('/trainings/result/' + training_id)
                }
              )

              self.props.history.push('/trainings/result/' + training_id)
        } else {
        
          message.success(
            'You have completed your training successfully, Thank you.',
            10,
            function() {
                self.props.history.push('/trainings/result/' + training_id)
            }
          )
        }

        return false
      },
      false
    )
  }
  attachListener(iframe) {
    if (iframe) {
      this.iframe = iframe
      if (iframe.attachEvent) {
        iframe.attachEvent('onload', this.onSubmit.bind(this))
      } else {
        iframe.onload = this.onSubmit.bind(this)
      }
    }
  }

  render() {
    var { loading, data } = this.state;
    if (loading) {
      return <CircularProgress></CircularProgress>;
    }
    const student_name = "zulqer"
    this.trainingId = this.state.data.campaignId.trainingId._id? this.state.data.campaignId.trainingId._id:this.state.data.campaignId._id
    const authToken = localStorage.getItem('accessToken')
    var url =
      '/api/Scorm/trainings/' +
      this.trainingId +
      '?student_name=' +
      student_name +
      '&trainingId=' +
      data.campaignId._id +
      '&authToken=' +
      authToken

    return <Auxiliary>
     <div className="fullscreen">
          <Spin style={{ maxHeight: 1200 }} spinning={loading}>
            <div style={{ height: '100%', flex: 1 }}>
              <iframe
                onLoad={function() {
                //   dispatch({
                //     type: 'training/updateState',
                //     payload: { isLoading: false },
                //   })
                }}
                id="iframe"
                ref={component => this.attachListener(component)}
                style={{ width: '100%', height: '100vh' }}
                src={url}
              />
            </div>
          </Spin>
        </div>
    </Auxiliary>;
  }
}
export default TrainingPlayer;
