import React from 'react';
import moment from 'moment';
import Aux from 'util/Auxiliary';
import { campaignType } from 'util/Utils';
import { Tag } from 'antd';
import { string } from 'prop-types';

const getTag = (type) => {
	return campaignType.map((tag, index) => {
		if (type && type === tag.type) {
			return tag.title;
		}
		return '';
	});
};

const TrainingItem = ({ data }) => {
	console.log({data})
	
	const formatTime = (date) => {
		return moment(date).format('DD MMM YYYY,  hh:mm A');
	};
	return (
		<Aux>
			<div key={data._id} className="gx-media gx-task-list-item gx-flex-nowrap">
				<div className="gx-media-body gx-task-item-content">
					<div className="gx-task-item-content-left">
						<h5 className="gx-text-truncate gx-task-item-title gx-text-hover">
							{data.name}
						</h5>
						<p key={data._id} className="gx-text-grey gx-fs-sm gx-mb-0">
							{formatTime(data.createdAt)}
						</p>
					</div>
					<div className="gx-task-item-content-right">
						<p>{data.targets}</p>
						<Tag className="gx-bg-grey gx-text-grey gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2">
							{getTag(data.campaignType)}
						</Tag>
					</div>
				</div>
			</div>
		</Aux>
	);
};

export default TrainingItem;
