import React from "react";
import renderEmpty from "antd/lib/config-provider/renderEmpty";
import CircularProgress from "../../../../components/CircularProgress";
var convert = require("xml-js");

const Section = modules => {
  if (Array.isArray(modules)) {
    return (
      modules &&
      modules.length > 0 &&
      modules.map(section => {
        return (
          <li className="uk-open tm-course-lesson-section uk-background-default">
            <a className="uk-accordion-title uk-padding-small" href="#">
              {/* <h6> section  one</h6> */}
              <h4 className="uk-margin-remove">
                {" "}
                {section._attributes.Name}
              </h4>{" "}
            </a>
            <div className="uk-accordion-content uk-margin-remove-top">
              <div className="tm-course-section-list">
                <ul>{subSection(section.Page)}</ul>
              </div>
            </div>
          </li>
        );
      })
    );
  } else {
    return (
      <li className="uk-open tm-course-lesson-section uk-background-default">
        <a className="uk-accordion-title uk-padding-small" href="#">
          <h4 className="uk-margin-remove">{modules._attributes.Name} </h4>{" "}
        </a>
        <div className="uk-accordion-content uk-margin-remove-top">
          <div className="tm-course-section-list">
            <ul>
              {/* {this.subSection()}                                                 */}
            </ul>
          </div>
        </div>
      </li>
    );
  }
};

const subSection = page => {
  console.log(page);

  if (Array.isArray(page)) {
    return page.map(subSection => {
      return (
        <li>
          <a className="uk-link-reset">
            {/* <!-- Play icon  -->                                                          */}
            <span className="uk-icon-button icon-play">
              {" "}
              <i className="fas fa-video ic-icon-small"></i>{" "}
            </span>
            {/* <!-- Course title  -->                                                          */}
            <div className="uk-panel uk-panel-box uk-text-truncate uk-margin-medium-right">
              {subSection._attributes.Name}{" "}
            </div>
            {/* <!-- preview link --> */}
          </a>
          {/* <a className="uk-link-reset uk-margin-large-right uk-position-center-right uk-padding-small uk-text-small uk-visible@s" href="#preview-video-1" data-uk-toggle> <i className="fas fa-play ic-icon-small uk-text-grey"></i> Preveiw  </a> */}
          {/* <!-- time --> */}
          {/* <span className="uk-position-center-right time uk-margin-right"> <i className="fas fa-clock ic-icon-small"></i>  2 min</span> */}
        </li>
      );
    });
  } else {
    return (
      <li>
        <a className="uk-link-reset">
          {/* <!-- Play icon  -->                                                          */}
          <span className="uk-icon-button icon-play">
            {" "}
            <i className="fas fa-play ic-icon-small"></i>{" "}
          </span>
          {/* <!-- Course title  -->                                                          */}
          <div className="uk-panel uk-panel-box uk-text-truncate uk-margin-medium-right">
            {page._attributes.Name}{" "}
          </div>
          {/* <!-- preview link --> */}
        </a>
        {/* <a className="uk-link-reset uk-margin-large-right uk-position-center-right uk-padding-small uk-text-small uk-visible@s" href="#preview-video-1" data-uk-toggle> <i className="fas fa-play ic-icon-small uk-text-grey"></i> Preveiw  </a> */}
        {/* <!-- time --> */}
        {/* <span className="uk-position-center-right time uk-margin-right"> <i className="fas fa-clock ic-icon-small"></i>  2 min</span> */}
      </li>
    );
  }
};
function CourseVideos(infoProps) {
  const { _id, modules, loading } = infoProps;
  console.log({ infoProps });
  return (
    <div className="   animation: uk-animation-slide-right-medium">
      <ul data-uk-accordion="" className="uk-accordion">
        {loading ? <CircularProgress></CircularProgress> : Section(modules)}
      </ul>

      {/* <!-- Model  Preview videos--> */}
      <div id="preview-video-1" data-uk-modal>
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-outside"
            type="button"
            data-uk-close
          ></button>
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/nOCXXHGMezU"
              className="uk-padding-remove"
              data-uk-video="automute: true"
              // frameborder="0"
              allowfullscreen
              data-uk-responsive
            ></iframe>
          </div>
        </div>
      </div>
      {/* <!-- Model  Preview videos--> */}
      <div id="preview-video-2" data-uk-modal>
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-outside"
            type="button"
            uk-close
          ></button>
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/nOCXXHGMezU"
              className="uk-padding-remove"
              data-uk-video="automute: true"
              frameborder="0"
              allowfullscreen
              data-uk-responsive
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
// class CourseVideos extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       modules: [],
//       loading: true
//     };
//   }
//   componentWillMount() {
//     const { _id, modules, loading } = this.props;
//     console.log(this.props, "--------course video");
//     var self = this;
//     self.setState({ modules, loading });
//         var self = this
//     fetch("/serve/" + _id + "/xmls/en/course.xml")
//       .then(res => res.text())
//       .then(data => {
//         console.log("err---", data.error);
//         console.log({ data });
//         var result = convert.xml2json(data, { compact: true, spaces: 4 });
//         result = JSON.parse(result);
//         console.log(result.Error);

//         var modules = result.course ? result.course.Modules.Module : [];
//         console.log(modules);
//         self.setState({ modules, loading: false });
//       });
//   }
//   Section = modules => {
//     if (Array.isArray(this.state.modules)) {
//       return this.state.modules.map(section => {
//         return (
//           <li className="uk-open tm-course-lesson-section uk-background-default">
//             <a className="uk-accordion-title uk-padding-small" href="#">
//               {/* <h6> section  one</h6> */}
//               <h4 className="uk-margin-remove">
//                 {" "}
//                 {section._attributes.Name}
//               </h4>{" "}
//             </a>
//             <div className="uk-accordion-content uk-margin-remove-top">
//               <div className="tm-course-section-list">
//                 <ul>{this.subSection(section.Page)}</ul>
//               </div>
//             </div>
//           </li>
//         );
//       });
//     } else {
//       return (
//         <li className="uk-open tm-course-lesson-section uk-background-default">
//           <a className="uk-accordion-title uk-padding-small" href="#">
//             <h4 className="uk-margin-remove">
//               {this.state.modules._attributes.Name}{" "}
//             </h4>{" "}
//           </a>
//           <div className="uk-accordion-content uk-margin-remove-top">
//             <div className="tm-course-section-list">
//               <ul>
//                 {/* {this.subSection()}                                                 */}
//               </ul>
//             </div>
//           </div>
//         </li>
//       );
//     }
//   };

//   subSection = page => {
//     console.log(page);

//     if (Array.isArray(page)) {
//       return page.map(subSection => {
//         return (
//           <li>
//             <a className="uk-link-reset">
//               {/* <!-- Play icon  -->                                                          */}
//               <span className="uk-icon-button icon-play">
//                 {" "}
//                 <i className="fas fa-video ic-icon-small"></i>{" "}
//               </span>
//               {/* <!-- Course title  -->                                                          */}
//               <div className="uk-panel uk-panel-box uk-text-truncate uk-margin-medium-right">
//                 {subSection._attributes.Name}{" "}
//               </div>
//               {/* <!-- preview link --> */}
//             </a>
//             {/* <a className="uk-link-reset uk-margin-large-right uk-position-center-right uk-padding-small uk-text-small uk-visible@s" href="#preview-video-1" data-uk-toggle> <i className="fas fa-play ic-icon-small uk-text-grey"></i> Preveiw  </a> */}
//             {/* <!-- time --> */}
//             {/* <span className="uk-position-center-right time uk-margin-right"> <i className="fas fa-clock ic-icon-small"></i>  2 min</span> */}
//           </li>
//         );
//       });
//     } else {
//       return (
//         <li>
//           <a className="uk-link-reset">
//             {/* <!-- Play icon  -->                                                          */}
//             <span className="uk-icon-button icon-play">
//               {" "}
//               <i className="fas fa-play ic-icon-small"></i>{" "}
//             </span>
//             {/* <!-- Course title  -->                                                          */}
//             <div className="uk-panel uk-panel-box uk-text-truncate uk-margin-medium-right">
//               {page._attributes.Name}{" "}
//             </div>
//             {/* <!-- preview link --> */}
//           </a>
//           {/* <a className="uk-link-reset uk-margin-large-right uk-position-center-right uk-padding-small uk-text-small uk-visible@s" href="#preview-video-1" data-uk-toggle> <i className="fas fa-play ic-icon-small uk-text-grey"></i> Preveiw  </a> */}
//           {/* <!-- time --> */}
//           {/* <span className="uk-position-center-right time uk-margin-right"> <i className="fas fa-clock ic-icon-small"></i>  2 min</span> */}
//         </li>
//       );
//     }
//   };

//   render() {
//     return (
//       <div className="   animation: uk-animation-slide-right-medium">
//         <ul data-uk-accordion="" className="uk-accordion">
//           {this.state.loading ? (
//             <CircularProgress></CircularProgress>
//           ) : (
//             this.Section()
//           )}
//         </ul>

//         {/* <!-- Model  Preview videos--> */}
//         <div id="preview-video-1" data-uk-modal>
//           <div className="uk-modal-dialog uk-margin-auto-vertical">
//             <button
//               className="uk-modal-close-outside"
//               type="button"
//               data-uk-close
//             ></button>
//             <div className="video-responsive">
//               <iframe
//                 src="https://www.youtube.com/embed/nOCXXHGMezU"
//                 className="uk-padding-remove"
//                 data-uk-video="automute: true"
//                 frameborder="0"
//                 allowfullscreen
//                 data-uk-responsive
//               ></iframe>
//             </div>
//           </div>
//         </div>
//         {/* <!-- Model  Preview videos--> */}
//         <div id="preview-video-2" data-uk-modal>
//           <div className="uk-modal-dialog uk-margin-auto-vertical">
//             <button
//               className="uk-modal-close-outside"
//               type="button"
//               uk-close
//             ></button>
//             <div className="video-responsive">
//               <iframe
//                 src="https://www.youtube.com/embed/nOCXXHGMezU"
//                 className="uk-padding-remove"
//                 data-uk-video="automute: true"
//                 frameborder="0"
//                 allowfullscreen
//                 data-uk-responsive
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default CourseVideos;
