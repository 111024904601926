import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { message, Spin, Popover } from "antd";
import Model from "./model";
import RatingModel from "./../courseIntro/model";
import { Link } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
import ViewModalRatings from "./modalRatings";
import { func } from "prop-types";
import trainee from "../../trainings/trainee";
import ReactPlayer from 'react-player'
import CustomScrollbars from "util/CustomScrollbars";
import { scormKey } from "util/config";

class TrainingPlayer extends PureComponent {
  state = {
    data: {},
    loading: true,
    visible: false,
    viewModel: false,
    modalCancelled: false,
    givenRating: 0,
    reviewsText: "",
    url:"",
    firstLoad: true
  };
  componentWillMount() {
    const { match } = this.props;
    console.log({ match });
    const trainingId = match.params.trainingId;

    const { params } = match;
    if (params.trainingType === "Assigned") {
      Model.getTrainingDetails({ trainingId: trainingId }).then(response => {
        console.log({ response });
        this.setState({ data: response.data, loading: false, trainingId });
      });
    } else {
      Model.getTrainingDetails({
        trainingId: trainingId,
        trainingType: params.trainingType
      }).then(response => {
        console.log({ response });
        this.setState({ data: response.data, loading: false, trainingId });
      });
    }
  }
  onSubmit = () => {
    const { match, history } = this.props;
    var self = this;

    const training_id = match.params.trainingId;
    this.iframe.contentWindow.document.addEventListener(
      "submit",
      function(e) {
        console.log("onsubmit", e);
        if (e.detail) {
          const failed =
            e.detail.data && e.detail.data.successStatus == "failed";

          // failed
          //   ? message.info(
          //       "You have failed this assessment training and you have been re-assigned a training, Please check your email.",
          //       10,
          //       function() {
          //         // self.props.history.push('/trainee/result/' + training_id)
          //         self.ratingModel();
          //       }
          //     )
          //   : message.success(
          //       "You have completed your training successfully, Thank you.",
          //       10,
          //       function() {
          //         // self.props.history.push('/trainee/result/' + training_id)
          //         self.ratingModel();
          //       }
          //     );

          // self.props.history.push('/trainee/result/' + training_id)
          self.ratingModel();
        } else {
          // message.success(
          //   "You have completed your training successfully, Thank you.",
          //   10,
          //   function() {
          // self.props.history.push('/trainee/result/' + training_id)
          self.ratingModel();
          //   }
          // );
        }

        return false;
      },
      false
    );
  };
  ratingModel() {
    if(!this.state.modalCancelled && !this.state.reviewSubmitted){
      this.setState({ viewModel: true });
    }
    
  }
  attachListener(iframe) {
    if (iframe) {
      this.iframe = iframe;
      if (iframe.attachEvent) {
        iframe.attachEvent("onload", this.onSubmit.bind(this));
      } else {
        iframe.onload = this.onSubmit.bind(this);
      }
    }
  }

  addReviews = trainingId => {
    if (this.state.givenRating == 0 && this.state.reviewsText.trim().length == 0) {
      // message.error("Please enter comment.");

      // this.props.history.push("/trainee/result/" + trainingId);
      this.setState({ viewModel: false, reviewSubmitted: true });
      return false;
    }
    // if (this.state.givenRating == 0) {
    //   message.error("Please select rating.");
    //   return false;
    // }
    // if (this.state.reviewsText.trim().length == 0) {
    //   message.error("Please enter comment.");
    //   return false;
    // }
    RatingModel.saveReviews(
      this.state.reviewsText,
      trainingId,
      null,
      null,
      this.state.givenRating
    ).then(response => {
      if (!response.error) {
        // this.props.history.push("/trainee/result/" + trainingId);
      }

      this.setState({ viewModel: false });
    });
  };

  setReviews = e => {
    this.setState({
      reviewsText: e.target.value
    });
  };
onProgress=(progressData)=>{
console.log({progressData})
this.setState({progressData,playedSeconds:progressData.playedSeconds},function(){

})
}
onEnded = (onEnded)=>{
console.log({onEnded})
this.setState({completionStatus:'completed',successStatus:'passed'},function(){

})
}
onSeek = (onSeek) =>{
console.log({onSeek})
this.setState({playedSeconds:onSeek},function(){
  
})
}
thirdPartyPlayer =( )=>{
  var { loading, data } = this.state;
  const training = data.campaignId.trainingId
    var {type,videoUrl} = training
    if(type=="pc" ||type=="trainingVideo"){
      videoUrl = '/serve/'+videoUrl
    }
    console.log({videoUrl})
  return <div className="fullscreen" style={{ height: "100vh", flex: 1 }}>
  <ReactPlayer progressInterval={30000} url={videoUrl} playing={false} controls={true} controlsList="nodownload" width='100%'
    height='100%'
    onProgress={this.onProgress}
    onEnded = {this.onEnded}
    onSeek={this.onSeek}
    ></ReactPlayer>
</div>
}


  changeLanguage = (e,val,language) => {
    const student_name = "Aditya";
    this.setState({
      selectedLanguage:language.language,
      popover:false
    })
    this.trainingId = this.state.data.campaignId.trainingId._id
          ? this.state.data.campaignId.trainingId._id
          : this.state.data.campaignId._id;
    var { loading, data } = this.state;

    const authToken = localStorage.getItem("accessToken");

    this.setState({
            url :
              "/api/Scorm/trainings/" +
              this.trainingId +
              "?student_name=" +
              student_name +
              "&trainingId=" +
              data.campaignId._id +
              "&token=" +
              authToken + "&language=" +val
        })


  }

 languageMenu = () => (

    <CustomScrollbars className="gx-popover-lang-scroll" >
      <ul className="gx-sub-popover">
        {
          Object.keys(this.state.data.campaignId.trainingId.languages).map(language => {
          let currentlanguage = this.state.data.campaignId.trainingId.languages[language]
          return (
            <li className="gx-media gx-pointer" key={JSON.stringify(currentlanguage)} onClick={(e) => this.changeLanguage(e,language,currentlanguage)}>
              <i className={`flag flag-24 gx-mr-2 flag-${currentlanguage.icon == "hi" ? 'in' : currentlanguage.icon}`}/>
              <span className="gx-language-text">{scormKey[currentlanguage.language]}</span>
            </li>
            )
          })
        }
      </ul>
    </CustomScrollbars>
 )

 handleVisibleChange = popover => {
    this.setState({ popover });
  };

  render() {
    const { match, history,locale } = this.props;
    console.log(this.props,"this.props come")
    var { loading, data } = this.state;
    const training_id = match.params.trainingId;
    var styleNav = {
      background: "#282828",
      height: "55px",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.14)"
    };
    if (loading) {
      return <CircularProgress></CircularProgress>;
    } else if (!loading && !data) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            style={{ width: 300 }}
            src={require("assets/images/no_data_found.png")}
          ></img>
        </div>
      );
    }
    const student_name = "Aditya";
    const training = data.campaignId.trainingId
    const {type,videoUrl,isScorm} = training
    console.log({type,videoUrl})
    this.trainingId = this.state.data.campaignId.trainingId._id
      ? this.state.data.campaignId.trainingId._id
      : this.state.data.campaignId._id;
    const authToken = localStorage.getItem("accessToken");
    var url = "";
    if (process.env.NODE_ENV === "production") {
      // url+="https://staging.ohphish.com"
    }
    if(this.state.firstLoad){
        this.setState({
            url :
              "/api/Scorm/trainings/" +
              this.trainingId +
              "?student_name=" +
              student_name +
              "&trainingId=" +
              data.campaignId._id +
              "&token=" +
              authToken,
            firstLoad:false
        })
    }
    const ratingsModelProps = {
      trainingId: training_id,
      trainingType: training.trainingType,
      onSubmit: trainingId => {
        this.addReviews(trainingId);
      },
      giveRating: value => {
        this.setState({
          givenRating: value
        });
      },
      setReviews: e => {
        this.setState({
          reviewsText: e.target.value
        });
      },
      modalVisible: this.state.viewModel,
      handleCancel: () => {
        this.props.history.push("/trainee/result/" + training_id);
        this.setState({ viewModel: false, modalCancelled: true });
      }
    };


    return (
      <Auxiliary>
      <div>
        {this.state.data && this.state.data.campaignId && this.state.data.campaignId.trainingId && this.state.data.campaignId.trainingId.languages && Object.keys(this.state.data.campaignId.trainingId.languages).length > 1 && (
          <nav
            className="uk-navbar-transparent tm-mobile-header uk-animation-slide-top uk-position-z-index"
            data-uk-navbar
            style={styleNav}
          >
          <ul className="uk-navbar-nav uk-flex-middle">
            <li>
               <Popover visible={this.state.popover} onVisibleChange={this.handleVisibleChange} overlayClassName="gx-popover-horizantal" placement="bottomLeft" content={this.languageMenu()} 
                       trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  {/* <i className={`flag flag-24 flag-${this.state.selectedLanguage.icon}`}/>  */}
                  <span style={{color:"white"}} className="gx-pl-2 gx-language-name">{scormKey[this.state.selectedLanguage || this.state.data.campaignId.trainingId.languages[this.state.data.campaignId.trainingId.language].language]}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
              </Popover>
              </li>
          </ul>
            </nav>
          )}
        <div className="fullscreen">
          <Spin style={{ maxHeight: 1200 }} spinning={loading}>
           {(type=="youtube"||type=="pc"||type=="vimeo"  || type ==  "trainingVideo")?
           this.thirdPartyPlayer()
            : 
            <div style={{ height: "100vh", flex: 1 }}>
              <iframe
                title="training Player"
                onLoad={function() {
                  //   dispatch({
                  //     type: 'training/updateState',
                  //     payload: { isLoading: false },
                  //   })
                }}
                width="100%"
                height="100%"
                id="iframe"
                ref={component => this.attachListener(component)}
                style={{ width: "100%", height: "100vh" }}
                allowFullScreen
                src={this.state.url}
              />
            </div>
            }
          </Spin>

        </div>
        {/* {this.state.viewModel && <ViewModalRatings {...ratingsModelProps} />} */}
      </div>
      </Auxiliary>
    );
  }
}
export default TrainingPlayer;
