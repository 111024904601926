import React from "react";
import moment from "moment";
// import { campaignType } from 'util/Utils';
import { Table, Tag, Tooltip, Divider, Popover, Button } from "antd";
import DropOption from "./DropOption";
import Widget from "components/Widget";
import CircularProgress from "components/CircularProgress";

const formatDate = (date, format) => moment(date).format(format);

const UserTable = campaignProps => {
  const {
    loader,
    showModal,
    campaign,
    data,
    type,
    downloadCertificate,
    promptInput,
    pagination,
    handlePagination
  } = campaignProps;
  let reAssign = false;
  data.map(user => {
    if (user.successStatus && user.successStatus != "" && !reAssign)
      reAssign = true;

    return user;
  });
  console.log({ campaignProps });
  const renderStatus = status => {
    return status == "Delivered" ? (
      <Tag color="#4CAF50"> {status} </Tag>
    ) : status == "Bounced" ? (
      <Tag color="#038fde"> {status} </Tag>
    ) : (
      <Tag color="#919191"> {status} </Tag>
    );
  };

  var columns = [
    {
      title: "Employee ID",
      key: "id",
      render: (value, row, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: "Employee Name",
      key: "name",
      dataIndex: "name",
      render: name => <span>{name}</span>
    },
    {
      title: "Training Name",
      key: "trainingName",
      dataIndex: "campaign.name",
      render: name => <span>{campaign.name}</span>
    },
    {
      title: "Attempted",
      key: "started",
      dataIndex: "started",
      render: text => <span>{text ? "Yes" : "No"}</span>
    },
    {
      title: "Completion Status",
      key: "completionStatus",
      dataIndex: "completionStatus",
      render: status => <span>{status ? status : "N/A"}</span>
    },
    {
      title: "Score",
      key: "scoreRaw",
      dataIndex: "scoreRaw",
      render: scoreRaw => <span>{scoreRaw}</span>
    },
    {
      title: "Result",
      key: "successStatus",
      dataIndex: "successStatus",
      render: successStatus => (
        <span>{successStatus ? successStatus : "N/A"}</span>
      )
    },
    {
      title: "Certificate",
      key: "trainee",
      dataIndex: "trainee",
      render: (trainee, record) => {
        return record.submitted ? (
          <a
            onClick={() =>
              downloadCertificate(campaign._id, trainee._id, trainee.userName)
            }
          >
            Download
          </a>
        ) : (
          "N/A"
        );
      }
    }
    // http://localhost:8090/api/trainings/5ce3bf47288f6079eaa0f933/download?traineeId=5ce3eeca7419cc1ab81478cb
    // https://staging.ohphish.com/api/trainings/5ce3bf47288f6079eaa0f933/download?traineeId=5ce3eeca7419cc1ab81478cb
    // {
    //     title: 'Status',
    //     key: 'status',
    //     dataIndex: 'status',
    //     render: (text) => <span>{renderStatus(text)}</span>,
    // }
  ];

  console.log("campaign.campaignType---", campaign.campaignType);
  console.log("columns---", columns);

  return (
    <Widget
      styleName="gx-campaign-card"
      title={
        <div>
          <div className="ant-row-flex gx-justify-content-between">
            <h3 className="h4">User Details</h3>
            <span className="gx-ml-2 gx-search-icon">
              {/* <i className="icon icon-search-new gx-text-primary gx-fs-xxl gx-pointer" /> */}
              <div
                id="page-buttons"
                className="btn-group pull-right"
                style={{ padding: "0px 20px;" }}
              >
                <Button
                  type="info"
                  style={{ marginRight: "10px;",marginBottom:"0px" }}
                  onClick={() => promptInput(campaign._id, "reminder")}
                >
                  Reminder
                </Button>
                {reAssign ? (
                  <Button
                    type="info"
                    onClick={() => promptInput(campaign._id, "re-assign")}
                  >
                    Re-Assign
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </span>
          </div>
          {/* <Divider /> */}
        </div>
      }
    >
      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
      <Table
        className="gx-table-responsive"
        pagination={{
          ...pagination,
          showTotal: total => `Total ${total} `,
          onChange: page => {
            handlePagination({
              page: page
            });
          }
        }}
        columns={columns}
        dataSource={data}
        bordered={false}
        // scroll={false}
      />
    </Widget>
  );
};

export default UserTable;
