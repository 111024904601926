import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import TraineeMainApp from "./TraineeMainApp";
import MainLanding from "../MainLanding/index";
import SignIn from "../SignIn/index";
import ForgotPassword from "../ForgotPassword/index";
import ResetPassword from "../ResetPassword/index";
import SignUp from "../SignUp";
import RedirectFile from "../redirect";
import shareInfo from "../shareInfo";
import CheckTokenModel from "./../model.js";
import store from "store";
import { setInitUrl, onPing, onTraineePing, userSignOut, userSignInSuccess, userSignOutAuth, findUserSuccess, onSetSessionAuth, userSignOutSuccess } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";
// import { withTranslation } from "react-i18next";

import TraineeDetail from "./../../routes/traineeMain/traineeDetail";
// import aesWrapper from '../../util/aes-wrapper'

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import ShareInfo from "../shareInfo";
import "./index.css"
import PrivacyStatement from '../PrivacyStatement/index';
import TermsAndUse from '../TermsAndUse/index';

require("../../util/converterWrapper");

const RestrictedRoute = ({
  component: Component,
  trainee,
  authUser,
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          !trainee ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: "/trainee",
                  state: { from: props.location }
                }}
              />
            )
        ) : (
            <Redirect
              to={{
                // pathname: "/home",
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );

const RestrictedTraineeRoute = ({
  component: Component,
  trainee,
  authUser,
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        authUser && trainee ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                // pathname: "/home",
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );

class App extends Component {
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  componentWillMount() {
    console.log("will mount",this.props.location)
    var { location } = this.props;
    if (
      location.pathname.indexOf("/auth") != -1
    ) {
        // store.clearAll();
        // localStorage.clear();
        this.props.userSignOutSuccess();
        var auth = location.pathname.split("/auth/")[1];
        localStorage.setItem("accessToken", auth);
    }
    
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }

    if(params.get("access") == "view"){
      this.setState({access: "view"})
    }

  }
  componentDidMount() {
    var { location } = this.props;
    this.setState({ checkToken: true })
    var { authUser, accessToken, onPing, onTraineePing, location, sessionAuth } = this.props;
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    if (
      location.pathname.indexOf("/auth") != -1
    ) {
      var auth = location.pathname.split("/auth/")[1];
      if (false && (authUser !== null || accessToken !== null)) {
        // this.props.userSignOutAuth();
        localStorage.setItem("accessToken", auth);
        this.props.onSetSessionAuth(auth);
        CheckTokenModel.checkToken(auth).then((response) => {
          console.log({ response }, "response-002");
          // this.props.userSignOutAuth(); 
          if (!response.error) {
            var user = response.data.user;
            //http://localhost:3000/auth/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFtaXRjaGF1aGFuNmRlY0BnbWFpbC5jb20iLCJpYXQiOjE1OTk0NzE0ODEsImV4cCI6MTU5OTQ3ODY4MX0.0K2kNcBnYJe2646mQQMnUnm4OtnuMIde6UVcbDz3nnk 
            user["accessToken"] = auth;
            console.log((!user.passwordSet && response.data.trainee), "isTrainee", user)
            if ((!user.passwordSet && response.data.trainee && user.organisationId.authMethod === "default") || (!user.passwordReset && !response.data.trainee && user.organisation.authMethod === "default")) {
              console.log({ response }, "response-00201");
              console.log({ user })
              var event = new CustomEvent("setPassword", {
                detail: { user: user }
              });
              // Dispatch/Trigger/Fire the event
              document.dispatchEvent(event);
              this.props.history.push("/signin");
            } 
            else if ((response.data.trainee && user.organisationId.authMethod !== "default")) {
              // If organisation has auth type google then user must have to login at time from coming by direct login.
              // this.props.userSignOutAuth()
              if(this.state.access == "view"){
                console.log({ user }, "------@--", this.state.access)
                localStorage.setItem("accessToken", user.accessToken);
                localStorage.setItem("user", JSON.stringify(user));
                this.props.userSignInSuccess(user);
               }else{
                console.log({ response }, "response-00202");
                var userAuth = {};
                userAuth = {};
                localStorage.clear();
                localStorage.setItem("accessToken", auth);
                localStorage.setItem("isTrainee", false);
                userAuth["isTrainee"] = false;
                userAuth["authMethod"] = user.organisationId.authMethod;
                userAuth["email"] = user.email;
                // this.props.findUserSuccess(userAuth) 
                localStorage.setItem("loginAuthMethod", JSON.stringify(userAuth));
                console.log({ response, userAuth }, "response-00202");
                console.log({ user })
                var event = new CustomEvent("loginAuthMethod", {
                  detail: { userAuth: userAuth, user: user }
                });
                // Dispatch/Trigger/Fire the event
                document.dispatchEvent(event);
                this.props.history.push("/signin?id=" + sessionAuth);
               }
              
            } 
            else {
              console.log({ response }, "response-00203");
              console.log({ user }, "------@--")
              localStorage.setItem("accessToken", user.accessToken);
              localStorage.setItem("user", JSON.stringify(user));
              this.props.userSignInSuccess(user);
            }
          } else {
            console.log("-response--clear localStorage---@--")
            localStorage.clear();
          }
        });
      } else if (auth) {
        CheckTokenModel.checkToken(auth).then((response) => {
          console.log({ response }, "response-003");
          // localStorage.clear();
          // this.props.userSignOutAuth(); 
          if (!response.error) {
            var user = response.data.user;
            //http://localhost:3000/auth/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFtaXRjaGF1aGFuNmRlY0BnbWFpbC5jb20iLCJpYXQiOjE1OTk0NzE0ODEsImV4cCI6MTU5OTQ3ODY4MX0.0K2kNcBnYJe2646mQQMnUnm4OtnuMIde6UVcbDz3nnk 
            user["accessToken"] = auth;
            console.log((!user.passwordSet && response.data.trainee), "isTrainee", user)

            if(user.organisationId._id == "60aa89e4fefc200f2bb99b66"){
              console.log("60aa89e4fefc200f2bb99b66")
              localStorage.setItem("accessToken", user.accessToken);
              localStorage.setItem("user", JSON.stringify(user));
              this.props.userSignInSuccess(user);
            }

            if ((!user.passwordSet && response.data.trainee && user.organisationId.authMethod === "default") || (!user.passwordReset && !response.data.trainee && user.organisation.authMethod === "default")) {
              console.log({ user })
              var event = new CustomEvent("setPassword", {
                detail: { user: user }
              });
              // Dispatch/Trigger/Fire the event
              document.dispatchEvent(event);
              this.props.history.push("/signin");
            }
             else if ((response.data.trainee && user.organisationId.authMethod !== "default")) {
               console.log("props ", this.props);
               if(this.state.access == "view"){
                console.log({ user }, "------@--", this.state.access)
                localStorage.setItem("accessToken", user.accessToken);
                localStorage.setItem("user", JSON.stringify(user));
                this.props.userSignInSuccess(user);
               }else{
                // If organisation has auth type google then user must have to login at time from coming by direct login.
                var userAuth = {};
                userAuth = {};
                console.log("--response-clear localStorage---@--121")
                localStorage.clear();
                localStorage.setItem("isTrainee", false);
                userAuth["isTrainee"] = false;
                userAuth["authMethod"] = user.organisationId.authMethod;
                userAuth["email"] = user.email;
                userAuth["tenantId"] = response.data.tenantId;
                // if(data.tenantId)
                //   userAuth["tenantId"] = data.tenantId;
                // this.props.findUserSuccess(userAuth)
                //eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDA3NjI3ODMsImV4cCI6MTYwMDc2OTk4M30.JLEn1nErJABP-8-cJDDuMhRLtMFrNmMcxzCXRU_zHL0
                console.log({ user })
                var event = new CustomEvent("loginAuthMethod", {
                  detail: { userAuth: userAuth, user: user }
                });
                // Dispatch/Trigger/Fire the event
                document.dispatchEvent(event);
                this.props.history.push("/signin");
               }
              
            }
             else {
              console.log({ user }, "------@--")
              localStorage.setItem("accessToken", user.accessToken);
              localStorage.setItem("user", JSON.stringify(user));
              this.props.userSignInSuccess(user);
            }
          } else {
            console.log("-response--clear localStorage---@-12-")
            localStorage.clear();
          }
        });
      }
    }
  }
  componentDidUpdate() {
    var { authUser, accessToken, onPing, onTraineePing, location, sessionAuth } = this.props;
    console.log({ sessionAuth }, "response-sessionAuth-001", location.pathname, authUser, accessToken)
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    if (
      location.pathname.indexOf("/auth") != -1 || location.pathname.indexOf("/signin") != -1
    ) {

      console.log({ location }, this.props, "this.props--")
      var auth = location.pathname.split("/auth/")[1]
      if (location.pathname.indexOf("/signin") != -1) {
        auth = sessionAuth;
      }
      console.log({ location }, this.props, "this.props--@@---", { auth })
      // this.props.onSetSessionAuth(auth);
      if ((auth || sessionAuth) && this.state.checkToken) {
        if (authUser !== null || accessToken !== null) {
          this.props.userSignOutAuth(auth);
        } else {
          this.setState({ checkToken: false }, function () {
            CheckTokenModel.checkToken(auth).then((response) => {
              console.log({ response }, "response-001");
              // this.props.userSignOutAuth(); 
              if (!response.error) {
                var user = response.data.user;
                //http://localhost:3000/auth/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFtaXRjaGF1aGFuNmRlY0BnbWFpbC5jb20iLCJpYXQiOjE1OTk0NzE0ODEsImV4cCI6MTU5OTQ3ODY4MX0.0K2kNcBnYJe2646mQQMnUnm4OtnuMIde6UVcbDz3nnk 
                user["accessToken"] = auth;
                console.log((!user.passwordSet && response.data.trainee), "isTrainee", user)
                if ((!user.passwordSet && response.data.trainee && user.organisationId.authMethod === "default") || (!user.passwordReset && !response.data.trainee && user.organisation.authMethod === "default")) {
                  console.log({ user })
                  var event = new CustomEvent("setPassword", {
                    detail: { user: user }
                  });
                  // Dispatch/Trigger/Fire the event
                  document.dispatchEvent(event);
                  this.props.history.push("/signin");
                }
                 else if ((response.data.trainee && user.organisationId.authMethod !== "default")) {
              // If organisation has auth type google then user must have to login at time from coming by direct login.
                  var userAuth = {};
                  userAuth = {};
                  console.log("--response-clear localStorage---@-211-")
                  // localStorage.clear();
                  localStorage.setItem("isTrainee", false);
                  userAuth["isTrainee"] = false;
                  userAuth["authMethod"] = user.organisationId.authMethod;
                  userAuth["email"] = user.email;
                  // this.props.findUserSuccess(userAuth)
                  console.log({ user })
                  var event = new CustomEvent("loginAuthMethod", {
                    detail: { userAuth: userAuth, user: user }
                  });
                  this.props.findUserSuccess(userAuth);
                  localStorage.setItem("loginAuthMethod", userAuth);
                  // Dispatch/Trigger/Fire the event
                  document.dispatchEvent(event);
                  // this.props.history.push("/signin");
                }
                 else {
                  console.log({ user }, "------@--")
                  localStorage.setItem("accessToken", user.accessToken);
                  localStorage.setItem("user", JSON.stringify(user));
                  this.props.userSignInSuccess(user);
                }
              } else {
                console.log("--response-clear localStorage---@-212-")
                localStorage.clear();
              }
            });
          })
        }
        // return <Redirect to={"/auth?_id="+auth} />;
      }
    } else if (authUser !== null || accessToken !== null) {
      // console.log("authUser", authUser)
      if (
        location.pathname.indexOf("/forgot/password/reset") != -1
      ) {
        this.props.userSignOut()
      }
      if (
        authUser &&
        typeof authUser != "object" &&
        authUser.constructor != Object
      ) {
        authUser = JSON.parse(authUser);
      }
      if (authUser.trainee) {
        onTraineePing();
      } else {
        onPing();
      }
    }
  }

  render() {
    var {
      match,
      location,
      layoutType,
      navStyle,
      locale,
      authUser,
      initURL,
      accessToken
    } = this.props;

    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    // /trainee/course-intro/
    // if (location.pathname.indexOf("/trainee/course-intro/") != -1) {
    //   if (authUser !== null || accessToken !== null) {
    //     if (!user) {
    //       return <Redirect to={"/trainee/course-intro"} />;
    //     } else if (user.trainee) {
    //       return <Redirect to={"/trainee"} />;
    //     } else {
    //       return <Redirect to={"/main/dashboard/crm"} />;
    //     }
    //   }
    // } else
    // if ( 
    //   location.pathname.indexOf("/auth") != -1
    // ) {
    //   console.log({location},this.props,"this.props--")
    //   var auth = location.pathname.split("/auth/")[1]
    //   console.log({location},this.props,"this.props--",{auth})
    //   if(auth){
    //     this.props.userSignOut()
    //     // localStorage.clear();
    //     // authUser = null;
    //     // accessToken = null;
    //     return <Redirect to={"/auth/redirect?_id="+auth} />;
    //   } else if (authUser !== null || accessToken !== null) {
    //     if (user&&user.trainee) {
    //       return <Redirect to={"/trainee"} />;
    //     } else {
    //       return <Redirect to={"/main/dashboard/crm"} />;
    //     }
    //   }
    // }else 
    if (
      location.pathname.indexOf("/redirect") != -1 ||
      location.pathname.indexOf("/auth") != -1
    ) {

      if (authUser !== null || accessToken !== null) {
        if (user && user.trainee) {
          return <Redirect to={"/trainee"} />;
        } else {
          return <Redirect to={"/main/dashboard/crm"} />;
        }
      }
    } else if (location.pathname === "/") {
      if (authUser === null || accessToken === null) {
        // return <Redirect to={"/home"} />;
        return <Redirect to={"/signin"} />;
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/home" ||
        initURL === "/signin" ||
        initURL === "/forgot" ||
        initURL === "/reset" ||
        initURL === "/forgot/password/reset/:reset_code"
      ) {
        if (authUser.trainee || user.trainee) {
          console.log("user.trainee", user.trainee);
          return <Redirect to={"/trainee"} />;
        } else {
          return <Redirect to={"/main/dashboard/crm"} />;
        }
      } else {
        console.log({ initURL })
        return <Redirect to={initURL} />;
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    console.log({ AppLocale },"currentAppLocale top",locale.locale)

    const currentAppLocale = AppLocale[locale.locale];
    console.log({ currentAppLocale },"currentAppLocale");
    console.log(this.props.location)
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/home" component={MainLanding} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/privacy-statement" component={PrivacyStatement} />
            <Route exact path="/terms-of-use" component={TermsAndUse} />
            <Route exact path="/terms-use" component={TermsAndUse} />

            <Route
              exact
              path="/trainee/course-intro/:trainingId"
              component={ShareInfo}
            />
            <Route exact path="/redirect/:_id" component={RedirectFile} />
            {/* <Route exact path="/auth/:_id" component={RedirectFile} />
            <Route exact path="/auth" component={RedirectFile} /> */}
            <Route
              exact
              path="/forgot/password/reset/:reset_code"
              component={ResetPassword}
            />
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/signup" component={SignUp} />
            {/* <Route exact path="/me/:id/:name/:badge?" component={TraineeDetail} /> */}

            {/* <Route path={`${match.url}trainee`} component={TraineeMain} />  */}
            {/* <RestrictedTraineeRoute path={`${match.url}`} authUser={authUser}
                              accessToken={accessToken}
                             component={TraineeMainApp}/>  */}
            {/* <RestrictedRoute path={`${match.url}`} authUser={authUser}
                                accessToken={accessToken}
                              component={MainApp}/>   */}
            {/* <RestrictedTraineeRoute 
                path={`${match.url}`} 
                authUser={authUser} 
                trainee = {user.trainee}
                accessToken={accessToken}
                component={MainApp}
              /> */}
            {authUser && user && !(authUser.trainee || user.trainee) ? (
              <RestrictedRoute
                path={`${match.url}`}
                authUser={authUser}
                trainee={user.trainee}
                accessToken={accessToken}
                component={MainApp}
              />
            ) : (
                <div>
                  {authUser && (authUser.trainee || user.trainee) ? (
                    <RestrictedTraineeRoute
                      path={`${match.url}`}
                      trainee={user.trainee}
                      authUser={authUser}
                      accessToken={accessToken}
                      component={TraineeMainApp}
                    />
                  ) : (
                      // <Redirect to={"/home"} />
                      <Redirect to={"/signin"} />
                    )}
                </div>
              )}
            {/* if(authUser.trainee){
              return ( <Redirect to={'/trainee'}/> );
            }else{
              return ( <Redirect to={'/main/dashboard/crm'}/> );
            } */}
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL, accessToken, sessionAuth } = auth;
  return { locale, navStyle, layoutType, authUser, initURL, accessToken, sessionAuth };
};
export default connect(mapStateToProps, {
  setInitUrl,
  onPing,
  onTraineePing,
  setThemeType,
  onNavStyleChange,
  onSetSessionAuth,
  onLayoutTypeChange, userSignOut, userSignInSuccess, userSignOutAuth, findUserSuccess, userSignOutSuccess
})(App);
