import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import IntlMessages from "util/IntlMessages";
import "./index.less";

const { confirm } = Modal;

const logoutModal = modalDeleteProps => {
  const { logoutModalVisible, handleCancel, handleConfirm } = modalDeleteProps;
  return (
    <div id="logoutModal">
      <Modal
        title={<IntlMessages id="logoutmodal.title"/>}
        visible={logoutModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            <IntlMessages
                id="logoutmodal.cancel"/>
          </Button>,
          <Button
            key="submit"
            type="info"
            //   loading={loading}
            onClick={handleConfirm}
          >
            <IntlMessages
                id="logoutmodal.confirm"/>
          </Button>
        ]}
        headerStyle={{ top: "20%", color: "red" }}
      >
        <p><IntlMessages
                id="logoutmodal.message"/></p>
      </Modal>
    </div>
  );
};

export default logoutModal;
