// import { Rate, Card} from "antd";
import React from "react";
 
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts'; //react-fusioncharts

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme); 

const BarChartReports = ( reportInfo) => { 
 
    const { Report, campaign, analytics } = reportInfo;
  console.log( 'reportInfo', reportInfo)
  var targets = 5;
  targets = analytics? analytics.slice(0, 1).map((info,index)=>   { return targets = info.targets; }) :null
  // console.log( 'targets', targets[0])
 
  const dataSource= {
    "chart": {
      "caption": " ", 
      "sYAxisName": "Compliance %",
      "numberPrefix": " ",
      "sNumberSuffix": "%", 
      "divlineColor": "#999999",
      "divLineDashed": "1",
      "yAxisMaxValue": targets ,
      // "xAxisMinValue": "100%",
      "sYAxisMaxValue": "100",
      "yAxisValueDecimals": "0",
      "sYAxisValueDecimals": "0",
      //Cosmetics
      "divlineAlpha": "100", 
      "divlineThickness": "1",
      "divLineIsDashed": "1",
      "divLineDashLen": "1",
      "divLineGapLen": "1",
      "usePlotGradientColor": "0",
      "anchorRadius": "3",
      "theme": "fusion",
      "palettecolors": "3498DB, 27AE60, f2930a, E74C3C, 689F38"
    },
    "categories": [{
      "category": Report.data.map(report => (  {"label":report._id})) 
    }],
    "dataset": [
      {
        "seriesname": Report.type === "training" ? "Attempted not Finished" : campaign.campaignType === 'sms' ? "Delivered" : "Not Opened",
        
        "data":
              Report.type === "training"
              ? Report.data.map(report => ({'value':(report.attemptedNotSubmitted || 0)}))
              : Report.data.map(report => ( {'value':(campaign.campaignType === 'sms') ? report.delivered :  (report.notOpened || 0)}))
      },
      {
        "seriesname": Report.type === "training" ? "Passed" : campaign.campaignType === 'sms' ? "Delivered not clicked" : "Opened not Clicked",
        "data": 
              Report.type === "training"
                ? Report.data.map(report => ( {'value': (report.passed || 0)}))
                : Report.data.map(report => ( {'value': (campaign.campaignType === 'sms') ? report.deliveredNotClicked : (report.openedNotClicked || 0)}))
      },
      {
        "seriesname": Report.type === "training" ? "Failed" : "Clicked",
        "data":
              Report.type === "training"
                ? Report.data.map(report => ( {'value':(report.failed || 0)}))
                : Report.data.map(report => ( {'value':(report.defaulters || 0)}))
      },
      {
        "seriesname": Report.type === "training" ? "Not Attempted" : "Repeat Defaulters",
        "data":
              Report.type === "training"
                ? Report.data.map(report => ( {'value':(report.notAttempted || 0)}))
                : Report.data.map(report => ( {'value':(report.repeaters || 0)})) 
      },
      {
        "seriesname": "Compliance %",
        "renderAs": "line",
        // "renderAs": "splinearea",
        "parentYAxis": "S",
        "showValues": "0",
        "data": Report.data.map(report => ( {"value": (report.compliance === "NaN") ?  100 : (report.compliance || 0).toFixed(2)}))
      }
    ]
  };
    
  const chartConfigs = {
    type: 'stackedcolumn2dline',
    renderAt: 'chart-container',
    width: '100%',
    height: 400,
    dataFormat: 'json', 
    dataSource: dataSource
};
  return (
    <div> 
      <ReactFC {...chartConfigs} /> 
    </div>
  );
};

export default BarChartReports;

 