import React from "react";
import { message, Rate, Button } from "antd";
import { Link, Redirect } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
import { imagePrefixUrl, apiUrl, stripeKey, imageMediaUrl } from "util/config";
import ModelTraining from "../../routes/traineeMain/model";
import { Helmet } from "react-helmet";
class ShareInfo extends React.Component {
  state = {
    reviews: [],
    id: "",
    trainingType: "",
    ratingCount: 5,
    givenRating: 0,
    reviewsText: "",
    trainingId: "",
    trainingInfo: {},
    loader: false,
    noDataFound: false,
    showReviewButton: true
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    const { match, location } = this.props;
    const { params } = match;
    if (params.trainingId) {
      var self = this;
      self.setState({
        loader: true,
        noDataFound: false,
        trainingInfo: {},
        reviews: []
      });
      ModelTraining.fetchTrainingV1Info(params.trainingId).then(response => {
        if (!response.error && response.data) {
          self.setState({
            trainingInfo: response.data,
            loader: false
          });
          // this.callReviewsApi(params.trainingId);
        } else {
          self.setState({
            noDataFound: true,
            loader: false
          });
        }
      });
    }
  }

  render() {
    var rating = 0;
    const { trainingInfo, loader } = this.state;
    var instructorInfo = {};
    var instructorId = trainingInfo && trainingInfo.instructorId;
    instructorInfo["name"] = instructorId ? instructorId.name : "";
    instructorInfo["description"] = instructorId
      ? instructorId.description
      : "";
    instructorInfo["imageUrl"] = instructorId
      ? instructorId.imageUrl
        ? imageMediaUrl + instructorId.imageUrl
        : null
      : null;
    var url = window.location.href;
    console.log("window.location.href", window.location.href);
    return (
      <div>
        {loader ? <CircularProgress></CircularProgress> : null}

        {!loader && trainingInfo && (
          <Helmet
            title={trainingInfo.name}
            meta={[
              { name: "author", content: trainingInfo.authorName },

              {
                name: "twitter:site",
                content:
                  "https://lms-staging.ohphish.com/trainee/course-intro/" +
                  trainingInfo._id
              },
              { name: "twitter:creator", content: trainingInfo.authorName },
              { name: "twitter:title", content: trainingInfo.name },
              {
                name: "twitter:image",
                content: `${imagePrefixUrl}${trainingInfo.coverImage}`
              },

              { property: "og:title", content: trainingInfo.name },
              {
                property: "og:site_name",
                content: "lms-staging.ohphish.com"
              },
              { property: "og:type", content: "website" },
              {
                property: "og:url",
                content:
                  "https://lms-staging.ohphish.com/trainee/course-intro/" +
                  trainingInfo._id
              },
              {
                property: "og:description",
                content: trainingInfo.shortDescription
              },
              {
                property: "og:image",
                content: `${imagePrefixUrl}${trainingInfo.coverImage}`
              },
              { property: "og:site_name", content: "lms-staging.ohphish.com" },

              {
                name: "viewport",
                content: "width=device-width, maximum-scale=1"
              },
              { name: "apple-itunes-app", content: "app-id=1125423676" }
            ]}
          />
        )}
        {!loader && trainingInfo && (
          <div className="course-intro uk-text-center topic4">
            <h2
              className="uk-light uk-text-uppercase uk-text-bold uk-text-white uk-margin-top"
              id="header-title"
            >
              {trainingInfo.name}
            </h2>
            <p className="uk-light uk-text-bold" id="header-desc">
              {trainingInfo.shortDescription}
            </p>

            <p
              className="uk-light uk-text-bold uk-text-small"
              style={{ fontSize: "16px" }}
            >
              {/* {ln.map((item, index) => {
                  if (index < totalRating) {
                    return (
                      <i
                        key={"icon-rate" + item}
                        className="fas fa-star ic-icon-small ic-icon-rate"
                      ></i>
                    );
                  } else {
                  }
                })} */}
              {/* <Rate disabled value={rating ? rating : 0} /> */}
              <span
                className="uk-margin-small-right"
                style={{ fontSize: "16px" }}
              >
                &nbsp;&nbsp;
                {rating ? rating + ".0" : 0} &nbsp;&nbsp; (
                {this.state.reviews ? this.state.reviews.length : `0`} ratings){" "}
              </span>
              {/* <span className="uk-margin-small-right"> 4.0 (2282 ratings) </span>  */}
              <br />
              <i className="fas fa-user ic-icon-small uk-margin-small-right"></i>
              {trainingInfo.userEnrolled ? trainingInfo.userEnrolled : `0`}{" "}
              students enrolled
            </p>

            <Link
              className="uk-button uk-button-white"
              to={{
                pathname: "/signin"
              }}
              data-uk-tooltip="title: Start a new discussion ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up"
              style={{ margin: "0 2px" }}
            >
              {" "}
              Go to Login
            </Link>

            <a
              className="uk-button uk-button-white"
              data-uk-toggle="target: #modal-preview"
              uk-tooltip=" title: Watch preview video ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up"
              style={{ margin: "0 2px" }}
            >
              Preview
            </a>

            <div id="modal-preview" data-uk-modal>
              <div className="uk-modal-dialog">
                <button
                  className="uk-modal-close-default uk-margin-small-top  uk-margin-small-right uk-light"
                  type="button"
                  data-uk-close
                ></button>
                <div className="uk-modal-header topic4 none-border">
                  <b className="uk-text-white uk-text-medium"> Preview </b>
                </div>
                <video
                  loop
                  // muted
                  playsinline
                  controls
                  data-uk-video="autoplay: inview"
                >
                  <source
                    src={
                      trainingInfo.previewVideo
                        ? `${imagePrefixUrl}${trainingInfo.previewVideo}`
                        : "videos/course-intro.mp4"
                    }
                    type="video/mp4"
                  />
                </video>
                {/* --> */}
                <div className="uk-modal-body">
                  <h4>{trainingInfo.name}</h4>
                  <p> {trainingInfo.shortDescription}</p>
                </div>
                <div className="uk-modal-footer uk-text-right">
                  <button
                    className="uk-button uk-button-default uk-modal-close"
                    type="button"
                    style={{ margin: "0 2px" }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      this.onClickStart("/signin");
                    }}
                    className="uk-button uk-button-grey uk-modal-close"
                    type="button"
                    style={{ margin: "0 2px" }}
                  >
                    {"Go to Login "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loader && trainingInfo && (
          <a
            href={
              "https://web.whatsapp.com/send?text=https://lms-staging.ohphish.com/trainee/course-intro/" +
              trainingInfo._id
            }
            data-action="share/whatsapp/share"
          >
            Share via Whatsapp web
          </a>
        )}
        {!loader && trainingInfo && (
          <ul
            className="uk-tab uk-flex-center  uk-margin-remove-top uk-background-default  uk-sticky"
            data-uk-sticky="animation: uk-animation-slide-top; bottom: #sticky-on-scroll-up ; media: @s;"
            data-uk-switcher="{connect:'#my-id'}"
          >
            <li aria-expanded="true" className="uk-active">
              <a href="#"> Course introduction </a>
            </li>
            <li>
              <a href="#"> Instructure </a>
            </li>
          </ul>
        )}

        {!loader && trainingInfo && (
          <ul
            id="my-id"
            className="uk-switcher"
            className="uk-switcher uk-margin uk-padding-small uk-container-small uk-margin-auto  uk-margin-top"
          >
            {/* <!-- tab 1 About the course --> */}
            <li className="uk-active uk-text-small ">
              <span
                dangerouslySetInnerHTML={{
                  __html: trainingInfo.description
                    ? trainingInfo.description
                    : ""
                }}
              />
            </li>

            {/* <!-- tab 2 About the instructor --> */}
            <li>
              <h2 className="uk-heading-line uk-text-center">
                <span> Meet the instructor </span>
              </h2>
              <div
                className="uk-grid-small  uk-margin-medium-top uk-padding-small"
                data-uk-grid
              >
                <div className="uk-width-1-4@m uk-first-column">
                  {instructorInfo.imageUrl && (
                    <img
                      alt="Image"
                      className="uk-width-2-3 uk-margin-small-top uk-margin-small-bottom uk-border uk-box-shadow-large  uk-animation-scale-up"
                      src={instructorInfo.imageUrl}
                    />
                  )}
                  <div className="uk-text-small uk-margin-small-top">
                    {/* <p> <i className="fas fa-star"></i> {trainerInfo.rating} Instructor Rating </p>
                                                <p> <i className="fas fa-comment-dots"></i>  {trainerInfo.reviews} Reviews </p>
                                                <p> <i className="fas fa-user"></i> {trainerInfo.totelTrainee} Trainee </p>
                                                <p> <i className="fas fa-play"></i> {trainerInfo.totelCourse} courses </p> */}
                  </div>
                </div>
                <div className="uk-width-3-4@m uk-padding-remove-left">
                  {/* <h4 className="uk-margin-remove"> Shield Alliance International Limited </h4>  */}
                  <h4 className="uk-margin-remove"> {instructorInfo.name} </h4>
                  <span className="uk-text-small">
                    {" "}
                    {instructorInfo.specialization}{" "}
                  </span>
                  <hr className="uk-margin-small" />

                  <span
                    dangerouslySetInnerHTML={{
                      __html: instructorInfo.description
                    }}
                  />
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default ShareInfo;
