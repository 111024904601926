import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table } from "antd";
import LzEditor from "react-lz-editor";
// import {DropOption} from '../../../components'
// import "antd/dist/antd.less";

const { confirm } = Modal;
const receiveHtml = content => {};

const modalDescription = modalDeleteProps => {
  const {
    modalVisible,
    handleCancel,
    description,
    receiveHtml,
    onSubmit,
    uploadProps
  } = modalDeleteProps;

  return (
    <Modal
      title="Training description"
      visible={modalVisible}
      onOk={onSubmit}
      onCancel={handleCancel}
      width="85%"
      style={{ top: "3%" }}
    >
      <LzEditor
        active={true}
        importContent={description}
        video={false}
        audio={false}
        image={false}
        urls={true}
        color={false}
        autoSave={false}
        cbReceiver={receiveHtml}
        // uploadProps={uploadProps}
        lang="en"
      />
    </Modal>
  );
};

export default modalDescription;
