const INIT_STATE = {
  submittingDemo: false,
  userMessage: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SUBMITTING_DEMO_REQUEST": {
      return {
        ...state,
        submittingDemo: true
      };
    }
    case "DEMO_REQUEST_DONE": {
      console.log(action.payload);
      return {
        ...state,
        ...action.payload,
        submittingDemo: false
      };
    }
    default: {
      return state;
    }
  }
};
