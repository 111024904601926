import React from 'react';  

function CourseResourceTabs( propsInfo){ 
    console.log({propsInfo})  
    return ( 
    <div className="demo1 uk-background-default" data-simplebar> 
        <img src="../assets/images/courses/course-lesson-icon.jpg" width="200" className="uk-margin-remove-bottom uk-align-center uk-width-2-3" alt=""/>
        <p className="uk-padding-small uk-margin-remove uk-text-center uk-padding-remove-top">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod dolore .</p> 
        <h4 className="uk-heading-line uk-text-center uk-margin-top"><span>Requirements </span></h4>
        <p className="uk-padding-small uk-margin-remove  uk-padding-remove-top uk-text-center">Etiam sit amet augue non velit aliquet consectetur molestie eros mauris  .</p>
        <h5 className="uk-padding-small uk-margin-remove uk-background-muted uk-text-black">  Web browser</h5>
        <div className="uk-grid-small" data-uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">Firefox  </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>                                     
        <hr className="uk-margin-remove-bottom"/> 
        <div className="uk-grid-small uk-margin-small-top" data-uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">Google Chrome  </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>                                     
        <hr className="uk-margin-remove-bottom"/> 
        <div className="uk-grid-small uk-margin-small-top" data-uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">UCBrwoser    </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>
        <h5 className="uk-padding-small uk-margin-remove-bottom uk-background-muted uk-text-black"> Text Editor </h5>
        <div className="uk-grid-small" data-uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">Atom  </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>                                     
        <hr className="uk-margin-remove-bottom"/> 
        <div className="uk-grid-small uk-margin-small-top" uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">Dreamweaver </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>                                     
        <hr className="uk-margin-remove-bottom"/> 
        <div className="uk-grid-small uk-margin-small-top" data-uk-grid> 
            <div className="uk-width-1-4  uk-margin-small-top">
                <img src="../assets/images/icons/software-icon.jpg" alt="Image" className="uk-align-center img-small"/>
            </div>
            <div className="uk-width-3-4 uk-padding-remove-left"> 
                <p className="uk-margin-remove-bottom uk-text-bold  uk-text-small uk-margin-small-top">Sublime   </p> 
                <p className="uk-margin-remove-top uk-margin-small-bottom">Lorem ipsum dolor adipiscing elit, sed do eiusmod tempor ...</p> 
                <a className="Course-tags uk-margin-small-right border-radius-6" href="#"> Official site </a>
                <a className="Course-tags uk-margin-small-right  border-radius-6 tags-active" href="#"> Download </a>
            </div>                                         
        </div>
    </div>                                 


    ) 
}
export default CourseResourceTabs;