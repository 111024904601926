import React, { PureComponent } from 'react';
import Auxiliary from "util/Auxiliary";

class Quizzes extends PureComponent {


    render() {
        return (
            <Auxiliary>
                <span>Quizzes</span>
            </Auxiliary>
        );
    }

}

export default Quizzes;