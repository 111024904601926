import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_AUTH_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  FIND_USER_SUCCESS,
  CLEAR_USER_DATA,
  SIGNIN_MICROSOFT_USER_SUCCESS,
  ON_PING_SUCCESS,
  FORGOT_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER_SUCCESS,
  SESSION_AUTH
} from "constants/ActionTypes"; 

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  successMessage:'',
  showMessage: false,
  userMessage:'',
  initURL: '',
  userData: null,
  authUser: localStorage.getItem('user'),
  accessToken: localStorage.getItem('accessToken'),
  sessionAuth:"",
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SESSION_AUTH: {
      console.log(action.payload,"action.payload")
      return {
        ...state,
        sessionAuth: action.payload
      }
    }
    case FORGOT_PASSWORD_USER_SUCCESS: {
      console.log(action.payload,"action.payload")
      return {
        ...state,
        loader: false,
        userMessage: action.payload
      }
    }
    case RESET_PASSWORD_USER_SUCCESS: {
      console.log(action.payload,"action.payload")
      return {
        ...state,
        loader: false,
        userMessage: action.payload
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: { 
      state.initURL= "/";// state.authUser && state.authUser.trainee? "/trainee":"/main/dashboard/crm"
      return {
        ...state,
        loader: false,
        initURL:action.payload && action.payload.trainee? "/trainee":"/main/dashboard/crm",
        authUser: action.payload,
        accessToken: action.payload.accessToken
      }
    }
    case ON_PING_SUCCESS: {
      return {
        ...state, 
        authUser: action.payload,
        accessToken: action.payload.accessToken
      }
    }
    case FIND_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        accessToken:null,
        initURL: '/',
        loader: false,
        sessionAuth:state.sessionAuth
      }
    }

    case SIGNOUT_AUTH_USER_SUCCESS: {
      
  console.log({state},"-----------state.sessionAuth--------------@-------@",state.sessionAuth)
      return {
        ...state,
        authUser: null,
        accessToken:null, 
        loader: false,
        sessionAuth:action.payload||state.sessionAuth
      }
    }
    case SHOW_MESSAGE: {
      console.log(state,"action.payload", action.payload)
      return {
        ...state,
        // alertMessage: action.payload, successMessage
        alertMessage: (action.payload.error)? action.payload.error:'',
        successMessage: (action.payload.success)? action.payload.success:'',
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        accessToken: action.payload.accessToken
      }
    }
    case SIGNIN_MICROSOFT_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        accessToken: action.payload.accessToken
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case CLEAR_USER_DATA: {
      console.log(state,"CLEAR_USER_DATA")
      return {
        ...state,
        userData: null,
        userMessage: '',
        loader: false
      }
    }
    default:
      return state;
  }
}
