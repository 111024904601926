
import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {  message } from 'antd';  
// import TopNavbar from '../../../components/TraineeMain/TopNavbar' 

import CourseVideoTabs from './components/Course-Video-Tabs' 
import CourseResourceTabs from './components/Course-Resource-Tabs' 
import './index.css'
// import { thisExpression } from '@babel/types';

import { 
	hideMessage, 
	showMessage, 
	getTraineeTraining,  
	showModal,
	hideModal,
	showLoader,
} from "./../../../appRedux/actions/Training"; 
import CircularProgress from 'components/CircularProgress/index'; 

class CourseLesson extends React.PureComponent {
   
    state={
        nightMode: false,
        tabView: 'Course Videos'
    }
    modeView=(nightMode)=>{ 
        console.log({nightMode})
        this.setState({nightMode}) 
        if(!nightMode){
            localStorage.setItem('gmtNightMode', true); 
        } else { 
            localStorage.removeItem('gmtNightMode');
        }
    }
    courseTabs = (tabView) => {
        this.setState({tabView})
    }
    render(){  
    console.log("CourseLesson")


        return ( 
            
              
        <div className="tm-course-lesson">
        {/* <!-- mobile-sidebar  --> */}
        <i className="fas fa-video ic-icon-large tm-side-right-mobile-icon uk-hidden@m" data-uk-toggle="target: #filters"></i>
        <div className="uk-grid-collapse" id="course-fliud" data-uk-grid>
            <div className="uk-width-3-4@m uk-margin-auto">
                <header className="tm-course-content-header uk-background-grey"> 
                    <a href="Course-resume.html" className="back-to-dhashboard uk-margin-small-left" data-uk-tooltip="title: Back to Course Dashboard  ; delay: 200 ; pos: bottom-left ;animation:uk-animation-scale-up ; offset:20"> Course Dhashboard</a> 
                    {/* <!--  night mode  -->                          */}
                    <span className="btn-night-lesson uk-margin-medium-right"><i className="fas fa-moon"></i> </span>
                    <div data-uk-drop="pos: top-right ;mode:click ; offset: 20;animation: uk-animation-scale-up" className="uk-drop"> 
                        <div className="uk-card-small uk-box-shadow-xlarge uk-card-default uk-maring-small-left  border-radius-6"> 
                            <div className="uk-card uk-card-default border-radius-6"> 
                                <div className="uk-card-header"> 
                                    <h5 className="uk-card-title uk-margin-remove-bottom">Swich to night mode</h5> 
                                </div>                                     
                                <div className="uk-card-body"> 
                                    <p>Turns the light surfaces of the page dark, creating an experience ideal for night. Try it!</p> 
                                    <p className="uk-text-small uk-align-left uk-margin-remove  uk-text-muted">DARK THEME </p> 
                                    {/* <!-- night mode button -->                                          */}
                                    <div className="btn-night uk-align-right" id="night-mode"> 
                                        {/* <label className="tm-switch"> 
                                            <div className="uk-switch-button"></div>                                                 
                                        </label> */}
                                        <label className="uk-switch"  for="default-1">
                                            <input type="checkbox" id="default-1"/>
                                            <div className="uk-switch-slider" onClick={(e)=> { this.modeView(!this.state.nightMode)}}></div>
                                        </label>                                             
                                    </div>                                         
                                    {/* <!-- end  night mode button -->                                          */}
                                </div>                                     
                            </div>                                 
                        </div>                             
                    </div>
                </header>
                {/* <!--Course-side icon make Hidden sidebar --> */}
                <i className="fas fa-angle-right ic-icon-large uk-float-right tm-side-course-icon  uk-visible@m" data-uk-toggle="target: #course-fliud; cls: tm-course-fliud" data-uk-tooltip="title: Hide sidebar  ; delay: 200 ; pos: bottom-right ;animation:uk-animation-scale-up ; offset:20"></i>
                {/* <!--Course-side active icon --> */}
                <i className="fas fa-angle-right ic-icon-large uk-float-right tm-side-course-active-icon uk-visible@m" data-uk-toggle="target: #course-fliud; cls: tm-course-fliud" data-uk-tooltip="title: Open sidebar  ; delay: 200 ; pos: bottom-right ;animation:uk-animation-scale-up ; offset:20"></i>
                {/* <!-- PreLoader -->                      */}
                <div id="spinneroverlay"> 
                    <div className="spinner"></div>                         
                </div>                     
                <ul id="component-nav" className="uk-switcher uk-position-relative uk-padding">
                    {/* <!--  Lesson one --> */}
                    <li>
                        <div className="navigation-controls">
                            <a href="#" className="previous uk-animation-fade" data-uk-switcher-item="previous" data-uk-tooltip="title: Previous Video  ; pos: right ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-left"></i> </a>
                            <a href="#" className="next uk-animation-fade" data-uk-switcher-item="next" data-uk-tooltip="title: Next Video  ; pos: left ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-right"></i> </a>
                        </div>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/9gTw2EDkaDQ" frameborder="0" data-uk-video="automute: true" allowfullscreen data-uk-responsive></iframe>
                        </div>                             
                    </li>
                    {/* <!--  Lesson two  --> */}
                    <li>
                        <div className="navigation-controls">
                            <a href="#" className="previous uk-animation-fade" data-uk-switcher-item="previous" data-uk-tooltip="title: Previous Video  ; pos: right ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-left"></i> </a>
                            <a href="#" className="next uk-animation-fade" data-uk-switcher-item="next" data-uk-tooltip="title: Next Video  ; pos: left ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-right"></i> </a>
                        </div>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/YcApt9RgiT0" frameborder="0" allowfullscreen uk-responsive></iframe>
                        </div>                             
                    </li>
                    {/* <!--  Lesson three --> */}
                    <li>
                        <div className="navigation-controls">
                            <a href="#" className="previous uk-animation-fade" data-uk-switcher-item="previous" data-uk-tooltip="title: Previous Video  ; pos: right ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-left"></i> </a>
                            <a href="#" className="next uk-animation-fade" data-uk-switcher-item="next" data-uk-tooltip="title: Next Video  ; pos: left ;animation:uk-animation-scale-up ; offset:20"><i className="fas fa-angle-right"></i> </a>
                        </div>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/CGSdK7FI9MY" frameborder="0" allowfullscreen data-uk-responsive></iframe>
                        </div>
                    </li>                         
                    {/* <!--  Lesson Four --> */}
                    <li>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/4I1WgJz_lmA" frameborder="0" allowfullscreen data-uk-responsive></iframe>
                        </div>
                    </li>
                    {/* <!--  Lesson five --> */}
                    <li>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/dDn9uw7N9Xg" frameborder="0" allowfullscreen data-uk-responsive></iframe>
                        </div>
                    </li>
                    {/* <!--  Lesson six --> */}
                    <li>
                        <div className="video-responsive">
                            <iframe src="https://www.youtube.com/embed/CPcS4HtrUEU" frameborder="0" allowfullscreen data-uk-responsive></iframe>
                        </div>
                    </li>
                </ul>                     
            </div>
            <div className="uk-width-1-4@m uk-offcanvas night-mode tm-filters uk-background-default tm-side-course uk-animation-slide-right-medium uk-overflow-hidden" id="filters" data-uk-offcanvas="overlay: true; container: false; flip: true">
                <div className="uk-offcanvas-bar uk-padding-remove uk-preserve-color">
                    {/* <!-- Sidebar menu-->                          */}
                    <ul className="uk-child-width-expand uk-tab tm-side-course-nav uk-margin-remove uk-position-z-index" data-uk-switcher="animation: uk-animation-slide-right-medium, uk-animation-slide-left-small" style={{boxShadow: '0px 0px 7px 1px gainsboro'}} data-uk-switcher>
                        <li className="uk-active">
                            <a onClick={()=> { this.courseTabs('Course Videos')}} data-uk-tooltip="title: Course Videos ; delay: 200 ; pos: bottom-left ;animation:uk-animation-scale-up"> <i className="fas fa-video ic-icon-medium"></i>  Videos </a>
                        </li>
                        <li>
                            <a onClick={()=> { this.courseTabs('Course Files')}} data-uk-tooltip="title: Course Files ; delay: 200 ; pos: bottom-center ;animation:uk-animation-scale-up"> <i className="fas fa-archive ic-icon-medium"></i>  Contents </a>
                        </li>                             
                    </ul>
                    {/* <!-- Sidebar contents -->                          */}
                    <ul className="uk-switcher" style={{display: 'unset'}} >
                        {/* <!-- Course Video tab  -->                              */}
                        <li style={{display: 'unset'}}> 
                            { this.state.tabView === 'Course Videos' && 
                                <CourseVideoTabs/> 
                            }
                        </li>  
                        {/* <!--  Course resource  tab  -->                              */}
                        <li style={{display: 'unset'}}>
                            { this.state.tabView === 'Course Files' && 
                                <CourseResourceTabs/> 
                            } 
                        </li>                             
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
       );
    }
}

// export default CourseLesson;
const mapStateToProps = ({ auth, trainingInfo }) => {
	const { authUser, accessToken } = auth;
	const { loader, alertMessage, successMessage, showMessage, allTraining, deleteModalVisible } = trainingInfo;
	  return { loader, alertMessage, successMessage, showMessage, authUser, accessToken, allTraining, deleteModalVisible };
  };
  
  export default withRouter(connect(mapStateToProps,{    
	hideMessage, 
	showMessage,
	getTraineeTraining, 
	showModal,
	hideModal,
	showLoader, 
  })(CourseLesson));