import React, { PureComponent } from 'react';
import Auxiliary from "util/Auxiliary"; 
import { Col, Row, message, Card, Icon, List } from 'antd';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom' 
import BarChartReports from './components/BarChartReports' 
import TopHeader from './components/TopHeader'  

import CircularProgress from 'components/CircularProgress/index';
import noDataImage from "assets/images/no_data.gif"

import styles from './index.less'

 
import { 
	hideMessage, 
    showMessage, 
    getAllCampaignsReportsInfo,
    getCampaignsAnalytics, 
    getAllCampaignsInfo,
    downloadXlsxFile,
    downloadPdfFile,

    showLoader,
    showModal,
    hideModal,
  } from "../../../appRedux/actions/Campaign"; 

 
class Campaigns extends PureComponent {
 
    reports = ["department", "designation", "branch"];
    ReportInfo = [];
    DetailReport = {};
    state = {
        keyInfo: {},
        index:0,
        changeData: false,
    }
    componentDidMount() {
        const { match } = this.props;  
        const { params } = match; 
        const _report = params._report 
        this.props.showLoader()
        this.props.getAllCampaignsInfo({}) 
      
    }  
    
    componentDidUpdate(prevProps) {  
        const { match, allCampaignsInfo, campaignsAnalytics } = this.props;  
        const { params } = match;   
        const _report = params._report 

        if( _report !== prevProps.match.params._report) { 
            this.setState({changeData:true}) 
            this.setState({index:0})
            this.props.showLoader()
            this.props.getAllCampaignsInfo({})  
        } else { 
            if( allCampaignsInfo.length > 0  && this.state.changeData ) {  
                var  currentCampaignId =  allCampaignsInfo[0]._id; 
                var obj = {};
                obj['campaignId'] = currentCampaignId//currentCampaignId;
                obj['xAxis'] = params._report;  
                this.props.showLoader();
                this.props.getAllCampaignsReportsInfo(obj)
                this.setState({changeData:false})
            }
        }  
        if(prevProps.allCampaignsInfo.length > 0) {  
            if( allCampaignsInfo.length > 0  ) {    
                if( prevProps.allCampaignsInfo[0]._id !== allCampaignsInfo[0]._id) { 
                    var  currentCampaignId =  allCampaignsInfo[0]._id; 
                    var obj = {};
                    obj['campaignId'] = currentCampaignId;
                    obj['xAxis'] = params._report; 
                    obj['yAxis'] = this.reports; 
                    this.props.showLoader();
                    this.props.getAllCampaignsReportsInfo(obj)
                } 
             }
        } else {  
            if( allCampaignsInfo.length > 0  ) {  
                var  currentCampaignId =  allCampaignsInfo[0]._id; 
                var obj = {};
                obj['campaignId'] = currentCampaignId//currentCampaignId;
                obj['xAxis'] = params._report;  
                this.props.showLoader();
                this.props.getAllCampaignsReportsInfo(obj)
            }
        } 
    }   
    
    downloadXlsxReport = (type) => { 
        const { match, campaignsAnalytics  } = this.props;  
        const { campaign } = campaignsAnalytics;
        const { params } = match;
        const campaignId = params._id
        this.props.showLoader(); 
        var paramsObj = {}
        paramsObj['type'] = type; 
        paramsObj['fileName']=`${campaign.name}-${type}_report.xlsx`
        var obj = {}
        obj['downloadUrl'] = `api/campaigns/${campaignId}/xlsreport`;
        obj['type'] = type;
        obj['name'] = campaign.name;
        obj['paramsObj'] = paramsObj; 
        obj['fileType'] = `-${type}_report.xlsx`;  
        this.props.downloadXlsxFile(obj) 
    }

    downloadPhishingXlsxReport = () => { 
        const { match, campaignsAnalytics  } = this.props;  
        const { campaign } = campaignsAnalytics; 
        const { params } = match;
        const campaignId = params._id
        this.props.showLoader(); 
        var paramsObj = {}
        var obj = {}
        obj['downloadUrl'] = `api/campaigns/${campaignId}/report`; 
        obj['name'] = `Phishing-Simulation-${campaign.name.replace(/\s/g, "-")}`;
        obj['paramsObj'] = paramsObj; 
        obj['fileType'] = `.xlsx`;  
        this.props.downloadXlsxFile(obj) 
    }
    
    downloadPdfReport = (type) => { 
        const { match, campaignsAnalytics  } = this.props;  
        const { campaign } = campaignsAnalytics;
        const { params } = match;
        const campaignId = params._id
        this.props.showLoader(); 
        var paramsObj = {}
        paramsObj['type'] = type; 
        paramsObj['fileName']=`${campaign.name}-${type}_report.xlsx`
        var obj = {}
        obj['downloadUrl'] = `api/campaigns/${campaignId}/xlsreport`;
        obj['type'] = type;
        obj['name'] = campaign.name;
        obj['paramsObj'] = paramsObj; 
        obj['fileType'] = `-${type}_report.xlsx`;  
        this.props.downloadXlsxFile(obj) 
    }
    viewReportModal(keyInfo){ 
        this.setState({keyInfo})
        this.props.showModal('reportModal');
    };

    render() {
        const { match, allCampaignsInfo, loader, alertMessage, showMessage, noData, campaignsReports, modalVisible, reportModalVisible } = this.props; 
        // const { graphReport , campaign, analytics, trainingAnalytics} = campaignsAnalytics; 
        var graphReport = campaignsReports;
        var noDataStatus = noData; 
        var  campaign =  allCampaignsInfo[0]; 
        const { params } = match; 
        const xAxis = params._report 
        const topHeaderView = {
            campaign: allCampaignsInfo, 
            index: this.state.index,
            onChangeSearch: (index) => {  
                this.setState({index})
                var obj = {};
                obj['campaignId'] = allCampaignsInfo[index]._id//currentCampaignId;
                obj['xAxis'] = params._report;  
                this.props.showLoader();    
                this.props.getAllCampaignsReportsInfo(obj) 
            }
        }  
 
        if (allCampaignsInfo.length) {   
            if (campaign.campaignType != "call"){ 
                graphReport.map(report => { 
                    if(Object.keys(report.data).length > 0){ 
                        noDataStatus = false;
                        return 
                    } 
                })
            } else { 
                graphReport.map(report => {  
                    if(Object.keys(report.data).length > 0){ 
                        noDataStatus = false;
                        return  
                    } 
                })
            }   
        } 
        return (
            <Auxiliary> 
                <Row> 
                    <Col  xl={24} lg={24} md={24} sm={24} xs={24} > 
                        <h1>{ xAxis.charAt(0).toUpperCase() + xAxis.substring(1, xAxis.length)} Detailed Report</h1> 
                        { !noDataStatus &&
                        (
                            <span style={{position:'absolute', right:'50px', top: '0px'}}>
                                <a onClick={ () => this.downloadPhishingXlsxReport()} style={{color: 'rgb(99, 138, 109)', margin:'5px', fontSize:'32px'}} ><Icon type="file-excel" /></a> 
                            </span>
                        )
                        }
                        <div className="gx-campaign-space" />
                    </Col> 
                    
                        { allCampaignsInfo.length > 0 && 
                            <Col  xl={24} lg={24} md={24} sm={24} xs={24} > 
                                <Card style={{marginBottom:'5px', padding:'5px'}}  >    
                                    <TopHeader {...topHeaderView}></TopHeader> 
                                </Card>
                            </Col>
                        } 

                </Row> 
              
                {loader ? (
                    <div className="gx-loader-view">
                        <CircularProgress />
                    </div>
                ) :
                ( 
                    <Row>   
                        { allCampaignsInfo.length ? 
                            !noDataStatus  ?   
                            campaign.campaignType != "call" ? 
                            graphReport.map(report => {
                                return Object.keys(report.data)
                                        .filter(
                                        key =>
                                            !(
                                            report.data[key].length == 0 ||
                                            (report.data[key].length == 1 && !report.data[key][0]._id[report.yAxis])
                                            )
                                        )
                                        .map(xAxis => {  
                                        return (
                                        <Col class ='card-view' xl={12} lg={12} md={12} sm={24} xs={24} > 
                                            <Card 
                                                title=  {(report.yAxis).charAt(0).toUpperCase()+''+(report.yAxis).substring(1, (report.yAxis).length)+' '+ (report.type == "training" ? "Training Report of " : "Report of ") + xAxis}//(report.data.map(reportInfo => { return reportInfo[report.xAxis]})) }
                                                extra={
                                                    <span>
                                                        {/* // { <a onClick={ () => this.downloadXlsxReport(graphReport[report].key)} style={{color: 'rgb(99, 138, 109)', margin:'5px', fontSize:'16px'}} ><Icon type="file-excel" /></a> 
                                                        // <a onClick={ () => this.downloadPdfReport(graphReport[report].key)} style={{color: 'rgb(204, 75, 76)', margin:'5px', fontSize:'16px'}}><Icon type="file-pdf" /></a> 
                                                        // <a onClick={ () => this.viewReportModal(graphReport[report])} style={{color: '#000', margin:'5px', fontSize:'16px'}}><Icon type="eye" /></a> } */}
                                                    </span>
                                                    }>
                                                <BarChartReports report= {report} campaign={ campaign} xAxis = {xAxis} ></BarChartReports>
                                            </Card>
                                        </Col> 
                                    );
                                }) 
                            })  :
                            graphReport.map(report => { 
                                return Object.keys(report.data) 
                                        .map(xAxis => {  
                                        return (
                                        <Col class ='card-view' xl={12} lg={12} md={12} sm={24} xs={24} > 
                                            <Card 
                                                title=  {(report.yAxis).charAt(0).toUpperCase()+''+(report.yAxis).substring(1, (report.yAxis).length)+' '+ (report.type == "training" ? "Training Report of " : "Report of ") + xAxis}//(report.data.map(reportInfo => { return reportInfo[report.xAxis]})) }
                                                extra={
                                                    <span>
                                                        {/* // { <a onClick={ () => this.downloadXlsxReport(graphReport[report].key)} style={{color: 'rgb(99, 138, 109)', margin:'5px', fontSize:'16px'}} ><Icon type="file-excel" /></a> 
                                                        // <a onClick={ () => this.downloadPdfReport(graphReport[report].key)} style={{color: 'rgb(204, 75, 76)', margin:'5px', fontSize:'16px'}}><Icon type="file-pdf" /></a> 
                                                        // <a onClick={ () => this.viewReportModal(graphReport[report])} style={{color: '#000', margin:'5px', fontSize:'16px'}}><Icon type="eye" /></a> } */}
                                                    </span>
                                                    }>
                                                <BarChartReports report= {report} campaign={ campaign} xAxis = {xAxis} ></BarChartReports>
                                            </Card>
                                        </Col> 
                                    );
                                }) 
                            }) 
                            : 
                            (     
                                <Col  xl={24} lg={24} md={24} sm={24} xs={24} > 
                                    <Card> 
                                        <Row> 
                                            <Col  xl={8} lg={8} md={24} sm={24} xs={24} > 
                                                
                                            </Col>
                                            <Col  xl={8} lg={8} md={24} sm={24} xs={24} > 
                                                    <img src={noDataImage} /> 
                                            </Col>
                                            <Col  xl={8} lg={8} md={24} sm={24} xs={24} > 
                                                
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col> 
                            ) 
                            : ''
                        }         
                    </Row>
                    )
                }
                {/* { modalVisible && <ViewModal {...viewModal}/>}
                { reportModalVisible && <ViewModalReport {...viewModalReport}></ViewModalReport>} */}

                {  showMessage ? alertMessage? message.error(alertMessage.toString()): null: null}

              
            </Auxiliary>
        )
    }
}
 
const mapStateToProps = ({ campaignInfo }) => {  
	const { loader, alertMessage, showMessage, campaigns, allCampaignsInfo, noData, campaignsReports, modalVisible, reportModalVisible } = campaignInfo;
	return { loader, alertMessage, showMessage, campaigns, allCampaignsInfo, noData, campaignsReports, modalVisible, reportModalVisible };
  };
  
  export default withRouter(connect(mapStateToProps,{    
	hideMessage, 
    showMessage,
    getAllCampaignsReportsInfo,

    getCampaignsAnalytics,
    getAllCampaignsInfo,
    downloadXlsxFile,
    downloadPdfFile,
    showLoader, 
    showModal,
    hideModal
  })(Campaigns));