import React, { Component } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import TopNavbar from "../components/TopNavbar";

class PaymentSuccess extends Component {
  state = {
    trainingId: null,
    trainingName: "",
    trainingCount: 0
  };
  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const session_id = params.get("session_id");
    this.setState({
      session_id,
      trainingId: params.get("trainingId"),
      trainingName: params.get("trainingName"),
      trainingCount: params.get("trainingCount"),
      orderId: params.get("orderId")
    });
  }
  render() {
    return (
      <div>
        <div style={{ background: "#3e474e", height: "70px" }}>
          <TopNavbar key="topNavbarKey" />
        </div>

        <div
          className="uk-container"
          data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
          style={{ height: "100vh" }}
        >
          <Result
            status="success"
            title={`Successfully Purchased ${this.state.trainingName ||
              this.state.trainingCount + " trainings"}`}
            subTitle={`Order ID: ${this.state.orderId} .`}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => this.props.history.push("/trainee")}
              >
                Explore More
              </Button>
            ]}
          />
        </div>
      </div>
    );
  }
}
export default PaymentSuccess;
