import React from "react";
import moment from 'moment';
// import { campaignType } from 'util/Utils';
import { Table, Tag, Tooltip, Divider, Popover, Button } from "antd";
import DropOption from './DropOption';
import Widget from "components/Widget";
import CircularProgress from 'components/CircularProgress';

const formatDate = (date, format) => moment(date).format(format);
  
const UserTable = (campaignProps) => {

    const {
      loader,
      showModal,
      campaign,
      data,
      type,
      downloadCertificate,
      pagination,
      handlePagination
    } = campaignProps;  
    console.log({campaignProps})
    const renderStatus = (status) => { 
        return (status == "Delivered") ? ( 
                <Tag color="#4CAF50"> {status} </Tag> 
            ) : (status == "Bounced") ? ( 
                <Tag color="#038fde"> {status} </Tag> 
            ):(
                <Tag color="#919191"> {status} </Tag> 
            )
        }

    var columns = [
        {
            title: 'Employee ID', 
            key: 'id',
            render: (value, row, index) => { return <span>{index+1}</span> } 
        },
        {
            title: 'Employee Name',
            key: 'name',
            dataIndex: 'name',
            render: name => <span>{name}</span>,
        },
        {
            title: (campaign.campaignType == "email" )?'Email': (campaign.campaignType == "whatsapp") ? "Whatsapp Number" : "Mobile",
            key: (campaign.campaignType == "email" )?'email': "mobile",
            dataIndex: (campaign.campaignType == "email" )?'email': "mobile", 
        },
        {
            title: 'Designation',
            key: 'designation',
            dataIndex: 'designation', 
        },
        {
            title: 'Department',
            key: 'department',
            dataIndex: 'department', 
        },
        {
            title: 'Branch',
            key: 'branch',
            dataIndex: 'branch', 
        } 
    ];
        
    if( campaign.campaignType == "email" && type == 'training' ) {  
        
            columns.push(
                {
                    title: 'Training Name',
                    key: 'trainingName',
                    dataIndex: 'campaign.name',
                    render: name =>  <span>{campaign.name }</span>,
                },
                {
                    title: 'Attempted',
                    key: 'started',
                    dataIndex: 'started',
                    render: text => <span>{text ? "Yes" : "No"}</span>,
                },
                {
                    title: 'Completion Status',
                    key: 'completionStatus',
                    dataIndex: 'completionStatus',
                    render: status => <span>{status}</span>,
                },
                {
                    title: 'Score',
                    key: 'scoreRaw',
                    dataIndex: 'scoreRaw',
                    render: scoreRaw => <span>{scoreRaw}</span>,
                }, 
                {
                    title: 'Certificate',
                    key: 'trainee',
                    dataIndex: 'trainee',
                    render: trainee => { return <a onClick={ () => downloadCertificate( campaign._id, trainee, campaign.name)} >Download</a>}
                },
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status',
                    render: (text) => <span>{renderStatus(text)}</span>,
                }
            )  
        if( campaign.browserId && campaign.browserId.length > 0 ) { 
            columns.push( {
                title: 'Browser Details',
                key: 'browserId',
                dataIndex: 'browserId',
                render: (text, record) => { return <a onClick={() => showModal('browserModal', record)} >Show</a>}
                // render: text => <span>{formatDate(text, 'lll')}</span>,
            } )
        } 
    } else if( campaign.campaignType == "email" ) {   
        if ( campaign.emailAction == "credentials") { 
            columns.push(
                {
                    title: 'Sent At',
                    key: 'mailSentAt',
                    dataIndex: 'mailSentAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Opened At',
                    key: 'mailOpenedAt',
                    dataIndex: 'mailOpenedAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Clicked At',
                    key: 'mailClickedAt',
                    dataIndex: 'mailClickedAt',
                    render: date => date?<span> {formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Click Count',
                    key: 'mailClickedCount',
                    dataIndex: 'mailClickedCount', 
                    render: (text, record) => {  
                        return (   
                            <Popover placement="left" title={'Click history'} content={(record.mailClickedTimes).map( (date) => {return <p> {date?<span>{formatDate(date, 'llll')}</span>:'N/A'}</p>  } )}  >
                                <Button>{record.mailClickedCount}</Button>
                            </Popover> 
                        )
                    },
                },
                {
                    title: 'Credentials',
                    key: 'credentials',
                    dataIndex: 'campaignId.credentials', 
                    render: (text, record) => { return <a onClick={ () => showModal('credentialsModal', record)} >Show</a>}
                },
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status',
                    render: (text) => <span>{renderStatus(text)}</span>,
                }
            )
        } else if(campaign.trainingId) { 
            columns.push(
                {
                    title: 'Sent At',
                    key: 'mailSentAt',
                    dataIndex: 'mailSentAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Opened At',
                    key: 'mailOpenedAt',
                    dataIndex: 'mailOpenedAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Clicked At',
                    key: 'mailClickedAt',
                    dataIndex: 'mailClickedAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Click Count',
                    key: 'mailClickedCount',
                    dataIndex: 'mailClickedCount', 
                    render: (text, record) => { 
                        return ( 
                            <Popover placement="left" title={'Click history'} content={(record.mailClickedTimes).map( (date) => {return <p> {date?<span>{formatDate(date, 'llll')}</span>:'N/A'}</p>  } )}  >
                                <Button>{record.mailClickedCount}</Button>
                            </Popover> 
                        )
                    },
                },
                {
                    title: 'Training',
                    key: 'training',
                    dataIndex: 'campaignId.training',
                    render: (text, record) => { return <a onClick={ () => showModal('trainingModal', record)} >View Details</a>}
                },
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status',
                    render: (text) => <span>{renderStatus(text)}</span>,
                }
            ) 
        } else { 
            columns.push(
                {
                    title: 'Sent At',
                    key: 'mailSentAt',
                    dataIndex: 'mailSentAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>: 'N/A',
                },
                {
                    title: 'Opened At',
                    key: 'mailOpenedAt',
                    dataIndex: 'mailOpenedAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Clicked At',
                    key: 'mailClickedAt',
                    dataIndex: 'mailClickedAt',
                    render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
                },
                {
                    title: 'Click Count',
                    key: 'mailClickedCount',
                    dataIndex: 'mailClickedCount', 
                    render: (text, record) => { 
                        return ( 
                            <Popover placement="left" title={'Click history'} content={(record.mailClickedTimes).map( (date) => {return <p> {date?<span>{formatDate(date, 'llll')}</span>:'N/A'}</p>  } )}  >
                                <Button>{record.mailClickedCount}</Button>
                            </Popover> 
                        )
                    },
                }, 
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status',
                    render: (text) => <span>{renderStatus(text)}</span>,
                }
            ) 
        }  
        if( campaign.browserId && campaign.browserId.length > 0 ) { 
            columns.push( {
                title: 'Browser Details',
                key: 'browserId',
                dataIndex: 'browserId',
                render: (text, record) => { return <a onClick={() => showModal('browserModal', record)} >Show</a>}
                // render: text => <span>{formatDate(text, 'lll')}</span>,
            } )
        } 
    } else if( campaign.campaignType == "call" ) {  
        console.log("-------------call--------------")
        columns.push(
            {
                title: 'Called At',
                key: 'callTriggeredAt',
                dataIndex: 'callTriggeredAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Answered At',
                key: 'callAnsweredAt',
                dataIndex: 'callAnsweredAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Duration',
                key: 'callDuration',
                dataIndex: 'callDuration',
            }, 
            {
                title: 'Recording',
                key: 'callRecordFie',
                dataIndex: 'callRecordFie',
                render: callRecordFie => callRecordFie?<a href={callRecordFie} target="_blank"> Download  </a>:"Not available",
            }, 
            {
                title: 'Credentials',
                key: 'ivrCapture',
                dataIndex: 'ivrCapture',
            }
        ) 
    }  else if( campaign.campaignType == "sms" ) {

        console.log("-------------sms--------------")
        columns.push(
            {
                title: 'Sent At',
                key: 'mailSentAt',
                dataIndex: 'mailSentAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Clicked At',
                key: 'mailClickedAt',
                dataIndex: 'mailClickedAt',
                render: date => date?<span> {formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Click Count',
                key: 'mailClickedCount', 
                render: (text, record) => { 
                    return ( 
                        <Popover placement="left" title={'Click history'} content={(record.mailClickedTimes).map( (date) => {return <p> {date?<span>{formatDate(date, 'llll')}</span>:'N/A'}</p>  } )}  >
                            <Button>{record.mailClickedCount}</Button>
                        </Popover> 
                    )
                },
            }, 
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: (text) => <span>{renderStatus(text)}</span>,
            }
        ) 
    } else if( campaign.campaignType == "whatsapp" ) {

        console.log("-------------whatsapp--------------")
        columns.push(
            {
                title: 'Sent At',
                key: 'smsSentAt',
                dataIndex: 'smsSentAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Processed At',
                key: 'messageProcessedAt',
                dataIndex: 'messageProcessedAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Clicked At',
                key: 'mailClickedAt',
                dataIndex: 'mailClickedAt',
                render: date => date?<span>{formatDate(date, 'llll')}</span>:'N/A',
            }, 
            {
                title: 'Click Count',
                key: 'mailClickedCount',
                dataIndex: 'mailClickedCount', 
                render: (text, record) => { 
                    return ( 
                        <Popover placement="left" title={'Click history'} content={(record.mailClickedTimes).map( (date) => {return <p> {date?<span>{formatDate(date, 'llll')}</span>:'N/A'}</p>  } )}  >
                            <Button>{record.mailClickedCount}</Button>
                        </Popover> 
                    )
                },
            } 
        ) 
    } 
         
            console.log("campaign.campaignType---", campaign.campaignType)
            console.log("columns---", columns)

    
    return (
      <Widget
        styleName="gx-campaign-card"
        title={
          <div>
            <div className="ant-row-flex gx-justify-content-between">
              <h3 className="h4">User Details</h3>
              <span className="gx-ml-2 gx-search-icon">
                {/* <i className="icon icon-search-new gx-text-primary gx-fs-xxl gx-pointer" /> */}
              </span>
            </div>
            {/* <Divider /> */}
          </div>
        }
      >
        {loader ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : null}
        <Table
          className="gx-table-responsive"
          pagination={  {
                  ...pagination,
                  showTotal: total => `Total ${total} `, 
                  onChange: page => {
                    handlePagination({
                      page: page 
                    }); 
                }
            }
          }
          columns={columns}
          dataSource={data}
          bordered={false}
          // scroll={false}
        />
      </Widget>
    );
};

export default UserTable;
