import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Table } from 'antd'
// import {DropOption} from '../../../components'

 
const { confirm } = Modal

const ViewModal = (campaignProps) => {
 
   
  const { modalVisible, handleCancel , campaign} = campaignProps 
  console.log("campaignProps", campaignProps)
  return (

    <Modal
    onCancel={handleCancel}
      title="Training Details"
      visible={modalVisible}  
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Close</Button> 
      ]}
      width='80%'
      style={{top:'15px'}}
    >
      <span dangerouslySetInnerHTML={ {__html:campaign.templateContent}} />
      {/* {  campaign.templateContent } */}
    
    </Modal> 
  ) 
}

export default ViewModal
