import {
  SHOW_MESSAGE,
  HIDE_MESSAGE, 
  GET_TRAINEE_TRAINING_SUCCESS, 
  GET_TRAINEE_ALL_TRAINING_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MODAL,
  HIDE_MODAL,  
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  modalVisible: false,
  deleteModalVisible: false,  
  training:{},
  allTraining:[], 
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRAINEE_ALL_TRAINING_SUCCESS: {  
      console.log(action.payload)
      return {
        ...state,
        loader: false, 
        allTraining: action.payload
      }
    }  
    case GET_TRAINEE_TRAINING_SUCCESS: {  
      console.log("GET_TRAINEE_TRAINING_SUCCESS",action.payload)
      return {
        ...state,
        loader: false, 
        training: action.payload
      }
    }  
    case SHOW_MODAL: {  
      return {
        ...state, 
        modalVisible: (action.payload === "modal")?true:false, 
        deleteModalVisible: (action.payload === "deleteModal")?true:false, 
        showMessage: false,  
      }
    }
    case HIDE_MODAL : { 
      return {
        ...state,  
        modalVisible: false,
        deleteModalVisible: false, 
      }
    }
    
    case SHOW_MESSAGE: { 
      return {
        ...state,
        alertMessage: (action.payload.error)? action.payload.error:'',
        successMessage: (action.payload.success)? action.payload.success:'',
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        successMessage:'',
        showMessage: false, 
      }
    } 
    
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    // case CLEAR_USER_DATA: {
    //   return {
    //     ...state,
    //     userData: null,
    //     loader: false
    //   }
    // }
    default:
      return state;
  }
}
