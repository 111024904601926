import React from "react";
import { Route, Switch } from "react-router-dom";
import Campaign from "./campaigns/index";
import Users from "./users/index";
import Reports from "./reports/reports";
import DetailsReports from "./reports/detailsReports";

const Campaigns = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/:_id/users`} component={Users} />
      <Route path={`${match.url}/:_report/report`} component={Reports} />
      <Route
        path={`${match.url}/:_id/details/:type?`}
        component={DetailsReports}
      />
      <Route path={`${match.url}`} component={Campaign} />
    </Switch>
  );
};

export default Campaigns;
