import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DatePicker, Checkbox, Radio, Select, Spin } from "antd";
import { confirmAlert } from "react-confirm-alert"; // Import
import debounce from "lodash/debounce";
import ModelReview from "./../../traineeMain/courseIntro/model";
import "./index.less";

import {
  Row,
  Col,
  Typography,
  // Button,
  Rate,
  Card,
  Icon,
  Modal,
  Table,
  TreeSelect,
  message,
  Button,
} from "antd";

import {
  hideMessage,
  showMessage,
  getAllTraining,
  getTraining,
  showLoader,
} from "./../../../appRedux/actions/Training";
import { imagePrefixUrl, imageMediaUrl } from "util/config";
import { func } from "prop-types";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
const { Title } = Typography;
const { TreeNode } = TreeSelect;
const { Option } = Select;
const { confirm } = Modal
const formatDate = (date, format) => moment(date).format(format);
const TraineeModel = require("../trainee/model");
const Model = require("./model");

class TrainingInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchOrganisations = debounce(this.fetchOrganisations, 800);
  }
  state = {
    tabName: "Description",
    confirmDirty: false,
    autoCompleteResult: [],
    current: 0,
    visible: false,
    importedUsers: [],
    selectedUsers: [],
    selectedRowKeys: [],
    loading: false,
    branches: [],
    designations: [],
    departments: [],
    selectedGroups: [],
    expiryDate: moment()
      .add(7, "days")
      .toDate(),
    autoDeliveryOfCampaignReport: false,
    scheduleLater: "no",
    scheduleDate: null,
    clientPage: 0,
    clients: [],
    loadingClients: false,
    hasMoreClients: true,
    value: [],
    fetching: false,
    organisationId: null,
    interval: "",
    intervals: {},
    reviews: [],
  };
  componentWillMount() {
    const { match } = this.props;
    const trainingId = match.params.id;
    console.log("--", this.props);
    this.props.showLoader();
    this.props.getTraining({ trainingId: trainingId });
    this.setState({ trainingId, user: JSON.parse(localStorage.getItem("user")) }, () => {
      if (this.state.user.accessLevel == 1) {
        this.fetchOrganisations({ page: this.state.clientPage });
      }
    });
    this.callReviewsApi(trainingId);
  }
  callReviewsApi(trainingId) {
    ModelReview.getReviews(trainingId).then((response) => {
      if (!response.error) {
        this.setState({
          reviews: response.data,
        });
      }
    });
  }
  chooseUsers = () => {
    var organisationId = JSON.parse(localStorage.getItem("user")).organisationId;
    if (JSON.parse(localStorage.getItem("user")).accessLevel == 1) {
      organisationId = this.state.organisationId;
    }

    if (!this.state.expiryDate) {
      message.error("Please choose expiry date!!");
      return false;
    }
    if (this.state.scheduleLater == "yes") {
      if (!this.state.scheduleBy) {
        message.error("Please choose schedule type date or interval!!");
        return false;
      }
      if (!this.state.scheduleDate) {
        message.error("Please choose schedule date and time!!");
        return false;
      }
    }
    if (organisationId) {
      this.showLoader();
      this.setState({ userModalVisible: true });
      TraineeModel.default.getTrainee({ organisationId }).then((response) => {
        console.log({ response });
        this.hideLoader();
        this.setState({
          importedUsers: response.data.trainees,
          userModalVisible: true,
          branches: response.data.branches,
          designations: response.data.designations,
          departments: response.data.departments,
          selectedRowKeys: this.state.selectedRowKeys,
          totalRecords:response.data.totalRecords
        });
      });
    } else {
      message.error("Please select client");
    }
  };

  createIntervals = () => {
    this.setState({
      intervals: {
        ...this.state.intervals,
        [this.state.interval]: this.state.intervals[this.state.interval] || [],
      },
      interval: "",
    });
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    var selectedUsers = this.state.selectedUsers;
    if (!selected) {
      if (this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval") {
        this.setState({
          selectedRowKeys: [],
          selectedUsers: [],
          intervals: {
            ...this.state.intervals,
            [this.state.selectedInterval]: [],
          },
        });
      } else {
        this.setState({ selectedRowKeys: [], selectedUsers: [] });
      }
    } else {
      if (this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval") {
        this.setState({
          intervals: {
            ...this.state.intervals,
            [this.state.selectedInterval]: this.state.importedUsers,
          },
          selectedUsers: this.state.importedUsers,
          selectedRowKeys: this.state.importedUsers.map((user) => user._id), //[...Array(this.state.importedUsers.length).keys()]
        });
      } else {
        this.setState({
          selectedUsers: this.state.importedUsers,
          selectedRowKeys: this.state.importedUsers.map((user) => user._id), //[...Array(this.state.importedUsers.length).keys()]
        });
      }
    }
  };

  onSelect = (record, selected, selectedRows, nativeEvent) => {
    console.log({ record, selected, selectedRows, nativeEvent });
    var { selectedRowKeys } = this.state;
    var selectedUsers =
      this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval"
        ? this.state.intervals[this.state.selectedInterval] || []
        : this.state.selectedUsers;
    if (selected) {
      if (selectedRowKeys.indexOf(record._id) < 0) {
        selectedRowKeys.push(record._id);
        selectedUsers.push(record);
      }
    } else {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record._id), 1);
      selectedUsers = selectedUsers.filter((user) => user._id != record._id);
    }

    if (this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval") {
      this.setState({
        selectedRowKeys,
        selectedUsers,
        intervals: {
          ...this.state.intervals,
          [this.state.selectedInterval]: selectedUsers,
        },
      });
    } else {
      this.setState({ selectedRowKeys, selectedUsers });
    }

    this.forceUpdate();
  };

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  hideUserModal = () => {
    this.setState({ userModalVisible: false });
  };

  formattedFilter = () => {
    console.log(this.state.selectedGroups);
    var selectedGroups = this.state.selectedGroups;
    var formatted = {
      branch: [],
      department: [],
      designation: [],
    };
    selectedGroups.map((value) => {
      if (value.includes("-all")) {
        var type = value.split("-")[0];
        // formatted[type]
      } else {
        var type = value.split("-")[0];
        const val = value.split(/-(.+)/)[1];
        formatted[type].push(val);
      }
    });
    return formatted;
  };

  assignGroup = () => {
    var selectedUsers = this.state.importedUsers.filter((user) => this.state.selectedRowKeys.includes(user._id));
    this.setState(
      {
        intervals: {
          ...this.state.intervals,
          [this.state.selectedInterval]: selectedUsers,
        },
      },
      () => this.hideUserModal()
    );
  };

  assignTraining = () => {
    var self = this;
    if (
      this.state.scheduleLater == "no" ||
      (this.state.scheduleLater == "yes" && this.state.scheduleBy != "interval")
    ) {
      var selectedUsers = this.state.importedUsers.filter((user) => this.state.selectedRowKeys.includes(user._id));
      if (JSON.parse(localStorage.getItem("user")).accessLevel == 1) {
        if (this.state.organisationId == null || this.state.organisationId == "") {
          message.error("Please select client!!");
          return false;
        }
      }
      if (!this.state.expiryDate) {
        message.error("Please choose expiry date!!");
        return false;
      }
      if (this.state.scheduleLater == "yes") {
        if (!this.state.scheduleBy) {
          message.error("Please choose schedule type date or interval!!");
          return false;
        }
        if (!this.state.scheduleDate) {
          message.error("Please choose schedule date and time!!");
          return false;
        }
      }

      var title =  "Confirm?";
      var content =  `This will trigger ${selectedUsers.length} ${
            selectedUsers.length == 1 ? "email" : "emails"
          }. Are you sure you want to assign this training?`;

      console.log({ selectedUsers });
      this.hideUserModal();
      this.showConfirm(title,content).then(resp => { 
          // resolve(null);
          self.showLoader();
          Model.default
            .assignTraining(
              selectedUsers,
              self.state.trainingId,
              self.state.expiryDate,
              self.state.autoDeliveryOfCampaignReport,
              self.state.scheduleLater,
              self.state.scheduleBy,
              self.state.scheduleDate,
              self.state.intervals,
              self.state.organisationId
            )
            .then((response) => {
              if (response.error) {
                message.error(response.userMessage);
              } else {
                message.success(response.userMessage, function() {
                  self.props.history.push("/campaigns/" + response.data._id + "/details/training");
                });
              }
              self.hideLoader();
            });
        
      })
   
      // confirmAlert({
      //   title: "Confirm?",
      //   message: `This will trigger ${selectedUsers.length} ${
      //     selectedUsers.length == 1 ? "email" : "emails"
      //   }. Are you sure you want to assign this training?`,
      //   buttons: [
      //     {
      //       label: "Yes",
      //       onClick: () => {
      //         this.showLoader();
      //         Model.default
      //           .assignTraining(
      //             selectedUsers,
      //             this.state.trainingId,
      //             this.state.expiryDate,
      //             this.state.autoDeliveryOfCampaignReport,
      //             this.state.scheduleLater,
      //             this.state.scheduleBy,
      //             this.state.scheduleDate,
      //             this.state.intervals,
      //             this.state.organisationId
      //           )
      //           .then((response) => {
      //             if (response.error) {
      //               message.error(response.userMessage);
      //             } else {
      //               message.success(response.userMessage, function() {
      //                 self.props.history.push("/campaigns/" + response.data._id + "/details/training");
      //               });
      //             }
      //             this.hideLoader();
      //           });
      //       },
      //     },
      //     {
      //       label: "No",
      //       onClick: () => {},
      //     },
      //   ],
      // });
    } else {
      var selectedUsers = this.state.importedUsers.filter((user) => this.state.selectedRowKeys.includes(user._id));
      this.setState({
        selectedUsers,
        intervals: {
          ...this.state.intervals,
          [this.state.selectedInterval]: selectedUsers,
        },
      });
      this.hideUserModal();
    }
  };

  showConfirm(title,content) {
    return new Promise(function(resolve, reject) {
      confirm({
        title: title,
        content: content,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          resolve(null);
        },
        onCancel() {
          reject(null);
        }
      });
    });
  }
  assignIntervalTraining = () => {
    if (JSON.parse(localStorage.getItem("user")).accessLevel == 1) {
      if (this.state.organisationId == null || this.state.organisationId == "") {
        message.error("Please select client!!");
        return false;
      }
    }
    if (!this.state.expiryDate) {
      message.error("Please choose expiry date!!");
      return false;
    }
    if (this.state.scheduleLater == "yes") {
      if (!this.state.scheduleBy) {
        message.error("Please choose schedule type date or interval!!");
        return false;
      }
      if (Object.keys(this.state.intervals).length == 0) {
        message.error("Please choose interval!!");
        return false;
      }
      if (Object.keys(this.state.intervals).some((interval) => this.state.intervals[interval].length == 0)) {
        message.error("Please assign users to each interval!!");
        return false;
      }
    }

    var selectedUsers = Object.keys(this.state.intervals).reduce((users, interval) => {
      users = users.concat(
        this.state.intervals[interval].map((user) => {
          user.interval = interval;
          return user;
        })
      );
      return users;
    }, []);
    // selectedUsers.length

    var title= "Confirm?";
   var content=`This will trigger ${selectedUsers.length} ${
      selectedUsers.length == 1 ? "email" : "emails"
    }. Are you sure you want to assign this training?`
    this.showConfirm(title,content).then(resp => {

      this.showLoader();
      Model.default
        .assignTraining(
          selectedUsers,
          this.state.trainingId,
          this.state.expiryDate,
          this.state.autoDeliveryOfCampaignReport,
          this.state.scheduleLater,
          this.state.scheduleBy,
          this.state.scheduleDate,
          this.state.intervals,
          this.state.organisationId
        )
        .then((response) => {
          console.log({ response });
          if (response.error) {
            message.error(response.userMessage);
          } else {
            message.success(response.userMessage);
          }
          this.hideLoader();
        });
      
    })
   
    // confirmAlert({
    //   title: "Confirm?",
    //   message: `This will trigger ${selectedUsers.length} ${
    //     selectedUsers.length == 1 ? "email" : "emails"
    //   }. Are you sure you want to assign this training?`,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         this.showLoader();
    //         Model.default
    //           .assignTraining(
    //             selectedUsers,
    //             this.state.trainingId,
    //             this.state.expiryDate,
    //             this.state.autoDeliveryOfCampaignReport,
    //             this.state.scheduleLater,
    //             this.state.scheduleBy,
    //             this.state.scheduleDate,
    //             this.state.intervals,
    //             this.state.organisationId
    //           )
    //           .then((response) => {
    //             console.log({ response });
    //             if (response.error) {
    //               message.error(response.userMessage);
    //             } else {
    //               message.success(response.userMessage);
    //             }
    //             this.hideLoader();
    //           });
    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };
  onTreeSelectChange = (value) => {
    console.log({ value });
    this.setState({ selectedGroups: value });
  };

  fetchUsers = () => {
    var formatted = this.formattedFilter();
    console.log({ formatted });
    var exclude = [];
    if (this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval") {
      exclude = Object.keys(this.state.intervals).reduce((result, interval) => {
        if (interval !== this.state.selectedInterval) {
          result = result.concat(this.state.intervals[interval].map((user) => user._id));
        }
        return result;
      }, []);
    }
    console.log("exclude ", exclude);
    Model.default.fetchFilteredUsers(formatted, this.state.organisationId, exclude.join(",")).then((response) => {
      console.log({ response });
      this.setState({
        importedUsers: response.data,
        selectedUsers: response.data,
        selectedRowKeys: response.data.map((user) => user._id),
      });
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      expiryDate: value ? value.toDate() : "",
    });
  };

  scheduleLater = (value, dateString) => {
    this.setState({
      scheduleDate: value ? value.toDate() : "",
    });
  };

  onRadioChange = (e) => {
    console.log("radio checked", e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchOrganisations = (value) => {
    console.log("fetching clients", value);

    if (!this.state.loadingClients && this.state.hasMoreClients) {
      this.setState({
        // data: [],
        loadingClients: true,
      });
      Model.default.getOrganisations(value).then((response) => {
        console.log({ response });

        const clients = response.items;
        // .map(client => ({
        //   text: `${client.name}`,
        //   value: client._id
        // }));

        this.setState(
          {
            clients: [...this.state.clients, ...clients],
            loadingClients: false,
            clientPage: value.page + 1,
            hasMoreClients: clients.length == 10,
          },
          () => {
            console.log("clients length", this.state.clients.length);
          }
        );
      });
    }
  };

  handleChange = (value) => {
    console.log(value, "handleChange");
    let text = value.split("~")[1];
    this.setState({
      value: text,
      organisationId: value.split("~")[0],
      // data: [],
      fetching: false,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      autoDeliveryOfCampaignReport: e.target.checked,
    });
    console.log(`checked = ${e.target.checked}`);
  };

  handleSchedule = (e) => {
    this.setState({
      scheduleByInterval: e.target.checked,
    });
    console.log(`schedule = ${e.target.checked}`);
  };
  removeInterval = (interval) => {
    var intervals = this.state.intervals; 
    this.setState({ loading: true }, function(){
      delete intervals[interval];
      console.log({ intervals });
      this.setState({ intervals: intervals, loading: false }, console.log("intervals", intervals));  
    }); 
   // console.log(" this.state", this.state)
    //   this.setState({
    //   intervals: {
    //     ...this.state.intervals,
    //     [this.state.interval]: this.state.intervals[this.state.interval] || [],
    //   },
    //   interval: "",
    // });
  };

  handlePagination(page) {
 
    var query = {
      page:page-1,
      organisationId:JSON.parse(localStorage.getItem("user")).organisationId
    }
  
    if (JSON.parse(localStorage.getItem("user")).accessLevel == 1) {
      query.organisationId = this.state.organisationId;
    }

    const state = this.state
    if(state.branch){
      query.branch = state.branch
    }
    if(state.designation){
      query.designation = state.designation
    }
    if(state.department){
      query.department = state.department
    }

    Model.default
      .getTrainee(query)
      .then(response => {
        console.log({ response });
        if (!response.error) {
          var { importedUsers } = this.state;
          importedUsers = response.data.trainees;
          this.setState({
            importedUsers,
            loading: false,
            totalRecords: response.data.totalRecords
          });
        }
      });
  }
  assignUsers = (interval) => (e) => {
    console.log("interval selected", interval);
    var organisationId = this.state.user.organisationId;
    if (this.state.user.accessLevel == 1) {
      organisationId = this.state.organisationId;
    }
    if (organisationId) {
      this.showLoader();
      this.setState({
        userModalVisible: true,
        selectedInterval: interval,
        selectedUsers: this.state.intervals[interval],
      });
      var organisationId = this.state.organisationId || this.state.user.organisationId;
      var exclude = [];
      if (this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval") {
        exclude = Object.keys(this.state.intervals).reduce((result, intrval) => {
          if (interval !== intrval) {
            result = result.concat(this.state.intervals[intrval].map((user) => user._id));
          }
          return result;
        }, []);
      }
      console.log("exclude ", exclude);
      TraineeModel.default.getTrainee({ organisationId, exclude: exclude.join(",") }).then((response) => {
        console.log({ response });
        this.hideLoader();
        this.setState({
          importedUsers: response.data.trainees,
          userModalVisible: true,
          branches: response.data.branches,
          designations: response.data.designations,
          departments: response.data.departments,
          selectedUsers:
            this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval"
              ? this.state.intervals[interval]
              : this.state.selectedUsers,
          selectedRowKeys:
            this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval"
              ? this.state.intervals[interval].map((user) => user._id)
              : this.state.selectedRowKeys,
          // selectedRowKeys: [...Array(response.data.trainees.length).keys()]
        });
      });
    } else {
      message.error("Please select client!!");
    }
  };

  render() {
    const { training, loader } = this.props;
    const { fetching, loading, value } = this.state;
    var { intervals } = this.state 
    var columns = [
      // {
      //   title: "Employee Id",
      //   key: "employeeId",
      //   dataIndex: "employeeId"
      // },
      {
        title: "Name",
        key: "userName",
        dataIndex: "userName",
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
      },
      {
        title: "Designation",
        key: "designation",
        dataIndex: "designation",
      },
      {
        title: "Department",
        key: "department",
        dataIndex: "department",
      },
      {
        title: "Branch",
        key: "branch",
        dataIndex: "branch",
      },
    ];
    var { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      // onChange: this.onSelectChange,
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All Data",
          onSelect: () => {
            this.setState({
              selectedRowKeys: this.state.importedUsers.map((user) => user._id), //[...Array(this.state.importedUsers.length).keys()] // 0...45
            });
          },
        },
      ],
    };
    const tablinks = (tabName) => {
      this.setState({ tabName });
    };
    var ln = [];
    var rating = 0;
    if (training && training.rating) {
      rating = training.rating ? training.rating : 0;
    }
    var instructorInfo = training.instructorId;
    rating = this.state.reviews.reduce(function(total, current) {
      return (total = total + current.rating);
    }, 0);

    rating = rating / this.state.reviews.length;
    rating = Math.round(rating);
    console.log({ rating });
    for (var i = 0; i < rating; i++) {
      ln.push(i);
    }
    return (
      <div style={{ margin: "-32px -32px 0" }}>
        <div
          className="course-dhashboard topic2"
          style={{
            marginTop: "-90px",
            backgroundImage: "url(" + require("assets/images/backgrounds/course-dhashboard-bg.jpg") + ")",
          }}
        >
          <div data-uk-grid>
            <div className="uk-width-1-2@m uk-padding-remove-left uk-visible@m">
              <div className="course-video-demo uk-position-relative">
                <img
                  width="100%"
                  src={
                    training.coverImage
                      ? `${imagePrefixUrl}${training.coverImage}`
                      : require("assets/images/courses/cover_image.jpg")
                  }
                  alt={training.name}
                  style={{ border: "4px solid #fefefe" }}
                />
              </div>
            </div>
            <div id="font-size-p" className="uk-width-1-2@m uk-padding">
              <h2 className="uk-light uk-text-uppercase uk-text-bold uk-text-white ">{training.name}</h2>
              <p className="uk-light uk-text-bold uk-padding-remove uk-margin-remove">
                <span style={{ fontWeight: "400" }}>Created by </span>
                {training.authorName}
              </p>
              <p className="uk-light uk-text-bold uk-padding-remove uk-margin-remove">
                <span style={{ fontWeight: "400" }}>Last updated </span>
                {formatDate(training.createdAt, "DD/MM/YYYY")}
              </p>
              <p className="uk-light uk-text-bold">{training.shortDescription}</p>

              <p className="uk-light uk-text-bold uk-text-small">
                {/* {ln.map(item => {
                      return (
                        <i
                          key={"icon-rate" + item}
                          className="fas fa-star ic-icon-small ic-icon-rate"
                        ></i>
                      );
                    })} */}
                <Rate disabled value={rating ? rating : 0} />
                &nbsp;&nbsp;
                {rating ? rating + ".0" : 0} &nbsp;&nbsp; ({this.state.reviews ? this.state.reviews.length : `0`}{" "}
                ratings){" "}
              </p>
              {/* <!-- students images  -->                          */}
              <div
                className="avatar-group uk-margin"
                data-uk-scrollspy="target: > img; cls:uk-animation-slide-right; delay: 200"
              >
                <img src={require("assets/images/avatures/avature-1.png")} alt="" />
                <img src={require("assets/images/avatures/avature.jpg")} alt="" />
                <img src={require("assets/images/avatures/avature-2.png")} alt="" />
                <span className="uk-text-bold uk-light">
                  {" "}
                  {training.userEnrolled ? training.userEnrolled : `0`} user enrolled
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ul className="uk-child-width-expand course-dhashboard-subnav" data-uk-tab>
            <li className="uk-active">
              <a href="#" className="tablinks" onClick={() => tablinks("Description")}>
                {" "}
                Description{" "}
              </a>
            </li>
            {/* <li>
              <a href="#" className="tablinks" onClick={() => tablinks("Instructor")}>
                Instructor
              </a>
            </li> */}
            {/* {training.trainingType !== 4 &&
                training.trainingType !== 3 &&
                this.state.modules.length > 0 && (
                  <li>
                    <a
                      href="#"
                      className="tablinks"
                      onClick={() => tablinks("Course-Videos")}
                    >
                      {" "}
                      <span className="uk-visible@m"> Course </span> Videos{" "}
                    </a>
                  </li>
                )} */}
                {training.showWeb && this.state.user.accessLevel !== 1 &&
                
                <li>
                  <a href="#" className="tablinks" onClick={() => tablinks("Assign_Training")}>
                    {" "}
                    Assign Training{" "}
                  </a>
                </li>
                }
          </ul>
          <div className="uk-container tm-hero">
            <div data-uk-grid>
              {/* <!-- page contant --> */}
              <div className="uk-width-1-1@m uk-first-column">
                {/* <!-- Description tab  -->                          */}
                {this.state.tabName === "Description" && (
                  <div className="  tab-default-open animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                    <div id="font-size-p" dangerouslySetInnerHTML={{ __html: training.description }} />
                  </div>
                )}
                {/* {this.state.tabName === "Instructor" && (
                  <div className="uk-padding-small uk-position-relative">
                    <h2 className="uk-heading-line uk-text-center">
                      <span> Meet the Instructor </span>
                    </h2>
                    <div className="uk-grid-small  uk-margin-medium-top uk-padding-small" data-uk-grid>
                      <div className="uk-width-1-4@m uk-first-column">
                        {instructorInfo.imageUrl && (
                          <img
                            alt="Image"
                            className="uk-width-2-3 uk-margin-small-top uk-margin-small-bottom uk-border uk-box-shadow-large  uk-animation-scale-up"
                            src={imageMediaUrl + instructorInfo.imageUrl}
                          />
                        )}
                        <div className="uk-text-small uk-margin-small-top"> 
                        </div>
                      </div>
                      <div className="uk-width-3-4@m uk-padding-remove-left">
                        <h4 className="uk-margin-remove"> {instructorInfo.name} </h4>
                        <span className="uk-text-small"> {instructorInfo.specialization} </span>
                        <hr className="uk-margin-small" />

                        <span
                          dangerouslySetInnerHTML={{
                            __html: instructorInfo.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                {/* <!-- Assign Training tab  --> */}
                {this.state.tabName === "Assign_Training" && (
                  <div data-uk-grid>
                    {/* <!-- page contant --> */}
                    <div className="uk-width-1-4@m uk-first-column"></div>
                    <div className="uk-width-1-2@m uk-first-column">
                      <Card style={{ padding: "20px" }}>
                        <div className="uk-padding-small uk-position-relative">
                          <div
                            className="uk-margin form-wrap uk-margin-auto "
                            id="assign-training"
                            style={{ fontSize: "16px" }}
                          >
                            {JSON.parse(localStorage.getItem("user")).accessLevel == 1 && (
                              <div id="selectClient" className="input-group">
                                <Row style={{ width: "100%", paddingBottom: "5px" }}>
                                  <Col
                                    xl={9}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={24}
                                    style={{ textAlign: "right", paddingTop: "7px", paddingRight: "0px" }}
                                  >
                                    <label className="input-label uk-margin-remove">Select Client:</label>
                                  </Col>
                                  <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{paddingRight: 0 }}>
                                    <Select
                                      style={{ width: "100%" }}
                                      showSearch
                                      value={value}
                                      placeholder="Select client"
                                      notFoundContent={fetching ? <Spin size="small" /> : null}
                                      filterOption={false}
                                      onSearch={(value) =>
                                        this.setState({ clients: [], hasMoreClients: true }, () =>
                                          this.fetchOrganisations({
                                            page: 0,
                                            searchText: value,
                                          })
                                        )
                                      }
                                      onDropdownVisibleChange={(open) =>
                                        open &&
                                        this.setState({ clients: [], hasMoreClients: true }, () =>
                                          this.fetchOrganisations({ page: 0 })
                                        )
                                      }
                                      onChange={this.handleChange}
                                      loading={this.state.loadingClients}
                                      onPopupScroll={(e) => {
                                        e.persist();
                                        let target = e.target;
                                        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                          // dynamic add options...
                                          console.log("scrolling");
                                          this.fetchOrganisations({
                                            page: this.state.clientPage,
                                          });
                                        }
                                      }}
                                    >
                                      {this.state.clients.map((client) => (
                                        <Option value={client.value + "~" + client.text} key={client.value}>
                                          {client.text}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <div className="input-group">
                              {" "}
                              <Row style={{ width: "100%" }}>
                                <Col
                                  xl={9}
                                  lg={10}
                                  md={10}
                                  sm={10}
                                  xs={24}
                                  style={{ textAlign: "right", paddingTop: "7px", paddingRight: "0px" }}
                                >
                                  <label className="expiryLabel input-label uk-margin-remove">Expiry Date:</label>
                                </Col>
                                <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{paddingRight: 0 }}>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    disabledDate={(current) => {
                                      return current && current < moment();
                                    }}
                                    onOpenChange={(value) => {
                                      console.log({ value });
                                    }}
                                    onChange={this.onChange}
                                    defaultValue={moment(this.state.expiryDate)}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div id="trainingCheckBox input-group">
                              <Row>
                                <Col
                                  xl={9}
                                  lg={10}
                                  md={10}
                                  sm={10}
                                  xs={24}
                                  style={{ textAlign: "right", paddingRight: "4px" }}
                                >
                                  <Checkbox
                                    id="handleCheckBox"
                                    onChange={this.handleCheckBox}
                                    style={{ margin: "10px" }}
                                  />
                                </Col>
                                <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{ padding: "8px" }}>
                                  {" "}
                                  <label
                                    for="handleCheckBox"
                                    className="checkBoxLabel"
                                    style={{ fontWeight: 600, color: "black" }}
                                  >
                                    Automatic Delivery of Campaign Reports
                                  </label>
                                </Col>
                              </Row>
                            </div>
                            <div className="input-group">
                              <Row style={{ width: "100%", paddingTop: "5px" }}>
                                <Col
                                  xl={9}
                                  lg={10}
                                  md={10}
                                  sm={10}
                                  xs={24}
                                  style={{ textAlign: "right", paddingTop: "7px", paddingRight: "0px" }}
                                >
                                  <label className="input-label uk-margin-remove">Schedule Later:</label>
                                </Col>
                                <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{ paddingTop: "5px" }}>
                                  <Radio.Group
                                    // className="uk-float-right"
                                    name="scheduleLater"
                                    onChange={this.onRadioChange}
                                    value={this.state.scheduleLater}
                                    buttonStyle="solid"
                                  >
                                    <Radio value={"yes"}>Yes</Radio>
                                    <Radio value={"no"}>No</Radio>
                                  </Radio.Group>
                                </Col>
                              </Row>
                            </div>
                            {this.state.scheduleLater === "yes" && (
                              <div>
                                <div className="input-group">
                                  <Row style={{ width: "100%", paddingTop: "5px" }}>
                                    <Col
                                      xl={9}
                                      lg={10}
                                      md={10}
                                      sm={10}
                                      xs={24}
                                      style={{ textAlign: "right", paddingTop: "7px", paddingRight: "0px" }}
                                    >
                                      <label className="input-label uk-margin-remove">Schedule By:</label>
                                    </Col>
                                    <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{ paddingTop: "5px" }}>
                                      <Radio.Group
                                        // className="uk-float-right"
                                        name="scheduleBy"
                                        onChange={this.onRadioChange}
                                        value={this.state.scheduleBy}
                                      >
                                        <Radio value={"date"}>Date</Radio>
                                        <Radio value={"interval"}>Interval</Radio>
                                      </Radio.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                            {this.state.scheduleBy == "date" && this.state.scheduleLater === "yes" ? (
                              <div id="scheduleDateTime" className="input-group">
                                <Row style={{ width: "100%", paddingTop: "5px" }}>
                                  <Col
                                    xl={9}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={24}
                                    style={{ textAlign: "right", paddingTop: "7px", paddingRight: "0px" }}
                                  >
                                    <label className="input-label uk-margin-remove">Schedule Date Time:</label>
                                  </Col>
                                  <Col xl={15} lg={14} md={14} sm={14} xs={24} style={{paddingRight: 0 }}>
                                    <DatePicker
                                      style={{ width: "100%" }}
                                      className="uk-float-right"
                                      showTime
                                      disabledDate={(current) => {
                                        return current && current < moment();
                                      }}
                                      disabledTime={(d) => !d || d.isBefore(moment().add(30, 'seconds'))}
                                      onOpenChange={(value) => {
                                        console.log({ value });
                                      }}
                                      onChange={this.scheduleLater}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              this.state.scheduleBy == "interval" &&
                              this.state.scheduleLater === "yes" && (
                                <div>
                                  <div id="scheduleInterval" className="input-group">
                                    <Row style={{ width: "100%", paddingTop: "5px" }}>
                                      <Col
                                        xl={9}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={24}
                                        style={{ textAlign: "right", paddingTop: "12px", paddingRight: 0 }}
                                      >
                                        <label className="intervalLabel input-label uk-margin-remove">
                                          Choose Interval:
                                        </label>
                                      </Col>
                                      <Col xl={11} lg={10} md={10} sm={10} xs={24}>
                                        <DatePicker
                                          style={{ width: "100%" }}
                                          disabledDate={(current) => {
                                            return current && current < moment();
                                          }}
                                          // disabledDate={d => !d || d.isBefore(new Date())}
                                          disabledTime={(d) => !d || d.isBefore(new Date())}
                                          onOpenChange={(value) => {
                                            console.log({ value });
                                          }}
                                          showTime
                                          onChange={(value) => {
                                            this.setState({
                                              interval: value ? value.toDate() : "",
                                            });
                                          }}
                                          style={{ margin: "5px" }}
                                        />
                                      </Col>
                                      <Col xl={4} lg={4} md={4} sm={4} xs={24} style={{ paddingTop: "5px", paddingLeft:0, paddingRight: 0 , textAlign:"right" }}>
                                        <Button
                                          type="info"
                                          style={{ marginBottom: 0, marginRight:0 }}
                                          disabled={!this.state.interval}
                                          onClick={ this.createIntervals}
                                        >
                                          Create
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                  {this.state.intervals && (
                                    <div
                                      className="intervals  uk-padding "
                                      style={{ paddingTop: "5px", paddingBottom: "0px" }}
                                    >
                                      {Object.keys(this.state.intervals).map((interval) => {
                                        console.log({interval})
                                        return (
                                          <div
                                            className="interval"
                                            key={interval}
                                            //
                                          >
                                            <span
                                              className="badge"
                                              title="Assign Users"
                                              onClick={this.assignUsers(interval)}
                                            >
                                              {this.state.intervals[interval].length}
                                            </span>
                                            <span>{moment(interval).format("lll")}</span>
                                            <i
                                              className="fa fa-times"
                                              onClick={() => this.removeInterval(interval)}
                                            ></i>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                            <Row style={{ width: "100%", padding: "14px" , paddingRight:0}}>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ paddingRight:0}}>
                                <div className="input-group" style={{ float: "right" }}>
                                  <Button
                                    type="info"
                                    style={{ marginRight:0}}
                                    // className="assignButton primary"
                                    onClick={(e) =>
                                      this.state.scheduleLater == "yes" && this.state.scheduleBy == "interval"
                                        ? this.assignIntervalTraining(e)
                                        : this.chooseUsers(e)
                                    }
                                  >
                                    {this.state.loading ? <Icon type="loading" /> : null}
                                    Assign
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className="uk-width-1-4@m uk-first-column"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
        <Modal
          title="Select User"
          visible={this.state.userModalVisible}
          onOk={() => this.assignTraining()}
          onCancel={this.hideUserModal}
          width={"80%"}
          // height={"50%"}
          // okText="Assign"
          footer={[
            <Button type="danger" key="1" onClick={this.hideUserModal}>
              Cancel
            </Button>,
            <Button type="info" key="ok" onClick={() => this.assignTraining()}>
              Assign
            </Button>,
          ]}
        >
          {this.state.loading ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : (
            <div style={{ paddingBottom: 20 }}>
              <Row>
                <Col span={6}>Select Groups</Col>
                <Col span={8} className="tree-selector">
                  <TreeSelect
                    showSearch
                    style={{ width: "100%", height: "100%" }}
                    value={this.state.selectedGroups}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onChange={this.onTreeSelectChange}
                  >
                    <TreeNode value="branch-all" title="Branches" key="0-1">
                      {this.state.branches.map(function(val) {
                        if (val) {
                          return <TreeNode key={val} value={"branch-" + val} title={val} />;
                        }
                      })}
                    </TreeNode>

                    <TreeNode value="department-all" title="Departments" key="0-2">
                      {this.state.departments.map(function(val) {
                        if (val) {
                          return <TreeNode key={val} value={"department-" + val} title={val} />;
                        }
                      })}
                    </TreeNode>
                    <TreeNode value="designation-all" title="Designations" key="0-3">
                      {this.state.designations.map(function(val) {
                        if (val) {
                          return <TreeNode key={val} value={"designation-" + val} title={val} />;
                        }
                      })}
                    </TreeNode>
                  </TreeSelect>
                </Col>
                <Col span={4}>
                  <Button icon="search" type="info" onClick={this.fetchUsers}>
                    Fetch Users
                  </Button>
                </Col>
              </Row>

              <Table
                className="gx-table-responsive"
                columns={columns}
                dataSource={this.state.importedUsers}
                bordered={false}
                rowSelection={rowSelection}
                rowKey={(record) => record._id}
                scroll={{ x: 1000 }}
                pagination={{
                  total: this.state.totalRecord,
                  showTotal: total => `Total ${this.state.totalRecord} Items`,
  
                  onChange: page => {
                    console.log(page);
  
                    this.handlePagination(page);
                  }
                }}
              />
            </div>
          )}
        </Modal>
      </div>
      /* <Auxiliary>
        <Modal
          title="Select User"
          visible={this.state.userModalVisible}
          onOk={() => this.assignTraining()}
          onCancel={this.hideUserModal}
          width={"80%"}
          // height={"50%"}
          okText="Assign"
        >
          {this.state.loading ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : (
            <div style={{ paddingBottom: 20 }}>
              <Row>
                <Col span={6}>Select Groups</Col>
                <Col span={8} className="tree-selector">
                  <TreeSelect
                    showSearch
                    style={{ width: "100%", height: "100%" }}
                    value={this.state.selectedGroups}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onChange={this.onTreeSelectChange}
                  >
                    <TreeNode value="branch-all" title="Branches" key="0-1">
                      {this.state.branches.map(function(val) {
                        if (val) {
                          return (
                            <TreeNode
                              key={val}
                              value={"branch-" + val}
                              title={val}
                            />
                          );
                        }
                      })}
                    </TreeNode>

                    <TreeNode
                      value="department-all"
                      title="Departments"
                      key="0-2"
                    >
                      {this.state.departments.map(function(val) {
                        if (val) {
                          return (
                            <TreeNode
                              key={val}
                              value={"department-" + val}
                              title={val}
                            />
                          );
                        }
                      })}
                    </TreeNode>
                    <TreeNode
                      value="designation-all"
                      title="Designations"
                      key="0-3"
                    >
                      {this.state.designations.map(function(val) {
                        if (val) {
                          return (
                            <TreeNode
                              key={val}
                              value={"designation-" + val}
                              title={val}
                            />
                          );
                        }
                      })}
                    </TreeNode>
                  </TreeSelect>
                </Col>
                <Col span={4}>
                  <Button
                    icon="search"
                    type="primary"
                    onClick={this.fetchUsers}
                  >
                    Fetch Users
                  </Button>
                </Col>
              </Row>
             
              <Table
                className="gx-table-responsive"
                columns={columns}
                dataSource={this.state.importedUsers}
                bordered={false}
                rowSelection={rowSelection}
                rowKey={record => record._id}
                scroll={{ x: 1000 }}
              />
               
            </div>
          )}
        </Modal>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1>Trainings</h1>
            <div className="gx-campaign-space" />
          </Col>
        </Row>

        {loader ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : (
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card style={{ padding: "20px" }}>
                <Row>
                  <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                    <div style={{ padding: "15px 15px 4px 15px" }}>
                      <Card loading={loader} actions={[]}>
                        <img
                          width="100%"
                          src={
                            training.coverImage
                              ? `${imagePrefixUrl}${training.coverImage}`
                              : require("assets/images/courses/cover_image.jpg")
                          }
                          alt="preview"
                        />
                      </Card>
                    </div>
                  </Col>
                  <Col xl={10} lg={10} md={10} sm={24} xs={24} offset={4}>
                    <div style={{ padding: "12px 1px 4px 1px" }}>
                      <Title level={2}> {training.name} </Title>
                      <p style={{ fontSize: "15px", margin: "4px" }}>
                        <span style={{ fontWeight: "400" }}>Created by </span>
                        {training.authorName}
                      </p>
                      <p style={{ fontSize: "15px", margin: "4px" }}>
                        <span style={{ fontWeight: "400" }}>Last updated </span>
                        {formatDate(training.createdAt, "DD/MM/YYYY")}
                      </p>

                      <p>
                        <span
                          style={{
                            fontStretch: "1px",
                            lineHeight: "1.43",
                            fontSize: "15px"
                          }}
                        >
                          <Rate
                            style={{ fontSize: "18px" }}
                            allowHalf
                            disabled
                            defaultValue={training.rating}
                          />
                          {" " + (training.rating ? training.rating : 0) + " "}(
                          {training.ratings ? training.ratings : 0} ratings)
                          {" " +
                            (training.userEnrolled
                              ? training.userEnrolled
                              : "0")}{" "}
                          user enrolled
                        </span>
                      </p>
                    </div>
                    <div className="uk-margin form-wrap">
                      {JSON.parse(localStorage.getItem("user")).accessLevel ==
                        1 && (
                        <div id="selectClient" className="input-group">
                          <label className="input-label">Select Client:</label>
                          <Select
                            showSearch
                            value={value}
                            placeholder="Select client"
                            notFoundContent={
                              fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={value =>
                              this.setState(
                                { clients: [], hasMoreClients: true },
                                () =>
                                  this.fetchOrganisations({
                                    page: 0,
                                    searchText: value
                                  })
                              )
                            }
                            onDropdownVisibleChange={open =>
                              open &&
                              this.setState(
                                { clients: [], hasMoreClients: true },
                                () => this.fetchOrganisations({ page: 0 })
                              )
                            }
                            onChange={this.handleChange}
                            loading={this.state.loadingClients}
                            onPopupScroll={e => {
                              e.persist();
                              let target = e.target;
                              if (
                                target.scrollTop + target.offsetHeight ===
                                target.scrollHeight
                              ) {
                                // dynamic add options...
                                console.log("scrolling");
                                this.fetchOrganisations({
                                  page: this.state.clientPage
                                });
                              }
                            }}
                          >
                            {this.state.clients.map(client => (
                              <Option
                                value={client.value + "~" + client.text}
                                key={client.value}
                              >
                                {client.text}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div className="input-group">
                        <label className="expiryLabel input-label">
                          Expiry Date:
                        </label>
                        <DatePicker
                          disabledDate={current => {
                            return current && current < moment();
                          }}
                          onOpenChange={value => {
                            console.log({ value });
                          }}
                          onChange={this.onChange}
                          defaultValue={moment(this.state.expiryDate)}
                        />
                      </div>
                      <div id="trainingCheckBox input-group">
                        <Checkbox
                          id="handleCheckBox"
                          onChange={this.handleCheckBox}
                          style={{ margin: "10px" }}
                        />
                        <label
                          for="handleCheckBox"
                          className="checkBoxLabel"
                          style={{ fontWeight: 600, color: "black" }}
                        >
                          Automatic Delivery of Campaign Reports
                        </label>
                      </div>
                      <div className="input-group">
                        <label className="input-label">Schedule Later:</label>
                        <Radio.Group
                          className="uk-float-right"
                          name="scheduleLater"
                          onChange={this.onRadioChange}
                          value={this.state.scheduleLater}
                        >
                          <Radio value={"yes"}>Yes</Radio>
                          <Radio value={"no"}>No</Radio>
                        </Radio.Group>
                      </div>
                      {this.state.scheduleLater === "yes" && (
                        <div>
                          <div className="input-group">
                            <label className="input-label">Schedule By:</label>
                            <Radio.Group
                              className="uk-float-right"
                              name="scheduleBy"
                              onChange={this.onRadioChange}
                              value={this.state.scheduleBy}
                            >
                              <Radio value={"date"}>Date</Radio>
                              <Radio value={"interval"}>Interval</Radio>
                            </Radio.Group>
                          </div>
                          {this.state.scheduleBy == "date" ? (
                            <div id="scheduleDateTime" className="input-group">
                              <label className="input-label">
                                Schedule Date Time:
                              </label>
                              <DatePicker
                                className="uk-float-right"
                                showTime
                                disabledDate={current => {
                                  return current && current < moment();
                                }}
                                disabledTime={d => !d || d.isBefore(new Date())}
                                onOpenChange={value => {
                                  console.log({ value });
                                }}
                                onChange={this.scheduleLater}
                              />
                            </div>
                          ) : (
                            this.state.scheduleBy == "interval" && (
                              <div>
                                <div
                                  id="scheduleInterval"
                                  className="input-group"
                                >
                                  <label className="intervalLabel input-label">
                                    Choose Interval:
                                  </label>
                                  <DatePicker
                                    disabledDate={current => {
                                      return current && current < moment();
                                    }}
                                    // disabledDate={d => !d || d.isBefore(new Date())}
                                    disabledTime={d =>
                                      !d || d.isBefore(new Date())
                                    }
                                    onOpenChange={value => {
                                      console.log({ value });
                                    }}
                                    showTime
                                    onChange={value => {
                                      this.setState({
                                        interval: value ? value.toDate() : ""
                                      });
                                    }}
                                    style={{ margin: "5px" }}
                                  />
                                  <Button
                                    primary
                                    style={{ marginBottom: "0px" }}
                                    disabled={!this.state.interval}
                                    onClick={this.createIntervals}
                                  >
                                    Create
                                  </Button>
                                </div>
                                <div className="intervals">
                                  {Object.keys(this.state.intervals).map(
                                    interval => (
                                      <div
                                        className="interval"
                                        key={interval}
                                        onClick={this.assignUsers(interval)}
                                        key={interval}
                                      >
                                        <span className="badge">
                                          {
                                            this.state.intervals[interval]
                                              .length
                                          }
                                        </span>
                                        <span>
                                          {moment(interval).format("lll")}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}

                      <Button
                        className="assignButton primary"
                        onClick={e =>
                          this.state.scheduleLater == "yes" &&
                          this.state.scheduleBy == "interval"
                            ? this.assignIntervalTraining(e)
                            : this.chooseUsers(e)
                        }
                      >
                        {this.state.loading ? <Icon type="loading" /> : null}
                        Assign
                      </Button>
                    </div>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div style={{ padding: "1px 15px 15px 15px" }}>
                       
                      <span
                        dangerouslySetInnerHTML={{
                          __html: training.description
                        }}
                        style={{ height: "100px" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Auxiliary> */
    );
  }
}

// export default TrainingInfo;

const mapStateToProps = ({ auth, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  const { loader, alertMessage, showMessage, training, allTraining } = trainingInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    accessToken,
    training,
    allTraining,
  };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    showMessage,
    getAllTraining,
    getTraining,
    showLoader,
  }
)(TrainingInfo);
