import { api, apiUrl } from "util/config";
import {message} from 'antd'
import $ from 'jquery'
var Actions ={

  getTrainingDetails:(payload)=>{
    message.destroy()
    return new Promise((resolve, reject) => {
     
      var form = new FormData();
      // form.append("users", trainees);
      form.append("trainingId", payload.trainingId);
      // const userData = localStorage.getItem('user');
      //   const user = JSON.parse(userData); 
      // form.append("organisationId", user.organisationId);
  
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee/trainings",
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        dataType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          // fngprt: m.cookie.get("fngprt")
        },
        data: payload,
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
           
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();window.location.reload();
          } else {
            message.error("Some error has occurred.");
            
          }
        }
      };
  
      $.ajax(settings).done();
    });
    
  },
  
  getMyTrainings: () =>{
    message.destroy()
    return new Promise((resolve, reject) => { 
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      // const userData = localStorage.getItem('user');
      //   const user = JSON.parse(userData); 
      // form.append("organisationId", user.organisationId);
  
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee/trainings",
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {},
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
           
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();window.location.reload();
          } else {
            message.error("Some error has occurred.");
            
          }
        }
      };
  
      $.ajax(settings).done();
    });
  },
 
}


export default Actions