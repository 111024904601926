import React from "react";

import TopNavbar from "./../components/TopNavbar";
import CatagoryNavbar from "./../components/CatagoryNavbar";
import TrainingTypeInfo from "./../components/CatagoryInfo/trainingType";

import { arrBookmarks } from "./../data";

class Bookmarks extends React.PureComponent {
  state = {
    _id: 0
  };

  render() {
    return (
      <div style={{ minHeight: "90vh" }}>
        <div>
          <TopNavbar key="topNavbarKey" />
          <ul key="catagory-navbar" className="uk-switcher switcher-container">
            {arrBookmarks &&
              arrBookmarks.map((categoryInfo, index) => {
                return (
                  <li className={index === this.state._id ? "uk-active" : ""}>
                    <CatagoryNavbar
                      categoryInfo={categoryInfo}
                      index={index + 1}
                    />
                  </li>
                );
              })}
          </ul>
          <button
            className="uk-button uk-hidden@m  mobile-catagory-button"
            type="button"
            data-uk-toggle="target: #tabs-moible; cls: tabs-moible"
          >
            {" "}
            Open subcatagory{" "}
          </button>
        </div>
        <ul
          key="CatagoryInfo"
          id="my-id2"
          className="uk-switcher switcher-container uk-margin"
        >
          {arrBookmarks &&
            arrBookmarks.map((categoryInfo, index) => {
              return (
                <li className={index == this.state._id ? "uk-active" : ""}>
                  <TrainingTypeInfo trainingType="bookmarks" />
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default Bookmarks;
// export default withRouter(Bookmarks) ;
