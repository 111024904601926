import React from "react";
// import "./index.less";
import { Icon } from 'antd' 
import TopNavbar from "./components/TopNavbar"; 

class NotFound extends React.PureComponent {
 
  render() { 
    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        <div className="uk-container uk-margin uk-align-center">
          {/* <h3
            className="uk-margin-small-bottom  "
            style={{ textAlign: "center" }}
          >
            {"Upcoming Training"}
          </h3> */}
        </div>
        <div className="uk-container uk-margin-medium-top">
          <div className="uk-card " style={{ marginBottom: "50px",textAlign:"center",marginTop:50, marginBottom:50, paddingBottom:20, paddingTop:20, minHeight:440 }}>
           
              <Icon type="frown-o" style={{fontSize:42}} />
              <h1>404 Not Found</h1>
          </div>
        </div>
      </div>
    );
  }
}
export default NotFound;
