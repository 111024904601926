import React from "react";
import CircularProgress from "components/CircularProgress";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Button, message, Row, Col } from "antd";
import IntlMessages from "util/IntlMessages";
import { userRedirect, showAuthLoader, hideMessage, userSignInSuccess } from "./../../appRedux/actions/Auth";
import store from "store";

import CheckTokenModel from "./../model.js";

class RedirectFile extends React.Component {
  state = {
    loader: true,
    messageStatus: true,
    errorMessage: "",
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(window.location.search);
    const authValue = urlParams.get("_id") || this.props.match.params._id;
    this.props.showAuthLoader();
    localStorage.setItem("accessToken", authValue);
    console.log("----------------------------------@@@--------")
    // store.set("accessToken",this.props.match.params._id)
    this.props.userRedirect({ token: this.props.match.params._id });
   
  }
  componentDidMount() {
    this.props.hideMessage();
    var self = this;
    document.addEventListener("message", function(e) {
      console.log("--------------", { e }); // Prints "Example of an event"
      self.setState({
        errorMessage: e.detail.error,
      });
    });
    document.addEventListener("redirectUser", function(e) {
      console.log("--------------", { e }); // Prints "Example of an event"
      // window.location.reload();
      //   var user = store.get("user")
      // if(user){
      //   if ( user.trainee) {
      //     return <Redirect to={"/trainee"} />;
      //   } else {
      //     return <Redirect to={"/main/dashboard/crm"} />;
      //   }
      // }
    });
    // window.location.reload();
  }

  componentDidUpdate() {
    const { loader, alertMessage, showMessage } = this.props;
    const { messageStatus } = this.state;
    if (messageStatus && alertMessage && alertMessage != "") {
      this.setState({ messageStatus: false });
    }
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }
  render() {
    const { loader, alertMessage, showMessage } = this.props;
    console.log("---------------------------&&&&&&&&&&&&&&&&------------");
    const { messageStatus } = this.state;
    return (
      <div>
        {messageStatus && alertMessage && alertMessage != "" ? message.error(alertMessage.toString()) : null}

        {loader && <CircularProgress />}
        <div
          style={{
            background: "white",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          <h2>
            Your session has expired <br></br>please login again or forgot password
          </h2>
          <Button
            onClick={() => {
              this.props.history.push("/signin");
            }}
            className=" uk-button-success"
            style={{
              backgroundColor: "#4cd964",
              color: "#ffffff",
              cursor: "pointer",
              border: "none",
              padding: "0 40px",
              fontWeight: "900",
            }}
          >
            <IntlMessages id="app.userAuth.signIn" />
          </Button>
          <br />
          <span className="gx-signup-form-forgot gx-link">
            <a
              onClick={() => {
                this.props.history.push("/forgot");
              }}
            >
              <IntlMessages id="app.userAuth.forgotPassword" />
            </a>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage } = auth;
  return {
    loader,
    alertMessage,
    showMessage,
  };
};

export default connect(
  mapStateToProps,
  {
    userRedirect,
    showAuthLoader,
    hideMessage,
    userSignInSuccess,
  }
)(RedirectFile);
