import { api, apiUrl } from "../../../util/config";
import { message } from "antd";
import $ from "jquery";
var Actions = {
  updateTrainingInstructor: info => {
    console.log("info---", { info });
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/instructor";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: info,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            message.success(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
        }
      };
      $.ajax(settings).done();
    });
  },
  fetchTrainingInstructor: instructorId => {
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/instructor";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { instructorId },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            if (!instructorId) {
              // message.success(response.userMessage);
            }
          }

          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
        }
      };
      $.ajax(settings).done();
    });
  }
};
$.ajaxQ = (function() {
  var id = 0,
    Q = {};

  $(document).ajaxSend(function(e, jqx) {
    jqx._id = ++id;
    Q[jqx._id] = jqx;
  });
  $(document).ajaxComplete(function(e, jqx) {
    delete Q[jqx._id];
  });

  return {
    abortAll: function() {
      var r = [];
      $.each(Q, function(i, jqx) {
        r.push(jqx._id);
        jqx.abort();
      });
      return r;
    }
  };
})();

export default Actions;
