import React from 'react';
import { Tabs, List } from 'antd';
import Widget from 'components/Widget';
import QuizItem from './QuizItem';

const TabPane = Tabs.TabPane;

class QuizList extends React.Component {
	render() {
		const { quizList } = this.props;
		const runningList = quizList.filter((item) => item.status === 'running' && item.quizId);

		const completedList = quizList.filter((item) => item.status === 'completed' && item.quizId);
		return (
			<Widget
				title="Quizzes"
				styleName="gx-card-tabs"
				extra={<i className="icon icon-search-new gx-pointer gx-fs-xxl gx-text-primary" />}
			>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Running" key="1">
						<List
							itemLayout="vertical"
							size="large"
							pagination={{
								onChange: (page) => {
									console.log(page);
								},
								pageSize: 5
							}}
							dataSource={runningList}
							renderItem={(item) => <QuizItem key={item._id} data={item} />}
						/>
					</TabPane>
					<TabPane tab="Completed" key="2">
						<List
							itemLayout="vertical"
							size="large"
							pagination={{
								onChange: (page) => {
									console.log(page);
								},
								pageSize: 5
							}}
							dataSource={completedList}
							renderItem={(item) => <QuizItem key={item._id} data={item} />}
						/>
					</TabPane>
				</Tabs>
			</Widget>
		);
	}
}

export default QuizList;
