import React from "react";
import { Link } from "react-router-dom";

import { IconFont } from "util/Utils";
import "./style.less";
import store from "store";
import { getSubdomain } from '../../util/Utils';
class Footer extends React.Component {
  render() {
    const { onClickViewModel, location } = this.props;
    console.log("location ", this.props);
    const fontStyle = {
      textDecoration: "none",
      color: "#ffffff",
      fontSize: "19px",
      textTransform: "uppercase",
      textAlign: "center",
      fontWeight: "600",
    };
    const fontStyle2 = {
      textDecoration: "none",
      color: "#ffffff",
      fontSize: "21px",
      textTransform: "uppercase",
      //   textAlign: "center",
      margin: "0px",
      fontWeight: "600",
    };

    let footerStyle = {};

    if (
      location &&
      (location.pathname === "/trainee/profile" ||
        location.pathname === "/trainee/settings" ||
        location.pathname === "/trainee/calendar" ||
        location.pathname === "/trainee/your/training" ||
        location.pathname === "/trainee/cart")
    ) {
      footerStyle = {
        // position: "fixed",
        bottom: 0,
        width: "100%",
      };
    }

    return (
      <div>
        <div
          className="uk-section-default uk-section"
          style={{ background: "#f9f9f9" }}
        >
          <div className="uk-container">
            <div className="uk-flex-middle" data-uk-grid>
              <div className="uk-width-1-1@m">

        <div
          className="uk-flex uk-flex-middle"
          // style={{ marginBottom: "-50px" }}
        >
        <i className={("fa fa-logo-black-icon " + getSubdomain())} ></i>
          {/* <IconFont
            type="icon-logo_web "
            style={{
              fontSize: "150px", 
              marginTop: "-28px",
              marginRight: "14px",
            }}
          ></IconFont> */}
          <div className="footer-title-top">
          <h2 className="uk-text-bold  "> 
                  An <strong > EC-C<span>ounc</span>il</strong> Company
                </h2>
          </div>
        </div>
                {/* <h1 className="uk-text-bold  ">
                  {" "}
                  Interactive Training Materials
                </h1> */}
                {/* <p className="uk-text-white uk-text-medium">Improve learner engagement, in addition to improving workflow and promoting higher levels of productivity through a variety of content materials such as gamification, training modules, quizzes and videos.Our training materials include:</p> */}

                <p className=" uk-text-medium" style={{color:"#000000"}}>
                At OhPhish, we believe mitigating cybersecurity risks, especially those involving human error, often begins with changing the cybersecurity hygiene of end-users. Our solution, which combines simulated phishing attacks with set-and-go training modules, can help improve awareness, alter user behavior and reduce the risk associated with social engineering attacks.
                </p>
                 
              </div> 
            </div>
          </div>
        </div>
        {/* 101C Sun Ave NE Albuquerque, New Mexico - 87109 */}
        <div  className="footer-bottom-bg-color" >
          <div className="uk-container text-center" >  
            <div className="footer-bottom-logo" >
              <i className={("fa fa-logo-white-icon " + getSubdomain())} ></i>
              {/* <IconFont
                type="icon-logo_web_white"
                style={{
                  fontSize: "150px",  
                }}
              ></IconFont>   */}
              </div>
            <h3 className=" uk-margin-remove uk-text-white"  style={{fontWeight:"500", fontSize: "1.3rem"}}>
            101C Sun Ave NE Albuquerque, New Mexico - 87109</h3>
            <p className="  uk-margin-remove uk-text-white" style={{  fontSize: "1.1rem"}}>www.ohphish.eccouncil.org | support@ohphish.com</p>
          </div>   
        </div>
        
      </div>
    );
  }
}

export default Footer;
