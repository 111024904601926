import React from "react";
import MetaTags from 'react-meta-tags';
export default class Traineedetail extends React.Component {


    componentWillMount(){

    }

    render(){
      const props = this.props

      console.log({props})
        return (
            <div className="wrapper">
              <MetaTags>
               <title>{props.match.params.name}</title>
                <meta name="description" property="og:description" content="Some description." />
                <meta name="title" property="og:title" content="Just earned CSCU badge from Ec-Council" />
                <meta name="image" property="og:image" content={require("assets/images/badges_image/cscu_badge.png")}/>
              </MetaTags>
              <div className="uk-container"> 
              <div className="uk-cover-container">

              <div >
                <div style={{height:400,backgroundColor:"#434f71",color:'white'}}>

                  <div>{props.match.params.name}</div>

                </div>
              </div>
   
              </div>

              
               </div>
            </div>
          )
    }
}