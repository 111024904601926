import React, { Component } from "react";
import { Layout } from "antd";

// import Sidebar from "./Sidebar/index";
import SideNavbar from "./../SideNavbar";
import TopNavbar from "./../TopNavbar";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

// import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import Customizer from "containers/Customizer";
import { connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";

import "./../../assets/css//main.css";
import "./../../assets/css/uikit.css";
import "./../../assets/css/fontawesome.css";
const { Content, Footer } = Layout;

export class MainApp extends Component {
  componentDidMount() {
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    if (!user) {
      // this.props.history.push("/home");
      this.props.history.push("/signin");
    } else if (user.trainee) {
      this.props.history.push("/trainee");
    }
  }
  render() {
    const { match, location } = this.props;
    console.log("match", { match })
    return (
      <Layout className="gx-app-layout app">
        {location.pathname.indexOf("/redirect") == -1 ? <SideNavbar /> : ""}
        <Layout>
          {location.pathname.indexOf("/redirect") == -1 ? <TopNavbar /> : ""}
          {location.pathname.indexOf("/trainings/details") == -1 &&
            <div className="dark-background" />
          }
          <Content className={`gx-layout-content`}>
            <App match={match} />
            {location.pathname.indexOf("/redirect") == -1 ? (
              <Footer>
                <div className="gx-layout-footer-content">{footerText}</div>
              </Footer>
            ) : (
                ""
              )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
};
export default connect(mapStateToProps)(MainApp);
