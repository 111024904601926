import React from 'react';  

function CourseVideoTabs( propsInfo){ 
    console.log({propsInfo})  
    return ( 
        
    <div className="demo1" data-simplebar> 
        <ul className="course-list video-list" data-uk-switcher="connect: #component-nav; animation: uk-animation-slide-right-small, uk-animation-slide-left-medium">
            {/* <!--  Lesson one --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate">Introduction to HTML</span>
                    <time>3 min. 40 sec.</time>
                </a>
            </li>                                         
            {/* <!--  Lesson two  --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Creating first page </span> 
                    <time>5 min. 0 sec.</time>
                </a>
            </li>                                         
            {/* <!--  Lesson three  --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Adding some pargraph </span>
                    <time>3 min. 30 sec.</time>
                </a>
            </li> 
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Adding some pargraph </span>
                    <time>3 min. 30 sec.</time>
                </a>
            </li> 
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Adding some pargraph </span>
                    <time>3 min. 30 sec.</time>
                </a>
            </li> 
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Adding some pargraph </span>
                    <time>3 min. 30 sec.</time>
                </a>
            </li> 
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Adding some pargraph </span>
                    <time>3 min. 30 sec.</time>
                </a>
            </li>                                         
            {/* <!--  Lesson Four  --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Tags and Elements </span>
                    <time>4 min. 50 sec.</time>
                </a>
            </li>                                         
            {/* <!--  Lesson five  --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Form Elements  </span>
                    <time>3 min. 20 sec.</time>
                </a>
            </li>                                         
            {/* <!--  Lesson six  --> */}
            <li>
                <a href="#">
                    <img src="../assets/images/courses/course-lesson-video-list.jpg" alt=""/>
                    <i className="fas fa-play-circle play-icon"></i>
                    <span className="now-playing">Now Playing</span>
                    <span className="uk-text-truncate"> Table & list </span>
                    <time>2 min. 20 sec.</time>
                </a>
            </li>                                         
        </ul>
    </div>

    ) 
}
export default CourseVideoTabs;