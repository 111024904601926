import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import TrainingCampaignTable from "../../campaigns/campaigns/components/TrainingCampaignTable";
import { Col, Row, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
import IntlMessages from "util/IntlMessages";

import {
  getAllCampaignTrainings,
  getDashboard,
  hideMessage,
  showMessage,
  showLoader
} from "../../../appRedux/actions/Dashboard";

// import {
// 	hideMessage,
// 	showMessage,
// 	getAllCampaign,
// 	showLoader,
//   } from "../../../appRedux/actions/Campaign";

const convertToObject = url => {
  const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
  let params = {};
  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i],
      value = arr[i + 1];
    params[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }
  return params;
};

class Campaigns extends PureComponent {
  state = {
    campType: 2
  };

  componentWillMount() {
    const { location } = this.props;
    const obj = convertToObject(location.search);
    this.props.showLoader();
    console.log({ location });
    // this.props.getAllCampaign(obj)
    this.props.getAllCampaignTrainings(obj);
    this.setState({
      campType:
        typeof obj.live !== "undefined" ? Number(obj.live) : this.state.campType
    });
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevObj = convertToObject(prevProps.location.search);
    const obj = convertToObject(location.search);
    if (obj.live !== prevObj.live) {
      this.props.showLoader();
      this.props.getAllCampaignTrainings(obj);
      // this.setState({campType: (typeof obj.live !== 'undefined') ? Number(obj.live): this.state.campType})
    }
  }

  render() {
    const {
      loader,
      alertMessage,
      showMessage,
      pagination,
      allCampaigns,
      campaignTrainings
    } = this.props;
    if (!campaignTrainings) {
      return <CircularProgress></CircularProgress>;
    }
    const campaignProps = {
      campaignsType: this.state.campType,
      pagination: pagination,
      data: campaignTrainings.campaigns,
      loader: loader,
      title: "", //"Recent Taining Campaings",
      handleChange: e => {
        var newQuery = {};
        newQuery["live"] = e.target.value;
        this.setState({ campType: newQuery["live"] });
        this.props.showLoader();
        this.props.getAllCampaignTrainings(newQuery);
      },
      handlePagination: newQuery => {
        console.log({ newQuery });
        console.log("this.state.campType", this.state.campType);
        newQuery["live"] = this.state.campType;
        console.log({ newQuery });
        this.props.showLoader();
        this.props.getAllCampaignTrainings(newQuery);
      },
      onClick: record => {
        this.props.history.push({
          pathname: `${"/campaigns/"}${record._id}${"/details/training"}`
        });
      },
      searchText: e => {
        console.log("e---7777------", e.target.value);
        let val = e.target.value;
        setTimeout(
          function() {
            this.props.showLoader();
            this.props.getAllCampaignTrainings({
              searchText: val
            });
          }.bind(this),
          200
        );
      }
      // onMenuClick: (key, record) => {
      //     if( key === '1') {
      //         this.props.history.push( {pathname:`${'/campaigns/'}${record._id}${'/details/training'}`});
      //     } else if ( key === '2' ) {
      //         this.props.history.push( {pathname:`${'/campaigns/'}${record._id}${'/users/training'}`});
      //     }
      // }
    };
    return (
      <Auxiliary>
        {showMessage
          ? alertMessage
            ? message.error(alertMessage.toString())
            : null
          : null}

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1><IntlMessages id="dashboard.trainingCampaign"/></h1>
            <div className="gx-campaign-space" />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <TrainingCampaignTable {...campaignProps} />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

// export default Campaigns;

const mapStateToProps = ({ dashboardInfo }) => {
  // const {  loader, alertMessage, showMessage, campaigns, allCampaigns, pagination } = campaignInfo;
  const {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    pagination,
    dashboard,
    campaignTrainings
  } = dashboardInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    pagination,
    campaignTrainings,
    dashboard
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideMessage,
    showMessage,
    getAllCampaignTrainings,
    showLoader
  })(Campaigns)
);
