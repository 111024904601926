import React from "react";
import { Link } from "react-router-dom";

import { Table, Button, message } from "antd";
import TopNavbar from "../components/TopNavbar";
import CartModel from "./model";
import { imagePrefixUrl, apiUrl, stripeKey } from "util/config";
import store from "store";
import emptyCartImg from "../../../assets/images/empty_cart_icon.png";

class MyCart extends React.PureComponent {
  state = {
    _id: 0,
    trainings: [],
    total: 0
  };

  componentWillMount() {
    this.setState({ trainings: store.get("myCart") });
    CartModel.getCart().then(response => {
      if (!response.error) {
        this.setState({ trainings: response.data });
        store.set("myCart", response.data);
      }
    });
  }

  onChange(value) {
    // console.log("changed", value);
  }

  onClickRemoveToCart = (trainingId, index) => {
    CartModel.actionCart(trainingId, "remove").then(response => {
      if (!response.error) {
        var storeTraining = store.get("myCart");
        if (storeTraining.length > 1) {
          storeTraining.splice(index, 1);
        } else {
          storeTraining = [];
        }
        store.set("myCart", storeTraining);

        var event = new CustomEvent("myCart", {
          detail: { storeTraining }
        });
        document.dispatchEvent(event);
        this.setState({ trainings: storeTraining });
      }
    });
  };
  displayPrice(price) {
    return parseFloat(price).toFixed(2);
  }

  createSessionCheckout = trainings => () => {
    fetch(`${apiUrl}api/lms/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      body: JSON.stringify({
        trainingIds: trainings.map(training => training.trainingId._id)
      })
    })
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        if (resp.error) {
          message.error(resp.userMessage, 5);
        } else {
          console.log(resp.data);
          window
            .Stripe(stripeKey)
            .redirectToCheckout({
              sessionId: resp.data
            })
            .then(function(result) {
              if (result.error) {
                // var displayError = document.getElementById("error-message");
                message.error(result.error.message, 5);
                // displayError.textContent = result.error.message;
              }
            });
        }
      });
  };
  render() {
    const totalPrice = this.state.trainings
      ? this.state.trainings.reduce(function(cnt, o) {
          return cnt + o.trainingId.price;
        }, 0)
      : 0;
    var columns = [
      {
        title: (
          <div style={{ left: "40px", position: "absolute" }}>Product</div>
        ),
        key: "id",
        render: (value, row, index) => {
          return (
            <div className="uk-margin-remove" style={{ borderRadius: "5px" }}>
              <img
                className="uk-align-left uk-width-1-3@m uk-margin-remove-bottom"
                src={
                  row.trainingId.coverImage
                    ? `${imagePrefixUrl}${row.trainingId.coverImage}`
                    : require("assets/images/courses/cover_image.jpg")
                }
              />
              <div className="uk-padding-small ">
                <h4
                  className="uk-margin-small-bottom uk-margin-remove "
                  style={{ textAlign: "left" }}
                >
                  {row.trainingId.name}
                </h4>

                <p style={{ margin: "5px 0", textAlign: "left" }}>
                  Category:{" "}
                  {row.trainingId.trainingType === 1 ||
                  row.trainingId.trainingType === 2
                    ? "Premium"
                    : "Basic"}
                </p>
                <a
                  className="uk-link-reset"
                  onClick={() => {
                    this.onClickRemoveToCart(row.trainingId._id, index);
                  }}
                >
                  <p
                    style={{
                      margin: "5px 0",
                      textAlign: "left",
                      textDecorationLine: "underline",
                      color: "#30826c"
                    }}
                  >
                    Remove
                  </p>
                </a>
              </div>
            </div>
          );
        }
      },
      {
        title: "Price",
        key: "price",
        dataIndex: "trainingId.price",
        render: price => <span>${this.displayPrice(price)}</span>
      }
      // {
      //     title: 'Quantity',
      //     key: 'quantity',
      //     dataIndex: 'quantity',
      //     render: quantity => <InputNumber min={1} max={10} defaultValue={1} value={quantity} onChange={this.onChange}  style={{width:'50px'}}/>,
      // },
      // {
      //     title: 'Total',
      //     key: 'total',
      //     dataIndex: 'total',
      //     render: total => <span>${this.displayPrice(total)}</span>,
      // }
    ];
    return (
      <div>
        <div>
          <TopNavbar key="topNavbarKey" />
        </div>
        <div className="uk-container uk-margin uk-align-center">
          <h3
            className="uk-margin-small-bottom  "
            style={{ textAlign: "center" }}
          >
            My Cart
          </h3>
        </div>
        {this.state.trainings.length > 0 ? (
          <div
            className="uk-container uk-margin uk-align-center"
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
          >
            <Table
              className="gx-table-responsive"
              pagination={false}
              columns={columns}
              dataSource={this.state.trainings ? this.state.trainings : []}
              bordered={false}
            />
            <div
              className="  uk-margin uk-margin-left uk-margin-right"
              style={{ borderRadius: "5px" }}
            >
              <div className=" uk-margin-top  " style={{ textAlign: "center" }}>
                <Link
                  className="Course-tags "
                  to="/trainee/premium"
                  style={{
                    borderRadius: "27px",
                    fontWeight: "600",
                    background: "#ffffff",
                    padding: "8px 18px",
                    color: "#30826c",
                    textDecoration: "none",
                    border: "1px solid #30826c",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                  }}
                >
                  Continue Learning
                </Link>
              </div>
              <div>
                <h4
                  className=" uk-margin-remove"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  Total: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; $
                  {this.displayPrice(totalPrice)}
                </h4>

                {/* <p style={{ margin: "5px 0", textAlign:'right', color:'#30826c' }}>taxes and shipping calculated at checkout</p>  */}

                <div className=" uk-margin-top uk-float-right ">
                  <a
                    className="Course-tags "
                    style={{
                      borderRadius: "27px",
                      fontWeight: "600",
                      background: "#30826c",
                      padding: "8px 18px",
                      color: "#ffffff",
                      textDecoration: "none",
                      border: "1px solid #30826c",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                    }}
                    onClick={this.createSessionCheckout(this.state.trainings)}
                  >
                    Checkout
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={emptyCartImg}
                style={{ height: "200px", width: "250px" }}
              />
              <h3
                className="uk-margin-small-bottom  "
                style={{ textAlign: "center" }}
              >
                Your cart is empty now.
              </h3>
              <Button
                style={{ background: "#2243da", color: "white" }}
                onClick={() => this.props.history.push("/trainee")}
              >
                Explore More
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
//
export default MyCart;
