import React from "react";

function Description(info) {
  const { description ,language} = info;
  console.log({ info });
  return (
    <div className="  tab-default-open animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
      <div  className={language == "ar" ? "rtl-text-right":""} id="font-size-p" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

export default Description;
