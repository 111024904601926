import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { apiUrl } from "util/config";
import { Col, Row, message, Card, Icon, List } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CampaignDetails from "./components/CampaignDetails";
import CampaignReports from "./components/CampaignReports";
import TrainingReports from "./components/TrainingReports";
import UserTrainingReports from "./components/UserTrainingReports";
import ChartReports from "./components/chartReports";
import TopHeader from "./components/TopHeader";
import UserTable from "../users/components/userTable";
import TrainingUserTable from "../users/components/trainingUserTable";
import ViewModal from "./components/modal";
import ViewModalReport from "./components/modalReport";

import CircularProgress from "components/CircularProgress/index";

import ModalTrainingDetails from "./../users/components/modalTrainingDetails";
import ModalBrowserInfo from "./../users/components/modalBrowserInfo";
import ModalCredentials from "./../users/components/modalCredentials";

import noDataImage from "assets/images/no_data.gif";

import {
  hideMessage,
  showMessage,
  getCampaignsAnalytics,
  getAllCampaignsInfo,
  getCampaignsUsers,
  campaignUser,
  downloadXlsxFile,
  downloadPdfFile,
  downloadCertificate,
  showLoader,
  hideLoader,
  showModal,
  hideModal
} from "../../../appRedux/actions/Campaign";
var Model = require("./model");

class Campaigns extends PureComponent {
  state = {
    keyInfo: {},
    index: 0,
    changeData: false,
    type: "campaigns",
    campaignId: "",
    userPage: 0
  };
  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const campaignId = params._id;
    this.setState({ campaignId });
    this.props.showLoader();
    this.props.getCampaignsUsers(campaignId, params.type, this.state.userPage);
    this.setState({ type: params.type });
    this.props.getCampaignsAnalytics(campaignId);
    this.props.getAllCampaignsInfo({});
    this.setState({ changeData: true });
  }

  componentDidUpdate(prevProps) {
    const { match, allCampaignsInfo } = this.props;
    const { params } = match;
    const { campaignId, type, userPage } = this.state;
    if (this.state.changeData) {
      var index = allCampaignsInfo.findIndex(item => item._id === campaignId);
      if (index !== -1) {
        this.setState({ index });
      }
      this.props.showLoader();
      this.props.getCampaignsUsers(campaignId, type, userPage);
      this.props.getCampaignsAnalytics(campaignId);
      this.setState({ changeData: false });
    }
  }

  downloadXlsxReport = type => {
    const { match, campaignsAnalytics } = this.props;
    const { campaign } = campaignsAnalytics;
    const { params } = match;
    // const campaignId = params._id;
    const campaignId = this.state.campaignId;
    this.props.showLoader();
    var paramsObj = {};
    paramsObj["type"] = type;
    paramsObj["fileName"] = `${campaign.name}-${type}_report.xlsx`;
    var obj = {};
    obj["downloadUrl"] = `api/campaigns/${campaignId}/xlsreport`;
    obj["type"] = type;
    obj["name"] = campaign.name;
    obj["paramsObj"] = paramsObj;
    obj["fileType"] = `-${type}_report.xlsx`;
    this.props.downloadXlsxFile(obj);
  };

  downloadPhishingXlsxReport = () => {
    const { match, campaignsAnalytics } = this.props;
    const { campaign } = campaignsAnalytics;
    const { params } = match;
    // const campaignId = params._id;
    const campaignId = this.state.campaignId;
    this.props.showLoader();
    var paramsObj = {};
    var obj = {};
    if (this.state.type == "training") {
      obj["downloadUrl"] = `api/lms/trainings/${campaignId}/users`;
      obj["name"] = `Trainings-${campaign.name.replace(/\s/g, "-")}`;
      paramsObj["export"] = "xlsx";
      obj["paramsObj"] = paramsObj;
      obj["fileType"] = `.xlsx`;
    } else {
      obj["downloadUrl"] = `api/campaigns/${campaignId}/report`;
      obj["name"] = `Phishing-Simulation-${campaign.name.replace(/\s/g, "-")}`;
      obj["paramsObj"] = paramsObj;
      obj["fileType"] = `.xlsx`;
    }
    this.props.downloadXlsxFile(obj);
  };

  downloadPhishingDocReport = () => {
    const campaignId = this.state.campaignId;
    // this.props.showLoader();
    
    window.open(apiUrl + "api/trainings/" + campaignId + "/doc_report?token="+localStorage.getItem("accessToken"),"_blank");
    // Model.default.downloadDoc(campaignId).then(response => {
    //   if (!response.error) {
    //     this.props.hideLoader();
    //     window.open(response.data);
    //   } else {
    //     this.props.hideLoader();
    //     alert(response.error);
    //   }
    // });
  };

  downloadPdfReport = type => {
    const { match, campaignsAnalytics } = this.props;
    const { campaign } = campaignsAnalytics;
    const { params } = match;
    // const campaignId = params._id;
    const campaignId = this.state.campaignId;
    this.props.showLoader();
    var paramsObj = {};
    paramsObj["type"] = type;
    paramsObj["fileName"] = `${campaign.name}-${type}_report.xlsx`;
    var obj = {};
    obj["downloadUrl"] = `api/campaigns/${campaignId}/xlsreport`;
    obj["type"] = type;
    obj["name"] = campaign.name;
    obj["paramsObj"] = paramsObj;
    obj["fileType"] = `-${type}_report.xlsx`;
    this.props.downloadXlsxFile(obj);
  };
  viewReportModal(keyInfo) {
    this.setState({ keyInfo });
    this.props.showModal("reportModal");
  }

  render() {
    const {
      allCampaignsInfo,
      loader,
      alertMessage,
      showMessage,
      campaignsAnalytics,
      userCampaignInfo,
      modalVisible,
      trainingModalVisible,
      browserModalVisible,
      credentialsModalVisible,
      reportModalVisible,
      campaignsUsers,
      pagination,
      userPagination,
      match
    } = this.props;
    const {
      graphReport,
      campaign,
      analytics,
      trainingAnalytics
    } = campaignsAnalytics;
    console.log(this.props, "this.props--");

    const campaignDetails = {
      campaign: campaign,
      showModal: viewModal => {
        this.props.showModal(viewModal);
      }
    };
    const topHeaderView = {
      campaign: allCampaignsInfo,
      index: this.state.index,
      onChangeSearch: index => {
        this.setState({ index });
        this.props.showLoader();

        this.setState({ campaignId: allCampaignsInfo[index]._id });
        this.props.getCampaignsUsers(
          allCampaignsInfo[index]._id,
          this.state.type,
          this.state.userPage
        );
        this.props.getCampaignsAnalytics(allCampaignsInfo[index]._id);
      }
    };
    const viewModal = {
      loader: loader,
      campaign: campaign,
      modalVisible: modalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const viewModalReport = {
      loader: loader,
      graphReport: this.state.keyInfo,
      modalVisible: reportModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const campaignReportsInfo = {
      campaign: campaign,
      campaignSummary: analytics
    };
    console.log("campaign-----", campaign);
    const userCampaignProps = {
      campaign: campaign,
      pagination: pagination,
      data: campaignsUsers || [],
      pagination: userPagination,
      loader: loader,
      type: this.state.type,
      showModal: (viewModal, record) => {
        console.log({ record });
        this.props.campaignUser(record);
        this.props.showModal(viewModal);
      },
      hideModal: () => {
        this.props.hideModal();
      },
      downloadCertificate: (campaignId, traineeId, trainingName) => {
        this.props.showLoader();
        Model.default
          .downloadCerificate(campaignId, traineeId, trainingName)
          .then(response => {
            if (!response.error) {
              this.props.hideLoader();
              message.success("Certificate Downloaded!!");
            } else {
              this.props.hideLoader();
              message.error(response.error);
            }
          });
        // var paramsObj = {}
        // paramsObj['traineeId'] = traineeId;
        // var obj = {}
        // obj['downloadUrl'] = `api/trainings/${campaignId}/download`;
        // obj['name'] = trainingName;
        // obj['paramsObj'] = paramsObj;
        // obj['fileType'] = '.pdf'; //fileType
        // this.props.downloadCertificate(obj)
      },
      promptInput: (campaignId, type) => {
        Model.default.sendReminder(campaignId, type).then(response => {
          if (!response.error) {
            this.props.hideLoader();
            message.success(response.error);
          } else {
            this.props.hideLoader();
            message.error(response.error);
          }
        });
      },
      handlePagination: userPage => {
        const { campaignId, type } = this.state;
        this.props.getCampaignsUsers(campaignId, type, userPage);
        this.setState({ userPage });
      }
    };

    const trainingModal = {
      campaign: campaign,
      loader: loader,
      trainingDetails: userCampaignInfo,
      modalVisible: trainingModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      },
      downloadCertificate: (campaignId, traineeId, trainingName) => {
        this.props.showLoader();
        var paramsObj = {};
        paramsObj["traineeId"] = traineeId;
        var obj = {};
        obj["downloadUrl"] = `api/trainings/${campaignId}/download`;
        obj["name"] = trainingName;
        obj["paramsObj"] = paramsObj;
        obj["fileType"] = ".pdf"; //fileType
        this.props.downloadCertificate(obj);
      }
    };

    const browserModal = {
      loader: loader,
      campaign: campaign,
      info: userCampaignInfo,
      modalVisible: browserModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    const credentialsModal = {
      loader: loader,
      campaign: campaign,
      info: userCampaignInfo,
      modalVisible: credentialsModalVisible,
      handleCancel: () => {
        this.props.hideModal();
      }
    };
    var targat = 0; //
    var type = this.state.type;
    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1>
              {type == "training"
                ? "Training Detailed Report"
                : "Campaign Detailed Report"}
            </h1>
            {loader ? null : (
              <span style={{ position: "absolute", right: "0px", top: "0px" }}>
                 <div className="  " id="circle-button-expanding">
            <ul>
              <a onClick={() => this.downloadPhishingXlsxReport()}  >
                <li>
                  <span>Download Excel</span>
                  <Icon type="file-excel" />
                </li>
              </a>
                {type == "training" && (
                  <a onClick={() => this.downloadPhishingDocReport()}  >
                    <li>
                      <span>Download Report</span>
                      <Icon type="file-word" />
                    </li>
                  </a>
                )}
            </ul>
            </div>
                {/* <a
                  onClick={() => this.downloadPhishingXlsxReport()}
                  style={{
                    color: "rgb(99, 138, 109)",
                    margin: "5px",
                    fontSize: "32px"
                  }}
                >
                  <Icon type="file-excel" />
                </a>
                {type == "training" ? (
                  <a
                    onClick={() => this.downloadPhishingDocReport()}
                    style={{
                      color: "rgba(4, 2, 77, 0.58)",
                      margin: "5px",
                      fontSize: "32px"
                    }}
                  >
                    <Icon type="file-word" />
                  </a>
                ) : (
                  ""
                )} */}
              </span>
            )}
            <div className="gx-campaign-space" />
          </Col>
        </Row>
        {loader ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {type != "training" ? (
              <Row>
                {allCampaignsInfo.length > 0 && !this.state.changeData && (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card style={{ marginBottom: "5px", padding: "5px" }}>
                      <TopHeader {...topHeaderView}></TopHeader>
                    </Card>
                  </Col>
                )}

                {campaignsAnalytics.campaign && (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card title="Campaign Details">
                      <CampaignDetails {...campaignDetails}></CampaignDetails>
                    </Card>
                  </Col>
                )}
              </Row>
            ) : null}
            {campaign && trainingAnalytics.length > 0 ? (
              trainingAnalytics.map(training => {
                return (
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Card title="Training Summary">
                        <TrainingReports
                          trainingSummary={training}
                          campaign={campaign}
                        ></TrainingReports>
                      </Card>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div>
                {type == "training" && (
                  <Card>
                    <Row>
                      <Col xl={8} lg={8} md={24} sm={24} xs={24} />
                      <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                        {/* <img src={noDataImage} />  */}
                        <h2
                          style={{
                            margin: "80px 0",
                            padding: "30px 0",
                            textAlign: "center",
                            color: "#282828 !important",
                            fontSize: "1.6em"
                          }}
                        >
                          No data generated yet
                        </h2>
                      </Col>
                      <Col xl={8} lg={8} md={24} sm={24} xs={24} />
                    </Row>
                  </Card>
                )}
              </div>
            )}

            <UserTrainingReports campaign_id={match.params._id} />
            {type != "training" ? (
              <Row>
                {campaign &&
                  analytics.map(analytic => {
                    return (
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Card title="Campaign Summary">
                          <CampaignReports
                            campaign={campaign}
                            campaignSummary={analytic}
                          ></CampaignReports>
                        </Card>
                      </Col>
                    );
                  })}

                {campaignsAnalytics.campaign &&
                  Object.keys(graphReport).map(report => {
                    return graphReport[report].data.length == 0 ||
                      (graphReport[report].data.length == 1 &&
                        !graphReport[report].data[0]._id) ? null : (
                      <Col
                        className="card-view"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                      >
                        <Card
                          title={graphReport[report].title}
                          extra={
                            <span>
                              <a
                                onClick={() =>
                                  this.downloadXlsxReport(
                                    graphReport[report].key
                                  )
                                }
                                style={{
                                  color: "rgb(99, 138, 109)",
                                  margin: "5px",
                                  fontSize: "16px"
                                }}
                              >
                                <Icon type="file-excel" />
                              </a>
                              <a
                                onClick={() =>
                                  this.downloadPdfReport(
                                    graphReport[report].key
                                  )
                                }
                                style={{
                                  color: "rgb(204, 75, 76)",
                                  margin: "5px",
                                  fontSize: "16px"
                                }}
                              >
                                <Icon type="file-pdf" />
                              </a>
                              <a
                                onClick={() =>
                                  this.viewReportModal(graphReport[report])
                                }
                                style={{
                                  color: "#000",
                                  margin: "5px",
                                  fontSize: "16px"
                                }}
                              >
                                <Icon type="eye" />
                              </a>
                            </span>
                          }
                        >
                          <ChartReports
                            Report={graphReport[report]}
                            campaign={campaignsAnalytics.campaign}
                            analytics={analytics}
                          ></ChartReports>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            ) : null}
          </div>
        )}
        {!loader && campaign && campaignsUsers.length > 0 && (
          <div>
            {type != "training" ? (
              // <Row>
              //   <Col xl={24} lg={12} md={12} sm={12} xs={24}>
                  <UserTable {...userCampaignProps} />
              //   </Col>
              // </Row>
            ) : (
              // <Row>
              //   <Col xl={24} lg={12} md={12} sm={12} xs={24}>
                  <TrainingUserTable {...userCampaignProps} />
              //   </Col>
              // </Row>
            )}
          </div>
        )}

        {browserModalVisible && <ModalBrowserInfo {...browserModal} />}
        {credentialsModalVisible && <ModalCredentials {...credentialsModal} />}
        {trainingModalVisible && <ModalTrainingDetails {...trainingModal} />}
        {modalVisible && <ViewModal {...viewModal} />}
        {reportModalVisible && (
          <ViewModalReport {...viewModalReport}></ViewModalReport>
        )}

        {showMessage
          ? alertMessage
            ? message.error(alertMessage.toString())
            : null
          : null}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ campaignInfo }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    allCampaignsInfo,
    campaignsAnalytics,
    userCampaignInfo,
    modalVisible,
    trainingModalVisible,
    browserModalVisible,
    credentialsModalVisible,
    reportModalVisible,
    campaignsUsers,
    pagination,
    userPagination
  } = campaignInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    allCampaignsInfo,
    campaignsAnalytics,
    userCampaignInfo,
    modalVisible,
    trainingModalVisible,
    browserModalVisible,
    credentialsModalVisible,
    reportModalVisible,
    campaignsUsers,
    pagination,
    userPagination
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideMessage,
    showMessage,
    getCampaignsAnalytics,
    getAllCampaignsInfo,
    getCampaignsUsers,
    campaignUser,
    downloadXlsxFile,
    downloadPdfFile,
    downloadCertificate,
    showLoader,
    hideLoader,
    showModal,
    hideModal
  })(Campaigns)
);
