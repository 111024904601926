import $ from "jquery";
import { message } from "antd";
import { apiUrl } from "../../util/config";
import { userSignOut } from "./../../appRedux/actions/Auth";

var Actions = {
  fetchUsersFromFile: file => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("file", file);
      form.append("campaignType", "email");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/import/file",
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: form,
        success: function(response) {
          response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          console.log({ error });

          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
             localStorage.clear();window.location.reload();
            userSignOut();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  saveTrainee: trainees => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("trainees", trainees);
      // form.append("campaignType", "email");

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee",
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: form,
        success: function(response) {
          response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            // location.reload();
            userSignOut();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },

  testAd: data => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("username", data.username);
      form.append("domain", data.domain);
      form.append("password", data.password);

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/adconnection",
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: form,
        success: function(response) {
          console.log({ response });
          response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          console.log({ error });
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            // location.reload();
            userSignOut();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  }
};

export default Actions;
