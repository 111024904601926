import React from "react";
import moment from "moment";
import { campaignType } from "util/Utils";
import { Table, Tag, Tooltip, Divider, Radio, Input } from "antd";
import DropOption from "./DropOption";
import Widget from "components/Widget/index";
import CircularProgress from "components/CircularProgress/index";
import IntlMessages from "util/IntlMessages";

const formatDate = (date, format) => moment(date).format(format);

const { Search } = Input;
const renderCampaignType = type => {
  return campaignType.map((tag, index) => {
    if (type && type === tag.type) {
      return tag.title;
    }
    return "";
  });
};

const renderStatus = campaign => {
  var active = moment(campaign.expireAt).isAfter(moment());
  var scheduleDate = campaign.scheduleDate
    ? moment(campaign.scheduleDate).format("LLL")
    : null;

  return campaign.scheduleDate &&
    moment(campaign.scheduleDate).isAfter(new Date()) ? (
    <Tooltip placement="top" title={`Campaign is scheduled on ${scheduleDate}`}>
      <Tag
        color="#038fde"
        className="gx-text-white gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2"
      >
        Scheduled
      </Tag>
    </Tooltip>
  ) : active ? (
    <Tooltip
      placement="top"
      title={
        scheduleDate
          ? `Campaign was scheduled on ${scheduleDate} and now currently is in progress.`
          : `Campaign is running.`
      }
    >
      <Tag
        color="#fa8c15"
        className="gx-text-white gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2"
      >
        In Progress
      </Tag>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title={`Campaign is completed.`}>
      <Tag
        color="#4CAF50"
        className="gx-text-white gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2"
      >
        Completed
      </Tag>
    </Tooltip>
  );
};

const CampaignTable = campaignProps => {
  const {
    loader,
    handleChange,
    handlePagination,
    campaignsType,
    data,
    onClick,
    searchText,
    title
  } = campaignProps;
  console.log("campaignsType", campaignProps);

  const columns = [
    {
      title: <IntlMessages id="dashboard.campaign"/>,
      key: "campaign",
      dataIndex: "name",
      render: (text, record) => (
        <span
          onClick={e => {
            onClick(record);
          }}
          className="gx-link"
        >
          {text}
        </span>
      )
    },
    {
      title: <IntlMessages id="dashboard.client"/>,
      key: "client",
      dataIndex: "organisation.name",
      render: client => <span>{client}</span>
    },
    // {
    //     title: 'Campaign Type',
    //     key: 'campaign-type',
    //     dataIndex: 'campaignType',
    //     render: campaignType => <Tag className="gx-bg-grey gx-text-grey gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2" style={{color:'#fff'}}>
    //         { renderCampaignType(campaignType) }
    //     </Tag>,
    // },
    {
      title: <IntlMessages id="dashboard.status"/>,
      key: "status",
      render: (text, record) => <span>{renderStatus(record)}</span>
    },
    {
      title: <IntlMessages id="dashboard.assigned-training"/>,
      key: "assigned-training",
      dataIndex: "trainingId.name",
      render: trainingName => (
        <span>{trainingName ? trainingName : "No Training Assigned"}</span>
      )
    },
    {
      title: <IntlMessages id="dashboard.started"/>,
      key: "started",
      dataIndex: "createdAt",
      render: date => <span>{date ? formatDate(date, "lll") : "N/A"}</span>
    },
    {
      title: <IntlMessages id="dashboard.stopped"/>,
      key: "stopped",
      dataIndex: "expireAt",
      render: date => <span>{date ? formatDate(date, "ll") : "N/A"}</span>
    },
    // {
    //     title: 'Scheduled',
    //     key: 'scheduled',
    //     dataIndex: 'scheduleDate',
    //     render: date => <span>{date ? formatDate(date, 'll') : 'NA'}</span>,
    // },
    {
      title: <IntlMessages id="dashboard.sent"/>,
      key: "sent",
      dataIndex: "users"
    }
    // {
    //     title: 'Clicked',
    //     key: 'clicked',
    //     dataIndex: 'defaulters',
    // },
    // {
    //     title: 'Compliance',
    //     key: 'compliance',
    //     dataIndex: 'compliance',
    //     render: compliance => <span>{`${compliance?compliance.toFixed(2):'N/A'}%`}</span>,
    // },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     fixed: 'right',
    //     render: (text, record) => {
    //         return (
    //             <DropOption
    //                 onMenuClick={e => { onMenuClick( e.key, record)}}
    //                 menuOptions={[
    //                     { key: '1', name: 'Report' },
    //                     { key: '2', name: 'Details' },
    //                 ]}
    //             />
    //         )
    //     },
    // }
  ];

  return (
    <Widget
      styleName="gx-campaign-card"
      title={
        <div>
          <div className="ant-row-flex gx-justify-content-between">
            <h3 className="h4">{title}</h3>
            {/* <h3 className="h4">{title|| "Recent Campaigns"}</h3> */}
            <div className="ant-row-flex">
              {(campaignsType!== -1) ?<Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={campaignsType}
                          value = {campaignsType}  onChange={handleChange}
                        >
                            
                            <Radio.Button value={2} className="gx-mb-1"><IntlMessages id="dashboard.all"/></Radio.Button>
                            <Radio.Button value={1} className="gx-mb-1"><IntlMessages id="dashboard.active"/></Radio.Button>
                            <Radio.Button value={0} className="gx-mb-1"><IntlMessages id="dashboard.completed"/></Radio.Button>
                        </Radio.Group>:null}
            </div>
            <span className="gx-ml-2 custom-search">
              <Input type="search" placeholder="Search" onChange={searchText} />
              {/* <i className="icon icon-search-new gx-text-primary gx-fs-xxl gx-pointer" /> */}
              {/* <Search placeholder="input search text" onChange={searchText} onSearch={value => console.log({value})} enterButton /> */}
            </span>
          </div>
          {/* <Divider /> */}
        </div>
      }
    >
      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : (
        <Table
          className="gx-table-responsive"
          pagination={
            campaignsType !== -1
              ? {
                  ...campaignProps.pagination,
                  showTotal: total => `Total ${total} Items`,

                  onChange: (page, live) => {
                    handlePagination({
                      page: page,
                      live: live ? live : undefined
                    });
                  }
                }
              : false
          }
          columns={columns}
          dataSource={data}
          bordered={false}
          scroll={{ x: 1000 }}
        />
      )}
    </Widget>
  );
};

export default CampaignTable;
