import React from "react";
import { Route, Switch } from "react-router-dom";
import Quizzes from "./quizzes/index";
import CreateQuiz from './create/index'

const Quizzer = ({ match }) => {
    console.log("---", match)
    return (
        <Switch>
            <Route path={`${match.url}/quizzes`} component={Quizzes} />
            <Route path={`${match.url}/create`} component={CreateQuiz} />
        </Switch>
    )
};

export default Quizzer;
