
  "use strict";
  // const crypto = require("crypto");
  import converterWrapper from './converterWrapper'
  var rsaWrapper
  var crypto = window.crypto.subtle;
  var rsaParams = { name: "RSA-OAEP", hash: { name: "SHA-1" } };

  function importPublicKey(keyInPemFormat) {
    return new Promise(function(resolve, reject) {
      var key = converterWrapper.convertPemToBinary2(keyInPemFormat);
      key =  converterWrapper.base64StringToArrayBuffer(key);

      crypto
        .importKey("spki", key, rsaParams, false, ["encrypt"])
        .then(function(cryptokey) {
          resolve(cryptokey);
        });
    });
  }

  function importPrivateKey(keyInPemFormat) {
    var key =  converterWrapper.convertPemToBinary2(keyInPemFormat);
    key =  converterWrapper.base64StringToArrayBuffer(key);

    return new Promise((resolve, reject) => {
      crypto
        .importKey("pkcs8", key, rsaParams, false, ["decrypt"])
        .then(function(cryptokey) {
          resolve(cryptokey);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  function publicEncrypt(keyInPemFormat, message) {
    return new Promise(function(resolve, reject) {
      importPublicKey(keyInPemFormat).then(function(key) {
        crypto
          .encrypt(rsaParams, key, converterWrapper.str2abUtf8(message))
          .then(function(encrypted) {
            resolve(converterWrapper.arrayBufferToBase64String(encrypted));
          });
      });
    });
  }
  function base64StringToArrayBuffer(b64str) {
    b64str = b64EncodeUnicode(b64str);
    var byteStr = atob(b64str);
    var bytes = new Uint8Array(byteStr.length);
    for (var i = 0; i < byteStr.length; i++) {
        bytes[i] = byteStr.charCodeAt(i);
    }
    return bytes.buffer;
    }
    
    
    function b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    }
  function privateDecrypt(keyInPemFormat, encryptedBase64Message) {
    return new Promise((resolve, reject) => {
      importPrivateKey(keyInPemFormat)
        .then(function(key) {
          crypto
            .decrypt(
              rsaParams,
              key,
              converterWrapper.base64StringToArrayBuffer(encryptedBase64Message)
            )
            .then(function(decrypted) {
              // console.log(new Uint8Array(decrypted));
              // resolve(new Uint8Array(decrypted));
              resolve(converterWrapper.arrayBufferToUtf8(decrypted));
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => reject(err));
    });
  }

  export default rsaWrapper = {
    importPrivateKey: importPrivateKey,
    importPublicKey: importPublicKey,
    privateDecrypt: privateDecrypt,
    publicEncrypt: publicEncrypt
  };

