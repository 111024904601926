import { apiUrl } from "util/config";
import { message } from "antd";

import $ from "jquery";
//updateMyCartTraining
var Actions = {
  ///trainee/notification
  fetchNotification: () => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/trainee/notification";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            //message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  actionCart: (trainingId, action) => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/cart",
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: {
          trainingId,
          action
        },
        success: function(response) {
          if (response.error) {
            if (action !== "remove") {
              message.error(response.userMessage);
            }
          } else {
            // var user = JSON.parse(localStorage.getItem('user'))
            // user.organisation = response.data
            // localStorage.setItem('user', JSON.stringify(user))
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  getCart: query => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/cart",
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  }
};

export default Actions;
