import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import Layout from "./layout";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:
import { AppContainer } from "react-hot-loader";
import { stripeKey } from "util/config";
import { StripeProvider } from "react-stripe-elements";
const cookie = require("js-cookie");

class App extends React.Component {
  state = {
    stripe: null
  };
  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      var script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.setState({ stripe: window.Stripe(stripeKey) }, () => {
          console.log(window.Stripe);
        });
      };
      document.head.appendChild(script);
    }
  }
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Layout />
      </StripeProvider>
    );
  }
}

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(<App />);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(<App />);
  });
}
