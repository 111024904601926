import React from "react";
import { Checkbox, Radio, Input, Button } from "antd";
import { Col, Row, message, Card, Icon, List } from "antd";
import Widget from "components/Widget/index";
import Auxiliary from "util/Auxiliary";
import "./index.less";
import store from "store";

const { Search } = Input;
function TrainingFilter(propsInfo) {
  console.log({ propsInfo });
  const {
    topicsList,
    categoryList,
    pricesList,
    durationsList,
    topicValue,
    categoryValue,
    priceValue,
    durationValue,
    onChangeTopic,
    onChangeCategory,
    onChangePrice,
    onChangeDuration,
    onApplyFilter,
    trainingType,
    searchText,
    onSearchText,
    onChangeText,
    loading,
    languageList,
    languageValue,
    onChangeLanguage, 
  } = propsInfo;

  return (
    <div key="Search-view">
      <span style={{ position: "absolute", right: "0px", top: "0px" }}>
        <div className="  " id="circle-button-expanding">
          <ul> 
              <li className="uk-dropdown-close" id="search-expanding">
                <span > 
                  <Input
                    key="search-filter"
                    placeholder="Search"
                    value={searchText}
                    onChange={onChangeText}
                    onSearch={value => {
                      onSearchText(value);
                    }} 
                    // enterButton
                  />
                </span>
                <i className="fa fa-search"  ></i>
              </li> 
            <a
            href="#"
            className="uk-link-reset uk-text-white"
            // data-uk-tooltip="title: Filter; pos: top-right"
            data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
          > 
                  <li>
                    <span>Filter</span>
                    <i className="fas fa-filter "></i>
                  </li>
                </a> 
          </ul>
        </div>
        
      </span> 
    
      <div className="gx-campaign-space" />

     

      <div
              id="Course-Filter"
              hidden
              className="uk-margin-top uk-margin-large-bottom uk-padding uk-position-relative uk-card-default uk-card-hover uk-card-small Course-card"
            >
                {/* <Widget
          styleName="gx-campaign-card" 
        > */}
              <h3 className="uk-visible@s uk-text-uppercase uk-margin-remove"> {""} &nbsp;</h3>
              <div   style={{position: "absolute",  top: "20px",  right: "20px"}}>
                <button
                  className="uk-button uk-button-white"
                  data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
                >
                  Cancel
                </button>
                <button
                  onClick={onApplyFilter}
                  className="uk-button uk-button-grey"
                  data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
                >
                  {" "}
                  Apply{" "}
                </button>
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-child-width-1-5@m uk-child-width-1-2" data-uk-grid>
                <div>
                  <h4 className="uk-margin-remove"> Topic </h4>
                  <div id="checkBox" className="uk-flex uk-flex-column">
                    <Checkbox.Group
                      key="Checkbox-Topic-filter"
                      options={topicsList}
                      value={topicValue}
                      onChange={onChangeTopic}
                    />
                  </div>
                </div>

                { 
                <div>
                    <h4 className="uk-margin-remove"> Language </h4>
                    <div id="checkBox" className="uk-flex uk-flex-column">
                      <Checkbox.Group
                        options={languageList}
                        value={languageValue}
                        onChange={onChangeLanguage}
                      />
                    </div>
                  </div> 
                }
                <div>
                  <h4 className="uk-margin-remove"> Category </h4>
                  <div id="checkBox" className="uk-flex uk-flex-column">
                    <Checkbox.Group
                      key="Checkbox-Category-filter"
                      options={categoryList}
                      value={categoryValue}
                      onChange={onChangeCategory}
                    />
                  </div>
                </div>
                <div>
                  <h4 className="uk-margin-remove"> Price </h4>
                  <div id="checkBox" className="uk-flex uk-flex-column">
                    <Checkbox.Group
                      key="Checkbox-Price-filter"
                      options={pricesList}
                      value={priceValue}
                      onChange={onChangePrice}
                    />
                  </div>
                </div>
                <div>
                  <h4 className="uk-margin-remove"> Duration </h4>
                  <div id="checkBox" className="uk-flex uk-flex-column">
                    <Radio.Group
                      key="Checkbox-Duration-filter"
                      options={durationsList}
                      defaultValue={durationValue}
                      onChange={e => onChangeDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            
          {/* </Widget> */}
          </div>
          
   
    {/* <div key="Search-view">
      <div className="uk-float-left">
        <Search
          key="search-filter"
          placeholder="Search"
          value={searchText}
          onChange={onChangeText}
          onSearch={value => {
            onSearchText(value);
          }}
          enterButton
        />
      </div>

      <div className="uk-float-right" style={{position:"relative"}}>
        <a
          href="#"
          className="uk-link-reset uk-margin-small-right uk-text-small uk-text-uppercase uk-text-white"
          data-uk-tooltip="title: Filter; pos: top-right"
          data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
        > 
          <i className="fas fa-filter "></i> FILTER
        </a>
      </div>
      <div
        id="Course-Filter"
        hidden
        className="uk-margin-top uk-margin-large-bottom uk-position-relative"
      >
        <h3 className="uk-visible@s uk-text-uppercase"> {""} &nbsp;</h3>
        <div className="uk-position-top-right">
          <button
            className="uk-button uk-button-white"
            data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
          >
            Cancel
          </button>
          <button
            onClick={onApplyFilter}
            className="uk-button uk-button-grey"
            data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
          >
            {" "}
            Apply{" "}
          </button>
        </div>
        <hr className="uk-margin-small" />
        <div className="uk-child-width-1-4@m uk-child-width-1-2" data-uk-grid>
          <div>
            <h4 className="uk-margin-remove"> Topic </h4>
            <div id="checkBox" className="uk-flex uk-flex-column">
              <Checkbox.Group
                key="Checkbox-Topic-filter"
                options={topicsList}
                value={topicValue}
                onChange={onChangeTopic}
              />
            </div>
          </div>
          <div>
            <h4 className="uk-margin-remove"> Category </h4>
            <div id="checkBox" className="uk-flex uk-flex-column">
              <Checkbox.Group
                key="Checkbox-Category-filter"
                options={categoryList}
                value={categoryValue}
                onChange={onChangeCategory}
              />
            </div>
          </div>
          <div>
            <h4 className="uk-margin-remove"> Price </h4>
            <div id="checkBox" className="uk-flex uk-flex-column">
              <Checkbox.Group
                key="Checkbox-Price-filter"
                options={pricesList}
                value={priceValue}
                onChange={onChangePrice}
              />
            </div>
          </div>
          <div>
            <h4 className="uk-margin-remove"> Duration </h4>
            <div id="checkBox" className="uk-flex uk-flex-column">
              <Radio.Group
                key="Checkbox-Duration-filter"
                options={durationsList}
                defaultValue={durationValue}
                onChange={e => onChangeDuration(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
   */}
    </div>
  );
}
export default TrainingFilter;
