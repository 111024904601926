import React from 'react';


class PrivacyStatement extends React.Component{
    render(){
        return (<section id="email-phishing">
        <div class="container">
            <div class="heading-text heading-section text-center m-b-40 animated fadeInUp visible" data-animate="fadeInUp">
                <h2>Privacy Policy</h2>
                <div class="row text-left" style={{  textAlign: "left", margin: "20px 50px" }}>
                    <div class="col-md-12">
                        <p>
          
       
       </p><h3>1. Shield Alliance International Limited Privacy Policy</h3>
       <p>We, at Shield Alliance International Limited and its affiliates and subsidiaries (collectively referred to as “we”), are committed to protecting your privacy. This Privacy Policy applies to both our website, shieldalliance.com and any other ancillary service portal(s) that either Shield Alliance and/or its affiliates or subsidiaries provide. This Privacy Policy governs our data collection, processing and usage practices of Shield Alliance and its affiliates of Shield Alliance. It also describes your choices regarding use, access and correction of your personal information.  By using the Websites or the Service Portal, you consent to the data practices described in this Privacy Policy. If you do not agree with the data practices described in this Privacy Policy, you should not use the Websites or the Service Portal.</p>
       <p>We periodically update this Privacy Policy. We encourage you to review this Privacy Policy periodically.</p>

       <h3>2. Privacy Notice</h3>
       <p>This Privacy Notice sets out how we may use, process and store your personally identifiable information (PII). We may get that information from you or our affiliates, subsidiaries, partners, through contracts or other legal arrangements you have with us or our partners on behalf of us, in order to deliver contractual/legal obligations. In other cases, we will get that information from you with your permission and consent, or we may receive your personal information from third parties who you have given consent to pass this information on to us.</p>
    <p>
        We may collect information from you because we have valid reason (allowed by law or under contract) to collect the information, or because you have consented for us to do so for a specific purpose.
    </p>

     <h3>3. Information Provided</h3>
     <p>You may give us information about you with your consent, for example:</p>
     <ul>
        <li>through Contractual agreements such as accepting the privacy policy</li>
        <li>By filling in a form or sending us an e-mail</li>
        <li> Marketing responses</li>

     </ul>
     <p>
        This information may be personal, financial, business or related to your contractual history with us. You may give us information for legal reasons, such as to enter into a contract with us, when you are buying goods or services from us, or when you are considering these options.
     </p>

     <h4>Information we collect about you</h4>
     <p>
        If you visit our website or member communities, support forums we may automatically collect information about you, for example:
     </p>
     <ul>
        <li>Technical information</li>
        <li>IP addresses</li>
        <li>Information about what type of device you use to connect to our website</li>
        <li>How you interact with our website.</li>
     </ul>
     <h4>Information we receive from other sources</h4>
     <p>We may receive information about you if you use any of the other websites we operate, any other services we provide, or from our business partners instructed to collect information on our behalf.</p>
     <p>We also work with third parties, including, for example, business partners, sub-contractors in technical, payment and may receive information about you from them as part of the service we provide you, to fulfill the requirements of such services.</p>

     <h3>4. How We Use Information We Collect</h3>
     <h4>Compliance with our Privacy Policy</h4>
     <p>We use the information we collect only in compliance with this Privacy Policy. Customers who subscribe to our Services are obligated through our agreements with them to comply with this Privacy Policy.</p>
     <h5>We do not share personal information with any third party unless separate consent has been obtained from you</h5>
     <p>We will never sell your Personal Information to any third party.</p>
     <h4>Use of personal information</h4>
     <p>In addition to the uses identified elsewhere in this Privacy Policy, we may use your Personal Information to: (a) improve your browsing experience by personalizing the Websites and to improve the Subscription Service; (b) send information to you which we think may be of interest to you by post, email, or other means; (c) send you marketing communications relating to our and/or our affiliates’ or subsidiaries’ business or the businesses of carefully-selected third parties which we think may be of interest to you. We may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, we do not transfer your unique Personal Information to the third party. In addition, we may share data with trusted partners to contact you based on your request to receive such communications, help us perform statistical analysis, or provide customer support. Such third parties are prohibited from using your Personal Information except for these purposes, and they are required to maintain the confidentiality of your information. If you do not want us to share your personal information with these companies, contact us at <a href="mailto:legal@eccouncil.org
" target="_top">legal@eccouncil.org</a>
</p>
     <p>We will also use the information collected through our Service by our customers for the following purposes: (a) to provide the Service (which may include the detection, prevention and resolution of security and technical issues); (b) to respond to customer support requests; and (c) otherwise to fulfil the obligations under our Terms of Service.</p>

     <h4>Customer testimonials and comments</h4>
     <p>We post customer testimonials and comments on our Websites, which may contain Personal Information. We obtain each customer's consent prior to posting the customer's name and testimonial.</p>
     <h4>Use of credit card information</h4>
     <p>If you give us credit card information, we use it solely to check your financial qualifications and collect payment from you. We use a third-party service provider to manage credit card processing. This service provider is not permitted to store, retain, or use information you provide except for the sole purpose of credit card processing on our behalf.</p>
     <h4>Service providers</h4>
     <p>We employ other companies and people to provide services to visitors to our Website, our customers, and users of the Service and may need to share your information with them to provide information, products or services to you. Examples may include removing repetitive information from prospect lists, analyzing data, providing marketing assistance, processing credit card payments, supplementing the information you provide us in order to provide you with better service, and providing customer service. In all cases where we share your information with such agents, we explicitly require the agent to acknowledge and adhere to our privacy and customer data handling policies.</p>

     <h4>Security of your personal information</h4>
     <p>We use a variety of security technologies and procedures to help protect your Personal Information from unauthorized access, use or disclosure. We secure the Personal Information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When sensitive Personal Information (such as a credit card number and/or geo-location data) is collected on our Websites and/or transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>
     <p>If you have any questions about the security of your Personal Information, you can contact us at <a href="mailto:legal@eccouncil.org
" target="_top">legal@eccouncil.org</a>
</p>

     <p>
       </p><h4><br/>Social media features</h4><br/>
Our Websites include Social Media Features, such as the Facebook Like button and Widgets, such as the Share This button or interactive mini-programs that run on our sites. These features may collect your IP address, which page you are visiting on our sites, and may set a cookie to enable the feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Websites. This Privacy Policy does not apply to these features. Your interactions with these features are governed by the privacy policy and other policies of the companies providing them.


    <h4><br/>External websites</h4><br/>
Our Websites provide links to other websites. We do not control, and are not responsible for, the content or practices of these other websites. Our provision of such links does not constitute our endorsement of these other websites, their content, their owners, or their practices. This Privacy Policy does not apply to these other websites, which are subject to any privacy and other policies they may have.

<h4><br/>Public forums</h4><br/>
We offer publicly accessible message boards, blogs, and community forums. Please keep in mind that if you directly disclose any information through our public message boards, blogs, or forums, this information may be collected and used by others. We will correct or delete any information you have posted on the Websites if you so request, as described in "Opting Out and Unsubscribing" below.


<h4><br/>Retention of personal information</h4><br/>
We retain personal information that you provide us as long as we consider it potentially useful in contacting you about the Subscription Service or our other services, or as needed to comply with our legal obligations, resolve disputes and enforce our agreements, and then we securely delete the information. We will delete this information from the servers at an earlier date if you so request, as described in "Opting Out and Unsubscribing" below. If you provide information to our customers as part of their use of the Subscription Service, our customers decide how long to retain the personal information they collect from you. After termination of the Service, we may, unless legally prohibited, delete all customer information, including your Personal Information, from the Subscription Service.

<h4><br/>Compelled disclosure</h4><br/>
We reserve the right to use or disclose your personal information if required by law or if we reasonably believe that use or disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or comply with court order, or legal or administrative process.


     <p></p>
     <h3>5. Navigational Information</h3>
     <h4><br/>Cookies</h4>
    

We use cookies or similar technologies to analyse trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. We use "cookies" to help you personalise your online experience. A cookie is a text file that is placed on your hard disk by a Web server. Cookies are not used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a Web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalise pages on our Websites, or register for the Service, a cookie helps us to recall your specific information on subsequent visits. When you return to the same Website, the information you previously provided can be retrieved, so you can easily use the customised features. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the shieldalliance website you visit. Shield Alliance International Limited keeps track of the Websites and pages you visit within Shield Alliance International Limited, in order to determine what portion of the shieldalliance Website is the most popular or most used. This data is used to deliver customised content and promotions within the shieldalliance Website and Service Portal to customers whose behaviour indicates that they are interested in a particular subject area. 

<h4><br/>Log files</h4><br/>
We may collect demographic information, such as your post code, age, gender, preferences, interests and favourites using log files that are not associated with your name or other personal information. There is also information about your computer hardware and software that is automatically collected by us. This information can include: your IP address, browser type, domain names, internet service provider (ISP), the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, clickstream data, access times and referring website addresses. This information is used by us to maintain the quality of the Service, and to provide general statistics regarding use of our Website. For these purposes, we do link this automatically-collected data to Personal Information such as name, email address, address, and phone number. 



<h3><br/>6. Information We Collect</h3>
When you visit our websites
You are free to explore the Websites without providing any Personal Information about yourself. When you visit the Websites or register for the email Subscription Service, we request that you provide Personal Information about yourself, and we collect Navigational Information.

<br/><h4>When you use our mobile application</h4>
We use website analytics software to allow us to better understand the functionality of our website. This software may record information such as how often you use the site, the events that occur within the website, aggregated usage, performance data, and where the website was visited from. Additional information we may collect on our Website includes your city location, device model and version, your device identifier (or “UDID”) and OS version. We may link information we store within the analytics software to Personal Information you submit within the service portal or website. We do this to improve services we offer you and improve our marketing, analytics and site functionality.

<br/><h4>“Personal Information”</h4>
This refers to any information that you voluntarily submit to us and that identifies you personally, including contact information, such as your name, e-mail address, company name, address, phone number, and other information about yourself or your business. Personal Information can also include information about any transactions, both free and paid, that you enter into on the Websites, and information about you that is available on the internet, such as from Facebook, LinkedIn, Twitter and Google, or publicly available information that we acquire from service providers. 

<br/><h4>Payment information</h4>
We collect and process payment information from you when you subscribe to or purchase any of our services, including credit cards numbers and billing information, using third party PCI-compliant service providers. Except for this, we do not collect Sensitive Information from you.

<br/><h4>Information about children</h4>
The Websites are not intended for or targeted at children under 13, and we do not knowingly or intentionally collect information about children under 13. If you believe that we have collected information about a child under 13, please contact us at <a href="mailto:legal@eccouncil.org
" target="_top">legal@eccouncil.org</a>
, so that we may delete the information.

<h4>Why do we collect Personal Identifiable Information (PII)?</h4>
<h5>We collect PII for the some or all of following reasons:</h5>

<ul></ul>

<li>To provide services and support to you after you have entered into a contract</li>
<li>To provide information about products or services you have shown interest in, if you are already an existing Shield Alliance International Limited customer</li>
<li>To provide information to you about products or services you have purchased from us, or related products or services</li>
<li>To provide goods or services to you under contract</li>
<li>For legal reasons, for example, if you have entered into a contract with us</li>
<li>To provide information to you about our products and services if you have consented to receive it.</li>

<h4>What legal basis do we have for processing PII?</h4>
<h5>we may process your information because:</h5>

<ul>

<li>We have a contract with you or agreed to this privacy policy</li>
<li>You have given us permission to do so</li>
<li>We must provide services to you after you have purchased something from us</li>
<li>To comply with the law.</li>
</ul>

<h4>Who might we share PII information with?</h4>
<p>We may share your personal information with third parties, either because you have consented to allow us to do so or for fulfilling the service. For example, we may share your personal information with:</p>

<ul>
<li>Sub-contractors and third parties for the purpose of providing or continuing to provide the products and services we are contracted to</li>
<li>With third parties because you have given consent</li>
<li>Shield Alliance International Limited must comply with a legal obligation</li>
<li>To protect the rights, property, or safety of Shield Alliance International Limited, our customers or others</li>
<li>We will exchange information with other companies or organizations to prevent fraud or to reduce our credit risks</li>

</ul>

<h4>Transmission and storage of personal data</h4>
<p>We primarily store and process PII data electronically. The company and its employees follow strict protocols in terms of handling and processing data.</p>
<h4>How long is PII retained?</h4>
<p>We will not retain PII for longer than required and we will keep this</p>
<ul>

<li>For as long as required by law</li>
<li>Until we no longer have a valid reason for keeping it</li>
<li> Until you request us to stop using it i.e. Right to Erasure</li>

</ul>
<p>The company has an information retention procedure and schedule, which is monitored and complied with.</p>

<h4>Refusal to provide Shield Alliance International Limited with personal information</h4>
<p>If the personal information is necessary in order to supply goods or services to you under a contract, then we will not be able to enter into that contract or provide the products or services without that information.</p>
<h3>7. Your Rights</h3>
<p>We respect individual’s legal rights to their data. These covers;</p>

<ul>
<li>The right to be informed - We are publishing this Privacy Notice to keep you informed as to what we do with your personal information. We strive to be transparent about how we use PII.</li>

<li>The right to access - the right to access your information. Contact us if you wish to access the personal information we hold about you.</li>

<li>The right to rectification - if the information we hold about you is inaccurate or not complete, you have the right to ask us to rectify it. If that data has been passed to a third party with your consent or for legal reasons, then we must also ask them to rectify the data.</li>

<li>The right to erasure - sometimes referred to as ‘the right to be forgotten’. If you want us to erase all your personal data and we do not have a legal reason to continue to process and hold it, please contact us.</li>

<li>The right to restrict processing - you have the right to ask us to restrict how we process your data. This means we are permitted to store the data but not further process it. We keep just enough data to make sure we respect your request in the future.</li>

<li>The right to data portability - we must allow you to obtain and reuse your personal data for your own purposes across services in a safe and secure way without this effecting the usability of your data. The data must be held by us by consent or for the performance of a contract.</li>

<li>The right to object – You have the right to object to us about processing your data even if it is based on our legitimate interests.</li>

<li>The right to withdraw consent - If you have given us your consent to process your data but change your mind later, you have the right to withdraw your consent at any time, and Shield Alliance International Limited stop processing your data.</li>

<li>The right to complain to a higher body - you have the right to complain to our Data Privacy Officer if you feel that we are not meeting its obligations in terms of GDPR or has not responded to your requests to solve a problem. Contact details here: dpo@eccouncil.org. </li>


</ul>

<h3>8. Opting Out and Unsubscribing</h3>

<h4>Reviewing, correcting and removing your Personal Information</h4>
<p>Upon request,we will provide you with information about whether we hold any of your Personal Information. If you provide us with your Personal Information, you have the following rights with respect to that information:</p>

<ul>

<li>To review the user information that you have supplied to us</li>
<li>To request that we correct any errors, outdated information, or omissions in user information that you have supplied to us</li>
<li>To request that your user information not be used to contact you</li>
<li>To request that your user information be removed from any solicitation list that we use</li>
<li>To request that your user information be deleted from our records</li>
<li>To opt out of being solicited by us or our affiliates or subsidiaries and third parties, if consented separately</li>

</ul>
<h4>To unsubscribe from our communications</h4>
<p>You may unsubscribe from our marketing communications by clicking on the "unsubscribe" link located on the bottom of our e-mails, or by sending us email us at support@shieldalliance.com,  Customers cannot opt out of receiving transactional emails related to their account with us or our Services.</p>
  

<h3>9. Links to Other Websites</h3>
<p>From time to time, our website may contain links to and from websites of our partner networks, advertisers, social media sites etc. If you follow a link to any of these websites, please note that these websites may have their own privacy notices and that we do not accept any responsibility or liability for any such notices. Please check these notices, where available, before you submit any personal data to these websites.</p>

<h3>10. Retention and Destruction Policy</h3>
<p>This Document Retention and Destruction Policy (Policy) provides for the systematic review, retention and destruction of documents received or created by the Shield Alliance International Limited in connection with the transaction of its business. This Policy covers all records and documents, regardless of physical form (including electronic documents), contains guidelines for how long certain documents should be kept and how records should be destroyed. </p>
<h4>I. Document Retention </h4>
<p>We follow the document retention procedures outlined below. Documents that are not listed, but are substantially similar to those listed in the schedule will be retained for the appropriate length of time.</p>

<h4>II. Customer Records</h4>
<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>Name&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;3 years</span></h1>
<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>Email address&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 years</span></h1>
<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>Contact Number&nbsp; &nbsp; &nbsp; &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 years</span></h1>
<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>IP Address&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 years</span></h1>
<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>Geographic Location:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 years</span></h1>

<h1 style={{marginTop:0,marginRight:0,marginBottom:".0001pt",marginLeft:"89.85pt",textIndent:"-18.0pt",fontSize:16,fontFamily:"Arial,sans-serif"}}><span style={{fontFamily:" Times New Roman, serif"}}>Company Information:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3 years</span></h1>


<h4><br/>III. Electronic Documents and Records </h4>
<p>
Electronic documents will be retained as if they were paper documents. Therefore, any electronic files, including records of donations made online, that fall into one of the document types on the above schedule will be maintained for the appropriate amount of time. If a user has sufficient reason to keep an email message, it should be kept in the appropriate file or moved to an “archive” computer file folder. Backup and recovery methods will be tested on a regular basis.</p>


<h4><br/>IV. Emergency Planning</h4>
<p>

The Organization’s records will be stored in a safe, secure and accessible manner. Documents and financial files that are essential to keeping the Organization operating in an emergency will be duplicated or backed up at least every week and maintained off site. 
</p>
<h4><br/>V. Document Destruction</h4> 

<p>
EC-Council  is responsible for the ongoing process of identifying its records, which have met the required retention period and overseeing their destruction. Destruction of financial and personnel-related documents will be accomplished by shredding if such information are held on physical files.

EC-Council also performs periodic destruction process for financial and personnel-related documents when such information is stored in electronic media. We may destroy in any of the following manners based on the kind of device used to store such data:
</p><ul>

<li>Physical Destruction</li>
<li>Overwriting</li>
<li>File deletion</li>
<li>Media formatting</li>

</ul>

<p>Document destruction will be suspended immediately, upon any indication of an official investigation or when a lawsuit is filed or appears imminent. Destruction will be reinstated upon conclusion of the investigation. </p>


<h4><br/>VI. Compliance</h4>

Failure on the part of employees or contract staff to follow this policy can result in possible civil and criminal sanctions against the Organization and its employees or contract staff and possible disciplinary action against responsible individuals. The Treasurer will periodically review these procedures with legal counsel or the organization’s certified public accountant to ensure that they are in compliance with new or revised regulations.

<h3><br/>11. Changes to Our Privacy Notice</h3>
We may update this Privacy Policy from time to time by posting a new version online. You should check this page occasionally to review any changes. If we make any material changes we will notify you by posting the revised Privacy Policy on our Websites and, if you have subscribed to our and/or affiliates’ and/or subsidiaries’ email communications, providing notice through the email address used when creating your subscription. This helps you to always be aware of what information we collect, how we use it and under what circumstances, if any, it is disclosed. Your continued use of the Websites, the Service Portal, and/or continued provision of Personal Information to us will be subject to the terms of the then-current Privacy Policy. 
<h4><br/>Contact Us</h4>

If you have any questions about this Privacy Policy, please write to us by email at: <a href="mailto:legal@eccouncil.org
" target="_top">legal@eccouncil.org</a>


<p></p>


                    </div>


                </div>
    </div></div></section>
    );
    }
}

export default PrivacyStatement;