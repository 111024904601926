import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import moment from "moment";

import {
  Row,
  Col,
  Card,
  Steps,
  message,
  Upload,
  Icon,
  Button,
  Pagination
} from "antd";
// import TrainingForm  from './components/trainingForm';
import CircularProgress from "components/CircularProgress";
import ModelInstructor from "../model";
import {
  api,
  apiUrl,
  imageMediaUrl,
  imagePrefixUrl,
  stripeKey
} from "util/config";
import LzEditor from "react-lz-editor";
// import "antd/dist/antd.less";
const { Dragger } = Upload;
const { Step } = Steps;

const stepStyle = {
  marginBottom: 60,
  boxShadow: "0px -1px 0 0 #e8e8e8 inset"
};
const numEachPage = 10;

class Instructor extends PureComponent {
  state = {
    imageUrl: undefined,
    description: "",
    name: "",
    instructorInfo: [],
    loader: false
  };
  componentWillMount() {
    this.fatchInstructor(this.state.page);
  }

  fatchInstructor = page => {
    this.setState({ loading: true });
    ModelInstructor.fetchTrainingInstructor(page).then(response => {
      if (!response.error) {
        this.setState({
          instructorInfo: response.data,
          loading: false,
          totalRecords: response.totalRecords
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };
  onImgClose = () => {
    this.setState({
      imageUrl: undefined
    });
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    console.log({ file });
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    console.log(isLt2M);
    if (!isLt2M) {
      message.error("Image must smaller than 300kb!");
    }
    return isJpgOrPng && isLt2M;
  };

  receiveHtml = content => {
    console.log(content);
    if (content !== "<p >Description</p>") {
      this.setState({ description: content });
    }
    // this.setState({content})
  };
  changeName = e => {
    this.setState({
      name: e.target.value
    });
  };

  formateDateTime(date) {
    return moment(date).format("MMMM Do YYYY");
  }

  render() {
    const { training, loader, steps, instructorInfo } = this.state;
    var accessToken = localStorage.getItem("accessToken");

    console.log(this.state, "-------------");
    return (
      <Auxiliary>
        <div className="uk-container uk-margin uk-align-center">
          <h3
            className="uk-margin-small-bottom  "
            style={{ textAlign: "center" }}
          >
            {"All Instructor"}
          </h3>
        </div>
        <div className="uk-container uk-margin uk-align-center">
          {instructorInfo && instructorInfo.length > 0 ? (
            instructorInfo.map((item, index) => {
              return (
                <div
                  className="uk-card uk-card-small uk-card-body  uk-card-default uk-margin uk-margin-left uk-margin-right"
                  style={{ borderRadius: "5px" }}
                >
                  <img
                    onClick={() => {
                      this.props.history.push(
                        "/trainings/instructor/" + item._id + "/update"
                      );
                    }}
                    src={require("assets/images/training/edit_icon.png")}
                    style={{
                      width: "44px",
                      position: "absolute",
                      right: "30px",
                      top: "35px",
                      cursor: "pointer"
                    }}
                  ></img>
                  <img
                    className="uk-align-left uk-width-1-3@m uk-margin-remove-bottom"
                    src={
                      item.imageUrl
                        ? `${imageMediaUrl}${item.imageUrl}`
                        : require("assets/images/courses/cover_image.jpg")
                    }
                    style={{ width: "200px",
                    height: "120px" }}
                  />
                  <div className="uk-padding-small-left uk-padding-small-right uk-padding-small-bottom">
                    <a className="uk-link-reset">
                      <h4
                        className="uk-margin-small-bottom uk-margin-top"
                        style={{ letterSpacing: "1px" }}
                      >
                        {item.name}
                      </h4>
                      <strong>Created By:</strong>{" "}
                      {item.createdBy && item.createdBy.userName}
                    </a>
                    <div
                      className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin-remove uk-margin-small-bottom"
                      data-uk-grid
                    >
                      <div style={{ padding: "0px", margin: "0px" }}>
                        <p>
                          {" "}
                          <strong>Created Date:</strong>{" "}
                          {this.formateDateTime(item.createdAt)}
                        </p>{" "}
                      </div>
                    </div>

                    {/* <div className=" uk-margin-top ">
                      {item.expire && item.transactionStatus === "success" && (
                        <a
                          className="Course-tags uk-margin-small-right"
                          style={{
                            borderRadius: "27px",
                            fontWeight: "600",
                            background: "rgb(241, 73, 73)",
                            padding: "8px 18px",
                            color: "#ffffff",
                            textDecoration: "none",
                            border: "1px solid rgb(241, 73, 73)",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                          }}
                        >
                          Expire
                        </a>
                      )}

                      {!item.expire &&
                        item.transactionStatus === "success" &&
                        !item.enrolled && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            onClick={() => {
                              this.enrollNow(item.training);
                            }}
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              backgroundColor: "#297eb8",
                              padding: "8px 18px",
                              color: "#ffffff",
                              textDecoration: "none",
                              border: "1px solid #297eb8",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                            }}
                          >
                            Assign Me
                          </a>
                        )}
                      {!item.expire &&
                        item.transactionStatus === "success" &&
                        item.enrolled && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            disabled="disabled"
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              backgroundColor: "rgb(136, 138, 140)",
                              padding: "8px 18px",
                              color: "#ffffff",
                              textDecoration: "none",
                              border: "1px solid rgb(136, 138, 140)",
                              boxShadow: "0 2px 5px rgba(47, 47, 47, 0.35)"
                            }}
                          >
                            Assign Me
                          </a>
                        )}
                      {item.expire ||
                        (item.transactionStatus === "failed" && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            // onClick={this.createSessionCheckout(item.training)}
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              background: "#fdfdfd",
                              padding: "8px 18px",
                              color: "#297eb8",
                              textDecoration: "none",
                              border: "1px solid #297eb8",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                            }}
                          >
                            Buy Again
                          </a>
                        ))}
                    </div>
                   */}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {this.state.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <div
                  style={{
                    marginLeft: "10px"
                  }}
                  className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                  data-uk-grid
                >
                  <div className="uk-child-width-1-4@m"></div>
                  <div className="uk-child-width-2-4@m">
                    <div className="uk-text-center uk-margin">
                      <div style={{ padding: "0 20%" }}>
                        <img src={require("assets/images/no_data_found.png")} />
                      </div>
                      <h3
                        className="uk-text-center "
                        style={{ margin: "20px 0 0" }}
                      >
                        Sorry!! No Data Found.
                      </h3>
                      <p className="uk-text-center uk-margin-remove">
                        Try somethings else.
                      </p>
                    </div>
                  </div>
                  <div className="uk-child-width-1-4@m"></div>
                </div>
              )}
            </div>
          )}
          {this.state.trainings && this.state.trainings.length > 0 && (
            <Pagination
              defaultCurrent={1}
              defaultPageSize={numEachPage} //default size of page
              onChange={this.handleChange}
              total={this.state.totalRecords} //total number of card data available
            />
          )}
        </div>
      </Auxiliary>
    );
  }
}

export default Instructor;
