import React from 'react'
import { Select } from 'antd';

import moment from 'moment';

const formatDate = (date, format) => moment(date).format(format);
const Option = Select.Option;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
 


const TopHeader = (campaignProps) => {
  
    const { onChangeSearch, index, campaign} = campaignProps  
   
    console.log('change-----------', index); 
    function onChange(index) { 
      console.log('change', index); 
      onChangeSearch(index);
    }

    function onBlur() {
      console.log('blur');
    }

    function onFocus() {
      console.log('focus');
    }

    function onSearch(val) {
      console.log('search:', val);
    }
    return (
      <div>
        
        <Select
          showSearch 
          value={ campaign[index].name + '/' + formatDate(campaign[index].expireAt, 'LL')}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          filterOption={(input, option) => 
            option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
            // option.props.children.indexOf(input) >= 0
          }
          style={{ width: 400 }}
        >
           { campaign.map( (item, index)  => {
            //  console.log("index", index)
              return  ( 
                      <Option value={index}>{item.name}{'/'} { formatDate(item.expireAt, 'LL')} </Option> 
                    );
              })
            }
          {/* <Option value="jack">Jack</Option>  */}
        </Select>

 
        {/* <Select
          mode="combobox" 
          // defaultValue={children[0]}
          onChange={handleChange}
          style={{ width: 400 }}
        >
          {children}
        </Select>   */}
      </div>
    ); 
}
export default TopHeader
