import {
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  INIT_URL,
  GET_CAMPAIGNS_USERS, // campaign CAMPAIGN
  GET_CAMPAIGNS_USERS_SUCCESS,
  GET_All_CAMPAIGN,
  GET_All_CAMPAIGN_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  CAMPAIGN_USER,
  CAMPAIGN_USER_SUCCESS,
  SHOW_MODAL,
  HIDE_MODAL,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_ALL_CAMPAIGN_TRAININGS,
  GET_ALL_CAMPAIGN_TRAININGS_SUCCESS
} from "constants/ActionTypes";

export const getDashboard = obj => {
  return {
    type: GET_DASHBOARD,
    payload: obj
  };
};
export const getAllCampaignTrainings = obj => {
  return {
    type: GET_ALL_CAMPAIGN_TRAININGS,
    payload: obj
  };
};
export const getCampaignsUsers = campaignId => {
  console.log("campaignId", campaignId);
  return {
    type: GET_CAMPAIGNS_USERS,
    payload: {
      campaignId: campaignId
    }
  };
};
export const getCampaignsUsersSuccess = campaignsUsers => {
  return {
    type: GET_CAMPAIGNS_USERS_SUCCESS,
    payload: {
      campaignsUsers: campaignsUsers
    }
  };
};

export const queryDashBoardSuccess = dashboard => {
  console.log({ dashboard });
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: {
      dashboard: dashboard
    }
  };
};
export const queryAllCampaignTrainingsSuccess = campaignTrainings => {
  console.log({ campaignTrainings });
  return {
    type: GET_ALL_CAMPAIGN_TRAININGS_SUCCESS,
    payload: {
      campaignTrainings: campaignTrainings
    }
  };
};

export const getAllCampaign = obj => {
  return {
    type: GET_All_CAMPAIGN,
    payload: obj
  };
};
export const getAllCampaignSuccess = campaign => {
  return {
    type: GET_All_CAMPAIGN_SUCCESS,
    payload: campaign
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const campaignUser = user => {
  console.log(user);
  return {
    type: CAMPAIGN_USER,
    payload: user
  };
};
export const showModal = model => {
  console.log(model);
  return {
    type: SHOW_MODAL,
    payload: model
  };
};
export const hideModal = () => {
  return {
    type: HIDE_MODAL
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
