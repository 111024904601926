import React from "react";
import { Typography, Form, Input, Icon, Button, message } from "antd";
import "./index.css";

const { Text } = Typography;

const TrialForm = ({ form, loading, handleSubmit, termCnChecked, handleCheckBox }) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log({ values });
        handleSubmit(values);
      }
    });
  };

  const getFieldDecorator = form.getFieldDecorator;
  return (
    <Form className="trial_form" onSubmit={handleFormSubmit}>
      <Form.Item className="ant-col-16" label="Name">
        {getFieldDecorator("name", {
          rules: [
            {
              required: true,
              pattern: /^[a-zA-Z0-9_\-,]/,
              message: "Please input your name!",
              whitespace: true,
            },
            // {
            //   type: "alphabet",
            //   message: "Please input valid email!"
            // }
          ],
        })(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Full Name" />)}
      </Form.Item>
      <Form.Item className="ant-col-16" label="Email">
        {getFieldDecorator("email", {
          rules: [
            { required: true, message: "Please input your email!" },
            {
              type: "email",
              message: "Please input valid email!",
            },
          ],
        })(<Input prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Email" />)}
      </Form.Item>
      <Form.Item className="ant-col-16" label="Password">
        {getFieldDecorator("password", {
          rules: [
            {
              required: true,
              pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[0-9]).{4,}$/,
              message: "Please input password!",
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Password"
            type="password"
          />
        )}
      </Form.Item>
      <div className="ant-row ant-col-16 ant-form-item" style={{ marginTop: "8px !important" }}>
        <label>
          <input className="uk-checkbox" type="checkbox" onChange={handleCheckBox} />
          <span className="checkmark uk-text-small"> I agree to the </span>
          <a
            href="https://www.shieldalliance.com/terms.html"
            className="uk-text-bold uk-text-small uk-link-reset"
            target="_blank"
          >
            Terms and Conditions
          </a>
        </label>
      </div>
      <Form.Item className="ant-col-16 btn-wrapper">
        <Button
          loading={loading}
          // className="uk-button uk-button-success uk-button-large"
          htmlType="submit" 
          type="success" 
          className="gx-mb-0 uk-button  uk-button-large" 
          disabled={!termCnChecked} 
        >
          Start free trial
        </Button>
      </Form.Item>
      {/* <div className="uk-width-1-3@s" style={{ marginTop: "40px" }}>
        <button className="uk-button uk-button-success uk-button-large"> Start free trial </button>
      </div> */}
    </Form>
  );
};

const TrialFormWrapper = Form.create({ name: "trial_form" })(TrialForm);

class LandingPage extends React.Component {
  state = {
    emailError: "",
    termCnChecked: false,
    loading: false,
  };

  sendTrialRequest = (values) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.trialRequest(values);
      }
    );
  };

  componentDidUpdate() {
    if (this.state.loading && this.props.trial.userMessage) {
      this.setState(
        {
          loading: false,
        },
        () => {
          if (this.props.trial.error) {
            message.error(this.props.trial.userMessage, 5);
          } else {
            message.success(this.props.trial.userMessage, 5);
          }
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className="uk-section uk-section-muted" style={{ background: "#eef5fd" }}>
          <div className="uk-container uk-align-center" data-uk-scrollspy="cls:uk-animation-fade">
            <div data-uk-grid>
              <div className="uk-width-2-3@m uk-padding-large uk-padding-remove-bottom">
                <img src={require("assets/images/backgrounds/efective_cyber_seurity_banner_design.png")} alt="" />
              </div>
              <div className="uk-width-1-3@m uk-position-relative">
                {/* <img src={require("./../../../assets/images/demos/oncomputer-gray.png")} className="uk-visible@m" style={{position: 'absolute', width: '80%',left: '-36%', bottom: '0'}}  alt=""/> */}
                <h2 data-uk-scrollspy="cls:uk-animation-slide-right-medium"> Effective Cybersecurity Training </h2>
                <p className="uk-text-bold" data-uk-scrollspy="cls:uk-animation-scale-up">
                  Transform your employees into your organization’s very own firewall against evolving cyberthreats.
                </p>
                <p className="uk-text-bold" data-uk-scrollspy="cls:uk-animation-scale-up">
                  Education is essential in any security program. However, we believe that for education to be
                  effective, employees would need to be able to remember and apply whatever they have learnt.
                </p>
                <p className="uk-text-bold" data-uk-scrollspy="cls:uk-animation-scale-up">
                  Our security awareness training content, which includes interactive training modules, gamification,
                  quizzes and videos, are designed to improve security awareness and change user behaviour – all the
                  while keeping your end users engaged!
                </p>
                <p className="uk-text-bold" data-uk-scrollspy="cls:uk-animation-scale-up">
                  Access our learning content anywhere, anytime, on any connected devices. As most of our training
                  modules are bite-sized (less than 15 minutes), your employees can easily integrate their cybersecurity
                  training into their schedules without causing much disruption to their daily work.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="uk-section-default uk-section-large">
            <div className="uk-container-small uk-padding-small uk-margin-auto">
                <h2 className="uk-h1 uk-text-center" data-uk-scrollspy="cls:uk-animation-fade"> Our Team </h2>
                <p className="uk-text-center uk-text-medium uk-margin uk-width-xlarge uk-margin-large-bottom uk-margin-auto uk-animation-slide-bottom-medium"> Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt labore dolore aliqua.    </p>
                <div className="uk-grid-large uk-text-center" data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 300;repeat:true" data-uk-grid> 
                    <div className="uk-width-1-3@m">
                        <img src={require("./../../../assets/images/avatures/Leadership.jpg")} className="uk-border-circle uk-box-shadow-large" alt=""/>
                        <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-remove-bottom">Nickolas Hill </h3>
                        <div className="uk-text-meta">Founder CEO</div>
                    </div>
                    <div className="uk-width-1-3@m"> 
                        <img src={require("./../../../assets/images/avatures/Leadership.jpg")} className="uk-border-circle uk-box-shadow-large" alt=""/>
                        <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-remove-bottom">Felix Hoffman  </h3>
                        <div className="uk-text-meta">PR Manager</div>
                    </div>
                    <div className="uk-width-1-3@m">
                        <img src={require("./../../../assets/images/avatures/Leadership.jpg")} className="uk-border-circle uk-box-shadow-large" alt=""/> 
                        <h3 className="uk-margin uk-margin-remove-adjacent uk-margin-remove-bottom">Erika Johnston   </h3>
                        <div className="uk-text-meta">Developer</div>
                    </div>
                </div>                 
            </div>
        </div>          */}
        <div className="uk-section-default uk-section" style={{ background: "#080127" }}>
          <div className="uk-container">
            <div className="uk-flex-middle" data-uk-grid>
              <div className="uk-width-1-2@m">
                <h1 className="uk-text-bold uk-text-white"> Interactive Training Materials</h1>
                {/* <p className="uk-text-white uk-text-medium">Improve learner engagement, in addition to improving workflow and promoting higher levels of productivity through a variety of content materials such as gamification, training modules, quizzes and videos.Our training materials include:</p> */}

                <p className="uk-text-white uk-text-medium">
                  Our training materials are constantly updated to reflect the latest cyberthreats and best practices to
                  protect your business.{" "}
                </p>
                <p className="uk-text-white uk-text-medium">
                  {" "}
                  We understand that every organization is different. Apart from our off-the-shelf training materials,
                  we can also help you to you to create a unique learning solution that corresponds with your
                  organization’s specific needs.
                </p>
                {/* <ul className="uk-text-white uk-text-bold" >
                            <li>
                                Engaging and on-demand training modules and videos that promote active learning and optimal retention
                            </li>
                            <li>Gamification that will help boost employee engagement and increase motivation
                        </li>
                            <li>Fun quizzes to track user learning </li>
                        </ul> */}
              </div>
              <div className="uk-width-1-2@m">
                <img src={require("assets/images/backgrounds/interactive_training_material_design.jpg")} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={require("assets/images/backgrounds/diver-1.png")}
          style={{ position: "absolute", marginTop: "-66px" }}
          alt=""
        />
        <div className="uk-section-grey uk-section" style={{ background: "#77b8bb" }}>
          <div className="uk-container">
            <div className="uk-flex-middle" data-uk-grid>
              <div className="uk-width-1-2@m">
                <h1 className="uk-text-bold uk-margin-small-bottom"> Improve Learner Engagement</h1>
                <p className="uk-text-medium uk-text-white uk-margin-remove-top ">
                  {" "}
                  Improve learner engagement, in addition to improving workflow and promoting higher levels of
                  productivity through a variety of content materials such as gamification, training modules, quizzes
                  and videos.
                </p>
                {/* <a className="el-content uk-button uk-button-primary uk-button-large" href="index1dfd.html?page_id=25"> 
                                    Start now                 </a> */}
              </div>
              <div className="uk-width-1-2@m uk-flex-first">
                <img src={require("assets/images/backgrounds/improve_learning_engagement.jpg")} alt="" />
              </div>
            </div>
            <div data-uk-grid className="uk-child-width-1-2@m uk-text-white">
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  <div className="uk-width-1-5">
                    <img
                      src={require("assets/images/icons/personalised_training_contents.png")}
                      className="img-small"
                      alt="Image"
                    />
                  </div>
                  <div className="uk-width-3-4">
                    <h3> Personalized Training Content </h3>
                    {/* <p> Customize your training materials in variety of different styles and preferences using our built-in customization tool </p> */}
                    <p>
                      Customize your training materials in variety of different styles and preferences using our
                      built-in customization tool to achieve the look and feel of a fully personalized training
                      experience.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  <div className="uk-width-1-5">
                    <img
                      src={require("assets/images/icons/intitutive_learning_paths.png")}
                      className="img-small"
                      alt="Image"
                    />
                  </div>
                  <div className="uk-width-3-4">
                    <h3> Intuitive Learning Paths </h3>
                    <p>
                      Manage your employees training progress and allow administrators to track the overall training
                      progress of subscribed learners
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  <div className="uk-width-1-5">
                    <img
                      src={require("assets/images/icons/customised_reporting.png")}
                      className="img-small"
                      alt="Image"
                    />
                  </div>
                  <div className="uk-width-3-4">
                    <h3> Customized Reporting </h3>
                    <p>
                      Monitor your training courses with a series of customizable reports and charts of your phishing
                      campaigns
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-grid-small" data-uk-grid>
                  <div className="uk-width-1-5">
                    <img src={require("assets/images/icons/Auto Enrollment.png")} className="img-small" alt="Image" />
                  </div>
                  <div className="uk-width-3-4">
                    <h3> Auto-Enrollment </h3>
                    <p> Automatically assign follow-up training to employees who have failed the phishing simulation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section-default uk-section" id="freeTraining">
          <div className="uk-container-small uk-margin-auto uk-padding-small">
            <h2 className="uk-text-center"> Start your Learning today </h2>
            <TrialFormWrapper
              termCnChecked={this.state.termCnChecked}
              handleCheckBox={(e) => this.setState({ termCnChecked: e.target.checked })}
              handleSubmit={this.sendTrialRequest}
              loading={this.state.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
