import { api, apiUrl } from "../../util/config";
import { message } from "antd";
import $ from "jquery";
import store from "store";

var Actions = {
  userInfoUpdate: (info) => {
    console.log("info---", { info });
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/users/profile";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: info,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            var userInfo = store.get("user");
            var userInfo = {
              ...response.data,
              name: response.data.userName,
            };
            console.log({ userInfo });
            store.set("user", userInfo);
            var event = new CustomEvent("userInfo", {
              detail: { userInfo: userInfo },
            });
            document.dispatchEvent(event);
            message.success(response.userMessage);
            resolve(userInfo);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  userPasswordReset: (info) => {
    console.log("info---", { info });
    var accessToken = localStorage.getItem("accessToken");
    if(info.accessToken&& info.accessToken !== "undefined"){
     accessToken = info.accessToken
    }
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/password/reset";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: info,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
            resolve(response);
          } else {
            message.success(response.userMessage);
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
          resolve();
        },
      };
      $.ajax(settings).done();
    });
  },
  userTraineeInfoUpdate: (info) => {
    console.log("info---", { info });
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/trainee/profile";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: info,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
            resolve();
          } else {
            var user = store.get("user");
            var userInfo = {
              ...response.data,
              name: response.data.userName,
              trainee: user.trainee,
              trial: user.trial,
            };
            console.log({ userInfo });
            store.set("user", userInfo);
            var event = new CustomEvent("userInfo", {
              detail: { userInfo: userInfo },
            });
            document.dispatchEvent(event);
            message.success(response.userMessage);
            resolve(userInfo);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
          resolve();
        },
      };
      $.ajax(settings).done();
    });
  },
  userTraineePasswordReset: (info) => {
    console.log("info---", { info });
    var accessToken = localStorage.getItem("accessToken");
     if(info.accessToken&& info.accessToken !== "undefined"){
      accessToken = info.accessToken
     }
      console.log({accessToken})
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/trainee/password/reset";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
       },
        data: info,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);

            resolve(response);
          } else {
            message.success(response.userMessage);
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
          resolve();
        },
      };
      $.ajax(settings).done();
    });
  },
  checkToken: (token) => {
    console.log("info---", { token });
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/checkToken";
      var settings = {
        async: true,
        crossDomain: true,
        url: url, 
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        data: {token},
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);

            resolve(response);
          } else {
            message.success(response.userMessage);
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
          } else {
            message.error("Some error has occurred.");
          }
          resolve();
        },
      };
      $.ajax(settings).done();
    });
  },
};

export default Actions;
