import React, { PureComponent } from 'react'

export default class UnSupportedBrowser extends PureComponent {
    render() {
        return (
            <div style={{
                background: "white",
                height: 100
            }}>
                <div class="page">
                    <div class="row">
                        <div class="col-3 col-s-3 menu" />

                        <div class="col-6 col-s-9">
                            <h1 align="center">Improve your experience</h1>
                            <p align="center">
                                You're using a web browser we don't support. Try one of these
                                options to have a better experience.
                            </p>
                        </div>

                        <div class="col-3 col-s-12" />
                    </div>

                    <div
                        class="row img"
                        style={{
                            display: 'flex',
                            'align-items': 'center',
                            'justify-content': 'center',
                        }}
                    >

                        {/* <div class="col-3" style={{ width: "25%" }}>
                            <img src={require("assets/images/safari.png")} alt="" />
                            <h2 align="center">Safari</h2>
                        </div> */}
                        <div class="col-3" style={{ width: "25%" }}>
                            <img src={require("assets/images/microsoftedge.png")} alt="" />
                            <h2 align="center">Microsoft Edge</h2>
                        </div>

                        <div class="col-3 col-s-12" style={{ width: "25%" }}>
                            <img src={require("assets/images/chrome.png")} alt="" />
                            <h2 align="center">Chrome</h2>
                        </div>
                        <div class="col-3 col-s-12" style={{ width: "25%" }}>
                            <img src={require("assets/images/mozilla.png")} alt="" />
                            <h2 align="center">Firefox</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
