import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios"; 
 
import { 
  GET_TRAINEE_TRAINING,
  GET_TRAINEE_TRAINING_SUCCESS, 
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import {  
  getTraineeTrainingSuccess, 
  getTraineeAllTrainingSuccess,
  showMessage,
  hideMessage,
    // getAllTraining
} from "../actions/Trainee"; 

import { api, apiUrl } from "util/config";

const { queryTraineeTraining } = api;
 
 
 
const getAllTrainingRequest = async (paramsObj) => 
  await axios.get(apiUrl + queryTraineeTraining,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      },
      params: paramsObj, 
    }
  )
  .then(response => response)
    .catch(error => error);
  
 
 
function* getAllTraining({ payload }) { 
  const { trainingId } = payload;
  yield put(hideMessage())
  try {
    const responseData = yield call(getAllTrainingRequest, payload); 
    if (responseData.message) {
      yield put(showMessage({ error:responseData.message}));
    } else {   
      if(responseData.data.data) { 
        const trainingInfo = responseData.data.data;  //getTraineeAllTrainingSuccess
        
        if(trainingId)
          yield put(getTraineeTrainingSuccess(trainingInfo)); 
        else
        yield put(getTraineeAllTrainingSuccess(trainingInfo)); 
      }
    }
  } catch (error) {
    yield put(showMessage({error}));
  }
} 
   
export function* trainingAllGet(){
  yield takeEvery( GET_TRAINEE_TRAINING, getAllTraining)
}

export default function* rootSaga() {
  yield all([  
    fork(trainingAllGet), 
  ]);
}
