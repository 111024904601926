import React from "react";
// import img from './assets/images/backgrounds'

import { Link } from "react-router-dom";

import { IconFont } from "util/Utils";

function NavbarMobile(info) {
  const { onClickViewModel } = info;
  return (
    <div className="tm-mobile-header uk-hidden@m">
      <nav className="uk-navbar-container uk-navbar" data-uk-navbar="">
        <div className="uk-navbar-left">
          <Link
            to="/"
            className="uk-hidden@m uk-logo"
            style={{ marginTop: "-10px" }}
          >
            <IconFont
              type="icon-Ophish_menu_logo2"
              style={{ fontSize: "55px", marginTop: "-30px" }}
            ></IconFont>
          </Link>
          {/* <a className="uk-hidden@m uk-logo" href="/" style={{left: '0', lineHeight: '35px'}}>  Shield Alliance </a>  */}
        </div>
        <div className="uk-navbar-right">
          <a className="uk-navbar-toggle" data-uk-toggle="target: #tm-mobile">
            <div className="uk-navbar-toggle-icon">
              <i className="far fa-bars ic-icon-large uk-text-black"></i>
            </div>
          </a>
        </div>
      </nav>
      {/* <!-- model mobile menu --> */}
      <div id="tm-mobile" className="uk-modal-full uk-modal" data-uk-modal>
        <div
          className="uk-modal-dialog uk-modal-body uk-text-center uk-flex"
          data-uk-height-viewport
        >
          <button
            className="uk-modal-close-full uk-close uk-icon"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-margin-auto-vertical uk-width-1-1">
            <div className="uk-child-width-1-1" data-uk-grid>
              <div>
                <div className="uk-panel">
                  <ul className="uk-nav uk-nav-primary uk-nav-center nav-white">
                    <li className="uk-active uk-modal-close ">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="uk-modal-close ">
                      <a href="#" onClick={onClickViewModel}>
                        Demo
                      </a>
                    </li>
                    <li>
                      <Link to="/signin"> Sign In</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="uk-panel widget-text" id="widget-text-1">
                  <div className="textwidget">
                    <p className="uk-text-center uk-modal-close-full uk-close">
                      <a
                        className="el-content uk-button uk-button-success uk-circle"
                        href="#freeTraining"
                        data-uk-tobottom
                        data-uk-scroll
                      >
                        {" "}
                        Free Trial{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarMobile;
