import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table } from "antd";
// import {DropOption} from '../../../components'
import { Row, Col, Upload, Radio, Form, Input } from "antd";

const { confirm } = Modal;

const viewDescriptionModal = modalProps => {
  console.log({ modalProps });
  const { modalVisible, handleCancel, fileUploadProps } = modalProps;

  return (
    <Modal
      title="Training description"
      visible={modalVisible}
      // width="85%"
      onCancel={handleCancel}
      footer={[<Button onClick={handleCancel}>Close</Button>]}
      style={{ top: "3%" }}
    >
       <Row
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Col
                style={{
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Upload {...fileUploadProps} style={{ width: "100%", alignItems: "center" }}>
                  <Button type="info" style={{ width: "100%" }} >
                    Upload SCORM Package
                  </Button>
                </Upload>
              </Col>
            </Row>
         
    </Modal>
  );
};

export default viewDescriptionModal;
