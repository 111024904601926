import {
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  INIT_URL,
  GET_TRAINING,
  GET_TRAINING_SUCCESS,
  GET_All_TRAINING,
  GET_All_TRAINING_SUCCESS,
  ON_ADD_TRAINING,
  ON_ADD_TRAINING_SUCCESS,
  UPDATE_TRAINING,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_ON_TRAINING,
  UPDATE_ON_TRAINING_SUCCESS,
  DELETE_TRAINING,
  DELETE_TRAINING_SUCCESS,
  GET_TRAINEE_TRAINING,
  GET_TRAINEE_ALL_TRAINING,
  GET_TRAINEE_TRAINING_SUCCESS,
  GET_TRAINEE_ALL_TRAINING_SUCCESS,
  SHOW_MODAL,
  HIDE_MODAL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  ACTION_BOOKMARKS_TRAINING,
  ACTION_BOOKMARKS_TRAINING_SUCCESS,
  GET_BOOKMARKS_TRAINING,
  GET_BOOKMARKS_TRAINING_SUCCESS,
  UPDATE_MY_CART_TRAINING
} from "constants/ActionTypes";

export const actionBookmarksTraining = info => {
  console.log({ info });
  return {
    type: ACTION_BOOKMARKS_TRAINING,
    payload: info
  };
};
export const actionBookmarksTrainingSuccess = training => {
  return {
    type: ACTION_BOOKMARKS_TRAINING_SUCCESS,
    payload: training
  };
};
export const getBookmarksTraining = info => {
  return {
    type: GET_BOOKMARKS_TRAINING,
    payload: info
  };
};
export const getBookmarksTrainingSuccess = training => {
  return {
    type: GET_BOOKMARKS_TRAINING_SUCCESS,
    payload: training
  };
};

export const getTraineeAllTraining = info => {
  return {
    type: GET_TRAINEE_ALL_TRAINING,
    payload: info
  };
};
export const getTraineeTraining = info => {
  console.log("info", { info });
  return {
    type: GET_TRAINEE_TRAINING,
    payload: info
  };
};
export const getTraineeAllTrainingSuccess = training => {
  return {
    type: GET_TRAINEE_ALL_TRAINING_SUCCESS,
    payload: training
  };
};

export const getTraineeTrainingSuccess = training => {
  return {
    type: GET_TRAINEE_TRAINING_SUCCESS,
    payload: training
  };
};

export const trainingUpdate = training => {
  return {
    type: UPDATE_TRAINING,
    payload: {
      training: training
    }
  };
};
export const trainingUpdateSuccess = training => {
  return {
    type: UPDATE_TRAINING_SUCCESS,
    payload: training
  };
};
export const trainingOnUpdate = training => {
  return {
    type: UPDATE_ON_TRAINING,
    payload: {
      training: training
    }
  };
};
export const trainingOnUpdateSuccess = training => {
  return {
    type: UPDATE_ON_TRAINING_SUCCESS,
    payload: training
  };
};
export const trainingAdd = training => {
  return {
    type: ON_ADD_TRAINING,
    payload: training
  };
};
export const trainingAddSuccess = allTraining => {
  return {
    type: ON_ADD_TRAINING_SUCCESS,
    payload: {
      allTraining: allTraining,
      trainings: {},
      steps: 0
    }
  };
};
export const getTraining = info => {
  console.log({ info });
  return {
    type: GET_TRAINING,
    payload: info
  };
};
export const getTrainingSuccess = training => {
  return {
    type: GET_TRAINING_SUCCESS,
    payload: training
  };
};
export const getAllTraining = info => {
  return {
    type: GET_All_TRAINING,
    payload: info
  };
};
export const getAllTrainingSuccess = training => {
  return {
    type: GET_All_TRAINING_SUCCESS,
    payload: training
  };
};

export const updateMyCartTraining = training => {
  console.log("updateMyCart", { training });
  return {
    type: UPDATE_MY_CART_TRAINING,
    payload: training
  };
};
export const deleteTraining = info => {
  return {
    type: DELETE_TRAINING,
    payload: info
  };
};
export const deleteTrainingSuccess = training => {
  return {
    type: DELETE_TRAINING_SUCCESS,
    payload: training
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showModal = modal => {
  return {
    type: SHOW_MODAL,
    payload: modal
  };
};
export const hideModal = () => {
  return {
    type: HIDE_MODAL
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
