
import React from 'react';
 

function BannerView() {
  return ( 
        <div className="uk-section-default" data-tm-header-transparent="dark" data-tm-header-transparent-placeholder="">
            <div  data-uk-img className="uk-background-norepeat uk-background-center-center uk-background-fixe uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover" uk-height-viewport="offset-top: true" style={{ backgroundImage: 'url('+require("assets/images/backgrounds/home-heros.jpg")+')', boxSizing: 'border-box', minHeight: 'calc(100vh - 0px)'}}>
                <div className="uk-width-1-1 uk-margin-xlarge-top">
                    <div className="uk-container">
                        
                        <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid="">
                            <div className="uk-width-1-1@m uk-first-column uk-margin-large-top">
                                <h2 className="uk-margin-remove-vertical uk-text-white  uk-h1 uk-scrollspy-inview uk-animation-slide-top-small">
                                Cybersecurity Training for <br/> Organizations Across the Globe    </h2>
                                <p className="uk-scrollspy-inview uk-light uk-animation-slide-top-small uk-text-medium uk-text-large  uk-text-light">
                                Our courses are much more than just online courses.  
                                 <br/>They will train your employees to recognize and prevent 
                                    <br/>cybersecurity threats in a fun and engaging way.
                                         </p>
                                
                                
                                <a className="el-content uk-button uk-button-success uk-button-large  uk-circle" href="home"> 
                                Learn More          </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

  );
}

export default BannerView;