import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./main/index";
import Profile from "./profile/index" 
import Settings from "./profile/components/resetPassword" 
import TraineeMain from "./traineeMain/index";
import Components from "./components/index";
import Campaigns from "./campaigns/index";
import Quizzer from "./quizzer/index"
import Trainings from "./trainings/index"

import './index.css'
import './index.less'

// import CustomViews from "./customViews/index";
// import Extensions from "./extensions/index";
// import ExtraComponents from "./extraComponents/index";
// import InBuiltApps from "./inBuiltApps/index";
// import SocialApps from "./socialApps/index";
// import Documents from "./documents/index";


const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}profile`} component={Profile} /> 
      <Route path={`${match.url}settings`} component={Settings} /> 
      <Route path={`${match.url}campaigns`} component={Campaigns} />
      <Route path={`${match.url}trainings`} component={Trainings} />
      <Route path={`${match.url}quizzer`} component={Quizzer} />
      <Route path={`${match.url}components`} component={Components} />
      {/* <Route path={`${match.url}trainee`} component={TraineeMain} /> */}
      {/* <Route path={`${match.url}custom-views`} component={CustomViews}/>
      <Route path={`${match.url}extensions`} component={Extensions}/>
      <Route path={`${match.url}extra-components`} component={ExtraComponents}/>
      <Route path={`${match.url}in-built-apps`} component={InBuiltApps}/>
      <Route path={`${match.url}social-apps`} component={SocialApps}/>
      <Route path={`${match.url}documents`} component={Documents}/> */}
    </Switch>
  </div>
);

export default App;
