import axios from "axios";
import { apiUrl } from "./../../util/config";

export const trialRequest = payload => dispatch => {
  dispatch({
    type: "SUBMITTING_TRIAL_REQUEST"
  });

  axios
    .post(`${apiUrl}api/start-trial`, {
      ...payload
    })
    .then(response => {
      console.log(response);
      dispatch({
        type: "TRIAL_REQUEST_DONE",
        payload: response.data
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: "TRIAL_REQUEST_DONE",
        payload: {
          error: true,
          userMessage: "Some server error has occurred."
        }
      });
    });
};
