import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  GET_CAMPAIGNS_USERS_SUCCESS,
  GET_All_CAMPAIGN_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  CAMPAIGN_USER,
  SHOW_MODAL,
  HIDE_MODAL,
  GET_DASHBOARD_SUCCESS
} from "constants/ActionTypes";
import { GET_ALL_CAMPAIGN_TRAININGS_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  campaign: {},
  allCampaigns: [],
  pagination: {},
  steps: 0,
  userCampaignInfo: {},
  campaignsUsers: [],
  modalVisible: false,
  browserModelVisible: false,
  credentialsModelVisible: false,
  trainingModelVisible: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_USERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignsUsers: action.payload.campaignsUsers.users,
        pagination: {
          current: Number(action.payload.page) || 1,
          pageSize: Number(action.payload.limit) || 10,
          total: action.payload.campaignsUsers.totalRecords
        }
      };
    }
    case GET_All_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loader: false,
        pagination: {
          current: Number(action.payload.page) || 1,
          pageSize: Number(action.payload.limit) || 10,
          total: action.payload.totalRecords,
          live: Number(action.payload.live)
        },
        campType: Number(action.payload.live),
        allCampaigns: action.payload.campaigns,
        campaign: {}
      };
    }
    case GET_DASHBOARD_SUCCESS: {
      console.log({ action });
      return {
        ...state,
        loader: false,
        dashboard: action.payload.dashboard
      };
    }
    case GET_ALL_CAMPAIGN_TRAININGS_SUCCESS: {
      console.log({ action });
      return {
        ...state,
        loader: false,
        pagination: {
          current: Number(action.payload.campaignTrainings.page) || 1,
          pageSize: Number(action.payload.limit) || 10,
          total: action.payload.campaignTrainings.totalRecords,
          live: Number(action.payload.campaignTrainings.live)
        },
        campaignTrainings: action.payload.campaignTrainings
      };
    }
    case GET_All_CAMPAIGN_SUCCESS: {
      console.log({ action });
      return {
        ...state,
        loader: false,
        trainings: action.payload.trainings
      };
    }
    // case CAMPAIGN_USER: {
    //   console.log("action.payload user--", action.payload)
    //   return {
    //     ...state,
    //     userCampaignInfo: action.payload,
    //   }
    // }

    // case SHOW_MODAL: {
    //   console.log("action.payload-99--", action.payload)
    //   return {
    //     ...state,
    //     modalVisible: (action.payload == "modal")?true:false,
    //     browserModelVisible: (action.payload == "browserModel")?true:false,
    //     credentialsModelVisible: (action.payload == "credentialsModel")?true:false,
    //     trainingModelVisible: (action.payload == "trainingModel")?true:false,
    //   }
    // }
    // case HIDE_MODAL : {
    //   return {
    //     ...state,
    //     modalVisible: false,
    //     browserModelVisible: false,
    //     credentialsModelVisible: false,
    //     trainingModelVisible: false,
    //   }
    // }
    // case SHOW_MESSAGE: {
    //   return {
    //     ...state,
    //     alertMessage: action.payload,
    //     showMessage: true,
    //     loader: false
    //   }
    // }
    // case HIDE_MESSAGE: {
    //   return {
    //     ...state,
    //     alertMessage: '',
    //     showMessage: false,
    //     loader: false
    //   }
    // }
    case ON_SHOW_LOADER: {
      console.log("dashboard ON_SHOW_LOADER");
      return {
        ...state,
        loader: true
      };
    }
    // case ON_HIDE_LOADER: {

    //   console.log("dashboard ON_HIDE_LOADER")
    //   return {
    //     ...state,
    //     loader: false
    //   }
    // }
    default:
      return state;
  }
};
