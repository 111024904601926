import React from "react";
import { Checkbox, Radio, Input, Button } from "antd";
import "./index.less";
import store from "store";

const { Search } = Input;
function TrainingFilter(propsInfo) {
  const {
    topicsList,
    categoryList,
    pricesList,
    languageList,
    durationsList,
    topicValue,
    categoryValue,
    languageValue, 
    priceValue,
    durationValue,
    onChangeTopic,
    onChangeCategory,
    onChangeLanguage,
    onChangePrice,
    onChangeDuration,
    onApplyFilter,
    trainingType,
    searchText,
    onSearchText,
    onChangeText,
    isCscuServiceAvailable,
    allTrainings,
    enrollAll,
    loading
  } = propsInfo;
  console.log({propsInfo})
  return (
    <div>
      <div className="uk-float-left">
        <Search
          placeholder="Search"
          value={searchText}
          onChange={onChangeText}
          onSearch={value => {
            onSearchText(value);
          }}
          enterButton
        />
      </div>

      <div className="uk-float-right">
        {trainingType === "cscu" && !loading && (
          <Button
            disabled={
              !isCscuServiceAvailable ||
              store.get("user").trial ||
              allTrainings.filter(
                training =>
                  training.trainingType === 3 &&
                  !training.enrolled &&
                  training.isPurchase
              ).length > 0 ||
              allTrainings.length === 0
            }
            type="primary"
            onClick={enrollAll}
          >
            Enroll in All Course
          </Button>
        )}

        {trainingType === "premium" && (
          <a
            href="#"
            className="uk-link-reset uk-margin-small-right uk-text-small uk-text-uppercase"
            data-uk-tooltip="title: Filter; pos: top-right"
            data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
          >
            {" "}
            <i className="fas fa-filter "></i> FILTER{" "}
          </a>
        )}
      </div>

      {trainingType === "premium" && (
        <div
          id="Course-Filter"
          hidden
          className="uk-margin-top uk-margin-large-bottom uk-position-relative"
        >
          <h3 className="uk-visible@s uk-text-uppercase"> {""} &nbsp;</h3>
          <div className="uk-position-top-right">
            <button
              className="uk-button uk-button-white"
              data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
            >
              Cancel
            </button>
            <button
              onClick={onApplyFilter}
              className="uk-button uk-button-grey"
              data-uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"
            >
              {" "}
              Apply{" "}
            </button>
          </div>
          <hr className="uk-margin-small" />
          <div className="uk-child-width-1-3@m uk-child-width-1-3" data-uk-grid>
            {trainingType === "premium" && (
              <div>
                <h4 className="uk-margin-remove"> Topic </h4>
                <div id="checkBox" className="uk-flex uk-flex-column">
                  <Checkbox.Group
                    options={topicsList}
                    value={topicValue}
                    onChange={onChangeTopic}
                  />
                </div>
              </div>
            )}
            {trainingType === "premium" && false  && (
              <div>
                <h4 className="uk-margin-remove"> Category </h4>
                <div id="checkBox" className="uk-flex uk-flex-column">
                  <Checkbox.Group
                    options={categoryList}
                    value={categoryValue}
                    onChange={onChangeCategory}
                  />
                </div>
              </div>
            )}
            {(trainingType === "premium" || trainingType === "cscu") && false && (
              <div>
                <h4 className="uk-margin-remove"> Price </h4>
                <div id="checkBox" className="uk-flex uk-flex-column">
                  <Checkbox.Group
                    options={pricesList}
                    value={priceValue}
                    onChange={onChangePrice}
                  />
                </div>
              </div>
            )}
            { 
             <div>
                <h4 className="uk-margin-remove"> Language </h4>
                <div id="checkBox" className="uk-flex uk-flex-column">
                  <Checkbox.Group
                    options={languageList}
                    value={languageValue}
                    onChange={onChangeLanguage}
                  />
                </div>
              </div> 
            }
            {(trainingType === "premium" || trainingType === "cscu") && (
              <div>
                <h4 className="uk-margin-remove"> Duration </h4>
                <div id="checkBox" className="uk-flex uk-flex-column">
                  <Radio.Group
                    options={durationsList}
                    defaultValue={durationValue}
                    onChange={e => onChangeDuration(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default TrainingFilter;
