import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import { imagePrefixUrl, apiUrl, stripeKey } from "util/config";
import { Button, message, Pagination } from "antd";
import store from "store";

import TopNavbar from "../components/TopNavbar";
import CircularProgress from "../../../components/CircularProgress/index.js";

import PurchaseModel from "./model";
import trainingModel from "../../trainings/$id/model";
const numEachPage = 10;
class YourTrainings extends React.PureComponent {
  state = {
    _id: 0,
    trainings: [],
    total: 0,
    loading: false,
    minValue: 0,
    maxValue: 1,
    page: 0,
    totalRecords: 0
  };
  componentWillMount() {
    this.purchaseTraining(this.state.page);
  }

  purchaseTraining = page => {
    this.setState({ loading: true });
    PurchaseModel.getPurchase(page).then(response => {
      if (!response.error) {
        var transactions = response.data;
        var totalRecords = response.totalRecords;

        var trainings = [];

        for (let i = 0; i < transactions.length; i++) {
          var arrayObj = transactions[i];
          arrayObj["expire"] = moment().diff(arrayObj["expireAt"]) > 0;

          trainings.push(arrayObj);
        }
        this.setState({
          trainings: trainings,
          loading: false,
          totalRecords: totalRecords
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };
  displayPrice(price) {
    return parseFloat(price).toFixed(2);
  }
  formateDateTime(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  trainingTypes(trainingType) {
    let type =
      trainingType === 4
        ? "Practice"
        : trainingType === 1 || trainingType === 2
        ? "premium"
        : trainingType === 3
        ? "cscu"
        : "";
    return type;
  }
  createSessionCheckout = training => () => {
    fetch(`${apiUrl}api/lms/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      body: JSON.stringify({
        trainingIds: [training._id]
      })
    })
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        if (resp.error) {
          message.error(resp.userMessage, 5);
        } else {
          console.log(resp.data);
          window
            .Stripe(stripeKey)
            .redirectToCheckout({
              sessionId: resp.data
            })
            .then(function(result) {
              if (result.error) {
                // var displayError = document.getElementById("error-message");
                message.error(result.error.message, 5);
                // displayError.textContent = result.error.message;
              }
            });
        }
      });
  };

  enrollNow = item => {
    trainingModel
      .assignTraining([store.get("user")], item._id)
      .then(response => {
        if (response.error) {
          message.error(response.userMessage);
        } else {
          message.success(response.userMessage);
          // (onclose = function() {

          this.props.history.push(
            item.trainingType === 4
              ? "/trainee/course-dhashboard/" +
                  item._id +
                  "/" +
                  this.trainingTypes(item.trainingType)
              : "/trainee/course-intro/" +
                  item._id +
                  "/" +
                  this.trainingTypes(item.trainingType)
          );
          // })
        }
        // this.hideUserModal()
        // this.hideLoader()
      });
  };
  handleChange = value => {
    this.setState({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
      page: value
    });
    this.purchaseTraining(value);
  };
  render() {
    return (
      <div>
        <div>
          <TopNavbar key="topNavbarKey" />
        </div>
        <div className="uk-container uk-margin uk-align-center">
          <h3 className="uk-margin-small-bottom  " style={{ textAlign: "center" }}>
            Purchase History
          </h3>
        </div>
        <div
          className="uk-container uk-margin uk-align-center"
          // data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
        >
          {/* <!-- course one -->                  */}
          {this.state.trainings && this.state.trainings.length > 0 ? (
            this.state.trainings.map((item, index) => {
              console.log("item----", { item });
              return (
                <div
                  className="uk-card uk-card-small uk-card-body  uk-card-default uk-margin uk-margin-left uk-margin-right"
                  style={{ borderRadius: "5px" }}
                >
                  <img
                    className="uk-align-left uk-width-1-3@m uk-margin-remove-bottom"
                    src={
                      item.training.coverImage
                        ? `${imagePrefixUrl}${item.training.coverImage}`
                        : require("assets/images/courses/cover_image.jpg")
                    }
                  />
                  <div className="uk-padding-small-left uk-padding-small-right uk-padding-small-bottom">
                    <a className="uk-link-reset">
                      <h4 className="uk-margin-small-bottom uk-margin-remove">
                        {item.training.name}
                      </h4>
                      <h4
                        className="uk-margin-remove"
                        style={{ fontWeight: "500" }}
                      >
                        ${this.displayPrice(item.training.price)}
                      </h4>
                      <p style={{ margin: "5px 0" }}>order: #{item.orderId}</p>
                      <p style={{ margin: "5px 0" }}>
                        Transaction Status:{" "}
                        <strong
                          style={{
                            color:
                              item.transactionStatus === "success"
                                ? "green"
                                : "red"
                          }}
                        >
                          {item.transactionStatus === "success"
                            ? "Success"
                            : "Failed"}
                        </strong>
                      </p>
                    </a>
                    <div
                      className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin-remove uk-margin-small-bottom"
                      data-uk-grid
                    >
                      <div style={{ padding: "0px", margin: "0px" }}>
                        <p>
                          <strong>Ship to</strong>{" "}
                          {store.get("user").userName || ""}
                        </p>
                      </div>
                      <div style={{ padding: "0px", margin: "0px" }}>
                        <p>
                          {" "}
                          <strong> Date:</strong>{" "}
                          {this.formateDateTime(item.createdAt)}
                        </p>{" "}
                      </div>
                      <div style={{ padding: "0px", margin: "0px" }}>
                        {item.transactionStatus === "success" && (
                          <p>
                            {" "}
                            <strong>Tenure:</strong>{" "}
                            {this.formateDateTime(item.expireAt)}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className=" uk-margin-top ">
                      {item.expire && item.transactionStatus === "success" && (
                        <a
                          className="Course-tags uk-margin-small-right"
                          style={{
                            borderRadius: "27px",
                            fontWeight: "600",
                            background: "rgb(241, 73, 73)",
                            padding: "8px 18px",
                            color: "#ffffff",
                            textDecoration: "none",
                            border: "1px solid rgb(241, 73, 73)",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                          }}
                        >
                          Expire
                        </a>
                      )}

                      {!item.expire &&
                        item.transactionStatus === "success" &&
                        !item.enrolled && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            onClick={() => {
                              this.enrollNow(item.training);
                            }}
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              backgroundColor: "#297eb8",
                              padding: "8px 18px",
                              color: "#ffffff",
                              textDecoration: "none",
                              border: "1px solid #297eb8",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                            }}
                          >
                            Assign Me
                          </a>
                        )}
                      {!item.expire &&
                        item.transactionStatus === "success" &&
                        item.enrolled && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            disabled="disabled"
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              backgroundColor: "rgb(136, 138, 140)",
                              padding: "8px 18px",
                              color: "#ffffff",
                              textDecoration: "none",
                              border: "1px solid rgb(136, 138, 140)",
                              boxShadow: "0 2px 5px rgba(47, 47, 47, 0.35)"
                            }}
                          >
                            Assign Me
                          </a>
                        )}
                      {item.expire ||
                        (item.transactionStatus === "failed" && (
                          <a
                            className="Course-tags uk-margin-small-right"
                            onClick={this.createSessionCheckout(item.training)}
                            style={{
                              borderRadius: "27px",
                              fontWeight: "600",
                              background: "#fdfdfd",
                              padding: "8px 18px",
                              color: "#297eb8",
                              textDecoration: "none",
                              border: "1px solid #297eb8",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.14)"
                            }}
                          >
                            Buy Again
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {this.state.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <div
                  style={{
                    marginLeft: "10px"
                  }}
                  className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                  data-uk-grid
                >
                  <div className="uk-child-width-1-4@m"></div>
                  <div className="uk-child-width-2-4@m">
                    <div className="uk-text-center uk-margin">
                      <div style={{ padding: "0 20%" }}>
                        <img src={require("assets/images/no_data_found.png")} />
                      </div>
                      <h3
                        className="uk-text-center "
                        style={{ margin: "20px 0 0" }}
                      >
                        Sorry!! No Data Found.
                      </h3>
                      <p className="uk-text-center uk-margin-remove">
                        Try somethings else.
                      </p>
                    </div>
                  </div>
                  <div className="uk-child-width-1-4@m"></div>
                </div>
              )}
            </div>
          )}
          {this.state.trainings && this.state.trainings.length > 0 && (
            <Pagination
              defaultCurrent={1}
              defaultPageSize={numEachPage} //default size of page
              onChange={this.handleChange}
              total={this.state.totalRecords} //total number of card data available
            />
          )}
        </div>
      </div>
    );
  }
}

// export default YourTrainings;
export default withRouter(YourTrainings);
