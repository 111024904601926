import React from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import CampaignTable from "../../../campaigns/campaigns/components/CampaignTable";
import TrainingCampaignTable from "../../../campaigns/campaigns/components/TrainingCampaignTable";
import TrainingList from "components/dashboard/CRM/TrainingList";
import QuizList from "components/dashboard/CRM/QuizList";
import Auxiliary from "util/Auxiliary";

import QueriesCard from "components/Metrics/QueriesCard";
import TrafficRaiseCard from "components/Metrics/TrafficRaiseCard";

import {
  hideMessage,
  showMessage,
  getAllCampaignTrainings,
  getDashboard,
  showLoader,
} from "../../../../appRedux/actions/Dashboard";
import { getAllCampaign } from "../../../../appRedux/actions/Campaign";
import { getAllTraining } from "../../../../appRedux/actions/Training";

import CircularProgress from "components/CircularProgress/index";
import store from "store";
import IntlMessages from "util/IntlMessages";

class CRM extends React.PureComponent {
  user = store.get("user");
  services = this.user.organisation ? this.user.organisation.services||[]:[]
  // services = this.user.organisation.services;
  state = {
    // campaignsType: this.services.includes("phishing") ? 0 : 1
    campaignsType: 1,
  };

  componentWillMount() {
    const { location } = this.props;
    this.props.showLoader();
    this.props.getDashboard({});
    this.props.getAllCampaignTrainings({ live: 1 });
    this.props.getAllCampaign({ live: 1 });
    // this.setState({campType: (typeof obj.live !== 'undefined')  ? Number(obj.live): this.state.campType})
  }
  render() {
    const {
      match,
      loader,
      dashboard,
      campaignTrainings,
      allCampaigns,
    } = this.props;
    // console.log(this.props);
    if (!dashboard || !campaignTrainings) {
      return "";
    }
    // const campaignProps = {
    //   ...{
    //     title: "Campaigns",
    //     id: "icon-ic_campaign",
    //     total: dashboard.analytics[0].totalCount,
    //     onClick: () => {
    //       this.props.history.push(`/campaigns/campaigns?live=1`);
    //     }
    //   }
    // };

    const totalTrainingProps = {
      ...{
        title: "Total Trainings",
        id: "training",// "icon-ic_campaign",
        total:
          dashboard.analytics[0] && dashboard.analytics[0].totalCount
            ? dashboard.analytics[0].totalCount
            : 0,
        onClick: () => {
          // this.props.history.push(`/trainings/trainings`);
          this.props.history.push(`/trainings/campaigns`);
        },
      },
    };
    const trainingProps = {
      ...{
        title: " Trainings",
        id: "icon-ic_training",
        total:
          dashboard.analytics[0] && dashboard.analytics[0].trainingsCount
            ? dashboard.analytics[0].trainingsCount
            : 0,
        onClick: () => {
          this.props.history.push(`/trainings/trainings`);
        },
      },
    };

    const completedTrainingProps = {
      ...{
        title: "Completed Trainings",
        id: "complated",// "icon-ic_campaign_complete",
        total:
          dashboard.analytics[0] && dashboard.analytics[0].completedCount
            ? dashboard.analytics[0].completedCount
            : 0,
        onClick: () => {
          // this.props.history.push(`/campaigns/campaigns?live=0`);
          this.props.history.push(`/trainings/campaigns?live=0`);
        },
      },
    };

    const pendingTrainingProps = {
      ...{
        title: "Pending Trainings",
        id: "pending",//"icon-ic_training",
        total:
          dashboard.analytics[0] && dashboard.analytics[0].activeCount
            ? dashboard.analytics[0].activeCount
            : 0,
        onClick: () => {
          // this.props.history.push(`/campaigns/campaigns?live=0`);
          this.props.history.push(`/trainings/campaigns?live=1`);
        },
      },
    };
    const trainingCampaignTableProps = {
      data: campaignTrainings.campaigns || [],
      campaignsType: -1,
      title: "Recent Training Campaigns",
      onMenuClick: (key, record) => {
        if (key == "1") {
          this.props.history.push(
            `${"/campaigns/"}${record._id}${"/details/training"}`
          );
        } else if (key == "2") {
          this.props.history.push(
            `${"/campaigns/"}${record._id}${"/users/training"}`
          );
        }
      },
    };
    const campaignTableProps = {
      data: this.state.campaignsType
        ? campaignTrainings.campaigns || []
        : allCampaigns || [],
      campaignsType: this.state.campaignsType,
      dashboardView: true,
      handleChange: (e) => {
        let campaignsType = e.target.value;
        this.setState({ campaignsType });
      },
      onClick: (record) => {
        this.state.campaignsType
          ? this.props.history.push(
              `${"/campaigns/"}${record._id}${"/details/training"}`
            )
          : this.props.history.push(
              `${"/campaigns/"}${record._id}${"/details"}`
            );
      },
      // onMenuClick: (key, record) => {
      // if( key == '1') {
      // 	this.state.campaignsType == 0 ?
      // 		this.props.history.push(`${'/campaigns/'}${record._id}${'/details/training'}`)
      // 		: this.props.history.push(`${'/campaigns/'}${record._id}${'/details'}`);
      // } else if ( key == '2' ) {
      // 	this.state.campaignsType == 0 ?
      // 		this.props.history.push(`${'/campaigns/'}${record._id}${'/users/training'}`)
      // 		: this.props.history.push(`${'/campaigns/'}${record._id}${'/users'}`);
      // }
      // }
    };
    return (
      <Auxiliary>
        {loader && (
          <div style={{ position: "fixed", zIndex: "+999", left: "47%" }}>
            <CircularProgress />
          </div>
        )}
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1><IntlMessages id="sidebar.dashboard"/></h1>
            <div className="gx-campaign-space" />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <QueriesCard {...totalTrainingProps} />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <QueriesCard {...completedTrainingProps} />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <QueriesCard {...pendingTrainingProps} />
          </Col>
          {/* 
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <TrafficRaiseCard
              reportName={"Phishing Report Raise"}
              reportId={"report_one"}
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <TrafficRaiseCard
              reportName={"Phishing Report Raise"}
              reportId={"report_two"}
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <TrafficRaiseCard
              reportName={"Phishing Report Raise"}
              reportId={"report_three"}
            />
          </Col> */}

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {/* <TrainingCampaignTable {...campaignTableProps} /> */}
            {this.services.includes("phishing") ||
            this.services.includes("training") ? (
              <CampaignTable {...campaignTableProps} />
            ) : null}
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {/* <QuizList quizList={quizData} /> */}
          </Col>

          {/* <Col span={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                  <WelComeCard/>
                </Col>

                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                  <SiteAudience/>
                </Col>

                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                  <SiteVisit/>
                </Col>
              </Row>
            </div>
          </div>
        </Col> */}
          {/* <Col xl={8} lg={24} md={8} sm={24} xs={24}>
					<TotalRevenueCard />
				</Col>
				<Col xl={8} lg={12} md={8} sm={24} xs={24}>
					<NewCustomers />
				</Col>
				<Col xl={8} lg={12} md={8} sm={24} xs={24}>
					<GrowthCard trafficData={trafficData} />
				</Col> */}

          {/* <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
					<Widget>
						<RecentActivity recentList={recentActivity} shape="circle" />
					</Widget>
					<CurrencyCalculator />
				</Col> */}

          {/* <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
					<Row>
						<Col xl={6} lg={6} md={6} sm={12} xs={12}>
							<IconWithTextCard cardColor="cyan" icon="diamond" title="09" subTitle="Projects" />
						</Col>
						<Col xl={6} lg={6} md={6} sm={12} xs={12}>
							<IconWithTextCard cardColor="orange" icon="tasks" title="687" subTitle="Tasks" />
						</Col>
						<Col xl={6} lg={6} md={6} sm={12} xs={12}>
							<IconWithTextCard cardColor="teal" icon="team" title="04" subTitle="Teams" />
						</Col>
						<Col xl={6} lg={6} md={6} sm={12} xs={12}>
							<IconWithTextCard cardColor="red" icon="files" title="09" subTitle="Files" />
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<TaskList taskList={taskList} />
						</Col>
						<Col xl={16} lg={16} md={16} sm={24} xs={24}>
							<TicketList />
						</Col>
						<Col xl={8} lg={8} md={8} sm={24} xs={24}>
							<TaskByStatus />
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<Overview />
						</Col>
					</Row>
				</Col> */}
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ dashboardInfo, trainingInfo, campaignInfo }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    pagination,
    dashboard,
    campaignTrainings,
  } = dashboardInfo;
  const { allTraining } = trainingInfo;
  const { allCampaigns } = campaignInfo;
  return {
    loader,
    alertMessage,
    showMessage,
    campaigns,
    allCampaigns,
    pagination,
    dashboard,
    allTraining,
    campaignTrainings,
  };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    showMessage,
    getAllCampaign,
    getAllCampaignTrainings,
    getAllTraining,
    getDashboard,
    showLoader,
  }
)(CRM);
