import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userSignOut } from "appRedux/actions/Auth";
import moment from "moment";
import { Form, Input, Card, Icon, message, Row, Col, Button, Upload } from "antd";
import { imageMediaUrl } from "util/config";
import "./index.less";
import $ from "jquery";
import TopNavbar from "./../components/TopNavbar";

import UserModel from "../../profile/model";
// import UserModel from "./model";
import { api, apiUrl } from "util/config";
import store from "store";
import SettingView from "./components/resetPassword";
// import ViewModalEdit from "./modalEdit";
import LogoutModal from "containers/TopNavbar/logoutModal";
const { Dragger } = Upload;

const formatTime = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};
// function UserProfile( info) {
class UserProfile extends React.PureComponent {
  state = {
    userInfo: {},
    loader: false,
    viewModel: false,
    onClickButton: true,
    logoutModalVisible: false,
    accessLevel: {
      0: "Super Admin",
      1: "Central User",
      2: "Client Approver",
      3: "Client Requester",
      4: "Consultant Requester",
      5: "Consultant Approver",
      6: "POC Requester",
      7: "POC Approver",
    },
  };

  componentWillMount() {
    var userInfo = store.get("user");
    this.setState({
      userInfo: userInfo,
    });
  }
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    console.log({ file });
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    console.log(isLt2M);
    if (!isLt2M) {
      message.error("Image must smaller than 300kb!");
    }
    return isJpgOrPng && isLt2M;
  };
  updateUserInfo = (info) => {
    this.setState({ loader: true, onClickButton: false });
    UserModel.userTraineeInfoUpdate(info).then((response) => {
      this.setState({ userInfo: response, loader: false, viewModel: false, onClickButton: true });
    });
  };

  onClickViewModel = () => {
    console.log("onClickViewModel---");
    this.setState({ viewModel: true });
  };
  importImage = function(e) {
    $("#importImage").val("");
    $("#importImage").trigger("click");
  };
  changeFile = function(e) {
    var profileImage = e.target.files[e.target.files.length - 1];
    const fileType = profileImage["type"];
    const validImageTypes = ["image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      alert("Please upload valid image", "red");
      return false;
    }

    console.log(" ctrl.profileImage--1", profileImage);
    $("#profileImageUpload").val("");
    $("#profileImageUpload").trigger("click");
    // console.log(ctrl.profileImage);
  };

  shareOnLinkedInButton(user, badge) {
    var url = "https://lms-staging.ohphish.com/me/" + user._id + "?badge=" + badge.name;

    var link = "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(url);

    // console.log({ link });
    // return (
    //   <div>
    //     <a
    //       onClick={() => {
    window.open(link, "", "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
    //       }}
    //     >
    //       Share onLinked
    //     </a>
    //   </div>
    // );
  }
  render() {
    const { form, accessToken } = this.props;
    var { getFieldDecorator } = this.props.form;
    const { userInfo, loader } = this.state;

    const formItemLayout = {
      style: { lineHeight: 28 },
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const logoutModalProps = {
      logoutModalVisible: this.state.logoutModalVisible,
      handleConfirm: () => {
        this.props.userSignOut();
      },
      handleCancel: () => {
        this.setState({ logoutModalVisible: false });
      },
    };
    let that = this;
    const uploadProps = {
      name: "file",
      multiple: false,
      action: apiUrl + "api/trainee/media/upload",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      beforeUpload: this.beforeUpload,
      onChange(info) {
        const { status, response } = info.file;
        if (status !== "uploading") {
        }
        if (status === "done") {
          if (!response.error && response.status === 200) {
            var objectValue = {};
            objectValue["userName"] = userInfo.userName;
            objectValue["profilePhoto"] = response.data;
            that.updateUserInfo(objectValue);
            // message.error(response.userMessage);
          }
        } else if (status === "error") {
          message.error("Uploading failed. Please try again");
        }
      },
    };
    const handleFormSubmit = (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        console.log("handleFormSubmit", err);
        if (!err) {
          that.updateUserInfo(values);
        }
      });
    };

    const onclose = () => {
      var userInfo = store.get("user");
      this.setState({
        userInfo: userInfo,
      });
    };

    return (
      <div>
        <TopNavbar key="topNavbarKey" />

        <div className="uk-container uk-margin-medium-top">
          {/* <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1 style={{marginBottom:4}}> {"Profile"} </h1>
          </Col>
        </Row> */}
          <div
            className={this.state.userInfo.organisationId.authMethod == "default" ? "uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" : "uk-child-width-1-2@s uk-child-width-1-2@m uk-grid-match uk-margin"}
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
            data-uk-grid
          >
            <div>
              <div
                className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
                tabindex="0"
              >
                <div className="my-profile-top">
                  <h3 className="panel-title text-center">
                    {/* <i className="fa fa-custom-my-profile"></i> */}
                    <img src={require("assets/images/my_profile_icon.png")}></img>
                    {"My Profile"}
                  </h3>
                </div>

                <div className="panel-body my-profile-view">
                  <div className="my-profile-info">
                    <div className="text-center">
                      <img
                        src={
                          userInfo.profilePhoto && userInfo.profilePhoto !== ""
                            ? `${imageMediaUrl}${userInfo.profilePhoto}?token=${accessToken}`
                            : require("assets/images/avatures/avature-2.png")
                        }
                        className="uk-border-circle user-profile-tiny"
                        alt="Profile Picture"
                        style={{
                          width: "100px",
                          height: "100px",
                          boxShadow: "0px 0px 4px 3px rgba(146, 146, 146, 0.27)",
                        }}
                      />
                      <div className="top-profile-div">
                        <h3 className="  text-overflow mar-no">{userInfo.userName}</h3>
                        <p style={{ fontSize: "15px", marginTop: "0px" }}>{userInfo.email}</p>
                        </div>
                        <div>
                          {userInfo.badges &&
                            userInfo.badges.map(function(badge) {
                              return (
                                <div className="uk-text-meta uk-text-center">
                                  <object
                                    data={require("assets/images/badge.svg")}
                                    type="image/svg+xml"
                                    style={{ width: 40, color: "green" }}
                                  ></object>
                                  <div style={{ fontSize: 15 }}>{badge.name}</div>
                                  <Button
                                    onClick={() => {
                                      console.log("share");
                                      that.shareOnLinkedInButton(userInfo, badge);
                                    }}
                                    style={{
                                      // display: "flex",
                                      alignItems: "center",
                                      color: "#1c70a0",
                                      fontSize: "initial",
                                      borderColor: "#1c70a0",
                                    }}
                                    icon="linkedin"
                                  >
                                    Share on linkedIn
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                     
                    </div>
                  </div>
                </div>
                <div className="panel-body" style={{ marginTop: "-100px" }}>
                  <div className="my-profile-info">
                    <div className="uk-grid-small uk-flex " uk-grid>
                      <div className="uk-width-auto">
                        <img
                          className="uk-border-circle"
                          src={require("assets/images/contact_icon.png")}
                          style={{ width: "40px" }}
                        />
                      </div>
                      <div className="uk-width-expand">
                        <h3 className="uk-card-title uk-margin-remove-bottom">Contact</h3>
                        <p className="uk-text-meta uk-margin-remove-top">{userInfo.contactNumber || "N/A"}</p>
                      </div>
                    </div>
                    {!userInfo.ccisoEmail && (
                      <div className="uk-grid-small uk-flex " uk-grid>
                        <div className="uk-width-auto">
                          <img
                            className="uk-border-circle"
                            src={require("assets/images/designation_icon.png")}
                            style={{ width: "40px" }}
                          />
                        </div>
                        <div className="uk-width-expand">
                          <h3 className="uk-card-title uk-margin-remove-bottom">Designation</h3>
                          <p className="uk-text-meta uk-margin-remove-top">{userInfo.designation || "N/A"}</p>
                        </div>
                      </div>
                    )}
                    {!userInfo.ccisoEmail && (
                      <div className="uk-grid-small uk-flex " uk-grid>
                        <div className="uk-width-auto">
                          <img
                            className="uk-border-circle"
                            src={require("assets/images/department_icon.png")}
                            style={{ width: "40px" }}
                          />
                        </div>
                        <div className="uk-width-expand">
                          <h3 className="uk-card-title uk-margin-remove-bottom">Department</h3>
                          <p className="uk-text-meta uk-margin-remove-top">{userInfo.department || "N/A"}</p>
                        </div>
                      </div>
                    )}
                    {userInfo.ccisoEmail ? (
                      <div className="uk-grid-small uk-flex " uk-grid>
                        <div className="uk-width-auto">
                          <img
                            className="uk-border-circle"
                            src={require("assets/images/profile_a_icon.png")}
                            style={{ width: "40px" }}
                          />
                        </div>
                        <div className="uk-width-expand">
                          <h3 className="uk-card-title uk-margin-remove-bottom">Official Email</h3>
                          <p className="uk-text-meta uk-margin-remove-top">{userInfo.ccisoEmail || "N/A"}</p>
                        </div>
                      </div>
                    ) : (
                      userInfo.organisation &&
                      !userInfo.organisation.trial && (
                        <div className="uk-grid-small uk-flex " uk-grid>
                          <div className="uk-width-auto">
                            <img
                              className="uk-border-circle"
                              src={require("assets/images/role_icon.png")}
                              style={{ width: "40px" }}
                            />
                          </div>
                          <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">Role</h3>
                            <p className="uk-text-meta uk-margin-remove-top">
                              {" "}
                              {this.state.accessLevel[userInfo.accessLevel] || "N/A"}
                            </p>
                          </div>
                        </div>
                      )
                    )}

                    <div
                      className="panel-footer text-center buttonColor"
                      style={{ background: "white", border: "unset", marginTop: "24px" }}
                    >
                      <Button
                        type="info"
                        onClick={() => {
                          this.setState({ logoutModalVisible: true });
                        }}
                        //  data-uk-toggle="target: #modal-logout"
                      >
                        <i className="fas fa-sign-out-alt" style={{ paddingRight: 4 }}>
                          {" "}
                        </i>{" "}
                        Log out
                      </Button>
                      {this.state.logoutModalVisible && <LogoutModal key="logoutModal" {...logoutModalProps} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip uk-transition-toggle"
                tabindex="0"
              >
                <div className="edit-profile-top">
                  <h3 className="panel-title text-center">
                    {/* <i className="fa fa-custom-edit-profile"></i> */}
                    <img src={require("assets/images/edit_profile_icon.png")}></img>
                    {"Edit Profile"}
                  </h3>
                </div>
                <div className="panel-body edit-profile-view">
                  <div className="text-center">
                    <img
                      src={
                        userInfo.profilePhoto && userInfo.profilePhoto !== ""
                          ? `${imageMediaUrl}${userInfo.profilePhoto}?token=${accessToken}`
                          : require("assets/images/avatures/avature-2.png")
                      }
                      className="uk-border-circle user-profile-tiny"
                      alt="Profile Picture"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginTop: "-22px",
                        boxShadow: "0px 0px 4px 3px rgba(146, 146, 146, 0.27)",
                      }}
                    />
                    <div id="custom-dragger">
                      <Dragger {...uploadProps}>
                        <div className="uk-position-center">
                          <img src={require("assets/images/cam_icon.png")}></img>
                        </div>
                      </Dragger>
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      id="importImage"
                      onChange={this.changeFile}
                      style={{ display: "none" }}
                    />
                    <button
                      id="profileImageUpload"
                      type="button"
                      style={{ display: "none" }}
                      onClick={this.insertProfileImage}
                      className="btn btn-info"
                      value="Insert"
                    ></button>
                    {/* </Dragger> */}
                  </div>
                </div>

                <div className="panel-body">
                  <div id="custom-ant-input">
                    <Form {...formItemLayout} onSubmit={handleFormSubmit} style={{ margin: "20px" }}>
                      <Form.Item
                        label="Email"
                        className="custom-input-readOnly"
                        style={{ marginBottom: 8, border: "unset !important" }}
                      >
                        {getFieldDecorator("email", {
                          initialValue: userInfo.email,
                        })(<Input readOnly />)}
                      </Form.Item>
                      <Form.Item label="Name" style={{ marginBottom: 8 }}>
                        {getFieldDecorator("userName", {
                          initialValue: userInfo.userName,
                          rules: [
                            {
                              pattern: /^[a-zA-Z0-9_\-,]/,
                              message: "Please input Name!",
                              required: true,
                              whitespace: true,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                      <Form.Item label="Contact" style={{ marginBottom: 8 }}>
                        {getFieldDecorator("contactNumber", {
                          initialValue: userInfo.contactNumber,
                          rules: [
                            {
                              pattern: /^[a-zA-Z0-9_\-,]/,
                              message: "Please input contact number!",
                              required: true,
                            },
                          ],
                        })(<Input type="number" />)}
                      </Form.Item>
                      <Form.Item label="Designation" style={{ marginBottom: 8 }}>
                        {getFieldDecorator("designation", {
                          initialValue: userInfo.designation,
                          rules: [
                            {
                              pattern: /^[a-zA-Z0-9_\-,]/,
                              message: "Please input designation!",
                              required: true,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                      <Form.Item label="Department" style={{ marginBottom: 8 }}>
                        {getFieldDecorator("department", {
                          initialValue: userInfo.department,
                          rules: [
                            {
                              pattern: /^[a-zA-Z0-9_\-,]/,
                              message: "Please input department!",
                              required: true,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>

                      <div className=" uk-text-center uk-margin buttonColor">
                        <Form.Item>
                          <Button
                            // onClick={handleFormSubmit.bind(this)}
                            type="info"
                            className="  uk-modal-close"
                            // htmlType="button"
                            htmlType="submit"
                            disabled={this.state.onClickButton ? false : true}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {this.state.userInfo.organisationId.authMethod == "default" && (
                <SettingView />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  const { loader, alertMessage, successMessage, showMessage } = trainingInfo;
  return {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    authUser,
    accessToken,
  };
};

export default Form.create()(
  withRouter(
    connect(
      mapStateToProps,
      {
        userSignOut,
      }
    )(UserProfile)
  )
);
