/* eslint-disable no-undef */
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import UnSupportedBrowser from './components/unSupportedBrowser'
import NextApp from "./NextApp";

class BaseLayout extends PureComponent {
    parseUA = () => {
        console.log('parseUA')
        var u = navigator.userAgent
        var u2 = navigator.userAgent.toLowerCase()
        return {
            trident: u.indexOf('Trident') > -1,
            presto: u.indexOf('Presto') > -1,
            webKit: u.indexOf('AppleWebKit') > -1,
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
            iPhone: u.indexOf('iPhone') > -1,
            iPad: u.indexOf('iPad') > -1,
            webApp: u.indexOf('Safari') == -1,
            iosv: u.substr(u.indexOf('iPhone OS') + 9, 3),
            weixin: u2.match(/MicroMessenger/i) == 'micromessenger',
            ali: u.indexOf('AliApp') > -1,
        }
    }
    isTablet = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        console.log(isTablet,"isTablet")
        return isTablet;
    }

    previousPath = ''
    isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0

    // Firefox 1.0+
    isFirefox = typeof InstallTrigger !== 'undefined'

    // Safari 3.0+ "[object HTMLElementConstructor]"
    isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]'
        })(
            !window['safari'] ||
            (typeof safari !== 'undefined' && safari.pushNotification)
        )

    // Internet Explorer 6-11
    isIE = /*@cc_on!@*/ false || !!document.documentMode

    // Edge 20+
    isEdge = (!this.isIE && !!window.StyleMedia) || window.navigator.userAgent.indexOf("Edge") > -1

    // Chrome 1 - 71
    isChrome =
        (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || (/Chrome/.test(window.navigator.userAgent) && /Google Inc/.test(window.navigator.vendor));


    render() {
        console.log("window", window);
        return (
            <Fragment>
                {this.parseUA().mobile || this.parseUA().iPad ||
                this.isTablet() ||
                    this.isChrome ||
                    this.isEdge ||
                    this.isFirefox ||  
                    this.isSafari ? (
                        <NextApp />
                    ) : (
                        <UnSupportedBrowser />
                    )}
            </Fragment>
        )
    }
}

BaseLayout.propTypes = {
    loading: PropTypes.object,
}

export default BaseLayout
