import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Table } from 'antd'
import CircularProgress from 'components/CircularProgress';
// import {DropOption} from '../../../components'

 
const { confirm } = Modal

const trainingDetailsModal = (campaignProps) => {
 
   
  const { loader, modalVisible, handleCancel , campaign, trainingDetails, downloadCertificate} = campaignProps
  console.log("campaignProps--", campaignProps)
  return (

    <Modal
      onCancel={handleCancel}
      title="Training Details"
      visible={modalVisible}  
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Close</Button> 
      ]}
    >

        {loader ? (
            <div className="gx-loader-view" >
                <CircularProgress />
            </div>
        ) : null}
      <div style={{ marginBottom:'10px'}}>
          <h4>Training Name</h4> 
          <p>{campaign.name}</p>
      </div>
      <div className="pad-btm">
          <h4>Attempted</h4> 
          <p>{trainingDetails.started ? "Yes" : "Not Attempted"}</p> 
      </div>
 
      {trainingDetails.started ? (
          <div>
              <div className="pad-btm">
                  <h4 className="text-main">Completion Status</h4>
                  <p> {trainingDetails.completionStatus}</p> 
              </div>
              <div className="pad-btm">
                  <h4 className="text-main">Score</h4>
                  <p>{trainingDetails.scoreRaw}</p> 
              </div>
              <div className="pad-btm">
                  <h4 className="text-main">Status</h4>
                  <p>{trainingDetails.successStatus}</p> 
              </div>
              <div className="pad-btm">
                  <button
                      className="btn btn-primary"
                      onClick={() => downloadCertificate( campaign._id, trainingDetails.trainee, campaign.name)} 
                  >
                      Download Certificate
                  </button>
              </div>
          </div>
      ) : (
          ""
      )}
    
    </Modal> 
  ) 
}

export default trainingDetailsModal
