import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import MyTrainings from './mytrainings/index'
import CRM from  './CRM/index'
var authUser= localStorage.getItem('user')
authUser= JSON.parse(authUser);
console.log({authUser})
const Dashboard = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/crm`}/>
    {/* <Route path={`${match.url}/crm`} component={ authUser.trainee?MyTrainings:CRM}/> */}
    <Route path={`${match.url}/crm`} component={CRM}/>
    <Route path={`${match.url}/crypto`} component={asyncComponent(() => import('./Crypto/index'))}/>
    <Route path={`${match.url}/listing`} component={asyncComponent(() => import('./Listing/index'))}/>
    {/* <Route path={`${match.url}/mytrainings`} component={asyncComponent(() => import('./mytrainings'))}/> */}
  </Switch>
);

export default Dashboard;
