import React from "react";
import { Route, Switch } from "react-router-dom";
import CreatorUpdate from "./instructorCreateUpdate/index.js";
import Instructor from "./intructorList/index.js";
const Trainings = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/:_id/update`} component={CreatorUpdate} />
      <Route path={`${match.url}/create`} component={CreatorUpdate} />
      <Route path={`${match.url}`} component={Instructor} />
    </Switch>
  );
};

export default Trainings;
