import React, { PureComponent } from "react";

import { Row, Col, Upload, Button, Icon } from "antd";
import styles from "./trainingForm.less";
import { imagePrefixUrl } from "util/config";

const { Dragger } = Upload;

class StepsThree extends PureComponent {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    current: 0,
    visible: false,
    coverImage: this.props.training.coverImage
  };

  onImgClose = () => {
    const { onImgClose } = this.props;
    this.setState({
      coverImage: ""
    });
    onImgClose();
  };
  render() {
    const {
      uploadProps,
      training,
      state,
      onClickNext,
      onClickBack,
      onImgClose
    } = this.props;
    const { coverImage } = this.state;
    // console.log("-----",{state})
    // var coverImage = (typeof training.coverImage !== "undefined") ? training.coverImage: state.coverImageUrl;
    console.log("-----", { training });
    return (
      <div>
        <Row>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            {typeof training.coverImage === "undefined" ||
            training.coverImage === "" ||
            coverImage === "" ? (
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="picture" />
                </p>
                <p className={styles.uploadText}>
                  Click or drag image to this area to upload cover image
                </p>
              </Dragger>
            ) : (
              <div className={styles.imageContainer}>
                <img
                  className={styles.imagePreview}
                  src={`${imagePrefixUrl}${training.coverImage}`}
                  alt="preview"
                  style={{ width: "100%", height: 300, objectFit: "cover" }}
                />
                <Button 
                  className="closeBtn"
                  shape="circle"
                  icon="close"
                  onClick={this.onImgClose}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl={20} lg={20} md={20} sm={24} xs={24}></Col>
          <Col xl={4} lg={4} md={4} sm={24} xs={24}>
            <Button onClick={onClickBack}>Back</Button>
            {!training.coverImage ? (
              ""
            ) : (
              <Button type="info" onClick={onClickNext}>
                Next
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default StepsThree;
