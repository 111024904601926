import { Row, Col, List, Card, Icon } from "antd";
import React from "react";
import { apiUrl } from "util/config";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts"; //react-fusioncharts
// import style from "../../../extensions/charts/recharts/radial/Components/style";
import Model from "./../model.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class UserTrainingReports extends React.PureComponent {
  state = {
    loading: false,
    trainingReports: []
  };

  componentDidMount() {
    const { match } = this.props;
    this.setState({ loading: true });
    Model.fatchCampaignUserAnalytics(this.props.campaign_id).then(response => {
      if (!response.error) {
        this.setState({ trainingReports: response.data[0], loading: false });
      }
    });
  }

  downloadUserTrainingXlsxReport = () => {
    console.log("downloadUserTrainingXlsxReport");
    var linkRef = React.createRef();
    fetch(`${apiUrl}api/campaign_users/analytics?export=true`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    })
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "User-Training-Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => console.error(err));
  };

  render() {
    const { trainingReports, loading } = this.state;
    var trainingSummary = trainingReports;
    // console.log({trainingSummary})
    var dataSource = {};
    var chartConfigs = {};
    var colView = 12;
    if (!loading) {
      dataSource = {
        chart: {
          // caption: ' ',
          // subCaption: 'In MMbbl = One Million barrels',
          // xAxisName: 'Country',
          // yAxisName: 'Reserves (MMbbl)',
          // numberSuffix: 'K',
          theme: "fusion"
        },
        data: [
          // {
          //   label: "Total Students" + (trainingSummary.totalStudent || 0),
          //   value: trainingSummary.totalStudent || 0
          // },
          {
            label: "Students passed " + (trainingSummary.totalpassed || 0),
            value: trainingSummary.totalpassed || 0
          },
          {
            label: "Students failed " + (trainingSummary.totalFailed || 0),
            value: trainingSummary.totalFailed || 0
          },
          {
            label:
              "Students not Attempted " +
              (trainingSummary.totalNotAttempted || 0),
            value: trainingSummary.totalNotAttempted || 0
          }
        ]
      };
      chartConfigs = {
        type: "Pie3D",
        width: "100%",
        height: 350,
        dataFormat: "json",
        dataSource: dataSource
      };
      colView =
        trainingSummary.totalpassed ||
        trainingSummary.totalFailed ||
        trainingSummary.totalStudent ||
        trainingSummary.totalNotAttempted
          ? 12
          : 24;
    }

    return (
      <div>
        {!loading && (
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                title="User Training Reports"
                extra={
                  // <span
                  // style={{ position: "absolute", right: "50px", top: "0px" }}
                  // >
                  <span style={{ position: "absolute", right: "0px", top: "0px" }}>
                  <div className="  " id="circle-button-expanding-header">
             <ul>
               <a onClick={() => this.downloadUserTrainingXlsxReport()}  >
                 <li>
                   <span>Download Excel</span>
                   <Icon type="file-excel" />
                 </li>
               </a>
               </ul>
               </div>
                    {/* <a
                      onClick={() => this.downloadUserTrainingXlsxReport()}
                      style={{
                        color: "rgb(99, 138, 109)",
                        margin: "5px",
                        fontSize: "32px"
                      }}
                    >
                      <Icon type="file-excel" />
                    </a> */}
                    {/* <a
                      // onClick={() => this.downloadPhishingDocReport()}
                      style={{
                        color: "rgba(4, 2, 77, 0.58)",
                        margin: "5px",
                        fontSize: "32px"
                      }}
                    >
                      <Icon type="file-word" />
                    </a> */}
                  </span>
                }
              >
                <Row>
                  <Col xl={colView} lg={colView} md={colView} sm={24} xs={24}>
                    <List
                      className="demo-loadmore-list"
                      // bordered
                      itemLayout="horizontal"
                      size="small"
                      style={{ margin: "5px 20px" }}
                    >
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalStudent || 0}
                          </a>
                        ]}
                      >
                        No. of students
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalAttempted || 0}
                          </a>
                        ]}
                      >
                        No. of students attempted
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?clicked=0"}
                          >
                            {trainingSummary.totalNotAttempted || 0}
                          </a>
                        ]}
                      >
                        No. of students not attempted
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                          >
                            {trainingSummary.totalpassed || 0}
                          </a>
                        ]}
                      >
                        No. of students passed
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            // target="_blank"
                            //  href="#"
                            // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                          >
                            {trainingSummary.totalFailed || 0}
                          </a>
                        ]}
                      >
                        No. of students failed
                      </List.Item>

                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalFirstAttemptPass || 0}
                          </a>
                        ]}
                      >
                        No. of first attempt passed
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalFirstAttemptFail || 0}
                          </a>
                        ]}
                      >
                        No. of first attempt fail
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalSecondAttemptPass || 0}
                          </a>
                        ]}
                      >
                        No. of second attempt passed
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalSecondAttemptFail || 0}
                          </a>
                        ]}
                      >
                        No. of second attempt fail
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalThirdAttemptPass || 0}
                          </a>
                        ]}
                      >
                        No. of third attempt passed
                      </List.Item>
                      <List.Item
                        actions={[
                          <a
                            key="list-loadmore-edit"
                            style={{ color: "inherit" }}
                            //  target="_blank"
                            //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                          >
                            {trainingSummary.totalThirdAttemptFail || 0}
                          </a>
                        ]}
                      >
                        No. of third attempt fail
                      </List.Item>
                    </List>
                  </Col>
                  {(trainingSummary.totalpassed ||
                    trainingSummary.totalFailed ||
                    trainingSummary.totalStudent ||
                    trainingSummary.totalNotAttempted) && (
                    <Col xl={colView} lg={colView} md={colView} sm={24} xs={24}>
                      <ReactFC
                        style={{ position: "relative" }}
                        {...chartConfigs}
                      />
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default UserTrainingReports;
