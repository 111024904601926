import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Tooltip, Icon, Row, Col, Button } from "antd";
import styles from "./trainingForm.less";
import { imagePrefixUrl } from "util/config";
import ViewDescriptionModal from "./viewDescriptionModal";

const { TextArea } = Input;
const categoryList = ["Premium", "Standard", "CSCU Video", "Practice"];
const visibilityList = ["Private", "Organization", "Public"];
class TrainingForm extends PureComponent {
  state = {
    visible: false,
    viewModel: false,
    content: ""
  };

  handleSubmit = e => {
    const { saveTrainingInfo } = this.props;
    saveTrainingInfo();
  };

  onChange = current => {
    // this.setState({ current });
  };
  onClickViewModel = () => {
    console.log("onClickViewModel---");
    this.setState({ viewModel: true });
  };

  displayTime(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? (h > 9 ? h + ":" : "0" + h + ":") : "";
    var mDisplay = m > 0 ? (m > 9 ? m + ":" : "0" + m + ":") : "00:";
    var sDisplay = s > 0 ? (s > 9 ? s : "0" + s) : "00";
    return hDisplay + mDisplay + sDisplay;
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { training, onClickBack, state, saveTrainingInfo } = this.props;

    const formItemLayout = {
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    const descriptionModelProps = {
      description: training.description,
      modalVisible: this.state.viewModel,
      handleCancel: () => {
        this.setState({ viewModel: false });
      }
    };
    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} className="preview-training">
          <Row  className=" gx-pl-5 gx-pr-5 gx-pt-5"  >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <div className="gx-pl-5 gx-pr-5 gx-pb-5" >
                <img
                  className="imagePreview"
                  src={`${imagePrefixUrl}${training.coverImage}`}
                  alt="preview"
                  // style={{ width: "100%", height: 460, objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Title">
                {getFieldDecorator("name", {
                  initialValue: training.name
                })(<Input disabled />)}
              </Form.Item> 

              <Form.Item label="Short description">
                {getFieldDecorator("shortDescription", {
                  initialValue: training.shortDescription
                })(
                  <TextArea
                    disabled
                    className={styles.textAreaStyle}
                    rows={6}
                    placeholder="shortDescription"
                  />
                )}
              </Form.Item>
              <Form.Item label="">
                <Button type="info" onClick={this.onClickViewModel}>
                  {" "}
                  View Description{" "}
                </Button>
              </Form.Item>
            </Col>

           
          </Row>
          <Row  className=" gx-pl-5 gx-pr-5 gx-pb-5" >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              {/* visibility */}
              <Form.Item label="Visibility">
                {getFieldDecorator("visibility", {
                  initialValue: visibilityList[parseInt(training.visibility)]
                })(<Input disabled />)}
              </Form.Item>

              {/* Category */}
              <Form.Item label="Category">
                {getFieldDecorator("trainingType", {
                  initialValue: training.trainingType
                    ? categoryList[training.trainingType - 1]
                    : ""
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item label="Sub Category">
                {getFieldDecorator("trainingSubType", {
                  initialValue: training.trainingSubType
                })(<Input disabled />)}
              </Form.Item>

              {/* Levels */}
              <Form.Item label="Levels">
                {getFieldDecorator("level", {
                  initialValue: training.level
                })(<Input disabled />)}
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              {/* AuthorName */}
              <Form.Item label="Author Name">
                {getFieldDecorator("authorName", {
                  initialValue: training.authorName
                })(<Input disabled />)}
              </Form.Item>
              {/* Training Length */}
              <Form.Item label="Training Duration">
                {getFieldDecorator("length", {
                  initialValue: this.displayTime(training.duration) 
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label="Training Price ">
                {getFieldDecorator("price", {
                  initialValue: training.price
                })(<Input disabled />)}
              </Form.Item>
                 {/* Credit resources */}
                 <Form.Item
                label={
                  <span>
                    {"Credit resources"}
                    {/* <Tooltip
                      placement="rightBottom"
                      title="Credit your sources by adding their names, links or texbook references here."
                    >
                      <span> </span>
                      <Icon
                        type="question-circle"
                        style={{
                          fontSize: "10px",
                          color: "#9e9e9e"
                        }}
                      />
                    </Tooltip> */}
                  </span>
                }
              >
                {getFieldDecorator("credits", {
                  initialValue: training.credits
                })(
                  <Input
                    disabled 
                    // onChange={saveValue}
                  />
                )}
              </Form.Item>
           
           <div style={{float:"right"}}>
           <Button onClick={onClickBack}>Back</Button>
              <Button type="info" onClick={saveTrainingInfo}>
                {state.trainingId ? "Update" : "Save"}
              </Button>
           </div>
            </Col>
             
          </Row>
 
        </Form>

        {this.state.viewModel && (
          <ViewDescriptionModal {...descriptionModelProps} />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(TrainingForm));
