import firebase from "firebase";

// Initialize Firebase

// var config = {
//     apiKey: "AIzaSyC0OUP8O24PSbqrxVVRn2CA-qGbP75Wyhg",
//     authDomain: "ohphish-lms.firebaseapp.com",
//     databaseURL: "https://ohphish-lms.firebaseio.com",
//     projectId: "ohphish-lms",
//     storageBucket: "",
//     messagingSenderId: "664081307503",
//     appId: "1:664081307503:web:b8518f46f8ce19f8"
//   };
  var  config = { apiKey: "AIzaSyBd7-ou24zYXExIsAAN2Y9lbGgNXtFlj-M",
  authDomain: "awareeccouncil.firebaseapp.com",
  databaseURL: "https://awareeccouncil.firebaseio.com",
  projectId: "awareeccouncil",
  storageBucket: "awareeccouncil.appspot.com",
  messagingSenderId: "985493008472",
  appId: "1:985493008472:web:0628ed037edfea467d1a2d",
  measurementId: "G-W3KM70QKCC"
};

firebase.initializeApp(config);
const auth = firebase.auth();

var googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
});
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider(); 
// auth.Auth.Persistence.NONE
const database = firebase.database();

export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider 
};
