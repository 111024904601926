import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_All_CAMPAIGN,
  GET_CAMPAIGNS_USERS,
  CAMPAIGNS_DOWNLOAD_CERTIFICATE,
  GET_All_CAMPAIGNS_REPORTS_INFO,
  GET_CAMPAIGNS_ANALYTICS,
  GET_All_CAMPAIGNS_INFO,
  DOWNLOAD_PDF_FILE,
  DOWNLOAD_XLSX_FILE
} from "../../constants/ActionTypes";
import {
  getAllCampaignSuccess,
  getCampaignsUsersSuccess,
  // downloadCertificateSuccess,
  getAllCampaignsInfoSuccess,
  getCampaignsAnalyticsSuccess,
  getAllCampaignsReportsInfoSuccess,
  showMessage,
  hideLoader,
  hideModal
  // getAllCampaign
} from "../actions/Campaign";

import { api, apiUrl } from "util/config";
// const cookie = require("js-cookie");

const { campaignsAnalytics, campaignsInfo } = api;

//campaigns/${campaignId}/stackablebar
const getCampaignsReportsInfoRequest = async (campaignId, xAxis, yAxis) =>
  await axios
    .get(apiUrl + `api/campaigns/${campaignId}/stackablebar`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: {
        xAxis: xAxis,
        yAxis: yAxis
      }
    })
    .then(response => response)
    .catch(error => error);

const getCampaignsAnalyticsRequest = async campaignId =>
  await axios
    .get(apiUrl + `api/campaigns/${campaignId}/analytics`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    })
    .then(response => response)
    .catch(error => error);

const downloadPdfRequest = async (downloadUrl, paramsObj) =>
  await axios
    .get(apiUrl + downloadUrl, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
        // 'Accept': 'application/pdf',
      },
      params: paramsObj,
      // Accept: 'application/octet-stream',
      responseType: "blob" //5ce3bf47288f6079eaa0f933
      // responseType: 'arraybuffer',
    })
    .then(response => response)
    .catch(error => error);

const downloadXlsxRequest = async (downloadUrl, paramsObj) =>
  await axios
    .get(apiUrl + downloadUrl, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
        // fngprt:cookie.get("fngprt")
      },
      params: paramsObj,
      Accept: "application/octet-stream",
      responseType: "blob"
    })
    .then(response => response)
    .catch(error => error);

const getCampaignsUsersRequest = async (campaignId, page) =>
  await axios
    .get(apiUrl + `api/campaigns/${campaignId}/users`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: { page }
    })
    .then(response => response)
    .catch(error => error);

const getTrainingUsersRequest = async (campaignId, page) =>
  await axios
    .get(apiUrl + `api/lms/trainings/${campaignId}/users`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: { page }
    })
    .then(response => response)
    .catch(error => error);

const getAllCampaignsInfoRequest = async obj =>
  await axios
    .get(apiUrl + campaignsInfo, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: obj
    })
    .then(response => response)
    .catch(error => error);

const getAllCampaignRequest = async obj =>
  await axios
    .get(apiUrl + campaignsAnalytics, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: obj
    })
    .then(response => response)
    .catch(error => error);

function* getCampaignsReportsInfo({ payload }) {
  const campaignId = payload.campaignId;
  var xAxis = payload.xAxis;
  var yAxis = ["department", "designation", "branch"];
  try {
    yAxis.splice(yAxis.indexOf(xAxis), 1);
    const responseData = yield yAxis.map(report => {
      return call(getCampaignsReportsInfoRequest, campaignId, xAxis, report);
    });
    if (responseData.error) {
      yield put(showMessage(responseData.data.userMessage));
    } else {
      var Report = [];
      var noData = true;
      for (let i = 0; i < responseData.length; i++) {
        var response = ["analytics", "trainingAnalytics"].reduce(
          (results, key) => {
            results[key] = responseData[i].data.data[key].reduce(
              (results, report) => {
                if (!report[xAxis]) {
                  return results;
                }
                report.yAxis = report[responseData[i].config.params.yAxis];
                report.training = key == "trainingAnalytics";
                if (results[report[xAxis]]) {
                  results[report[xAxis]].push(report);
                } else {
                  results[report[xAxis]] = [report];
                }
                noData = false;
                return results;
              },
              {}
            );
            return results;
          },
          {}
        );

        Report.push({
          xAxis: responseData[i].config.params.xAxis,
          yAxis: responseData[i].config.params.yAxis,
          data: response.analytics
        });
        Report.push({
          xAxis: responseData[i].config.params.xAxis,
          yAxis: responseData[i].config.params.yAxis,
          data: response.trainingAnalytics,
          type: "training"
        });
      }
      yield put(getAllCampaignsReportsInfoSuccess(Report));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* getCampaignsAnalytics({ payload }) {
  const campaignId = payload.campaignId;
  try {
    const responseData = yield call(getCampaignsAnalyticsRequest, campaignId);
    if (responseData.error) {
      yield put(showMessage(responseData.data.userMessage));
    } else {
      if (responseData.data.data) {
        const campaignInfo = responseData.data.data;
        yield put(getCampaignsAnalyticsSuccess(campaignInfo));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* downloadXlsxReport({ payload }) {
  const name = payload.name;
  const downloadUrl = payload.downloadUrl;
  const fileType = payload.fileType;
  var paramsObj = payload.paramsObj;
  try {
    const responseData = yield call(
      downloadXlsxRequest,
      downloadUrl,
      paramsObj
    );
    if (responseData.error) {
      yield put(showMessage(responseData.data.userMessage));
    } else {
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = (responseData.name || name) + fileType; //".pdf" // whatever your file name .
      link.setAttribute("download", fileName);
      link.click();
      link.remove();
      yield put(hideModal());
      yield put(hideLoader());
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* downloadPdfReport({ payload }) {
  const name = payload.name;
  const downloadUrl = payload.downloadUrl;
  const fileType = payload.fileType;
  var paramsObj = payload.paramsObj;
  try {
    const responseData = yield call(downloadPdfRequest, downloadUrl, paramsObj);
    if (responseData.error) {
      yield put(showMessage(responseData.data.userMessage));
    } else {
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = (responseData.name || name) + fileType; //".pdf" // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      yield put(hideModal());
      yield put(hideLoader());
    }
    // axios({
    //   url: 'http://api.dev/file-download',
    //   method: 'GET',
    //   responseType: 'blob', // important
    // }).then((response) => {
    //    const url = window.URL.createObjectURL(new Blob([response.data]));
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.setAttribute('download', 'file.pdf'); //or any other extension
    //    document.body.appendChild(link);
    //    link.click();
    // });
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* downloadCertificate({ payload }) {
  const name = payload.name;
  const downloadUrl = payload.downloadUrl;
  const fileType = payload.fileType;
  var paramsObj = payload.paramsObj;
  try {
    const responseData = yield call(downloadPdfRequest, downloadUrl, paramsObj);
    if (responseData.error) {
      yield put(showMessage(responseData.data.userMessage));
    } else {
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = (responseData.name || name) + fileType; //".pdf" // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      yield put(hideModal());
      yield put(hideLoader());
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* getCampaignsUsers({ payload }) {
  const campaignId = payload.campaignId;
  const page = payload.page ? payload.page : 0;
  const type = payload.type;
  if (type == "training") {
    try {
      const responseData = yield call(
        getTrainingUsersRequest,
        campaignId,
        page
      );
      if (responseData.error) {
        yield put(showMessage(responseData.data.userMessage));
      } else {
        if (responseData.data.data) {
          // const campaignInfo = responseData.data.data;
          const campaignInfo = responseData.data.data;
          const totalRecords = responseData.data.totalRecords;
          yield put(getCampaignsUsersSuccess(campaignInfo, totalRecords, page));
        }
      }
    } catch (error) {
      yield put(showMessage(error));
    }
  } else {
    try {
      const responseData = yield call(
        getCampaignsUsersRequest,
        campaignId,
        page
      );
      if (responseData.error) {
        yield put(showMessage(responseData.data.userMessage));
      } else {
        if (responseData.data.data) {
          const campaignInfo = responseData.data.data;
          const totalRecords = responseData.data.totalRecords;
          yield put(getCampaignsUsersSuccess(campaignInfo, totalRecords, page));
        }
      }
    } catch (error) {
      yield put(showMessage(error));
    }
  }
}

function* getAllCampaign({ payload }) {
  const { live, page, campaignType } = payload;
  const obj = {};
  obj["live"] = Number(live);
  obj["page"] = page ? Number(page) - 1 : 0;
  obj["campaignType"] = campaignType; //||"email"
  try {
    const responseData = yield call(getAllCampaignRequest, obj);
    if (responseData.message) {
      yield put(showMessage(responseData.response.data.userMessage));
    } else {
      if (responseData.data.data) {
        var campaignInfo = responseData.data.data;
        campaignInfo["page"] = page ? Number(page) : 1;
        campaignInfo["live"] = obj["live"];
        yield put(getAllCampaignSuccess(campaignInfo));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* getAllCampaignInfo({ payload }) {
  const { build, pagination } = payload;
  const obj = {};
  obj["build"] = build ? build : true;
  obj["pagination"] = pagination ? pagination : false;
  try {
    const responseData = yield call(getAllCampaignsInfoRequest, obj);

    if (responseData.message) {
      yield put(showMessage(responseData.response.data.userMessage));
    } else {
      if (responseData.data.data) {
        var campaignInfo = responseData.data.data;
        yield put(getAllCampaignsInfoSuccess(campaignInfo));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

export function* campaignsReportsInfoGet() {
  yield takeEvery(GET_All_CAMPAIGNS_REPORTS_INFO, getCampaignsReportsInfo);
}
export function* campaignsAnalyticsGet() {
  yield takeEvery(GET_CAMPAIGNS_ANALYTICS, getCampaignsAnalytics);
}

export function* campaignsDownloadCertificate() {
  yield takeEvery(CAMPAIGNS_DOWNLOAD_CERTIFICATE, downloadCertificate);
}

export function* campaignsUsersGet() {
  yield takeEvery(GET_CAMPAIGNS_USERS, getCampaignsUsers);
}

export function* campaignAllGet() {
  yield takeEvery(GET_All_CAMPAIGN, getAllCampaign);
}
export function* campaignsInfoGet() {
  yield takeEvery(GET_All_CAMPAIGNS_INFO, getAllCampaignInfo);
}
export function* reportDownloadPdf() {
  yield takeEvery(DOWNLOAD_PDF_FILE, downloadPdfReport);
}
export function* reportDownloadXlsx() {
  yield takeEvery(DOWNLOAD_XLSX_FILE, downloadXlsxReport);
}

export default function* rootSaga() {
  if (typeof localStorage.getItem("accessToken") !== undefined) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
  }
  yield all([
    fork(campaignsDownloadCertificate),
    fork(campaignAllGet),
    fork(campaignsUsersGet),
    fork(campaignsReportsInfoGet),
    fork(campaignsAnalyticsGet),
    fork(campaignsInfoGet),
    fork(reportDownloadPdf),
    fork(reportDownloadXlsx)
  ]);
}
