import { api, apiUrl } from "../../../util/config";
import $ from "jquery";
import { message } from "antd";
var Actions = {
  trainingRequest: ( 
    trainingId, 
  ) => {
    return new Promise((resolve, reject) => { 
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/training/requests",
        method: "POST", 
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: { 
          trainingId: trainingId, 
        },
        success: function(response) { 
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  assignTraining: (
    trainees,
    trainingId,
    expiryDate,
    autoDeliveryReport,
    scheduleLater,
    scheduleBy,
    scheduleDate,
    scheduleIntervals,
    organisationId
  ) => {
    return new Promise((resolve, reject) => {
      console.log(expiryDate, autoDeliveryReport);
      var form = new FormData();
      form.append("users", trainees);
      form.append("trainingId", trainingId);
      form.append("expireAt", expiryDate);
      form.append("isAutoDeliveryCampaignReport", autoDeliveryReport);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      form.append("organisationId", user.organisationId);

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/training/campaign",
        method: "POST",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          users: trainees,
          trainingId: trainingId,
          organisationId:
            user.accessLevel == 1 ? organisationId : user.organisationId,
          expireAt: expiryDate,
          isAutoDeliveryCampaignReport: autoDeliveryReport,
          scheduleLater,
          scheduleBy,
          scheduleDate,
          scheduleIntervals
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  assignTrainingInBulk: trainings => {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      form.append("organisationId", user.organisationId);

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/trainings/assign_bulk",
        method: "POST",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: { trainings: trainings, organisationId: user.organisationId },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  fetchFilteredUsers: (formatted, organisationId, exclude) => {
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/filteredTrainee",
        method: "POST",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          groups: formatted || [],
          organisationId: organisationId || user.organisationId,
          exclude: exclude || ""
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  getOrganisations: value => {
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);
      if (user.accessLevel == 1) {
        var settings = {
          async: true,
          crossDomain: true,
          url:
            apiUrl +
            `api/organisation?type=select&ne=${
              user.demoAccount ? "" : user.organisation._id
            }`,
          method: "GET",
          data: value,
          // processData: false,
          // contentType: false,
          // mimeType: "multipart/form-data",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
            // fngprt: m.cookie.get("fngprt")
          },
          success: function(response) {
            // response = JSON.parse(response);
            if (response.error) {
              message.error(response.userMessage);
            } else {
              resolve(response);
            }
          },
          error: function(error) {
            if (error.status == 401) {
              message.error("Your session has been expired. Please relogin.");
              localStorage.clear();
              window.location.reload();
            } else {
              message.error("Some error has occurred.");
            }
          }
        };

        $.ajax(settings).done();
      }
    });
  }
};

export default Actions;
