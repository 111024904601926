import { Row, Col, List} from "antd";
import React from "react";
 
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts'; //react-fusioncharts

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
 

const CampaignReports = ( info ) => { 

  const { campaignSummary, campaign } = info;
  console.log({info})
  const dataSource = {
    chart: { 
      // exportenabled:"1",
      // exportMode: "client", 
      // exportFileName: campaign.name,
      // exportFormats: "CSV =Export Chart Data",
      "showPercentValues": "1",
        "showPercentInTooltip": "0",
        "decimals": "1",
        "useDataPlotColorForLabels": "1",
        //Disable Smart Labels
        "enableSmartLabels": "0",
      theme: 'fusion'
    },
    data:  
      campaign.campaignType != "call"
      ? [
        { label: 'Users clicked'+ ' ' + (campaignSummary.defaulters || 0), value: (campaignSummary.defaulters || 0) }, 
        { label: 'Users not clicked' + ' ' + (campaignSummary.nonDefaulters || 0), value: (campaignSummary.nonDefaulters || 0) }, 
        { label: 'Users Defaulters'+ ' ' + (campaignSummary.repeaters || 0), value: (campaignSummary.repeaters || 0) }
      ] :  [
        { label: 'Call Triggered'+ ' ' + (campaignSummary.defaulters || 0), value: (campaignSummary.defaulters || 0) }, 
        { label: 'Call Not Answered' + ' ' + (campaignSummary.nonDefaulters || 0), value: (campaignSummary.nonDefaulters || 0) }, 
        { label: 'Call Answered'+ ' ' + (campaignSummary.repeaters || 0), value: (campaignSummary.repeaters || 0) }
      ]
     
  };
  
  const chartConfigs = {
    // type: 'Pie3D',
    type: 'pie2d',
    renderAt: 'chart-container',
    width: '100%',
    height: 400,
    dataFormat: 'json',
    dataSource: dataSource
};

var colView = ( campaignSummary.defaulters || campaignSummary.nonDefaulters || campaignSummary.repeaters ) ? 12: 24;
  return (
    <div> 
      <Row>  
         
        <Col  xl={colView} lg={colView} md={colView} sm={24} xs={24} >  
          {campaign.campaignType == "email" ?
          
            <List
              className="demo-loadmore-list"
              // bordered
              itemLayout="horizontal" 
              size="small"
              style={{margin:'5px 20px'}}
              >

              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: '#000'}} 
                    target="_blank" 
                    href={"/campaigns/" + campaign._id + "/users"}
                  > {campaignSummary.targets|| 0 }
                  </a>
                ]}
              >
              No. of targets
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank" 
                    href={"/campaigns/" + campaign._id + "/users?clicked=1"}
                  >
                  {campaignSummary.defaulters || 0}
                  </a>
                ]}
              >
                No. of targets who have clicked the link (defaulters)
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    // target="_blank" 
                    href="#"
                    // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                  >
                  {campaignSummary.repeaters || 0}
                  </a>
                ]}
              >
                No. of repeated defaulters
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank"
                    href={"/campaigns/" + campaign._id + "/users?clicked=0"}
                  >
                  {campaignSummary.nonDefaulters || 0}
                  </a>
                ]}
              >
                No. of targets who have not clicked the link
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank"
                    href={"/campaigns/" + campaign._id + "/users?opened=1"}
                  >
                  {campaignSummary.opened || 0}
                  </a>
                ]}
              >
                No. of targets who have opened the mail 
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank"
                    href={"/campaigns/" + campaign._id + "/users?opened=0"}
                  >
                  {campaignSummary.notOpened || 0}
                  </a>
                ]}
              >
                No. of targets who have not opened the mail 
              </List.Item>
              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank"
                    href={"/campaigns/" + campaign._id + "/users?clicked=0&opened=1"}
                  >
                  {campaignSummary.openedNotClicked || 0}
                  </a>
                ]}
              >
                No. of targets who have opened the mail but not clicked  
              </List.Item>
              <List.Item
                actions={[
                  <span >{(campaignSummary.compliance || 0).toFixed(2) + "%"}</span>
                ]}
              >
                Compliance percentage 
              </List.Item>
            
            </List> 
            
          :
          campaign.campaignType == "call" ?
            
            <List
            className="demo-loadmore-list"
            // bordered
            itemLayout="horizontal" 
            size="small"
            style={{margin:'5px 20px'}}
            >

            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: '#000'}} 
                  target="_blank" 
                  href={"/campaigns/" + campaign._id + "/users"}
                > {campaignSummary.targets|| 0 }
                </a>
              ]}
            >
            No. of targets
            </List.Item>
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  target="_blank" 
                  href={"/campaigns/" + campaign._id + "/users?triggered=1"}
                >
                {campaignSummary.triggered || 0}
                </a>
              ]}
            >
              No. of call triggered
            </List.Item>
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  // target="_blank" 
                  href="#"
                  // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                >
                {campaignSummary.answered || 0}
                </a>
              ]}
            >
              No. of targets who have answered the call
            </List.Item>
            { campaign.voiceAction == "ivr" &&

              <List.Item
                actions={[
                  <a 
                    key="list-loadmore-edit" 
                    style={{color: 'inherit'}} 
                    target="_blank"
                    href={"/campaigns/" + campaign._id + "/users?clicked=0"}
                  >
                  {campaignSummary.enteredCredentials || 0}
                  </a>
                ]}
              >
                No. of targets who have entered the credentials
              </List.Item>
            }
            
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  target="_blank"
                  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                >
                {campaignSummary.voiceAction == 'ivr'
                  ? `${(
                      (campaignSummary.enteredCredentials / campaignSummary.triggered || 0) * 100
                  ).toFixed(2)}%`
                  : `${((campaignSummary.receivedNotAnswered / campaignSummary.targets || 0) * 100).toFixed(
                      2
                  )}%`
                }
                </a>
              ]}
            >
              Compliance percentage 
            </List.Item>
            
          
          </List> 
        
          :
          
            
            <List
            className="demo-loadmore-list"
            // bordered
            itemLayout="horizontal" 
            size="small"
            style={{margin:'5px 20px'}}
            >

            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: '#000'}} 
                  target="_blank" 
                  href={"/campaigns/" + campaign._id + "/users"}
                > {campaignSummary.targets|| 0 }
                </a>
              ]}
            >
            No. of targets
            </List.Item>
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  target="_blank" 
                  href={"/campaigns/" + campaign._id + "/users?clicked=1"}
                >
                {campaignSummary.defaulters || 0}
                </a>
              ]}
            >
              No. of targets who have clicked the link (defaulters)
            </List.Item>
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  // target="_blank" 
                  href="#"
                  // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                >
                {campaignSummary.repeaters || 0}
                </a>
              ]}
            >
              No. of repeated defaulters
            </List.Item>
            <List.Item
              actions={[
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  target="_blank"
                  href={"/campaigns/" + campaign._id + "/users?clicked=0"}
                >
                {campaignSummary.nonDefaulters || 0}
                </a>
              ]}
            >
              No. of targets who have not clicked the link
            </List.Item>
            
            <List.Item
              actions={[
                <span >{(campaignSummary.compliance || 0).toFixed(2) + "%"}</span>
              ]}
            >
              Compliance percentage 
            </List.Item>
          
          </List> 
          
          }
        </Col>
        { ( campaignSummary.defaulters || campaignSummary.nonDefaulters || campaignSummary.repeaters ) &&
          <Col  xl={colView} lg={colView} md={colView} sm={24} xs={24} >  
            <ReactFC style={{position: 'relative', height: '30vh'}} {...chartConfigs} />  
          </Col> 
         }     
      </Row> 
    </div> 
  );
};

export default CampaignReports;

 