module.exports = {
    siteName: "OhPhish LMS",
    footerText:
      "Copyright © All rights reserved | Shield Alliance International Limited",
    apiPrefix: "http://demo2679133.mockable.io/",
    imagePrefixUrl: "/serve/",
    imageMediaUrl: (process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ? "https://api.ohphish.com/" :  "https://staging.ohphish.com/")).replace(/\/$/, ''), 
    apiUrl: process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ? "https://api.ohphish.com/" : "https://staging.ohphish.com/"), 
    socketURL: process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ?  "https://api.ohphish.com/":"https://staging.ohphish.com/"), 
    stripeKey: "pk_test_XdTe5pNvAopfYbbJzALPh3bk00SRyIcUms",
    api: {
      queryAuthMethod: "api/auth/method",
  
      queryUserInfo: "api/ping",
      queryUserInfoElearning: "api/elearning/ping",
      logoutUser: "/user/logout",
      queryForgotPassword: "api/lms/forgot",
      queryResetPassword: "api/reset",
      authLoginUser: "api/auth/login",
      otpLoginUser: "api/otp/auth/login",
      loginUser: "api/elearning/login/v2",
      removeUserList: "POST /users/delete",
      queryPostList: "/posts",
      queryDashboard: "api/dashboard/analytics/v1?active=true&build=true",
      queryAllCampaignTrainings: "api/trainings",
      // queryAllCampaignTrainings: "api/trainings/v1",
      //trainee api
      queryTraineeTraining: "api/lms/trainee/trainings",
  
      //training api//
      queryAllTrainings: "api/lms/training",
      queryTrainings: "api/trainings",
      importNewFileTraining: "api/training/import/v1",
      uploadMediaFileTraining: "api/training/upload_media",
      queryUpdateTraining: "api/training/update_training",
  
      //campaigns api//
      campaignsInfo: "api/campaigns",
      campaignsAnalytics: "api/campaigns/analytics",
      campaignsUsers: `api/campaigns/:campaignId/users`,
      importFile: "/import/file",
  
      //----USER API---//
  
      userProfileUpdate: "api/users/profile",
      passwordReset: "api/password/reset"
    },
    CLIENT_PRIVATE_KEY: JSON.stringify(`-----BEGIN PRIVATE KEY-----
      MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCPd6yH7y8h1Ihv
      jh3TIYFYPA6BAMBSptltJSRLuGj04/em2imarDbQFzftF8oCoSOpjzyTOXTXUFbD
      8wH+7TDl/mmzxUf+WoUT9Z5RZu5hkaLvDl1watSaINg4/LPgHsaVgVTCSSJp24gU
      hHA7fr/5XD2gLhxenLGMl8WBVYvKG8LC5M5LLwT4uBlU2t4gZ4rTHUKDcfefa1H0
      j9mZ1amhWpBLfDsDFxLDPyD4p6QsRXAWO6hYS7bPQoeuAujIjiSwMkjqkIj4+OFD
      J1wuFJO9IkJIF76qV5p5zGstHc/xezkcti7cxzhKyx277/dsFtQq5+fFpNfpGSn5
      J/HAbIqvAgMBAAECggEASEcZY65rh1akmdb2TZzOph4zjGhNfBZU6bjRjVhNgDqt
      VKEKXsMuJi3cXhUjD6oQ5makNOO4apUt8TAnLEBg5y4CILBeMdV2v/R5GzeJFxyh
      AmCxUGZxz2iGpkchc+LtVvq+MddYgA46g2Opiz+zBbSj02QHpN66UENSHHN1po8K
      y9yNcXBxta3fwRS0g/ARLxbGnkKBhOxraV6UwOcjRFDAO6aC25KyP59JAM0SxmnO
      G1sIDwiYz8lfM1D5ydffWAKGCU7ZBkrVDM4yumSpqEwCMTTcnEjTbQ8ZDYSz1vZZ
      haVGzcgUrPtB9SGOgt9GbVrr0CMrSkEH9QVoPuVlGQKBgQDqYk+N92CZZ22pQ8te
      kz1wyyqcJWrltMHKMLxzbDFzcJoL219XmGAcPG7fk4Qc6zq8E0f/+dfrbNyCUBEf
      Zxal0t70QJWhCTxdQnLCTqLn1sdMr5POkVjEnF04kwacqolmNVC50H6sW2ilzzQn
      CzjwoTzfGWoyLWU5YZXCfXXEXQKBgQCcst57Qyz/Sa6SaFjHzVQeDc9TqtVo9niu
      H6DUTnCgM57mvBeYsedet359NJ3ET0vQT5RRAkPRBqgezxNb3OAa/dhV1glvArHr
      7UPsAH6Yp98taYFHomqivR8CXq9AxV2EcAwIHBYJR21U6g/7XGUEExz2tQ+AzrBd
      IFVo9CDaewKBgAMLIcNTKgLz792ZzsM8oDidusDqT3gKH9YTSe8pwX6hQK7Uu2k0
      xlK3ii0HClkhyNJ2YaH2SZJ6CGb8ySwiN44Rrel4CTldGFaRrVHOmZjvFglt4jp1
      crSi3ycD6bsRD9Wu7YxsI6jzSumURjYXlDazsUmoV9Os+TqEhOBQpr3VAoGAHBRx
      ieUfyx+JCPNp9WP2DuyqmnOiioygU5OXXnQv+oVFlFNgZxx6OZ7oK8eh/eu3yjx4
      d4vQW0S2G88/yNZr0mpqufcA+cOh3oVGBqSQCwsKEzk00YFpWoBJbkNJZHH5sCHk
      BhACYudJ0E2hT4nfEDvclNkdThe7wvRoWcZlnMECgYBDSLnDeyduLhvctgcJUU9F
      QdqFYAWjzXFfJItUGWAGFC7Lpn8PVcc7u6hH1oFJlohi39T2gYrKB5PmYN9/52/r
      u8ThbAvTm+yqMmsDNkBkohfx25p3NLsV0r46mpRXuraQTFXcYrc1mW+BkoteY06S
      Oe6lSHTplzRc0QPTat5+mQ==
      -----END PRIVATE KEY-----
      `),
    scormKey:{
      "en-us" : "English(United States)",
      "en-au" : "English(Australia)",
      "fr-CA": "Canada(French)",
      myb : "Bahasa Malaysia",
      ar : "Arabic",
      vi : "Vietnam",
      th : "Thailand",
      zh : "Chinese",
      el : "Greek",
      uk : "Ukraine",
      fr : "French",
      te : "Telgu",
      dk : "Danish",
      no : "Norwegian",
      se : "Swedish",
      hi : "Hindi"
    },
  };