import React, { PureComponent } from "react";

import { Row, Col, Upload, Button, Icon } from "antd";
import styles from "./trainingForm.less";
import { imagePrefixUrl } from "util/config";

const { Dragger } = Upload;

class PreviewVideo extends PureComponent {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    current: 0,
    visible: false,
    previewVideo: this.props.training.previewVideo
  };

  onPreviewVideoClose = () => {
    const { onPreviewVideoClose } = this.props;
    this.setState({
      previewVideo: ""
    });
    onPreviewVideoClose();
  };
  render() {
    const {
      uploadProps,
      training,
      state,
      onClickNext,
      onClickBack,
      onPreviewVideoClose
    } = this.props;
    const { previewVideo } = this.state;
    // console.log("-----",{state})
    // var previewVideo = (typeof training.previewVideo !== "undefined") ? training.previewVideo: state.previewVideoUrl;
    console.log("-----", { uploadProps });
    console.log("-----", { training });
    return (
      <div>
        <Row>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            {typeof training.previewVideo === "undefined" ||
            training.previewVideo === "" ||
            previewVideo === "" ? (
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="play-square" />
                </p>
                <p className={styles.uploadText}>
                  Click or drag video to this area to upload video
                </p>
              </Dragger>
            ) : (
              <div className={styles.imageContainer}>
                {/* <img
                  className={styles.imagePreview}
                  src={`${imagePrefixUrl}${training.previewVideo}`}
                  alt="preview"
                  style={{width:'100%',height:300,objectFit:'cover'}}
                /> */}

                <video
                  // muted
                  // playsinline
                  controls
                  data-uk-video="autoplay: false"
                >
                  <source
                    src={
                      training.previewVideo
                        ? `${imagePrefixUrl}${training.previewVideo}`
                        : "videos/course-intro.mp4"
                    }
                    type="video/mp4"
                  />
                </video>
                <Button
                  style={{  }}
                  className="closeBtn"
                  shape="circle"
                  icon="close"
                  onClick={this.onPreviewVideoClose}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl={20} lg={20} md={20} sm={24} xs={24}></Col>
          <Col xl={4} lg={4} md={4} sm={24} xs={24}>
            <Button onClick={onClickBack}>Back</Button>
            {/* {!training.previewVideo ? (
              ""
            ) : ( */}
              <Button type="info" onClick={onClickNext}>
                Next
              </Button>
            {/* )} */}
          </Col>
        </Row>
      </div>
    );
  }
}

export default PreviewVideo;
