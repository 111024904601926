import { Col, Row, Icon } from "antd";
import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip } from "recharts";
import Widget from "components/Widget/index";

import { IconFont } from "util/Utils";
import "./index.less";
const QueriesCard = data => {
  console.log({ data });
  return (
    <div id={`dashbord-card-${data.id}`}>
      <Widget>
        <div onClick={data.onClick} style={{ cursor: "pointer" }}>
          <div className="gx-d-flex" onClick={data.onClick}>
            {/* <p className="gx-text-uppercase gx-chart-title">{data.title}</p> */}
            {/* <p className="gx-ml-auto gx-text-primary">{`${data.completePercent}%`} <i className="icon icon-menu-up gx-fs-sm"/></p> */}
          </div>
          <Row style={{ padding: "12px" }}>
            <Col
              lg={16}
              md={16}
              sm={24}
              xs={24}
              className="gx-pr-2" 
            >
              <div className="gx-pt-2">
                <h2
                  className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-white"
                  style={{ marginTop: "5px" }}
                >
                  {data.total}
                </h2> 
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              {/* <ResponsiveContainer width="100%" height={60}> */}
              {/* <i className="fa fa-custom-complated" /> */}
              {/* <IconFont
                type={data.id}
                style={{ fontSize: "60px", padding: "10px", float: "right" }}
              /> */}
            <img src={require(`assets/images/icon/${data.id}.png`)}></img>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-pr-2" 
            >
              <div className=""> 
                <p className="gx-chart-title" style={{ marginBottom: "10px"}}>{data.title}</p>
              </div>
            </Col>
           
            {/* <Col lg={24} md={24} sm={24} xs={24} className="gx-pr-2">
              <div className="gx-pt-2">
                <p
                  className="gx-mb-1 gx-text-white gx-fs-sm"
                  style={{ paddingRight: "20px", fontWeight: "700" }}
                >
                  Past 1 year{" "}
                  <Icon
                    type="right"
                    id="right-arrow"
                    style={{
                      float: "right",
                      borderRadius: "25px",
                      padding: "4px 4px",
                      backgroundColor: " #00000029"
                    }}
                  />
                </p>
              </div>
            </Col> */}
          </Row>
        </div>
      </Widget>
    </div>
  );
};

export default QueriesCard;
