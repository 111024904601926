import { Icon } from "antd";

export const IconFont = Icon.createFromIconfontCN({
  scriptUrl:"//at.alicdn.com/t/font_1494228_3qpwqxk8834.js",
  // scriptUrl: "//at.alicdn.com/t/font_1494228_gb0kawweuoc.js", // "//at.alicdn.com/t/font_1494228_imygxrstux.js"
});

export const campaignType = [
  {
    id: 1,
    type: "email",
    title: "Email",
    icon: "mail",
  },
  {
    id: 2,
    type: "sms",
    title: "Sms",
    icon: "message",
  },
  {
    id: 3,
    type: "call",
    title: "Voice",
    icon: "phone",
  },
  {
    id: 4,
    type: "whatsapp",
    title: "Whatsapp",
    icon: "whatsapp",
  },
];


export const getSubdomain = ()=>{
  let host = window.location.host;
  let parts = host.split(".");
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  return  parts[0];
}