import { Row, Col} from "antd";
import React from "react";
import moment from 'moment';

 const defaultGroups = [
    { id: "non_it", name: "Non IT" },
    { id: "it", name: "IT" },
    { id: "risk", name: "Risk" },
    { id: "accounts", name: "Accounts" },
    { id: "others", name: "Others" }
 ]  
 const formatTime = (date) => {
    return moment(date).format('dddd, MMMM Do YYYY');
};
const CampaignDetails = (campaignDetails) => { 
    const {campaign, showModal} = campaignDetails;
    console.log("campaignDetails", campaignDetails)
    var templates = []
    if (campaign.groupedSelection && !campaign.groupedSelection["all"]) {
        Object.keys(campaign.groupedSelection).forEach(function (key) {
            var l = (
                <tr className="list-inline">
                    <td>{defaultGroups.filter(g => g.id == key)[0].name}</td>
                    <td>
                        {campaign.groupedSelection[key].category
                            ? campaign.groupedSelection[key].category.name
                            : ""}
                    </td>
                    <td>
                        <a onClick={ () => showModal('modal')}> {campaign.groupedSelection[key].template.name}</a>
                        {/* <a href="#" onclick={_ => {
                                console.log("----------66---",campaign.groupedSelection[key].content);
                                 
                            }}
                        >
                            {campaign.groupedSelection[key].template.name}
                        </a> */}
                    </td>
                </tr>
            );
            templates.push(l); 
        });
    }
 
  return (
      <div>
  
        { campaign.campaignType == "email" ?
            
            <Row style={{ padding:'10px'}}>  
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                    <div  >
                        <h5  style={{marginBottom:'0px'}}>Campaign Name</h5> 
                        <p style={{color:'#999999'}}>{campaign.name}</p>
                    </div>
                </Col>
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                    <div  >
                        <h5 style={{marginBottom:'0px'}}>Date Initiated</h5> 
                        <p style={{color:'#999999'}}>{ formatTime(campaign.createdAt)}</p>
                    </div>
                </Col>  
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                    <div  >
                        <h5  style={{marginBottom:'0px'}}>Expiry Date</h5> 
                        <p style={{color:'#999999'}}>{ formatTime(campaign.expireAt)}</p>
                    </div>
                </Col>
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                    <div  >
                        <h5 style={{marginBottom:'0px'}}>Domain</h5> 
                        <p style={{color:'#999999'}}>{ (campaign.organisationId ? campaign.organisationId.website : "")}</p>
                    </div>
                </Col> 
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                    <div  >
                        <h5  style={{marginBottom:'0px'}}>
                        {templates.length == 0 ? 
                            ( templates.length > 0
                                ? "Groups"
                                : "Template Name" 
                            ) : (
                                ""
                            )}
                        </h5> 
                        {!campaign.groupedSelection ||
                                                    (campaign.groupedSelection["all"] &&
                                                        campaign.templateId != null) ? (
                                                        <a onClick={ () => showModal('modal')}
                                                        
                                                        >
                                                            {campaign.templateId != null ? campaign.templateId.name : "Custom"}
                                                        </a>
                                                    ) : campaign.groupedSelection &&
                                                        !campaign.groupedSelection["all"] ? (
                                                            <table className="table">
                                                                <tr>
                                                                    <th style="padding: 0 5px;">User group</th>
                                                                    <th style="padding: 0 5px;">
                                                                        Template Category
                                </th>
                                                                    <th style="padding: 0 5px;">Template</th>
                                                                </tr>
                                                                <tbody>{templates}</tbody>
                                                            </table>
                                                        ) : (
                                                            "NA"
                                                        )} 
                    </div>
                </Col>
                
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                    <div  >
                        <h5 style={{marginBottom:'0px'}}>Template Category</h5> 
                        <p style={{color:'#999999'}}>
                            {campaign.templateId != null
                            ? campaign.templateId.categoryId
                                ? campaign.templateId.categoryId.name
                                : "NA"
                            : "NA"}
                        </p>
                    </div>
                </Col>           
            </Row>
            
            
            :
            campaign.campaignType == "call" ?
            
            <Row style={{ padding:'10px'}}>  
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                    <div  >
                        <h5  style={{marginBottom:'0px'}}>Campaign Name</h5> 
                        <p style={{color:'#999999'}}>{campaign.name}</p>
                    </div>
                </Col>
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                    <div  >
                        <h5 style={{marginBottom:'0px'}}>Date Initiated</h5> 
                        <p style={{color:'#999999'}}>{ formatTime(campaign.createdAt)}</p>
                    </div>
                </Col>  
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                    <div  >
                        <h5  style={{marginBottom:'0px'}}>Expiry Date</h5> 
                        <p style={{color:'#999999'}}>{ formatTime(campaign.expireAt)}</p>
                    </div>
                </Col>
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                    <div  >
                        <h5 style={{marginBottom:'0px'}}>Domain</h5> 
                        <p style={{color:'#999999'}}>{ (campaign.organisationId ? campaign.organisationId.website : "")}</p>
                    </div>
                </Col> 
                <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                        { campaign.number && 
                        
                        <div  >
                            <h5 style={{marginBottom:'0px'}}>Sender Number</h5> 
                            <p style={{color:'#999999'}}>{ (campaign.number)}</p>
                        </div>
                        }
                </Col> 
            
            </Row>
            
            
            :
           
            <Row style={{ padding:'10px'}}>  
            <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                <div  >
                    <h5  style={{marginBottom:'0px'}}>Campaign Name</h5> 
                    <p style={{color:'#999999'}}>{campaign.name}</p>
                </div>
            </Col>
            <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                <div  >
                    <h5 style={{marginBottom:'0px'}}>Date Initiated</h5> 
                    <p style={{color:'#999999'}}>{ formatTime(campaign.createdAt)}</p>
                </div>
            </Col>  
            <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                <div  >
                    <h5  style={{marginBottom:'0px'}}>Expiry Date</h5> 
                    <p style={{color:'#999999'}}>{ formatTime(campaign.expireAt)}</p>
                </div>
            </Col>
            <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                <div  >
                    <h5 style={{marginBottom:'0px'}}>Domain</h5> 
                    <p style={{color:'#999999'}}>{ (campaign.organisationId ? campaign.organisationId.website : "")}</p>
                </div>
            </Col> 
             
            {/* <Col  xl={12} lg={12} md={12} sm={24} xs={24} >  
                <div  >
                    <h5  style={{marginBottom:'0px'}}>
                    {templates.length == 0 ? 
                        ( templates.length > 0
                            ? "Groups"
                            : "Template Name" 
                        ) : (
                            ""
                        )}
                    </h5> 
                    {!campaign.groupedSelection ||
                                                (campaign.groupedSelection["all"] &&
                                                    campaign.templateId != null) ? (
                                                    <a onClick={ () => showModal('modal')}
                                                    
                                                    >
                                                        {campaign.templateId != null ? campaign.templateId.name : "Custom"}
                                                    </a>
                                                ) : campaign.groupedSelection &&
                                                    !campaign.groupedSelection["all"] ? (
                                                        <table className="table">
                                                            <tr>
                                                                <th style="padding: 0 5px;">User group</th>
                                                                <th style="padding: 0 5px;">
                                                                    Template Category
                            </th>
                                                                <th style="padding: 0 5px;">Template</th>
                                                            </tr>
                                                            <tbody>{templates}</tbody>
                                                        </table>
                                                    ) : (
                                                        "NA"
                                                    )} 
                </div>
            </Col>
            
            <Col  xl={12} lg={12} md={12} sm={24} xs={24} > 
                <div  >
                    <h5 style={{marginBottom:'0px'}}>Template Category</h5> 
                    <p style={{color:'#999999'}}>
                        {campaign.templateId != null
                        ? campaign.templateId.categoryId
                            ? campaign.templateId.categoryId.name
                            : "NA"
                        : "NA"}
                    </p>
                </div>
            </Col>           
              */}
        </Row>
    
        } 
      </div>
  );
};

export default CampaignDetails;

 