import React from "react";
import { Link } from "react-router-dom";

import store from "store";
import { IconFont } from "util/Utils";
import { getSubdomain } from "../../../../util/Utils";
////at.alicdn.com/t/font_1494228_8xr1xto1mhf.js
// import './index.css'; //at.alicdn.com/t/font_1494228_03ecnl4m3zs3.js
import IntlMessages from "util/IntlMessages";

const styleIcon = {
  fontSize: "42px",
  marginTop: "-10px",
};
function SideNavbar() {
  var user = store.get("user");
  const services = user.organisationId? user.organisationId.services||[]:[];
  const showExploreTab = user.organisationId? user.organisationId.showExploreTab:false;
  
  return (
    <div className="side-nav uk-animation-slide-left-medium" id="side-nav">
      {/* <!--Mobile icon wiill close nav-side   -->              */}
      <span className="uk-animation-fade tm-mobile-close-icon" data-uk-toggle="target: #side-nav; cls: side-nav-active">
        {" "}
        <i className="fas fa-times ic-icon-large"></i>
      </span>
      <div id="side-nav">
        <div className="side-nav-bg"></div>
        <div className="uk-navbar-left uk-visible@s">
          <Link className="uk-logo" to="/trainee">
            {" "}

            <i className={("fa fa-logo-aware-icon " + getSubdomain())}  ></i>
            {/* <IconFont type="icon-Ophish_menu_logo" style={{ fontSize: "32px", color: "#ffffff" }}></IconFont>{" "} */}
          </Link>
        </div>
        <ul>
          <li>
            <Link to={"/trainee/"}>
              {" "}
              <i className="fas fa-play"></i>{" "}
            </Link>
            <div className="side-menu-slide">
              <div className="side-menu-slide-content">
                {/* //Assigned Training, Standard Training, Premium Training, Practice Training, CSCU Video */}
                <ul data-simplebar>
                  <li>
                    <Link to={"/trainee/"}>
                      <IconFont type="icon-assigned_training" style={{ styleIcon }} />
                      <IntlMessages
                id="mainTraining.heading"/>
                    </Link>
                    {/* {(services.includes("premium") ||
                      services.includes("standard")) && ( */}
                   {showExploreTab && (
                    <Link to={"/trainee/premium"}>
                      <IconFont type="icon-premium_icon" style={{ styleIcon }} />
                     <IntlMessages
                id="mainTraining.explore"/>
                    </Link>
                     )}
                    {/* <Link  
                                    to={ '/trainee/Standard' }
                                > 
                                <IconFont type="icon-standard_icon" style={{styleIcon}} />
                                    Standard Training
                                </Link>  */}
                    {false&&<Link to={"/trainee/practice"}>
                      <IconFont type="icon-practice_icon" style={{ styleIcon }} />
                      Practice Quizes
                    </Link>}

                    {/* {services.includes("cscu") ||
                      (store.get("user").trial &&  false&& ( */}
                      {false&&(
                        <Link to={"/trainee/cscu"}>
                          <IconFont type="icon-video_training" style={{ styleIcon }} />
                          CSCU Training
                        </Link>
                      )}
                    {/* <Link to={"/trainee/favorite"}>
                      <i className="fas fa-heart " style={{ styleIcon }}></i>
                      <IntlMessages
                id="mainTraining.favorite"/>
                    </Link>
                    <Link to={"/trainee/calendar"}>
                      <i className="fas fa-calendar " style={{ styleIcon }}></i>
                     <IntlMessages
                id="mainTraining.upcoming"/> 
                    </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <Link
              to="/trainee/favorite"
              className="active"
              data-uk-tooltip="title: Favorite Training ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"
            >
              {" "}
              <i className="fas fa-heart "></i>{" "}
            </Link>
          </li>
          {/* <li>
            <Link
              to="/trainee/discussion"
              className="active"
              data-uk-tooltip="title: Discussion ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"
            >
              {" "}
              <i className="fas fa-comment-alt"></i>{" "}
            </Link>
          </li> */}
          <li>
            <Link
              to="/trainee/calendar"
              className="active"
              data-uk-tooltip="title: Upcoming Training ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"
            >
              {" "}
              <i className="fas fa-calendar "></i>{" "}
            </Link>
          </li>
          {/* {store.get("user").trial && (
            <li>
              <Link
                to="/trainee/your/training"
                className="active"
                data-uk-tooltip="title: Purchase History ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"
              >
                {" "}
                <i className="fas fa-shopping-bag "></i>{" "}
              </Link>
            </li>
          )} */}
          {/* <li> 
                <a href="books" data-uk-tooltip="title: Books ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-book-open"></i> </a> 
            </li>                     
            <li> 
                
                <a href="#"> <i className="fas fa-code"></i> </a> 
                <div className="side-menu-slide"> 
                    <div className="side-menu-slide-content"> 
                        <ul> 
                            <li> 
                                <a href="Scripts"> <i className="fas fa-code ic-icon-medium"></i> Browse All Scripts </a> 
                            </li>                                     
                            <li> 
                                <a href="Scripts_single_page"> <i className="fab fa-wordpress ic-icon-medium"></i>   Cms Plugins </a> 
                            </li>                                     
                            <li> 
                                <a href="Scripts_single_page"> <i className="fab fa-php ic-icon-medium"></i>   PHP Scripts </a> 
                            </li>                                     
                            <li> 
                                <a href="Scripts_single_page"> <i className="fab fa-wordpress-simple ic-icon-medium"></i> Cms themes </a> 
                            </li>                                     
                            <li> 
                                <a href="Scripts_single_page"> <i className="fab fa-html5 ic-icon-medium"></i> Html templates  </a> 
                            </li>                                     
                            <li> 
                                <a href="Scripts_single_page"> <i className="fab fa-android ic-icon-medium"></i>  Apps Source codes </a> 
                            </li>                                     
                        </ul>                                 
                    </div>                             
                </div>                         
            </li>                     
             <li> 
                 
                <a href="Blog" className="active" data-uk-tooltip="title: Blogs ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-file-alt"></i> </a> 
            </li>                     
            <li> 
                
                <a href="#"> <i className="fas fa-columns"></i> </a> 
                <div className="side-menu-slide"> 
                    <div className="side-menu-slide-content"> 
                        <ul data-uk-accordion> 
                            <li className="uk-open"> 
                                <a href="ui-components" className="uk-accordion-title"> <i className="fas fa-layer-group"></i> Ui Elements </a> 
                                <div className="uk-accordion-content uk-margin-remove-top"> 
                                    <a href="ui-elements"> 
                                        Breadcrumb  </a> 
                                    <a href="ui-elements"> 
                                        Card </a> 
                                    <a href="ui-elements"> 
                                        Dropdown </a> 
                                    <a href="ui-elements"> 
                                        Forum </a> 
                                    <a href="ui-elements"> 
                                        Grid  </a> 
                                    <a href="ui-elements"> 
                                        Nav   </a> 
                                    <a href="ui-elements"> 
                                        Heading  </a> 
                                    <a href="ui-elements"> 
                                        Pagination  </a> 
                                </div>                                         
                            </li>                                     
                            <li className=" uk-margin-remove-top"> 
                                <a href="ui-components" className="uk-accordion-title"> <i className="fas fa-layer-group"></i> Ui Compounents  </a> 
                                <div className="uk-accordion-content uk-margin-remove-top"> 
                                    <a href="ui-components"> 
                                       Modal  </a> 
                                    <a href="ui-components"> 
                                        Slideshow </a> 
                                    <a href="ui-components"> 
                                        Tooltip </a> 
                                    <a href="ui-components"> 
                                        Accordian </a> 
                                    <a href="ui-components"> 
                                        Toggle  </a> 
                                    <a href="ui-components"> 
                                        Swicher </a> 
                                </div>                                         
                            </li>                                     
                            <li className=" uk-margin-remove-top"> 
                                <a href="ui-helpers" className="uk-accordion-title"> <i className="fas fa-layer-group"></i> Ui Helpers   </a> 
                                <div className="uk-accordion-content uk-margin-remove-top"> 
                                    <a href="ui-helpers"> 
                                        Text</a> 
                                    <a href="ui-helpers"> 
                                        Padding </a> 
                                    <a href="ui-helpers"> 
                                        Margin </a> 
                                    <a href="ui-helpers"> 
                                        Bacgkround </a> 
                                    <a href="ui-helpers"> 
                                        Alingn </a> 
                                </div>                                         
                            </li>                                     
                        </ul>                                 
                    </div>                             
                </div>                         
            </li>                     
            <li> 
                 
                <a href="#"> <i className="fas fa-clone"></i> </a> 
                <div className="side-menu-slide"> 
                    <div className="side-menu-slide-content"> 
                        <ul> 
                            <li> 
                                <a href="pages-about"> <i className="fas fa-question"></i>   About  </a> 
                                <div uk-drop="pos: right-center;animation: uk-animation-slide-left-medium" className="uk-drop uk-drop-right-center"> 
                                    <div className="uk-card  uk-box-shadow-xlarge uk-card-default uk-maring-small-left"> 
                                        <img src="../assets/images/demos/pages-about.jpg" alt=""/> 
                                        <p className="uk-padding-small uk-margin-remove"> About Page is : ipsum dolor sit amet, consectetur adipiscing elit.. </p> 
                                    </div>                                             
                                </div>                                         
                            </li>                                     
                            <li> 
                                <a href="pages-faq"> <i className="fas fa-comment-alt"></i> FAQ  </a> 
                                <div uk-drop="pos: right-center;animation: uk-animation-slide-left-medium" className="uk-drop uk-drop-right-center"> 
                                    <div className="uk-card  uk-box-shadow-xlarge uk-card-default uk-maring-small-left"> 
                                        <img src="../assets/images/demos/pages-faq.jpg" alt=""/> 
                                        <p className="uk-padding-small uk-margin-remove">  FAQ is : ipsum dolor sit amet, consectetur adipiscing elit </p> 
                                    </div>                                             
                                </div>                                         
                            </li>                                     
                            <li> 
                                <a href="pages-terms"> <i className="fas fa-comment-dots"></i>  Terms &amp; Services </a> 
                                <div uk-drop="pos: right-center;animation: uk-animation-slide-left-medium" className="uk-drop uk-drop-right-center"> 
                                    <div className="uk-card  uk-box-shadow-xlarge uk-card-default uk-maring-small-left"> 
                                        <img src="../assets/images/demos/pages-terms.jpg" alt=""/> 
                                        <p className="uk-padding-small uk-margin-remove">  Term Services ipsum dolor sit amet, consectetur adipiscing elit </p> 
                                    </div>                                             
                                </div>                                         
                            </li>                                     
                            <li> 
                                <a href="pages-help"> <i className="fas fa-comments"></i> Help </a> 
                                <div uk-drop="pos: right-center;animation: uk-animation-slide-left-medium" className="uk-drop uk-drop-right-center"> 
                                    <div className="uk-card  uk-box-shadow-xlarge uk-card-default uk-maring-small-left"> 
                                        <img src="../assets/images/demos/pages-help.jpg" alt=""/> 
                                        <p className="uk-padding-small uk-margin-remove">  help Page ipsum dolor sit amet, consectetur adipiscing elit </p> 
                                    </div>                                             
                                </div>                                         
                            </li>                                     
                        </ul>                                 
                    </div>                             
                </div>                         
            </li>                     
         */}
        </ul>
        {/* <ul className="uk-position-bottom"> 
            <li>  
                <a href="#" data-uk-tooltip="title: Lunch information box ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up" data-uk-toggle="target: #infoBox; cls: infoBox-active"><i className="fas fa-question"></i> </a> 
            </li>                     
            <li>  
                <a href="#" data-uk-tooltip="title: Night mode ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-moon"></i> </a> 
                <div uk-drop="pos: right-bottom ;mode:click ; offset: 10;animation: uk-animation-slide-left-medium" className="uk-drop"> 
                    <div className="uk-card-small uk-box-shadow-xlarge uk-card-default uk-maring-small-left  border-radius-6"> 
                        <div className="uk-card uk-card-default border-radius-6"> 
                            <div className="uk-card-header"> 
                                <h5 className="uk-card-title uk-margin-remove-bottom">Swich to night mode</h5> 
                            </div>                                     
                            <div className="uk-card-body"> 
                                <p>Turns the light surfaces of the page dark, creating an experience ideal for night. Try it!</p> 
                                <p className="uk-text-small uk-align-left uk-margin-remove  uk-text-muted">DARK THEME </p>  
                                <div className="btn-night uk-align-right" id="night-mode"> 
                                    <label className="tm-switch"> 
                                        <div className="uk-switch-button"></div>                                                 
                                    </label>                                             
                                </div>                                          
                            </div>                                     
                        </div>                                 
                    </div>                             
                </div>                         
            </li>                     
        </ul>                 
     */}
      </div>
    </div>
  );
}

export default SideNavbar;
