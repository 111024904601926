import React, { Component } from "react";
import CardBox from "components/CardBox";
import {
  Button,
  Card,
  Form,
  Icon,
  InputNumber,
  Radio,
  Rate,
  Select,
  Slider,
  Switch,
  Upload,
  Modal,
  Table,
  Input,
  message,
  Row,
  Col,
} from "antd";

import $ from "jquery";
import axios from "axios";
import ThreeSizes from "../../routes/components/dataEntry/DatePicker/ThreeSizes";
var Model = require("./model");
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const gapi = window.gapi;
const hello = require("hellojs/dist/hello.all.js");
const ButtonGroup = Button.Group;
// console.log({ Actions });
var CLIENT_ID = "593372458051-pcqi1pua1c14s535popbg5eb6p0p1imk.apps.googleusercontent.com";
var API_KEY = "AIzaSyAJuZDBWkmieQypyUpyjdENZpmAx_9M3gI";

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = "https://www.googleapis.com/auth/admin.directory.user.readonly";
const Configs = {
  appId: "21fce665-b007-4c02-89d0-b9b929660906",
  scope: "openid+user.readbasic.all+email+profile",
};
class importUsers extends Component {
  importUsers = Form.create({})(importUsers);
  state = {
    userModalVisible: false,
    importedUsers: [],
    selectedRowKeys: [],
    maualEntry: false,
    ADImport: false,
    contentType: "from_file",
  };
  componentDidMount() {
    console.log(this.props,"")
    var self = this;
    this.props.form.validateFields();
    const gapiScript = document.createElement("script");
    gapiScript.src = "https://apis.google.com/js/api.js?onload=handleClientLoad";
    gapiScript.async = true;

    gapiScript.onload = function() {
      window.gapi.load("client:auth2", initClient);
    };
    function initClient() {
      console.log("Init Client");
      window.gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(
          function() {
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
            updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
          },
          function(error) {
            console.log(error);
          }
        );
    }
    function updateSigninStatus(isSignedIn) {
      console.log(isSignedIn);
      if (isSignedIn) {
        // self.listUsers();
      }
    }
    document.body.appendChild(gapiScript);
  }
  onImportTypeChange = (e) => {
    console.log("e", e.target.value);
    switch (e.target.value) {
      case "from_file":
        $("#importfile").val("");
        $("#importfile").trigger("click");
        this.setState({ contentType: "from_file", maualEntry: false, importType: "file", importedUsers: [] });
        break;
      case "from_ad":
        this.setState({
          contentType: "from_ad",
          maualEntry: false,
          ADImport: true,
          userModalVisible: true,
          importType: "ad",
          importedUsers: [],
        });
        break;
      case "manual":
        this.setState({
          contentType: "manual",
          maualEntry: true,
          ADImport: false,
          userModalVisible: true,
          importType: "manual",
          importedUsers: [],
        });
        break;
      case "oauth365":
        this.setState({ contentType: "oauth365", maualEntry: false, importType: "microsoft", importedUsers: [] });
        this.oathusers();
        break;

      case "gsuit":
        this.setState({ contentType: "gsuit", maualEntry: false, importType: "google", importedUsers: [] });
        this.gsuiteUsers();
        break;
      default:
      // code block
    }
  };
  handleFile = (e) => {
    // $("#importfile").val("");
    e.preventDefault();
    this.file = e.target.files[e.target.files.length - 1];
    console.log(this.file);
    Model.default.fetchUsersFromFile(this.file).then((response) => {
      console.log({ response });
      if (!response.error) {
        this.setState({
          importedUsers: response.data,
          userModalVisible: true,
          selectedRowKeys: [...Array(response.data.length).keys()],
        });
        // this.props.importedTrainees({ data: response.data, type: "from_file" });
      }
    });
  };
  hideUserModal = () => {
    this.setState({ userModalVisible: false });
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (!selected) {
      this.setState({ selectedRowKeys: [] });
    } else {
      this.setState({
        selectedRowKeys: [...Array(this.state.importedUsers.length).keys()],
      });
    }
  };
  onSelect = (record, selected, selectedRows, nativeEvent) => {
    console.log({ record, selected, selectedRows, nativeEvent });
    let index = this.state.importedUsers.findIndex((x) => x.EmployeeId === record.EmployeeId);
    var { selectedRowKeys } = this.state;
    if (selectedRowKeys.includes(index)) {
      selectedRowKeys = selectedRowKeys.filter(function(value, i, arr) {
        return value != index;
      });
    } else {
      console.log("pushing");
      Array.prototype.insert = function(index, item) {
        this.splice(index, 0, item);
      };
      selectedRowKeys.insert(index, index);
      console.log({ selectedRowKeys });
      // this.setState({ selectedRowKeys,importedUsers })
    }
    this.setState({ selectedRowKeys: selectedRowKeys });
    this.forceUpdate();
  };

  sendUsers = () => {
    console.log(this.props,"sendUsers")
    var { selectedRowKeys, importedUsers, importType } = this.state;
    importedUsers = importedUsers.filter((u, i) => {
      return selectedRowKeys.includes(i);
    });
    console.log({ selectedRowKeys }, { importedUsers }, { importType });
    this.props.importedTrainees({
      importedUsers,
      importType,
    });
    this.hideUserModal();
    this.setState({ importUsers: [], selectedRowKeys: [] });
  };

  gsuiteUsers = function() {
    var self = this;

    window.gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(function(data) {
        self.listUsers();
      });
  };

  listUsers = function() {
    var self = this;
    if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      window.gapi.client.directory.users
        .list({
          customer: "my_customer",
          orderBy: "email",
        })
        .then(
          function(response) {
            var users = response.result.users;
            console.log({ users });
            if (users && users.length > 0) {
              var modified_array_of_user = users.map(function(val) {
                var employeeid = val.id;
                val.EmployeeId = employeeid;
                delete val.id;
                var name = val.name.fullName;
                val.EmployeeName = name;
                delete val.name.fullName;
                var email = val.primaryEmail;
                val.EmployeeMail = email;
                delete val.primaryEmail;
                var mobile = "";
                val.EmployeeMobile = mobile;
                var designation = "";
                val.Designation = designation;
                return val;
              });
              // ctrl.users = modified_array_of_user;
              self.setState({
                importedUsers: modified_array_of_user,
                userModalVisible: true,
                selectedRowKeys: [...Array(modified_array_of_user.length).keys()],
              });
              // window.gapi.auth2.getAuthInstance().signout();
              window.gapi.auth2.getAuthInstance().disconnect();
            } else {
              console.log("No users found.");
              window.gapi.auth2.getAuthInstance().disconnect();
            }
          },
          function(error) {
            console.log(error);
            if (error.result.error.code === 503) {
              // alert("Please login with administrator id", "red");
              message.error("Please login with administrator id");
            } else {
              message.error(error.result.error.message);
            }
          }
        );
    } else {
      window.gapi.auth2.getAuthInstance().signIn();
    }
  };

  oathusers = function() {
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    console.log("Oauth userssssss", user);
    let tenantId = "";
    // if (user.accessLevel == 1) {
    //   alert("You can't import employee");
    //   return false;
    // if (!ctrl.campaign.organisationId) {
    //   alert("Please select client");
    //   return false;
    // }
    // tenantId = this.state.organisations.filter(org => org._id == user.organisationId);
    // console.log(tenantId);
    // if (tenantId.length > 0) {
    //   if (tenantId[0].tenantId)  tenantId = tenantId[0].tenantId;
    //   else {
    //     alert("Please add tenant id", "red");
    //     return false;
    //   }
    // }
    // console.log(tenantId);
    // } else {
    if (user.organisation.tenantId) tenantId = user.organisation.tenantId;
    else {
      alert("Please add tenant id", "red");
      return false;
    }
    // }
    hello.init(
      {
        msft: {
          id: Configs.appId,
          oauth: {
            version: 2,
            auth: "https://login.microsoftonline.com/" + tenantId + "/oauth2/v2.0/authorize",
          },
          scope_delim: "openid+user.readbasic.all+email+profile",
        },
      },
      { redirect_uri: "http://localhost:8888/campaigns" }
    );

    hello("msft")
      .login({ scope: Configs.scope, display: "popup" })
      .then(
        () => {
          console.log("Oauth user Success");
          const msft = hello("msft").getAuthResponse();
          console.log(msft);
          axios
            .get("https://graph.microsoft.com/v1.0/users", {
              headers: { Authorization: `Bearer ${msft.access_token}` },
            })
            .then((res) => {
              console.log(res.data, "************");
              var users = typeof res.data == "object" ? res.data : JSON.parse(res.data);
              console.log(users, ">>users");
              var value = users.value;
              var modified_array_of_user = value.map(function(val) {
                var employeeid = val.id;
                val.EmployeeId = employeeid;
                delete val.id;
                var name = val.displayName;
                val.EmployeeName = name;
                delete val.displayName;
                var email = val.userPrincipalName;
                val.EmployeeMail = email;
                delete val.userPrincipalName;
                var mobile = val.mobilePhone;
                val.EmployeeMobile = mobile;
                delete val.mobilePhone;
                var designation = val.jobTitle;
                val.Designation = designation;
                delete val.jobTitle;
                console.log(val);
                return val;
              });

              this.setState({
                importedUsers: modified_array_of_user,
                userModalVisible: true,
                selectedRowKeys: [...Array(modified_array_of_user.length).keys()],
              });
              hello("msft")
                .logout({ force: true })
                .then(
                  function() {
                    console.log("Log out");
                  },
                  function(e) {
                    console.log("Signed out error: " + e.error.message);
                  }
                );
            });
        },
        (e) => console.log(e.error.message, "******88")
      );
  };
  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }
  addManualEmployee = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        var { importedUsers } = this.state;
        importedUsers.push(values);
        this.setState({
          importedUsers,
          selectedRowKeys: [...Array(importedUsers.length).keys()],
        });
        this.props.form.resetFields();
      }
    });
  };
  testADConnection = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        // var { importedUsers } = this.state;
        // importedUsers.push(values);
        // this.setState({
        //   importedUsers,
        //   selectedRowKeys: [...Array(importedUsers.length).keys()]
        // });
        Model.default.testAd(values).then((response) => {
          var Id = 0;

          var users = (response.data || []).reduce((result, user) => {
            result.push({
              EmployeeId: ++Id,
              EmployeeName: user.displayName,
              EmployeeMail: user.mail,
              EmployeeMobile: user.mobile,
              Department: user.department,
              Designation: user.title,
              Branch: user.office,
            });

            return result;
          }, []);

          console.log(users);
          this.props.form.resetFields();
          this.setState({
            importedUsers: users,
            selectedRowKeys: [...Array(users.length).keys()],
          });
        });
      }
    });
  };
  manualEtryForm = () => {
    const formItemLayout = {
      labelAlign: "right",
      
      labelCol: {
        xs: { span: 7 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 12 },
      },
    };
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    return (
      <Form layout="inline" {...formItemLayout} onSubmit={this.addManualEmployee}>
        {/* <Form.Item>
          {getFieldDecorator("EmployeeId", {
            rules: [{ required: false, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Employee Id"
            />
          )}
        </Form.Item> */}
        <Row >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Name" style={{width:"100%"}}>
              {getFieldDecorator("EmployeeName", {
                rules: [{ required: true, message: "Please input employee name!" }],
              })(
                <Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Employee Name" />
              )}
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Email" style={{width:"100%"}}>
              {getFieldDecorator("EmployeeMail", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Please input valid email id",
                  },
                ],
              })(
                <Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Employee Email" />
              )}
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Designation" style={{width:"100%"}}>
              {getFieldDecorator("Designation", {
                rules: [{ required: false, message: "Please input valid Designation!", max: 50 }],
              })(
                <Input
                  prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Employee Designation"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Department" style={{width:"100%"}}>
              {getFieldDecorator("Department", {
                rules: [{ required: false, message: "Please input valid department!", max: 50 }],
              })(
                <Input
                  prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Employee Department"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Branch" style={{width:"100%"}}>
              {getFieldDecorator("Branch", {
                rules: [{ required: false, message: "Please input valid Branch!", max: 50 }],
              })(
                <Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Employee Branch" />
              )}
            </Form.Item>
          </Col>
          
          <Col xl={18} lg={18} md={18} sm={18} xs={18} style={{textAlign:"right"}}>
            <Form.Item>
              <Button type="info" htmlType="submit" disabled={this.hasErrors(getFieldsError())} style={{marginRight:0}}>
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };
  ADForm = () => {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    return (
      <Form layout="inline" onSubmit={this.testADConnection}>
        <Form.Item>
          {getFieldDecorator("domain", {
            rules: [{ required: false, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Enter Domain" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: false, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Enter user Name" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: false, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="id" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Enter Password" />)}
        </Form.Item>
        <Form.Item>
          <Button type="info" htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
            Fetch Employees
          </Button>
        </Form.Item>
      </Form>
    );
  };
  render() {
    var columns = [
      // {
      //   title: "Employee Id",
      //   key: "EmployeeId",
      //   dataIndex: "EmployeeId"
      // },
      {
        title: "Name",
        key: "EmployeeName",
        dataIndex: "EmployeeName",
      },
      {
        title: "Email",
        key: "EmployeeMail",
        dataIndex: "EmployeeMail",
      },
      {
        title: "Designation",
        key: "Designation",
        dataIndex: "Designation",
      },
      {
        title: "Department",
        key: "Department",
        dataIndex: "Department",
      },
      {
        title: "Branch",
        key: "Branch",
        dataIndex: "Branch",
      },
    ];
    const { selectedRowKeys, contentType } = this.state;
    const userImport = this.props.userImport;
    const rowSelection = {
      selectedRowKeys,
      // onChange: this.onSelectChange,
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      // hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All Data",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...Array(this.state.importedUsers.length).keys()], // 0...45
            });
          },
        },
        {
          key: "odd",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: "even",
          text: "Select Even Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };

    return (
      <CardBox styleName="gx-text-center" heading={"Import Users"}>
        <Radio.Group value={contentType} >
          <Radio.Button value="from_file" onClick={this.onImportTypeChange}>
            <Icon type="file-excel" /> From file
          </Radio.Button>
          <Radio.Button value="from_ad" onClick={this.onImportTypeChange}>
            <Icon type="cloud-server" />{" "}
            {userImport && userImport.ad ? "Sync users from AD Server" : "Import users from AD Server"}
          </Radio.Button>
          <Radio.Button value="manual" onClick={this.onImportTypeChange}>
            <Icon type="form" /> Manual Entry
          </Radio.Button>
          {JSON.parse(localStorage.getItem("user")).organisation.authMethod == "microsoft" && (
            <Radio.Button value="oauth365" onClick={this.onImportTypeChange}>
              <Icon type="windows" />{" "}
              {userImport && userImport.microsoft ? "Sync users from Oauth 365" : "Import users from Oauth 365"}
            </Radio.Button>
          )}{" "}
          {JSON.parse(localStorage.getItem("user")).organisation.authMethod == "google" && (
            <Radio.Button value="gsuit" onClick={this.onImportTypeChange}>
              <Icon type="google" />{" "}
              {userImport && userImport.google ? "Sync users from Gsuite" : "Import users from Gsuite"}
            </Radio.Button>
          )}
        </Radio.Group>

        {/* <ButtonGroup>
          <Button value="from_file" onClick={this.onImportTypeChange}>
            {" "}
            <Icon type="file-excel" /> From file
          </Button>
          <Button value="from_ad" onClick={this.onImportTypeChange}>
            <Icon type="cloud-server" />
            {userImport && userImport.ad ? "Sync users from AD Server" : "Import users from AD Server"}
          </Button>
          <Button value="manual" onClick={this.onImportTypeChange}>
            <Icon type="form" />
            Manual Entry
          </Button>
          {JSON.parse(localStorage.getItem("user")).organisation.authMethod == "microsoft" ? (
            <Button value="oauth365" onClick={this.onImportTypeChange}>
              {" "}
              <Icon type="windows" />
              {userImport && userImport.microsoft ? "Sync users from Oauth 365" : "Import users from Oauth 365"}
            </Button>
          ) : (
            ""
          )}
          {JSON.parse(localStorage.getItem("user")).organisation.authMethod == "google" ? (
            <Button value="gsuit" onClick={this.onImportTypeChange}>
              <Icon type="google" />
              {userImport && userImport.google ? "Sync users from Gsuite" : "Import users from Gsuite"}
            </Button>
          ) : (
            ""
          )}
        </ButtonGroup>
         */}
        <input type="file" className="form-control" id="importfile" hidden onChange={this.handleFile} />
        <Modal
          title="Select User"
          visible={this.state.userModalVisible}
          onOk={this.sendUsers}
          onCancel={this.hideUserModal}
          width={"80%"}
          footer={[
            <Button type="danger" key="1" onClick={this.hideUserModal}>
              Cancel
            </Button>,
            <Button type="info" key="ok" onClick={this.sendUsers}>
              OK
            </Button>,
          ]}
        >
          {this.state.maualEntry ? this.manualEtryForm() : null}
          {this.state.ADImport ? this.ADForm() : null}
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={this.state.importedUsers}
            bordered={false}
            rowSelection={rowSelection}
            // rowKey={record => record.EmployeeId}
            scroll={{ x: 1000 }}
          />
        </Modal>
      </CardBox>
    );
  }
}
const WrappedNormalLoginForm = Form.create()(importUsers);

export default WrappedNormalLoginForm;
