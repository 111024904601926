import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import trainingSagas from "./Training";
import campaignSagas from "./Campaign";
import notesSagas from "./Notes";
import dashboardSagas from "./Dashboard";
import traineeSagas from "./Trainee";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    trainingSagas(),
    campaignSagas(),
    dashboardSagas(),
    traineeSagas()
  ]);
}
