import React, { PureComponent } from "react";

import { Row, Col, Upload, Button, Radio, Form, Input } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import Model from "./model";
class StepsOne extends PureComponent {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    current: 0,
    visible: false,
    contentType: "scorm"
  };
  onContenetTypeChange = e => {
    console.log(e.target.value);
    this.props.form.setFieldsValue({ videoUrl: "" });
    var self = this;
    self.setState({ contentType: e.target.value });
  };
  onSubmit = e => {
    var self = this;
    e.preventDefault();
    self.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log({ values });
        if (values.videoUrl) {
          values["url"] = values.videoUrl;
        }
        Model.createNewTraining(values).then(response => {
          console.log({ response });
          self.props.uploadFromUrl(response.data);
        });
        // this.props.uploadFromUrl(values)
        // this.props.trainingUpdate(values);
      }
    });
  };

  handleUrlValidation = (rule, value, callback) => {
    var self = this;
    const contentType = self.state.contentType;
    var re = "youtube.com";
    if (contentType === "vimeo") {
      re = "vimeo.com";
    }
    const form = this.props.form; 
    
    if (form.getFieldValue("videoUrl") && !form.getFieldValue("videoUrl").match("https://")) { 
      callback("Please provide valid URL for video upload!");
    } else{
      if (form.getFieldValue("videoUrl") && !form.getFieldValue("videoUrl").match(re)) {
        // console.log({ re }, form.getFieldValue("videoUrl").match(re));
        callback("Please provide valid URL for video upload!");
      } else {
        // console.log({ re }, form.getFieldValue("videoUrl").match(re));
        callback();
      }
    }
    // }
  };
  render() {
    const {
      fileUploadProps,
      uploadFromPCProps,
      uploadFromUrl,
      uploadStatus
    } = this.props;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    var self = this;
    const contentType = self.state.contentType;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>
          <Row style={{ marginBottom: 30 }}>
            <Radio.Group
              value={contentType}
              onChange={self.onContenetTypeChange}
            >
              <Radio.Button
                value="scorm"
                disabled={
                  !(contentType !== "scorm" && !uploadStatus) ? false : true
                }
              >
                SCORM Package
              </Radio.Button>
              <Radio.Button
                value="pc"
                disabled={
                  !(contentType !== "pc" && !uploadStatus) ? false : true
                }
              >
                From computer
              </Radio.Button>
              {/* <Radio.Button
                value="vimeo"
                disabled={
                  !(contentType !== "vimeo" && !uploadStatus) ? false : true
                }
              >
                vimeo Link
              </Radio.Button>
              <Radio.Button
                value="youtube"
                disabled={
                  !(contentType !== "youtube" && !uploadStatus) ? false : true
                }
              >
                Youtube Link
              </Radio.Button>
           */}
            </Radio.Group>
          </Row>
          {contentType === "scorm" ? (
            <Row
              style={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Col
                style={{
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Upload {...fileUploadProps} style={{ width: "100%", alignItems: "center" }}>
                  <Button type="info" style={{ width: "100%" }} disabled={!uploadStatus}>
                    Upload SCORM Package
                  </Button>
                </Upload>
              </Col>
            </Row>
          ) : contentType === "pc" ? (
            <Row style={{ padding: "0px" }}>
              <Col
                style={{
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", textAlign:"center"
                }}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Upload {...uploadFromPCProps} style={{ width: "100%",  alignItems: "center", textAlign:"center" }}>
                  <Button type="info" style={{ width: "100%" }} disabled={!uploadStatus}>
                    Upload Video From PC
                  </Button>
                </Upload>
              </Col>
            </Row>
          ) : contentType === "vimeo" || contentType === "youtube" ? (
            <div>
              <Form onSubmit={this.onSubmit}>
                <Row>
                  <Col xl={2} lg={2} md={2} sm={24} xs={24}>
                    </Col>
                  <Col xl={15} lg={15} md={15} sm={24} xs={24} style={{paddingRight:0}}>
                    <Form.Item
                      wrapperCol={{ sm: 24 }}
                      style={{ width: "100%" }}
                    >
                      {getFieldDecorator("videoUrl", {
                        initialValue: "",
                        width: "100%",
                        rules: [
                          {
                            // required: true,
                            // type: "url",
                            // message: "Please provide valid URL for video upload!"
                          },
                          {
                            type: "text",
                            validator: this.handleUrlValidation
                          }
                        ]
                      })(
                        <Input
                          style={{ width: "100%" }}
                          placeholder={contentType=="vimeo"?"e.g. https://vimeo.com":"e.g. https://youtube.com"}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ display: "none" }}
                      hidden={true}
                      label="Title"
                    >
                      {getFieldDecorator("type", {
                        initialValue: contentType,
                        rules: [
                          {
                            required: true,

                            message: "Please input training title!"
                          }
                        ]
                      })(<Input value={contentType} />)}
                    </Form.Item>
                 
                  </Col>
                  <Col xl={5} lg={5} md={5} sm={24} xs={24}>
                    <Form.Item>
                      <Button type="info" type="info" htmlType="submit">
                        Create
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : null}
        </div>

        {/* <Row style={{ marginBottom: 80, height:100 }}>
          <Col xl={4} lg={4} md={4} sm={24} xs={24}>
            <Button type="info" onClick={() => this.next(1)}>
                  Next
                </Button>
          </Col>
        </Row> */}
      </div>
    );
  }
}
export default Form.create()(StepsOne);
