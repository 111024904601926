import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table } from "antd";
// import {DropOption} from '../../../components'

const { confirm } = Modal;

const viewDescriptionModal = modalProps => {
  console.log({ modalProps });
  const { modalVisible, handleCancel, description } = modalProps;

  return (
    <Modal
      title="Training description"
      visible={modalVisible}
      width="85%"
      onCancel={handleCancel}
      footer={[<Button onClick={handleCancel}>Close</Button>]}
      style={{ top: "3%" }}
    >
      <span
        dangerouslySetInnerHTML={{ __html: description }}
        style={{ height: "100px" }}
      />
    </Modal>
  );
};

export default viewDescriptionModal;
