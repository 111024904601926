import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { Col, Row, message, Card, Icon, List } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Model from './model'
import { Link } from 'react-router-dom'
import CircularProgress from 'components/CircularProgress/index';

import styles from './index.css'
import moment from'moment'
class MyTrainings extends PureComponent {
   
    componentDidMount(){
        console.log({Model})
        Model.getMyTrainings().then(response=>{
            console.log(response)
            this.setState({loading:false,traings:response.data})
        })
    }

    state={
        loading:true,
        traings:[]
    }
    formateDateTime(date) {
        return moment(date).format('DD MMM YYYY,  hh:mm A')
      }

render(){
    var traings = this.state.traings
    const notAttempted = traings.filter(
        item => item.attempted == false && item.campaignId.trainingId
      )
  
      const Attempted = traings.filter(
        item => item.attempted == true && item.campaignId.trainingId
      )
    if(this.state.loading){
        return <CircularProgress></CircularProgress>
    }
    return <Auxiliary className={styles.dashboard}>
        
          <Row gutter={12} loading={this.state.loading}>

          <Col lg={12} sm={12}>
            <Card
              className="list"
              style={{ height: 350, }}
              bordered={false}
              title={'Current Assigned Training'}
            >
              <div style={{ overflow: 'scroll', height: 296 }}>
                <List
                  style={{ padding: 0 }}
                  header={null}
                  footer={null}
                  dataSource={notAttempted}
                  renderItem={(item, index) => (
                    <List.Item
                      className={
                        index % 2 === 0
                          ? styles.alternate_selection
                          : styles.list_item
                      }
                    >
                      <List.Item.Meta
                        title={
                          <Link
                            to={{
                              pathname: '/trainings/play/' + item.campaignId._id,
                              state: {
                                item: item,
                              },
                            }}
                          >
                            <div> {item.campaignId.trainingId.name}</div>
                            <div style={{ opacity: 0.5 }}>
                              Assigned Date & Time :
                              {this.formateDateTime(item.assignedAt)}
                            </div>
                          </Link>
                        }
                        // description={"Assigned Date & Time : "+item.createdAt}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
          <Col lg={12} sm={12}>
            <Card
              className="list"
              style={{ height: 350 }}
              bordered={false}
              title={'Completed Trainings'}
            >
              <div style={{ overflow: 'scroll', height: 296 }}>
                <List
                  dataSource={Attempted}
                  renderItem={(item, index) => (
                    <List.Item
                      className={
                        index % 2 === 0
                          ? styles.alternate_selection
                          : styles.list_item
                      }
                    >
                      <List.Item.Meta
                        title={
                          <Link
                            to={{
                              pathname:
                                '/trainings/result/' + item.campaignId._id,
                              state: {
                                item: item,
                              },
                            }}
                          >
                            <div>{item.campaignId.trainingId.name}</div>
                            <div style={{ opacity: 0.5 }}>
                              Assigned Date & Time :{' '}
                              {this.formateDateTime(item.assignedAt)}
                            </div>
                          </Link>
                        }
                        // description={"Assigned Date & Time : "+item.createdAt}
                      />

                      {/*<Link*/}
                      {/*  to={{*/}
                      {/*    pathname:*/}
                      {/*      '/dashboard/result/' + item.campaignId._id,*/}
                      {/*    state: {*/}
                      {/*      item: item,*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {item.campaignId.trainingId.name}*/}
                      {/*</Link>*/}
                      {/*<a onClick={} val={item} href={"/dashboard/result/"+item.campaignId.trainingId._id}>{item.campaignId.trainingId.name}</a>*/}
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
        </Row>
     
    </Auxiliary>
}
}

export default MyTrainings