import React from "react";
import { Route, Switch } from "react-router-dom";
import Training from "./trainings";
import Trainee from "./trainee";
import Creator from "./creator/index.js";
import Update from "./creator/update.js";
import Instructor from "./instructor/index.js";
import TrainingInfo from "./$id"; 
import Campaigns from "./campaigns";
import TrainingPlayer from "./play/$id";
import Result from "./result/$trainingId";
const Trainings = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/creator`} component={Creator} />
      <Route path={`${match.url}/instructor`} component={Instructor} />
      <Route path={`${match.url}/:_id/update/`} component={Update} />
      <Route path={`${match.url}/details/:id`} component={TrainingInfo} /> 
      <Route path={`${match.url}/trainings/:page`} component={Training} />
      <Route path={`${match.url}/trainings`} component={Training} />
      <Route path={`${match.url}/trainee`} component={Trainee} />
      <Route path={`${match.url}/campaigns`} component={Campaigns} />
      <Route
        path={`${match.url}/play/:trainingId`}
        component={TrainingPlayer}
      />
      <Route path={`${match.url}/result/:trainingId`} component={Result} />
    </Switch>
  );
};

export default Trainings;
