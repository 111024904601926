import React, { Component } from "react";
import store from "store";
import { Input, Button } from "antd";
import styles from "./Discussion.less";
import { imageMediaUrl } from "util/config";
import IntlMessages from "util/IntlMessages";

const { TextArea } = Input;

function Discussions(propsInfo) {
  const {
    discussions,
    setDiscussion,
    addDiscussion,
    discussionsText,
    showDescriptionButton
  } = propsInfo;

  return (
    <div>
      <div className="animation:uk-animation-fade">
        <h3><IntlMessages id="trainee.discussion"/></h3>
        {discussions && discussions.length > 0
          ? discussions.map((discussion, index) => {
              return (
                <div
                  key={"discussion" + index}
                  className="uk-padding-small uk-background-light uk-position-relative"
                >
                  <div
                    className="uk-flex-middle uk-grid-small uk-grid-stack"
                    data-uk-grid
                  >
                    <div className="uk-width-1-6 uk-flex-first uk-first-column">
                      <img
                        src={
                          discussion.userId &&
                          discussion.userId.profilePhoto &&
                          discussion.userId.profilePhoto != ""
                            ? `${imageMediaUrl}${discussion.userId.profilePhoto}?token=${localStorage.getItem('accessToken')}`
                            : require("assets/images/avatures/avature-3.png")
                        }
                        alt="Image"
                        className="uk-width-1-2 uk-border-circle"
                      />
                    </div>
                    <div className="uk-width-5-6">
                      <p className="uk-margin-remove-bottom uk-text-bold uk-text-small">
                        {discussion.userId ? discussion.userId.userName : "NA"}
                      </p>
                      <p className="uk-margin-remove">
                        {discussion.description}
                      </p>
                    </div>
                  </div>
                  <hr className="uk-margin-small" />
                </div>
              );
            })
          : "No Discussions for this training"}
      </div>
      <div className="uk-padding-small uk-position-relative">
        <div
          className="uk-flex-middle uk-grid-small uk-grid-stack"
          data-uk-grid
        >
          <div className="uk-width-1-6 uk-flex-first uk-first-column">
            <img
              src={
                store.get("user").profilePhoto &&
                store.get("user").profilePhoto != ""
                  ? `${imageMediaUrl}${store.get("user").profilePhoto}?token=${localStorage.getItem('accessToken')}`
                  : require("assets/images/avatures/avature-3.png")
              }
              alt="Image"
              className="uk-width-1-2 uk-border-circle"
            />
          </div>
          <div className="uk-width-5-6">
            <p className="uk-margin-remove-bottom uk-text-bold uk-text-small">
              {store.get("user").userName}
            </p>
            {/* <textarea></textarea> */}
            <TextArea
              className={styles.textAreaStyle}
              rows={4}
              placeholder="Start a new discussion."
              value={discussionsText}
              onChange={setDiscussion}
            />
            {/* <input
              style={{ margin: "10px 0 20px 0" }}
              type="button"
              value="Send"
              onClick={addDiscussion}
              disabled={showDescriptionButton ? false : true}
            ></input> */}
            <Button
              style={{ margin: "10px 0 20px 0" }}
              type="button"
              value="Send"
              onClick={addDiscussion}
              disabled={showDescriptionButton ? false : true}
            >
              <IntlMessages id="trainee.send"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discussions;
