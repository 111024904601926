import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { imagePrefixUrl } from "util/config";

import store from "store";
import moment from "moment";
import axios from "axios";
import LogoutModal from "./logoutModal.js";
import { imageMediaUrl } from "util/config";
import { apiUrl } from "util/config";
import IntlMessages from "util/IntlMessages";
import languageData from "./../Topbar/languageData";
import CustomScrollbars from "util/CustomScrollbars";
import {
  switchLanguage
} from "appRedux/actions/Setting";

axios.defaults.headers.common["Authorization"] = `Bearer ${
  store.get("user") ? store.get("user").accessToken : ""
}`;
const languageDatas = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'Arabic',
    icon: 'sa'
  }
];
class TopNavbar extends Component {
  state = {
    yourCourses: true,
    userInfo: {},
    logoutModalVisible: false,
    selectedLanguage:{
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us'
    }
  };


  componentDidMount() {
    var userInfo = store.get("user");
    this.setState({
      userInfo: userInfo
    });
  }
  componentDidUpdate() {
    var self = this;
    document.addEventListener("userInfo", function(e) {
      self.setState({
        userInfo: e.detail.userInfo
      });
    });
  }
  // componentDidUpdate(){
  //   var self = this
  //   const userData = localStorage.getItem("user");
  //   const userInfo = JSON.parse(userData);
  //   self.setState({userInfo})
  // }
  // function TopNavbar(info) {

  changeLanguage = (e,val) => {
    console.log(e,val,"asasa")
    this.setState({selectedLanguage:val})
    this.props.switchLanguage(val)
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageDatas.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) => this.changeLanguage(e,language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  render() {
    var styleNav = {
      background: "#ffffff",
      height: "55px",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.14)"
    };
    const logoutModalProps = {
      logoutModalVisible: this.state.logoutModalVisible,
      handleConfirm: () => {
        this.props.userSignOut();
      },
      handleCancel: () => {
        this.setState({ logoutModalVisible: false });
      }
    };
    const { userInfo } = this.state;
    if (!userInfo) {
      return "";
    }
    return (
      <nav
        className="uk-navbar-transparent tm-mobile-header uk-animation-slide-top uk-position-z-index"
        data-uk-navbar
        style={styleNav}
      >
        {/* <!-- mobile icon for side nav on nav-mobile-->                  */}
        <span
          className="uk-hidden@m tm-mobile-menu-icon"
          data-uk-toggle="target: #side-nav; cls: side-nav-active"
        >
          <i className="fas fa-bars ic-icon-large"></i>
        </span>
        {/* <!-- mobile icon for user icon on nav-mobile -->                  */}
        <span
          className="uk-hidden@m tm-mobile-user-icon uk-align-right"
          data-uk-toggle="target: #tm-show-on-mobile; cls: tm-show-on-mobile-active"
        >
          <i className="far fa-user ic-icon-large"></i>
        </span>
        {/* <!-- mobile logo -->                  */}
        <a className="uk-hidden@m uk-logo"> SECAWARE</a>
        <div className="uk-navbar-left uk-visible@m">
          {/* <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#" data-uk-tooltip="title: back-to-dashboard ; delay: 700 ; pos: bottom-left ;animation:	uk-animation-scale-up">My Dashboard</a> 
                                <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#">Browse All Courses</a>  */}
        </div>
        <div
          className="uk-navbar-right tm-show-on-mobile uk-flex-right"
          id="tm-show-on-mobile"
        >
          {/* <!-- this will clouse after display user icon -->                      */}
          <span
            className="uk-hidden@m tm-mobile-user-close-icon uk-align-right"
            data-uk-toggle="target: #tm-show-on-mobile; cls: tm-show-on-mobile-active"
          >
            <i className="fas fa-times ic-icon-large"></i>
          </span>
          <ul className="uk-navbar-nav uk-flex-middle">
            {/* <li>
               <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()}
                       trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${this.state.selectedLanguage.icon}`}/> 
                  <span className="gx-pl-2 gx-language-name">{this.state.selectedLanguage.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
              </Popover>
              </li> */}
            <li>
              {/* <!-- User profile --> */}
              <a href="#">
                <img
                  src={
                    userInfo.profilePhoto && userInfo.profilePhoto !== ""
                      ? `${imageMediaUrl}${userInfo.profilePhoto}?token=${store.get("user").accessToken}`
                      : require("assets/images/avatures/avature-2.png")
                  }
                  alt=""
                  className="uk-border-circle user-profile-tiny"
                />
              </a>
              <div
                data-uk-dropdown=" mode :  hover;animation: uk-animation-slide-right"
                className="uk-dropdown  uk-dropdown-top-right  tm-dropdown-small-custom border-radius-6 angle-top-right"
              >
                <div
                  className="uk-grid-small uk-flex-middle uk-margin-small-bottom"
                  data-uk-grid
                >
                  <div className="uk-width-1-4  uk-first-column">
                    <img
                      src={
                        userInfo.profilePhoto && userInfo.profilePhoto !== ""
                          ? `${imageMediaUrl}${userInfo.profilePhoto}`
                          : require("assets/images/avatures/avature-2.png")
                      }
                      alt="Image"
                      className="uk-align-center uk-border-circle  user-profile-tiny"
                    />
                  </div>
                  <div className="uk-width-3-4">
                    <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                      {" "}
                      {userInfo.name}{" "}
                    </p>
                    {/* <p className="uk-margin-remove-top uk-text-small uk-margin-small-bottom"> {`India`}</p>  */}
                  </div>
                </div>
                <ul className="uk-nav uk-dropdown-nav">
                  <li>
                    <Link to="/profile">
                      {" "}
                      <i className="fas fa-user uk-margin-small-right"></i>{" "}
                      Profile
                    </Link>
                  </li>
                  {/* <li> 
                                                    <a href="#"> <i className="fas fa-envelope uk-margin-small-right"></i> Messages </a> 
                                                </li>                                      */}
                  {/* <li> 
                                                    <a href="#"> <i className="fas fa-share uk-margin-small-right"></i> Invite freind</a> 
                                                </li>                                      */}
                  {/* <li>
                    <Link to="/settings">
                      {" "}
                      <i className="fas fa-cog uk-margin-small-right"></i>{" "}
                      Settings
                    </Link>
                  </li> */}
                  <li className="uk-nav-divider"></li>
                  <li>
                    {/* <a onClick={() => this.props.userSignOut()}> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> Log out</a>  */}
                    <a
                      onClick={() => {
                        this.setState({ logoutModalVisible: true });
                      }}
                    >
                      {" "}
                      <i className="fas fa-sign-out-alt uk-margin-small-right"></i>{" "}
                      Log out{" "}
                    </a>

                    {/* <div id="modal-Logout" data-uk-modal>
                      <div className="uk-modal-dialog">
                        <button
                          className="uk-modal-close-default uk-margin-small-top  uk-margin-small-right uk-light"
                          type="button"
                          data-uk-close
                        ></button>
                        <div className="uk-modal-header ">
                          <b className=" uk-text-medium"> Log out </b>
                        </div>
                        <div className="uk-modal-body">
                          <h5>
                            {" "}
                            Are you sure you want to logout from EC-Council Aware ?
                          </h5>
                        </div>
                        <div className="uk-modal-footer uk-text-right">
                          <button
                            className="uk-button uk-button-default uk-modal-close"
                            type="button"
                            style={{ margin: "0 2px" }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => this.props.userSignOut()}
                            className="uk-button uk-button-grey uk-modal-close"
                            type="button"
                            style={{ margin: "0 2px" }}
                          >
                            Confirm{" "}
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        {this.state.logoutModalVisible && (
          <LogoutModal key="logoutModal" {...logoutModalProps} />
        )}
        {/* <!-- search box -->                  */}
        <div
          id="modal-full"
          className="uk-modal-full uk-modal uk-animation-scale-down"
          data-uk-modal
        >
          <div
            className="uk-modal-dialog uk-flex uk-flex-center"
            data-uk-height-viewport
          >
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <form className="uk-search uk-margin-xlarge-top uk-search-large uk-animation-slide-bottom-medium">
              <i className="fas fa-search uk-position-absolute uk-margin-top icon-xxlarge"></i>
              <input
                className="uk-search-input uk-margin-large-left"
                type="search"
                placeholder="Search..."
                // autofocus
              />
            </form>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({}) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    userSignOut,switchLanguage
  })(TopNavbar)
);
