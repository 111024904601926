import React, { PureComponent } from 'react';
import Auxiliary from "util/Auxiliary";
import CampaignTable from "./components/CampaignTable"
import { Col, Row, message,Menu,Icon,Dropdown} from 'antd';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom'

import { 
	hideMessage, 
	showMessage, 
	getAllCampaign, 
    showLoader,
    cleanAllCampaigns,
  } from "../../../appRedux/actions/Campaign"; 

const convertToObject = (url) => {
    const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
    let params = {}; 
    for(let i = 0; i < arr.length; i += 2){
      const key = arr[i], value = arr[i + 1];
      params[key] = value ; // build the object = { limit: "10", page:"1", status:"APPROVED" }
    }
    return params;
  };

class Campaigns extends PureComponent {

    state = {
        live: 2,
        campaignType:"email",
        campaignTypes:["email","call","sms"] 
    }
    handleMenuClick = (e)=>{ 
      var self  = this
      this.setState({campaignType:e.key},()=>{
          this.despatchNewquery()
      })
    }
    dropdownComponent=()=>{
        const menu = (
            <Menu onClick={this.handleMenuClick}>
              <Menu.Item key="email">
                <Icon type="user" />
                Email
              </Menu.Item>
              <Menu.Item key="call">
                <Icon type="mobile" />
                Voice
              </Menu.Item>
              <Menu.Item key="sms">
                <Icon type="message" />
                SMS
              </Menu.Item>
            </Menu>
          );

      return  <Dropdown.Button  overlay={menu}>
                Campaign Type: {(this.state.campaignType == 'call'? 'Voice': (this.state.campaignType).charAt(0).toUpperCase()+''+(this.state.campaignType).substring(1, (this.state.campaignType).length))} 
              </Dropdown.Button>
    }

    componentDidMount() {
		    this.props.showLoader();
        const { location } = this.props;  
        var  obj = convertToObject(location.search);  
        obj['campaignType'] =this.state.campaignType
        this.props.cleanAllCampaigns()
		    this.props.showLoader();
        this.props.getAllCampaign(obj) 
        this.setState({live: (typeof obj.live !== 'undefined')  ? Number(obj.live): this.state.live}) 
    }  
    componentWillMount(){
      this.props.showLoader();
    }
    componentDidUpdate(prevProps) {  
        const { location } = this.props; 
        const prevObj = convertToObject(prevProps.location.search);  
         var obj = convertToObject(location.search);   
        if (obj.live !== prevObj.live) {
          this.props.cleanAllCampaigns()
            this.props.showLoader();
            obj['campaignType'] =this.state.campaignType||'email'
            this.props.getAllCampaign(obj) 
            this.setState({live: (typeof obj.live !== 'undefined') ? Number(obj.live): this.state.live}) 
        } 
    }  
    despatchNewquery=()=>{
      var self = this 
      var newQuery = {};
      newQuery['live'] = Number(self.state.live)
      newQuery['campaignType'] =self.state.campaignType 
      this.props.showLoader();
      this.props.getAllCampaign(newQuery) 
        
    }
    render() {
        var self = this
        const { loader, alertMessage, showMessage, pagination, allCampaigns} = this.props; 
        const dropdownComponent = this.dropdownComponent()
        
        const campaignProps = {
            campaignsType: this.state.live,
            type:this.state.campaignType,
            dropdownComponent : dropdownComponent,
            pagination: pagination,
            title:'',
            data: allCampaigns,
            loader:loader, 
            handleChange: (e) => {  
                var newQuery = {};
                newQuery['live'] = Number(e.target.value)
                newQuery['campaignType'] = self.state.campaignType|| 'email' 
                this.setState({live: newQuery['live']}) 
                this.props.showLoader();
                this.props.getAllCampaign(newQuery) 
            },
            handlePagination: (newQuery) => {  
                this.props.showLoader();
                newQuery['campaignType'] = self.state.campaignType|| 'email' 
                this.props.getAllCampaign(newQuery) 
            },
            onClick :( record) => {
                this.props.history.push(`${'/campaigns/'}${record._id}${'/details'}`);
            } 
        }
        return (
            <Auxiliary> 
                {  showMessage ? alertMessage? message.error(alertMessage.toString()): null: null}

                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <h1>Phishing Campaign History</h1>
                        <div className="gx-campaign-space" />
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <CampaignTable {...campaignProps} />
                    </Col>
                </Row>

            </Auxiliary>
            
        )
    }
}

// export default Campaigns;

const mapStateToProps = ({ campaignInfo }) => {   
	const {  loader, alertMessage, showMessage, campaigns, allCampaigns, pagination } = campaignInfo;
	return {  loader, alertMessage, showMessage, campaigns, allCampaigns, pagination };
};
  
  export default withRouter(connect(mapStateToProps,{    
    hideMessage, 
    showMessage,
    getAllCampaign,
    showLoader, 
    cleanAllCampaigns,
  })(Campaigns));