import {
    apiUrl
} from "util/config";
import {
    message
} from 'antd'
import $ from 'jquery'
var Actions = {

    createNewTraining: (values) => {
        return new Promise((resolve, reject) => {

            var settings = {
                async: true,
                crossDomain: true,
                url: apiUrl + "api/training/import/from_url",
                method: "POST",
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    // fngprt: m.cookie.get("fngprt")
                },
                data: {
                    ...values
                },
                success: function (response) {
                    console.log({response})
                    if (response.error) {
                        message.error(response.userMessage);

                    } else {
                        
                        resolve(response);
                    }
                },
                error: function (error) {

                    if (error.status == 401) {
                        message.error("Your session has been expired. Please relogin.");
                        localStorage.clear();window.location.reload();
                    } else {
                        message.error("Some error has occurred.");

                    }
                }
            };

            $.ajax(settings).done();
        });
    },

}


export default Actions