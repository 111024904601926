import React from "react";
// import img from './assets/images/backgrounds'

import { Link } from "react-router-dom";
import moment from "moment";
import { imagePrefixUrl } from "util/config";
import { Button, message, Input } from "antd";
import trainingModel from "../../../trainings/$id/model";
import store from "store";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Model from "../../model.js";
import CartModel from "./../../myCart/model.js";
import TrainingFilter from "./trainingFilter.js";
import CircularProgress from "../../../../components/CircularProgress/index.js";
import { traineeTrainingAllGet } from "../../../../appRedux/sagas/Training";
import trainee from "../../../trainings/trainee";
import $id from "../../../trainings/$id";
import $ from "jquery";
const { Search } = Input;
const categoryList = [{ label: "All", value: 0 }, { label: "Basic", value: 2 }, { label: "Premium", value: 1 }];
var languageData = [
  { label: "All", value: "all" },
  { label: "English(United States)", value: "en-us" },
  { label: "English(Australia)", value: "en-au" },
  { label: "Bahasa Malaysia", value: "myb" },
  { label: "Arabic", value: "ar" },
  { label: "Canada(French)", value: "fr-CA" }
]
const pricesList = ["All", "Free", "Paid"];

const durationsList = [
  { label: "All", value: 0 },
  { label: "0-10 Minutes", value: 1 },
  { label: "10-15 Minutes", value: 2 },
  { label: "15-30 Minutes", value: 3 },
  { label: "30 + Minutes", value: 4 },
];
class trainingType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allTrainings: [],
      trainingSubType: "All",
      pagination: {
        totalRecords: 0,
        page: 0,
      },
      loading: true,
      loader: false,
      categoryIndex: 0,
      searchText: "",
      category: "All",
      topicsList: [],
      languageList: [],
      topicValue: [],
      categoryValue: [],
      languageValue:["en-us"],
      languageValueList:[],
      priceValue: [],
      durationValue: "",
      duration: {},
      bookmarkStatus: true,
      scrollThreshold: "200px",
      enrollNowButton: true,
    };
  }
  componentWillMount() {
    //console.log(prevProps.trainingType, this.state.trainingType);
    var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    console.log({ browserZoomLevel });
    if (browserZoomLevel > 67) {
      this.setState({ scrollThreshold: "200px" });
    } else {
      this.setState({ scrollThreshold: "-100px" });
    }
    this.initialApiCall();

    Model.fetchTrainingCategory().then((response) => {
      var language = response.data.language||languageData;
      var subCategory = response.data.subCategory.filter((item) => item !== null && item !== "");
      subCategory.splice(0, 0, "All");
      var languageValueList = []
      for(let i = 0 ; i < language.length ; i++){
        languageValueList.push(language[i].value)
      }
      console.log({language})
      this.setState({
        topicsList: subCategory,
        languageList: language,
        languageValueList
      });
    });
  }

  initialApiCall = () => {
    var self = this;
    const { trainingType } = self.props;
    const {languageValue} = this.state
    if (trainingType === "bookmarks") {
      Model.fetchBookmarks().then(function(response) {
        var trainings = [];
        if (response.data.length > 0) {
          trainings = response.data.filter((item) => item.trainingId !== null);
        }
        self.setState({
          allTrainings: trainings,
          loading: false,
          pagination: { totalRecords: response.totalRecords, page: 0 },
        });
      });
    } else {
      Model.fetchTrainingInfo(
        null,
        trainingType === "cscu" ? 3 : trainingType === "practice" ? 4 : "",
        0,
        this.state.trainingSubType,undefined,undefined,undefined,undefined,true,languageValue
      ).then(function(response) {
        //console.log({ response });
        self.setState({
          allTrainings: response.data,
          loading: false,
          pagination: { totalRecords: response.totalRecords, page: 0 },
        });
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.trainingType && prevProps.trainingType !== this.state.trainingType) {
      $.ajaxQ.abortAll();
      this.onClickCleanFilter();
      var self = this;
      self.setState({
        loading: true,
        allTrainings: [],
        trainingSubType: "All",
        searchText: "",
        category: "All",
      });
      const { trainingType } = self.props;
      Model.fetchTrainingInfo(null, trainingType === "cscu" ? 3 : trainingType === "practice" ? 4 : "", 0,undefined,undefined,undefined,undefined,undefined,true).then(
        function(response) {
          //console.log({ response });
          self.setState({
            allTrainings: response.data,
            pagination: {
              totalRecords: response.totalRecords,
              page: 0,
            },
            loading: false,
          });
        }
      );
    }
  }

  loadMore = () => {
    var self = this;
    var { pagination, allTrainings, topicValue, categoryValue, priceValue, duration, searchText, languageValue } = this.state;
    const { trainingType } = this.props;

    self.setState({
      loading: true,
    });
    if (trainingType === "bookmarks") {
      Model.fetchBookmarks(pagination.page + 1, searchText).then(function(response) {
        //console.log({ response });
        var trainings = [];
        if (response.data.length > 0) {
          trainings = response.data.filter((item) => item.trainingId !== null);
        }
        self.setState({
          allTrainings: allTrainings.concat(trainings),
          loading: false,
          pagination: {
            page: pagination.page + 1,
            totalRecords: response.totalRecords,
          },
        });
      });
    } else {
      Model.fetchTrainingInfo(
        null,
        trainingType === "cscu" ? 3 : trainingType === "practice" ? 4 : "",
        pagination.page + 1,
        searchText,
        topicValue,
        categoryValue,
        priceValue,
        duration,true,
        languageValue
      ).then((response) => {
        self.setState({
          allTrainings: allTrainings.concat(response.data),
          pagination: {
            page: pagination.page + 1,
            totalRecords: response.totalRecords,
          },
          loading: false,
        });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    //console.log({ nextProps });
    var self = this;
    self.setState({ trainingType: nextProps.trainingType });
  }

  enrollAll = () => {
    var self = this;
    const services = store.get("user").organisationId.services;

    var trainings = self.state.allTrainings;
    if (!services.includes("cscu")) {
      trainings = trainings.filter(
        (training) =>
          training.trainingType === 3 && !(training.enrolled && training.enrolled.length > 0) && training.isPurchase
      );
    }

    //console.log({ trainings });
    self.setState({ loading: true });
    trainingModel.assignTrainingInBulk(trainings).then((response) => {
      self.setState({ loading: false });
      store.set("notificationCount", parseInt(store.get("notificationCount")) + response.data.length);
      var notifications = store.get("notifications");
      notifications = notifications.concat(response.data.reverse());
      store.set("notifications", notifications);

      var event = new CustomEvent("notification", {
        detail: {
          notifications: response.data,
        },
      });
      document.dispatchEvent(event);
      this.initialApiCall();
      message.success(response.userMessage);
    });
  };
  onClickCleanFilter = () => {
    this.setState({
      topicValue: [],
      categoryValue: [],
      languageValue:[],
      priceValue: [],
      durationValue: "",
      duration: {},
    });
  };
  onApplyFilter = (e) => {
    var self = this;
    const { trainingType } = self.props;
    var { searchText, topicValue, categoryValue, priceValue, duration,languageValue } = self.state;

    $.ajaxQ.abortAll();
    self.setState({ loading: true, allTrainings: [] });
    Model.fetchTrainingInfo(
      null,
      trainingType === "cscu" ? 3 : trainingType === "practice" ? 4 : "",
      0,
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration,true,languageValue
    ).then(function(response) {
      //console.log({ response });
      self.setState({
        allTrainings: response.data,
        pagination: {
          totalRecords: response.totalRecords,
          page: 0,
        },
        loading: false,
      });
    });
  };
  bookmarksAction(action, index, info) {
    var self = this;
    const { trainingType } = self.props;
    var allTrainings = self.state.allTrainings;

    $.ajaxQ.abortAll();
    self.setState({ bookmarkStatus: false });

    Model.actionBookmarks(action, info._id).then(function(response) {
      if (trainingType === "bookmarks") {
        if (action === "remove") {
          if (allTrainings.length > 1) {
            allTrainings.splice(index, 1);
          } else {
            allTrainings = [];
          }
          self.setState({ allTrainings, bookmarkStatus: true });
        }
      } else {
        if (action === "remove") {
          info["bookmark"] = false;
          info["totalBookmarks"] = info["totalBookmarks"] - 1;
          allTrainings[index] = info;
          self.setState({ allTrainings, bookmarkStatus: true });
        } else {
          info["bookmark"] = true;
          info["totalBookmarks"] = info["totalBookmarks"] + 1;
          allTrainings[index] = info;
          self.setState({ allTrainings, bookmarkStatus: true });
        }
      }
    });
  }

  displayTime(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? (h > 9 ? h + ":" : "0" + h + ":") : "";
    var mDisplay = m > 0 ? (m > 9 ? m + ":" : "0" + m + ":") : "00:";
    var sDisplay = s > 0 ? (s > 9 ? s : "0" + s) : "00";
    return hDisplay + mDisplay + sDisplay;
  }

  displayPrice(price) {
    return parseFloat(price).toFixed(2);
  }

  onChangeText = (e) => {
    let searchText = e.target.value;
    this.onSearchText(searchText);
  };

  onSearchText = (searchText) => {
    this.setState({ searchText });
    var { topicValue, categoryValue, priceValue, duration, languageValue } = this.state;
    var self = this;
    var { trainingType } = this.props;
    self.setState({ loading: true, allTrainings: [] });
    $.ajaxQ.abortAll();

    if (trainingType === "bookmarks") {
      Model.fetchBookmarks(0, searchText).then(function(response) {
        self.setState({
          allTrainings: response.data,
          pagination: {
            totalRecords: response.totalRecords,
            page: 0,
          },
          loading: false,
        });
      });
    } else {
      Model.fetchTrainingInfo(
        null,
        trainingType === "cscu" ? 3 : trainingType === "practice" ? 4 : "",
        0,
        searchText,
        topicValue,
        categoryValue,
        priceValue,
        duration,
        true,
        languageValue
      ).then(function(response) {
        self.setState({
          allTrainings: response.data,
          pagination: {
            totalRecords: response.totalRecords,
            page: 0,
          },
          loading: false,
        });
      });
    }
  };

  onClickAddToCart = (training) => {
    var storeTraining = store.get("myCart");
    if (!storeTraining.some((info) => info.trainingId._id === training._id)) {
      CartModel.actionCart(training._id, "add").then((response) => {
        if (!response.error) {
          let trainerInfo = {};
          trainerInfo["trainingId"] = training;
          storeTraining.push(trainerInfo);
          store.set("myCart", storeTraining);
          var event = new CustomEvent("myCart", {
            detail: { storeTraining },
          });
          document.dispatchEvent(event);
          message.success(response.userMessage);
        } else {
          message.error(response.userMessage);
        }
      });
    } else {
      message.success("Added to Cart");
    }
  };
  render() {
    var { trainingType, pagination } = this.props;
    const totalT = this.state.allTrainings;
    pagination = this.state.pagination;
    //console.log({ totalT }, this.state.loading);

    var disabled = {
      pointerEvents: "none",
      cursor: "default",
    };
    var self = this;
    const organisationId = store.get("user").organisationId;
    const services = store.get("user").organisationId.services;
    const isCscuServiceAvailable = services.includes("cscu");
    const enrollNow = (trainingId, item) => {
      self.setState({ enrollNowButton: false });
      if (organisationId.isSelfAssign) {
        // if (true) {
        trainingModel.assignTraining([store.get("user")], trainingId).then((response) => {
          if (response.error) {
            message.error(response.userMessage);
            self.setState({ enrollNowButton: true });
          } else {
            store.set("notificationCount", parseInt(store.get("notificationCount")) + 1);
            var event = new CustomEvent("notification", {
              detail: {
                notifications: response.data,
                status: 0,
              },
            });
            document.dispatchEvent(event);
            message.success(
              response.userMessage,
              (onclose = function() {
                self.props.history.push(
                  trainingType == "Practice"
                    ? "/trainee/course-dhashboard/" + item._id + "/" + trainingType
                    : "/trainee/course-intro/" + item._id + "/" + trainingType,
                  { trainingInfoProps: item }
                );
              })
            );
          }
        });
      } else {
        trainingModel.trainingRequest(trainingId).then((response) => {
          if (response.error) {
            message.error(response.userMessage);
            self.setState({ enrollNowButton: true });
          } else {
            message.success(
              response.userMessage,
              (onclose = function() {
                self.props.history.push(
                  trainingType == "Practice"
                    ? "/trainee/course-dhashboard/" + item._id + "/" + trainingType
                    : "/trainee/course-intro/" + item._id + "/" + trainingType,
                  { trainingInfoProps: item }
                );
              })
            );
          }
        });
      }
    };

    const trainingFilterProps = {
      topicsList: this.state.topicsList,
      categoryList: categoryList,
      pricesList: pricesList,
      durationsList: durationsList,
      languageList: this.state.languageList, 
      onChangeLanguage: (languageValue) => {
        console.log({ languageValue });
        if (
          this.state.languageValue &&
          this.state.languageValue.includes("all") &&
          languageValue &&
          !languageValue.includes("all")
        ) {
          this.setState({ languageValue: [] });
        } else {
          if (languageValue && languageValue.includes("all")) { 
            this.setState({ languageValue: this.state.languageValueList });
          } else {
            this.setState({ languageValue });
          }
        }
      },
      onChangeTopic: (topicValue) => {
        //console.log({ topicValue });
        if (
          this.state.topicValue &&
          this.state.topicValue.includes("all") &&
          topicValue &&
          !topicValue.includes("all")
        ) {
          this.setState({ topicValue: [] });
        } else {
          if (topicValue && topicValue.includes("all")) {
            this.setState({ topicValue: this.state.topicsList });
          } else {
            this.setState({ topicValue });
          }
        }
      },
      onChangeCategory: (categoryValue) => {
        //console.log({ categoryValue });
        if (
          this.state.categoryValue &&
          this.state.categoryValue.includes(0) &&
          categoryValue &&
          !categoryValue.includes(0)
        ) {
          this.setState({ categoryValue: [] });
        } else {
          if (categoryValue && categoryValue.includes(0)) {
            this.setState({ categoryValue: [0, 2, 1] });
          } else {
            this.setState({ categoryValue });
          }
        }
      },
      onChangePrice: (priceValue) => {
        //console.log({ priceValue });
        if (priceValue && !priceValue.includes("All") && this.state.priceValue.includes("All")) {
          this.setState({ priceValue: [] });
        } else {
          if (priceValue && priceValue.includes("All")) {
            this.setState({ priceValue: pricesList });
          } else {
            this.setState({ priceValue });
          }
        }
      },
      onChangeDuration: (durationValue) => {
        //console.log({ durationValue });
        this.setState({ durationValue });
        var duration =
          durationValue === 1
            ? { gte: 0, lte: 600 }
            : durationValue === 2
            ? { gte: 600, lte: 900 }
            : durationValue === 3
            ? { gte: 900, lte: 1800 }
            : durationValue === 4
            ? { gte: 1800 }
            : undefined;

        this.setState({ duration });
      },
      onApplyFilter: this.onApplyFilter,
      topicValue: this.state.topicValue,
      categoryValue: this.state.categoryValue,
      languageValue: this.state.languageValue,
      priceValue: this.state.priceValue,
      durationValue: this.state.durationValue,
      trainingType: trainingType,
      searchText: this.state.searchText,
      onSearchText: this.onSearchText,
      onChangeText: this.onChangeText,
      isCscuServiceAvailable: isCscuServiceAvailable,
      allTrainings: this.state.allTrainings,
      enrollAll: this.enrollAll,
      loading: this.state.loading,
    };
    return (
      <div className="uk-container">
        <div className="uk-clearfix boundary-align">
          <TrainingFilter {...trainingFilterProps} />
          {this.state.loader && (
            <div
              style={{
                position: "fixed",
                zIndex: "+99",
                left: "50%",
                top: "35%",
              }}
            >
              <CircularProgress></CircularProgress>
            </div>
          )}
        </div>
        <div className="uk-clearfix boundary-align">
          {this.state.allTrainings.length > 0 ? (
            <InfiniteScroll
              dataLength={this.state.allTrainings.length / 9}
              next={this.loadMore}
              scrollThreshold={this.state.scrollThreshold}
              hasMore={pagination.page + 1 * 9 < pagination.totalRecords}
              loader={self.state.loading ? <CircularProgress></CircularProgress> : ""}
            >
              <div
                className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                data-uk-grid
              >
                {this.state.allTrainings.map((item, index) => {
                  //console.log({ item });
                  item = trainingType === "bookmarks" ? item.trainingId : item;
                  item["bookmark"] = trainingType === "bookmarks" ? true : item.bookmark;
                  console.log({item})
                  return (
                    <div key={"allTrainings" + index}>
                      <div
                        className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
                        tabindex="0"
                      >
                        {/**set price */}
                        {item.price && item.price !== 0 ? (
                          <div
                            // onClick={() => viewTraining(index, data._id)}
                            className=" uk-position-top-left uk-padding-small uk-padding-remove-left uk-position-z-index"
                            style={{ width: "100px", cursor: "pointer" }}
                          >
                            <img src={require("assets/images/training/price_tag_image.png")} />
                            <strong
                              style={{
                                position: "absolute",
                                left: "15px",
                                bottom: "16px",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {item.price && item.price !== 0 ? "$" + this.displayPrice(item.price) : " "}
                            </strong>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* <div
                          className="  uk-position-top-left uk-position-z-index"
                          style={{ margin: "10px" }}
                        >
                          {!(
                            isCscuServiceAvailable && item.trainingType == 3
                          ) &&
                            !item.isPurchase &&
                            !item.isFree &&
                            item.trainingType !== 4 && (
                              <img
                                className="Course-tags uk-margin-small-right"
                                src={require("assets/images/lock_icon.png")}
                                style={{
                                  borderRadius: "27px"
                                  // backgroundColor: '#000000',
                                  // opacity: '0.3',
                                  // boxShadow: "0 2px 15px rgba(0, 0, 0, 0.14)"
                                }}
                              />
                            )}
                        </div> */}

                        <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                          {/* <a className="uk-button course-badge" href="#"> <i className="fas fa-heart ic-icon-medium"></i> </a>  */}
                          <a
                            className="uk-button course-badge"
                            style={this.state.bookmarkStatus ? {} : disabled}
                            onClick={() => this.bookmarksAction(item.bookmark ? "remove" : "add", index, item)}
                          >
                            {item.bookmark ? (
                              <i className="fas fa-heart ic-icon-medium " style={{ color: "#1e87f0" }}></i>
                            ) : (
                              <i className="fas fa-heart ic-icon-medium "></i>
                            )}
                          </a>
                          {store.get("user").trial && item.isPurchase ? "trial user" : null}
                          {!(  item.trainingRequest && item.trainingRequest._id )
                             ? (
                            <>
                              {((store.get("user").trial && item.isPurchase) ||
                                true ||
                                // item.isFree ||
                                item.trainingType == 4 ||
                                (!store.get("user").trial && isCscuServiceAvailable && item.trainingType == 3) ||
                                item.isPurchase) &&
                              !(item.enrolled && item.enrolled.length > 0) ? (
                                <a
                                  className="uk-button course-badge uk-button-primary"
                                  style={{ marginLeft: 10 }}
                                  onClick={() => {
                                    if (store.get("user").trial && !item.isFree && !item.trainingType == 4) {
                                      message.error("Please upgrade your plan to start training", 5);
                                    } else {
                                      enrollNow(item._id, item);
                                    }
                                  }}
                                  disabled={this.state.enrollNowButton ? false : true}
                                >
                                  {"Enroll Now"}
                                </a>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {  item.trainingRequest && 
                            item.trainingRequest.rejected
                            ?   <a
                            data-uk-tooltip="title:  Request is declined by Admin  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                 
                            className="uk-button course-badge uk-button-primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              if (store.get("user").trial && !item.isFree && !item.trainingType == 4) {
                                message.error("Please upgrade your plan to start training", 5);
                              } else {
                                enrollNow(item._id, item);
                              }
                            }}
                          >
                            {"Enroll Again"}
                          </a>:
                          <>
                             {  !(item.trainingRequest && 
                              item.trainingRequest.approved)
                              ? <a 
                              className="uk-button course-badge uk-button-primary"
                              style={{ marginLeft: 10 }}
                              disabled
                               
                            >
                              Request Sent 
                            </a>:""}
                            </>
                            }
                            </>
                          )}

                          {/* {store.get("user").trial &&
                          item.isPurchase &&
                          item.isFree ? (
                            // ||
                            // item.trainingType == 4 ||
                            // (!store.get("user").trial &&
                            //   isCscuServiceAvailable &&
                            //   item.trainingType == 3) ||
                            // item.isPurchase
                            <a
                              className="uk-button course-badge uk-button-primary"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                this.onClickAddToCart(item);
                              }}
                            >
                              Add to Cart
                            </a>
                          ) : (
                            ""
                          )} */}
                        </div>

                        <Link
                          // to={
                          //   trainingType == "Practice"
                          //     ? "/trainee/course-dhashboard/" +
                          //       item._id +
                          //       "/" +
                          //       trainingType
                          //     : "/trainee/course-intro/" +
                          //       item._id +
                          //       "/" +
                          //       trainingType
                          // }
                          to={{
                            pathname:
                              trainingType == "Practice"
                                ? "/trainee/course-dhashboard/" + item._id + "/" + trainingType
                                : "/trainee/course-intro/" + item._id + "/" + trainingType,
                            trainingInfoProps: item,
                          }}
                          className="uk-link-reset"
                        >
                          <img
                            src={
                              item.coverImage
                                ? `${imagePrefixUrl}${item.coverImage}`
                                : require("assets/images/courses/cover_image.jpg")
                            }
                            className="course-img"
                          />

                          <div className="uk-clearfix" style={{ position: "absolute", right: "10px" }}>
                            <div className="uk-float-right" style={{ position: "relative", bottom: "40px" }}>
                              {item.trainingType !== 4 && item.duration  ? (
                                <span
                                  className="Course-tags "
                                  style={{
                                    borderRadius: "27px",
                                    background: "#fdfdfd",
                                    padding: "5px 18px",
                                    opacity: "0.8",
                                    fontWeight: "600",
                                    color: "rgb(255, 255, 255)",
                                    border: "1px solid rgb(0, 0, 0)",
                                    background: "rgb(0, 0, 0)",
                                    boxShadow: "0 2px 15px rgba(0, 0, 0, 0.14)",
                                  }}
                                >
                                  {item.duration ? this.displayTime(item.duration) : "00:00"}
                                </span>
                              ):""}
                            </div>
                          </div>
                          {/* <div
                            className="uk-clearfix"
                            style={{ margin: "0 10px" }}
                          >
                            {item.hasAssessment && (
                              <div
                                className="uk-float-left"
                                style={{
                                  position: "absolute",
                                  top: "157px",
                                  backgroundColor: "#ffffff00",
                                  zIndex: "+999"
                                }}
                              >
                                <img
                                  className="Course-tags uk-margin-small-right"
                                  src={require("assets/images/quiz_icon.png")}
                                  style={{
                                    borderRadius: "27px"
                                  }}
                                />
                              </div>
                            )} 
                          </div> */}

                          <div className="uk-card-body">
                            <h4 className={item.language == "ar"?"rtl-text-right":""}>{item.name ? item.name : " "}</h4>

                            <div className="uk-clearfix">
                              <div
                                className="uk-float-left"
                                // onClick={() => viewTraining(index, item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <p style={{ marginBottom: "12px" }}>{`${
                                  item.authorName && item.authorName !=="" ? item.authorName : ""
                                }`}</p>
                              </div>

                              <div className="uk-float-right ">
                                {/* <div
                                    style={{
                                      position: "absolute",
                                      right: "70px",
                                      cursor: "pointer"
                                    }}
                                  >
                                    <img
                                      onClick={() => editTraining(data)}
                                      src={require("assets/images/training/edit_icon.png")}
                                    ></img>
                                  </div>
                                   */}
                                <div
                                  style={{
                                    position: "relative",
                                    cursor: "default",
                                  }}
                                >
                                  {/* <img
                                    // onClick={() => showModal(data, index)}
                                    src={require("assets/images/training/delete_icon.png")}
                                  ></img> */}
                                  {!(isCscuServiceAvailable && item.trainingType == 3) &&
                                    !item.isPurchase &&
                                    !true &&
                                    // !item.isFree &&
                                    item.trainingType !== 4 && (
                                      <img
                                        // className="Course-tags uk-margin-small-right"
                                        src={require("assets/images/lock_icon.png")}
                                        style={
                                          {
                                            // borderRadius: "27px"
                                            // backgroundColor: '#000000',
                                            // opacity: '0.3',
                                            // boxShadow: "0 2px 15px rgba(0, 0, 0, 0.14)"
                                          }
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            {/* <div
                              className="uk-clearfix"
                              style={{ marginTop: "5px" }}
                            > */}
                            {/* <div className="uk-float-left">
                                <div
                                  className="uk-float-left"
                                  // onClick={() => viewTraining(index, data._id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <h4 style={{ color: "#8e8a84" }}>
                                    {item.authorName ? item.authorName : " "}
                                  </h4>
                                </div>
                              </div> */}
                            {/* <div className="uk-float-right ">
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "70px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <a>
                                    <img
                                      src={require("assets/images/training/share_icon.png")}
                                    ></img>
                                  </a>
                                </div>
                              </div> */}
                            {/* <div className="uk-float-right">
                                <h4>
                                  {item.price
                                    ? "$" + this.displayPrice(item.price)
                                    : " "}
                                </h4>
                              </div> */}
                            {/* </div> */}
                            <p style={{ marginTop: "0px", textAlign: "justify" }} className={item.language == "ar"?"rtl-text-right":""}>
                              {item.shortDescription ? item.shortDescription : ""}
                            </p>
                            {item.hasAssessment && <hr className="uk-margin-remove" />}
                            <div className="uk-clearfix " style={{ padding: "10px", cursor: "default" }}>
                              {item.hasAssessment && (
                                <div className="uk-float-left uk-margin-right">
                                  <span
                                    className="Course-tags"
                                    style={{
                                      borderRadius: "4px",
                                      background: "rgb(237, 239, 240)",
                                      padding: "4px 10px",
                                    }}
                                  >
                                    <img
                                      src={require("assets/images/training/quiz_icon.png")}
                                      style={{
                                        width: "20px",
                                        padding: "0 5px 5px 0",
                                      }}
                                    />
                                    Quiz
                                  </span>
                                </div>
                              )}
                              {/* <div className="uk-float-left uk-margin-right">
                                <span
                                  className="Course-tags uk-margin-small-right"
                                  style={{
                                    borderRadius: "4px",
                                    background: "rgb(237, 239, 240)",
                                    padding: "4px 10px"
                                  }}
                                >
                                  <img
                                    src={require("assets/images/training/bookmark_icon.png")}
                                    style={{
                                      width: "20px",
                                      padding: "0 8px 5px 0"
                                    }}
                                  />
                                  {item.totalBookmarks
                                    ? item.totalBookmarks
                                    : 0}
                                </span>
                              </div>
                             */}
                            </div>

                            <hr className="uk-margin-remove-top" />
                            <div
                              className="uk-clearfix uk-margin-remove"
                              style={{
                                cursor: "default",
                              }}
                            >
                              <ul
                                style={{
                                  listStyleType: "none",
                                  margin: 0,
                                  padding: 0,
                                  cursor: "default",
                                }}
                              >
                                {item.trainingSubType && item.trainingSubType != "" && (
                                  <li
                                    style={{
                                      padding: "8px 0",
                                      margin: "4px 0",
                                      float: "left",
                                    }}
                                  >
                                    <a
                                      className="Course-tags  "
                                      style={{
                                        borderRadius: "27px",
                                        background: "#fdfdfd",
                                        padding: "8px 18px",
                                        color: "#464748",
                                        border: "1px solid rgb(232, 229, 229)",
                                      }}
                                    >
                                      {" "}
                                      {item.trainingSubType}{" "}
                                    </a>
                                  </li>
                                )}

                                {item.trainingType && false && (
                                  <li
                                    style={{
                                      padding: "8px 0",
                                      margin: "4px 0",
                                      float: "right",
                                      // border: '1px solid rgb(232, 229, 229)'
                                    }}
                                  >
                                    <span
                                      className="Course-tags  uk-text-white"
                                      style={{
                                        borderRadius: "27px",
                                        padding: "8px 18px",
                                        background:
                                          item.trainingType == "1" || item.trainingType == "3"
                                            ? "rgba(224, 153, 66, 0.64)"
                                            : "rgba(78, 72, 64, 0.64)",
                                        color: "#ffffff !important",
                                      }}
                                    >
                                      {" "}
                                      {item.trainingType == "1" || item.trainingType == "3" ? "Premium" : "Basic"}
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </Link>
                        {/* <div className="uk-card-footer">
                          <Button type="primary" style={{marginBottom:0,width:'100%'}}>Enroll</Button>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div>{self.state.loading?"Loading More Trainings": pagination.totalRecords>self.state.allTrainings.length?"Load more":""}</div> */}
            </InfiniteScroll>
          ) : (
            <div>
              {self.state.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <div
                  style={{
                    marginLeft: "10px",
                    minHeight: "90vh",
                  }}
                  className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                  data-uk-grid
                >
                  <div className="uk-child-width-1-4@m"></div>
                  <div className="uk-child-width-2-4@m uk-text-center uk-margin">
                    <div className="uk-text-center uk-margin">
                      <div style={{ padding: "0 20%" }}>
                        <img src={require("assets/images/no_data_found.png")} />
                      </div>
                      <h3 className="uk-text-center " style={{ margin: "20px 0 0" }}>
                        Sorry!! No Data Found.
                      </h3>
                      <p className="uk-text-center uk-margin-remove">Try somethings else.</p>
                    </div>
                  </div>
                  <div className="uk-child-width-1-4@m"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(trainingType);
