import axios from "axios";
import { apiUrl } from "./../../util/config";

export const requestDemo = payload => dispatch => {
  dispatch({
    type: "SUBMITTING_DEMO_REQUEST"
  });
  payload.preferredDateTime = payload.preferredDateTime ? payload.preferredDateTime.toDate() : null;
  axios
    .post(`${apiUrl}api/lms-demo-request`, {
      ...payload
    })
    .then(response => {
      console.log(response);
      dispatch({
        type: "DEMO_REQUEST_DONE",
        payload: response.data
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: "DEMO_REQUEST_DONE",
        payload: {
          error: true,
          userMessage: "Some server error has occurred."
        }
      });
    });
};
