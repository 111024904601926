import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { Col, Row } from "antd";

import Widget from "components/Widget/index";

import "./index.less";
const TrafficRaiseCard = info => {
  const { reportName, reportId } = info;
  var data = [];
  if (reportId === "report_two") {
    data = [
      { name: "Page A", traffic: 10 },
      { name: "Page B", traffic: 20 },
      { name: "Page C", traffic: 30 },
      { name: "Page D", traffic: 40 },
      { name: "Page D", traffic: 100 },
      { name: "Page D", traffic: 200 },
      { name: "Page D", traffic: 300 }
    ];
  } else {
    data = [
      { name: "Page A", traffic: 10 },
      { name: "Page B", traffic: 40 },
      { name: "Page C", traffic: 30 },
      { name: "Page D", traffic: 20 },
      { name: "Page D", traffic: 80 },
      { name: "Page D", traffic: 70 },
      { name: "Page D", traffic: 150 }
    ];
  }
  return (
    <div id={reportId}>
      <Widget>
        <Row className="gx-align-items-center">
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className="gx-actchart gx-px-3">
              <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-white">
                07% <i className="icon icon-menu-up gx-fs-sm" />
              </h2>
              <p className="gx-mb-0">{reportName}</p>
            </div>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ResponsiveContainer width="100%" height={100}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              >
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="traffic"
                  stroke="#ffffff"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Widget>
    </div>
  );
};

export default TrafficRaiseCard;
