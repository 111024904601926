import { apiUrl } from "util/config";
import { message, Modal } from "antd";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const { confirm } = Modal;
var Actions = {
  fatchCampaignUserAnalytics: campaignId => {
    return new Promise((resolve, reject) => {
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/campaign_users/analytics",
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          campaignId: campaignId
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  downloadDoc: campaignId => {
    //  window.open(apiUrl + "api/trainings/" + campaignId + "/doc_report");
    return new Promise((resolve, reject) => {
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/trainings/" + campaignId + "/doc_report",//lms_doc_report",
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  sendReminder: (campaignId, type) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        async: true,
        crossDomain: true,
        url:
          apiUrl + "api/trainings/" + campaignId + "/expiry?mailType=" + type,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            if (response.data != null) {
              confirm({
                title: "Confirm?",
                content: `This will trigger ${response.data} ${
                  response.data == 1 ? "email" : "emails"
                }. Are you sure you want to trigger this event?`,
                okText: 'Yes',
                cancelText: 'No',
                onOk() {
                  $.ajax({
                    async: true,
                    crossDomain: true,
                    url: apiUrl + "api/trainings/" + campaignId + "/action",
                    method: "POST",
                    headers: {
                      Authorization:
                        "Bearer " + localStorage.getItem("accessToken")
                    },
                    data: { mailType: type },
                    success: function(response) {
                      if (response.error) {
                        message.error(response.userMessage);
                      } else {
                        message.success(response.userMessage);
                      }
                    }
                  });
                },
                onCancel() {
                  reject(null);
                }
              });

              // confirmAlert({
              //   title: "Confirm?",
              //   message: `This will trigger ${response.data} ${
              //     response.data == 1 ? "email" : "emails"
              //   }. Are you sure you want to trigger this event?`,
              //   buttons: [
              //     {
              //       label: "Yes",
              //       onClick: () => {
              //         // var form = new FormData();
              //         // form.append("mailType", type);

              //         $.ajax({
              //           async: true,
              //           crossDomain: true,
              //           url: apiUrl + "api/trainings/" + campaignId + "/action",
              //           method: "POST",
              //           headers: {
              //             Authorization:
              //               "Bearer " + localStorage.getItem("accessToken")
              //           },
              //           data: { mailType: type },
              //           success: function(response) {
              //             if (response.error) {
              //               message.error(response.userMessage);
              //             } else {
              //               message.success(response.userMessage);
              //             }
              //           }
              //         });
              //         // $.ajax(settings2).done();
              //       }
              //     },
              //     {
              //       label: "No",
              //       onClick: () => {}
              //     }
              //   ]
              // });
            } else {
              message.error(response.userMessage);
            }
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        }
      });

      // $.ajax(settings).done();
    });
  },
  downloadCerificate: (campaignId, traineeId, trainingName) => {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.open(
        "GET",
        apiUrl +
          "api/trainings/" +
          campaignId +
          "/download?traineeId=" +
          traineeId,
        true
      );
      request.responseType = "blob";
      request.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("accessToken")
      );
      // request.setRequestHeader("fngprt", m.cookie.get("fngprt"));
      request.onload = function(e) {
        // $(e.target).removeClass("loading");
        if (this.status === 200) {
          var file = window.URL.createObjectURL(this.response);
          var a = document.createElement("a");
          a.href = file;
          a.download = trainingName + ".pdf";
          document.body.appendChild(a);
          a.click();
          resolve("done");
        }
      };

      request.send();
    });
  }
};

export default Actions;
