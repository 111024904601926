import React, { PureComponent } from "react";

import { Row, Col, Upload, Button, Icon } from "antd";
import styles from "./trainingForm.less";
import { imagePrefixUrl } from "util/config";

const { Dragger } = Upload;

class UploadVideo extends PureComponent {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    current: 0,
    visible: false,
    videoUrl: this.props.trainingVideoUrl
  };

  onUploadVideoClose = () => {
    const { onUploadVideoClose } = this.props;
    this.setState({
      videoUrl: ""
    });
    onUploadVideoClose();
  };
  render() {
    const {
      uploadProps,
      trainingVideoUrl,
      state,
      onClickNext,
      onClickBack,
      onUploadVideoClose,
      type
    } = this.props;
    const { videoUrl } = this.state;
    // console.log("-----",{state})
    // var videoUrl = (typeof training.videoUrl !== "undefined") ? training.videoUrl: state.videoUrlUrl;
    console.log("-----", { uploadProps });
    console.log("---@@--", { trainingVideoUrl,videoUrl });
    console.log(typeof trainingVideoUrl === "undefined",trainingVideoUrl === "",videoUrl === "")
    return (
      <div>
        <Row>
          <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            {typeof trainingVideoUrl === "undefined" ||
            trainingVideoUrl === ""   ? (
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="play-square" />
                </p>
                <p className={styles.uploadText}>
                  Click or drag video to this area to upload video
                </p>
              </Dragger>
            ) : (
              <div className={styles.imageContainer}>
                {/* <img
                  className={styles.imagePreview}
                  src={`${imagePrefixUrl}${training.videoUrl}`}
                  alt="preview"
                  style={{width:'100%',height:300,objectFit:'cover'}}
                /> */}

                <video
                  // muted
                  // playsinline
                  controls
                  data-uk-video="autoplay: false"
                  height="200"
                  style={{height:200}}
                >
                  <source
                    src={
                      trainingVideoUrl && !(type=="youtube"||type=="vimeo")
                        ? `${imagePrefixUrl}${trainingVideoUrl}`
                        :type=="youtube" ||type=="vimeo"?trainingVideoUrl: "videos/course-intro.mp4"
                    }
                    type="video/mp4"
                  />
                </video>
                <Button
                  style={{ position: "absolute",
                    top: 0 }}
                  className="closeBtn"
                  shape="circle"
                  icon="close"
                  onClick={this.onUploadVideoClose}
                />
              </div>
            )}
          </Col>
        </Row> 
      </div>
    );
  }
}

export default UploadVideo;
