import React, { Component } from "react";
import App from "./../../routes/traineeRoute";
import { connect } from "react-redux";
import Footer from "./../../components/Footer";
import SideNavbar from "../../routes/traineeMain/components/SideNavbar";
// import { withTranslation } from "react-i18next";

import "./../../assets/css/main.css";
import "./../../assets/css/uikit.css";
import "./../../assets/css/fontawesome.css";

export class TraineeMainApp extends Component {
  componentDidMount() {
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    if (!user) {
      // this.props.history.push("/home");
      this.props.history.push("/signin");
    } else if (!user.trainee) {
      this.props.history.push("/main/dashboard/crm");
    }
  }
  render() {
    const { match, location, navStyle } = this.props;
    const { pathname } = location;
    var path = pathname.split("/");
    if (path.length > 1) {
      path = path[1] + "/" + path[2];
    }
    return (
      <div className="app" style={{ backgroundColor: "#edeff0" }}>
        <SideNavbar />
        <App match={match} i18n={this.props.i18n} />
        {path != "trainee/play" && <Footer {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
};

// const TransApp = withTranslation()(connect(mapStateToProps)(TraineeMainApp))


export default connect(mapStateToProps)(TraineeMainApp);
