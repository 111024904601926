import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";
import { Col, Row } from "antd";
import { withRouter } from "react-router-dom";
import TrainingsCard from "./components/TrainingsCard";
import ModalDelete from "./components/modalDelete";
import ModelTraining from "../../traineeMain/model";
import { connect } from "react-redux";

import { durationsList, pricesList, categoryList } from "./data.js";
import TrainingFilter from "./components/trainingFilter.js";
import InfiniteScroll from "react-infinite-scroll-component";
import { imagePrefixUrl } from "util/config";
import {
  hideMessage,
  showMessage,
  getAllTraining,
  trainingOnUpdate,
  deleteTraining,
  showModal,
  hideModal,
  showLoader
} from "./../../../appRedux/actions/Training";
import CircularProgress from "components/CircularProgress/index";

import { Pagination } from "antd";

import $ from "jquery";
var languageData = [
  { label: "All", value: "all" },
  { label: "English(United States)", value: "en-us" },
  { label: "English(Australia)", value: "en-au" },
  { label: "Bahasa Malaysia", value: "myb" },
  { label: "Arabic", value: "ar" },
  { label: "Canada(French)", value: "fr-CA" }
]
class Trainings extends React.Component {
  state = {
    campType: 0,
    searchText: "",
    deleteTrainingInfo: {},
    allTrainings: [],
    subCategory: "All",
    pagination: {
      totalRecords: 0,
      page: 0
    },
    loading: true,
    topicsList: ["All"],
    category: "All",
    categoryIndex: 0,
    loader: false,
    categoryIndex: 0,
    searchText: "",
    category: "All",
    topicsList: [],
    topicValue: [],
    categoryValue: [],
    priceValue: [],
    durationValue: "",
    duration: {},
    languageValue:["en-us"],
    languageValueList:[],
    languageList:[],
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    const { match } = this.props;
    var page =
      match.params.page && match.params.page !== 0 ? match.params.page - 1 : 0;
    this.callTrainingApi(page);
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
    const { match } = this.props;
    console.log({ match });
    var page =
      match.params.page && match.params.page !== 0 ? match.params.page - 1 : 0;
    console.log({ match }, this.state.pagination.page);
    if (
      match.path === "/trainings/trainings" &&
      this.state.pagination.page !== 0
    ) {
      this.callTrainingApi(0);
    }
  }
  callTrainingApi(page) {
    const {languageValue} = this.state;
    var self = this;
    ModelTraining.fetchTrainingInfo(null, null, page,null,null,null,null,null, null,languageValue).then(response => {
      self.setState({
        allTrainings: response.data,
        pagination: { page: page, totalRecords: response.totalRecords },
        loading: false
      });
    });
    ModelTraining.fetchTrainingCategory().then(response => {
      var language = response.data.language||languageData;
      var subCategory = response.data.subCategory.filter(
        item => item !== null && item !== ""
      );
      subCategory.splice(0, 0, "Select All");
      var languageValueList = []
      for(let i = 0 ; i < language.length ; i++){
        languageValueList.push(language[i].value)
      }
      console.log({language})
      self.setState({
        topicsList: subCategory,
        languageList: language,
        languageValueList
      });  
    });
  }

  loadMore = page => {
    window.scrollTo(0, 0);
    this.props.history.push("/trainings/trainings/" + page);
    var self = this;
    var {
      pagination,
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration,languageValue
    } = self.state;
    self.setState({ loading: true });
    $.ajaxQ.abortAll();
    ModelTraining.fetchTrainingInfo(
      null,
      null,
      page - 1,
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration, null,languageValue
    ).then(response => {
      self.setState({
        allTrainings: response.data,
        pagination: {
          page: page - 1,
          totalRecords: response.totalRecords
        },
        loading: false
      });
      window.scrollTo(0, 0);
    });
  };

  onClickCleanFilter = () => {
    this.setState({
      topicValue: [],
      categoryValue: [],
      priceValue: [],
      durationValue: "",
      duration: {}
    });
  };
  onApplyFilter = e => {
    var self = this;
    const { trainingType } = self.props;
    var {
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration,languageValue
    } = self.state;

    $.ajaxQ.abortAll();
    self.setState({ loading: true, allTrainings: [] });
    ModelTraining.fetchTrainingInfo(
      null,
      null,
      0,
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration, null,languageValue
    ).then(function(response) {
      self.setState({
        allTrainings: response.data,
        pagination: {
          totalRecords: response.totalRecords,
          page: 0
        },
        loading: false
      });
    });
  };

  onChangeText = e => {
    let searchText = e.target.value;
    this.onSearchText(searchText);
    if (e.target.value.trim() != "") {
      $("#search-expanding").addClass("active");
    } else {
      // $("#search-expanding input").addClass("");
      $("#search-expanding").removeClass("active");
    }
  };
  onSearchText = searchText => {
    this.setState({ searchText });
    var self = this;
    var { topicValue, categoryValue, priceValue, duration,languageValue } = this.state;
    self.setState({ loading: true, allTrainings: [] });
    $.ajaxQ.abortAll();
    ModelTraining.fetchTrainingInfo(
      null,
      null,
      0,
      searchText,
      topicValue,
      categoryValue,
      priceValue,
      duration,null,languageValue
    ).then(function(response) {
      self.setState({
        allTrainings: response.data,
        pagination: {
          totalRecords: response.totalRecords,
          page: 0
        },
        loading: false
      });
    });
  };
  render() {
    var self = this;
    var { allTraining, loader, deleteModalVisible } = this.props;
    var pagination = this.state.pagination;
    allTraining = self.state.allTrainings;
    loader = self.state.loading;
    const trainingCardProps = {
      editTraining: data => {
        data["steps"] = 1;
        this.props.trainingOnUpdate(data);
        this.props.history.push(`/trainings/${data._id}/update`);
      },
      viewTraining: (index, id) => {
        this.props.history.push(`/trainings/details/${id}`);
        // this.props.history.push(`/trainings/intro/${index}/${id}`);
      },
      showModal: (deleteTrainingInfo, index) => {
        deleteTrainingInfo["index"] = index;
        this.setState({ deleteTrainingInfo });
        this.props.hideMessage();
        this.props.showModal("deleteModal");
      }
    };
    const modalDeleteProps = {
      info: this.state.deleteTrainingInfo,
      modalVisible: deleteModalVisible,
      deleteTraining: () => {
        // this.child.close();
        var objInfo = {};
        objInfo["trainingId"] = this.state.deleteTrainingInfo._id;
        objInfo["index"] = this.state.deleteTrainingInfo.index;
        objInfo["allTraining"] = allTraining;
        this.props.showLoader();
        this.props.hideModal();
        this.props.deleteTraining(objInfo);
      },
      handleCancel: () => {
        // this.child.close();
        this.props.hideModal();
      }
    };

    const trainingFilterProps = {
      topicsList: this.state.topicsList,
      categoryList: categoryList,
      pricesList: pricesList,
      durationsList: durationsList, 
      languageValue: this.state.languageValue,
      languageList: this.state.languageList, 
      onChangeLanguage: (languageValue) => {
        console.log({ languageValue });
        if (
          this.state.languageValue &&
          this.state.languageValue.includes("all") &&
          languageValue &&
          !languageValue.includes("all")
        ) {
          this.setState({ languageValue: [] });
        } else {
          if (languageValue && languageValue.includes("all")) { 
            this.setState({ languageValue: this.state.languageValueList });
          } else {
            this.setState({ languageValue });
          }
        }
      },
      onChangeTopic: topicValue => {
        if (
          this.state.topicValue &&
          this.state.topicValue.includes("Select All") &&
          topicValue &&
          !topicValue.includes("Select All")
        ) {
          this.setState({ topicValue: [] });
        } else {
          if (topicValue && topicValue.includes("Select All")) {
            this.setState({ topicValue: this.state.topicsList });
          } else {
            this.setState({ topicValue });
          }
        }
      },
      onChangeCategory: categoryValue => {
        if (
          this.state.categoryValue &&
          this.state.categoryValue.includes(0) &&
          categoryValue &&
          !categoryValue.includes(0)
        ) {
          this.setState({ categoryValue: [] });
        } else {
          if (categoryValue && categoryValue.includes(0)) {
            this.setState({ categoryValue: [0, 1, 2, 3, 4] });
          } else {
            this.setState({ categoryValue });
          }
        }
      },
      onChangePrice: priceValue => {
        if (
          priceValue &&
          !priceValue.includes("All") &&
          this.state.priceValue.includes("All")
        ) {
          this.setState({ priceValue: [] });
        } else {
          if (priceValue && priceValue.includes("All")) {
            this.setState({ priceValue: pricesList });
          } else {
            this.setState({ priceValue });
          }
        }
      },
      onChangeDuration: durationValue => {
        this.setState({ durationValue });
        var duration =
          durationValue === 1
            ? { gte: 0, lte: 600 }
            : durationValue === 2
            ? { gte: 600, lte: 900 }
            : durationValue === 3
            ? { gte: 900, lte: 1800 }
            : durationValue === 4
            ? { gte: 1800 }
            : undefined;

        this.setState({ duration });
      },
      onApplyFilter: this.onApplyFilter,
      topicValue: this.state.topicValue,
      categoryValue: this.state.categoryValue,
      priceValue: this.state.priceValue,
      durationValue: this.state.durationValue,
      searchText: this.state.searchText,
      onSearchText: this.onSearchText,
      onChangeText: this.onChangeText,
      loading: this.state.loading
    };
    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1>  { "Trainings"} </h1> 
              <TrainingFilter key="TrainingFilter" {...trainingFilterProps} />
          </Col>
        </Row>
          {/* <div className="uk-clearfix boundary-align">
          </div>  */}
        {loader ? (
          <CircularProgress></CircularProgress>
        ) : (
          <div className="uk-clearfix boundary-align">
            {allTraining && allTraining.length > 0 ? (
              <div
                className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                data-uk-grid
              >
                {allTraining.map((item, index) => {
                  return (
                    <TrainingsCard
                      key={"TrainingsCard" + index}
                      {...trainingCardProps}
                      data={item}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : (
              <div>
                <div
                  style={{ minHeight: "90vh" }}
                  className="uk-child-width-1-1@s uk-child-width-3-3@m uk-grid-match uk-margin"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                  data-uk-grid
                >
                  <div className="uk-child-width-1-4@m"></div>
                  <div className="uk-child-width-2-4@m uk-text-center uk-margin">
                    <div className="uk-text-center uk-margin">
                      <div style={{ padding: "0 40%" }}>
                        <img src={require("assets/images/no_data_found.png")} />
                      </div>
                      <h3
                        className="uk-text-center "
                        style={{ margin: "20px 0 0" }}
                      >
                        Sorry!! No Data Found.
                      </h3>
                      <p className="uk-text-center uk-margin-remove">
                        Try somethings else.
                      </p>
                    </div>
                  </div>
                  <div className="uk-child-width-1-4@m"></div>
                </div>
              </div>
            )}
            <Pagination
              hideOnSinglePage={true}
              // defaultCurrent={pagination.page + 1}
              current={pagination.page + 1}
              defaultPageSize={9}
              total={pagination.totalRecords}
              onChange={this.loadMore}
            />
          </div>
        )}

        {deleteModalVisible && <ModalDelete {...modalDeleteProps} />}
     
</Auxiliary>
    );
  }
}

// export default Trainings;
const mapStateToProps = ({ auth, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  const {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    training,
    allTraining,
    deleteModalVisible
  } = trainingInfo;
  return {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    authUser,
    accessToken,
    training,
    allTraining,
    deleteModalVisible
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideMessage,
    showMessage,
    getAllTraining,
    trainingOnUpdate,
    deleteTraining,
    showModal,
    hideModal,
    showLoader
  })(Trainings)
);
