import React, { Component } from "react";
import { Result, Button, message } from "antd";
import { apiUrl } from "util/config";
import { stripeKey } from "util/config";
import TopNavbar from "../components/TopNavbar";
import { CartesianGrid } from "recharts";

class PaymentSuccess extends Component {
  state = {
    trainingIds: []
  };

  componentWillMount() {
    console.log("cancelled payment ", this.props.location);
  }

  render() {
    const { trainingIds } = this.state;
    return (
      <div>
        <div style={{ background: "#3e474e", height: "70px" }}>
          <TopNavbar key="topNavbarKey" />
        </div>
        <div
          className="uk-container"
          data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
          style={{ height: "100vh" }}
        >
          <Result
            status="error"
            title="Transaction Failed"
            subTitle="Your item is added to cart if it was not added there."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => this.props.history.push("/trainee")}
              >
                Explore More
              </Button>,
              <Button
                key="buy"
                onClick={() => this.props.history.push("/trainee/cart")}
              >
                Buy Again
              </Button>
            ]}
          ></Result>
        </div>
      </div>
    );
  }
}
export default PaymentSuccess;
