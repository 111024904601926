// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const DOWNLOAD_XLSX_FILE = 'DOWNLOAD_XLSX_FILE';
export const DOWNLOAD_PDF_FILE = 'DOWNLOAD_PDF_FILE';



//Auth const
export const USER_REDIRECT = 'USER_REDIRECT';
export const USER_REDIRECT_SUCCESS = 'USER_REDIRECT_SUCCESS';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SIGNOUT_AUTH_USER = 'SIGNOUT_AUTH_USER';
export const SIGNOUT_AUTH_USER_SUCCESS = 'SIGNOUT_AUTH_USER_SUCCESS';
export const SESSION_AUTH = 'SESSION_AUTH';

export const INIT_URL = 'INIT_URL';
export const FIND_USER = 'FIND_USER';
export const FIND_USER_SUCCESS = 'FIND_USER_SUCCESS';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const ON_PING = 'ON_PING'; 
export const ON_PING_TRAINEE = 'ON_PING_TRAINEE';
export const ON_PING_SUCCESS = 'ON_PING_SUCCESS'; 
export const FORGOT_PASSWORD_USER = 'FORGOT_PASSWORD_USER';
export const FORGOT_PASSWORD_USER_SUCCESS = 'FORGOT_PASSWORD_USER_SUCCESS';
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER';
export const RESET_PASSWORD_USER_SUCCESS = 'RESET_PASSWORD_USER_SUCCESS';

//Microsoft 
export const SIGNIN_MICROSOFT_USER = "SIGNIN_MICROSOFT_USER";
export const SIGNIN_MICROSOFT_USER_SUCCESS = "SIGNIN_MICROSOFT_USER_SUCCESS";

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';


//CAMPAIGN
export const CAMPAIGN_USER = 'CAMPAIGN_USER';
export const CAMPAIGN_USER_SUCCESS = 'CAMPAIGN_USER_SUCCESS';
export const GET_CAMPAIGNS_USERS = 'GET_CAMPAIGNS_USERS';
export const GET_CAMPAIGNS_USERS_SUCCESS = 'GET_CAMPAIGNS_USERS_SUCCESS';
export const GET_All_CAMPAIGN = 'GET_All_CAMPAIGN';
export const GET_All_CAMPAIGN_SUCCESS = 'GET_All_CAMPAIGN_SUCCESS';

export const CLEAN_All_CAMPAIGNS = 'CLEAN_All_CAMPAIGNS';
export const ON_ADD_CAMPAIGN = 'ON_ADD_CAMPAIGN';
export const ON_ADD_CAMPAIGN_SUCCESS = 'ON_ADD_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN ='UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS ='UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_ON_CAMPAIGN ='UPDATE_ON_CAMPAIGN';
export const UPDATE_ON_CAMPAIGN_SUCCESS ='UPDATE_ON_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN ='DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS ='DELETE_CAMPAIGN_SUCCESS';
export const CAMPAIGNS_DOWNLOAD_CERTIFICATE = 'CAMPAIGNS_DOWNLOAD_CERTIFICATE';
export const GET_CAMPAIGNS_ANALYTICS = 'GET_CAMPAIGNS_ANALYTICS';
export const GET_CAMPAIGNS_ANALYTICS_SUCCESS = 'GET_CAMPAIGNS_ANALYTICS_SUCCESS';
export const GET_All_CAMPAIGNS_INFO = 'GET_All_CAMPAIGNS_INFO';
export const GET_All_CAMPAIGNS_INFO_SUCCESS = 'GET_All_CAMPAIGNS_INFO_SUCCESS';
export const GET_All_CAMPAIGNS_REPORTS_INFO = 'GET_All_CAMPAIGNS_REPORTS_INFO';
export const GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS = 'GET_All_CAMPAIGNS_REPORTS_INFO_SUCCESS';




//Training
export const GET_TRAINING = 'GET_TRAINING';
export const GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_All_TRAINING = 'GET_All_TRAINING';
export const GET_All_TRAINING_SUCCESS = 'GET_All_TRAINING_SUCCESS';
export const ON_ADD_TRAINING = 'ON_ADD_TRAINING';
export const ON_ADD_TRAINING_SUCCESS = 'ON_ADD_TRAINING_SUCCESS';
export const UPDATE_TRAINING ='UPDATE_TRAINING';
export const UPDATE_TRAINING_SUCCESS ='UPDATE_TRAINING_SUCCESS';
export const UPDATE_ON_TRAINING ='UPDATE_ON_TRAINING';
export const UPDATE_ON_TRAINING_SUCCESS ='UPDATE_ON_TRAINING_SUCCESS';
export const DELETE_TRAINING ='DELETE_TRAINING';
export const DELETE_TRAINING_SUCCESS ='DELETE_TRAINING_SUCCESS'; 
export const UPDATE_MY_CART_TRAINING ='UPDATE_MY_CART_TRAINING'; 

//User

export const USER_UPDATE_INFO = 'USER_UPDATE_INFO';
export const USER_UPDATE_INFO_SUCCESS = 'USER_UPDATE_INFO_SUCCESS';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_TRAINEE_UPDATE_INFO = 'USER_TRAINEE_UPDATE_INFO';
export const USER_TRAINEE_UPDATE_INFO_SUCCESS = 'USER_TRAINEE_UPDATE_INFO_SUCCESS';
export const USER_TRAINEE_RESET_PASSWORD = 'USER_TRAINEE_RESET_PASSWORD';
export const USER_TRAINEE_RESET_PASSWORD_SUCCESS = 'USER_TRAINEE_RESET_PASSWORD_SUCCESS';


//Dashboard

export const GET_DASHBOARD ='GET_DASHBOARD'
export const GET_DASHBOARD_SUCCESS ='GET_DASHBOARD_SUCCESS'
export const GET_ALL_CAMPAIGN_TRAININGS='GET_ALL_CAMPAIGN_TRAININGS'
export const GET_ALL_CAMPAIGN_TRAININGS_SUCCESS='GET_ALL_CAMPAIGN_TRAININGS_SUCCESS'


//TRAINEE 

export const ACTION_BOOKMARKS_TRAINING='ACTION_BOOKMARKS_TRAINING'
export const ACTION_BOOKMARKS_TRAINING_SUCCESS='ACTION_BOOKMARKS_TRAINING_SUCCESS'
export const GET_BOOKMARKS_TRAINING='GET_BOOKMARKS_TRAINING'
export const GET_BOOKMARKS_TRAINING_SUCCESS='GET_BOOKMARKS_TRAINING_SUCCESS'
export const GET_TRAINEE_TRAINING='GET_TRAINEE_TRAINING'
export const GET_TRAINEE_TRAINING_SUCCESS='GET_TRAINEE_TRAINING_SUCCESS'
export const GET_TRAINEE_ALL_TRAINING='GET_TRAINEE_ALL_TRAINING'
export const GET_TRAINEE_ALL_TRAINING_SUCCESS='GET_TRAINEE_ALL_TRAINING_SUCCESS'

export const SIGNIN_WITH_OTP = "SIGNIN_WITH_OTP"