import React, {Component} from "react";
import TopNavbar from "../components/TopNavbar";
import Footer from "../../../components/Footer/index"
import Model from "./model";
import CircularProgress from "components/CircularProgress";
import { Row, Col, Card, List, Progress, Divider, Table, Tag, Space } from "antd";


export default class LeaderBoard extends Component {

state = {
    data: {},
    loading: true,
  };
  componentWillMount() {
    const { match } = this.props;
    const trainingId = match.params.campaignId;
    Model.getLeaderBoardDetails({ campaignId: trainingId }).then((response) => {
      this.setState({ data: response.data, loading: false});
    });
  }

render(){

    var { loading, data} = this.state;


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Score',
            dataIndex: 'scoreRaw',
            key: 'scoreRaw',
        },
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
        }
    ];

    let dataSource = []
    for(let i = 0; i < data.length; i++){
        let row = {};
        row.key = i;
        row.name = data[i].name;
        row.scoreRaw = data[i].scoreRaw;
        row.rank = i + 1;
        dataSource.push(row)
    }

    return(
        <div>
            <TopNavbar key="topNavbarKey" />
            {loading ? (
            <CircularProgress></CircularProgress>
            ) : (
                <div>
                <div style={{padding:"40px",marginTop:"60px"}}>
                    <Row>
                        <Col lg={7} md={7} sm={24} xs={24} style={{marginLeft:"6%"}}>
                            <div style={{padding:"20px",backgroundColor:"#fffafa"}}>
                                <h4 style={{color:"red",textAlign:"right"}}>Rank-1</h4>
                                <h6 style={{textAlign:"center",margin:0,padding:0}}>{data[0].name}</h6>
                                <h6 style={{textAlign:"center",color:"red",margin:0,padding:0}}>{data[0].scoreRaw}<span> Score</span></h6>
                            </div>
                        </Col>
                        {data.length >= 2 && (
                            <Col lg={7} md={7} sm={24} xs={24}>
                            <div style={{padding:"20px",backgroundColor:"#fffafa"}}>
                                <h4 style={{color:"red",textAlign:"right"}}>Rank-2</h4>
                                <h6 style={{textAlign:"center",margin:0,padding:0}}>{data[1].name}</h6>
                                <h6 style={{textAlign:"center",color:"red",margin:0,padding:0}}>{data[1].scoreRaw}<span> Score</span></h6>
                            </div>
                            </Col>
                        )}

                        {data.length >= 3 && (
                            <Col lg={7} md={7} sm={24} xs={24}>
                            <div style={{padding:"20px",backgroundColor:"#fffafa"}}>
                                <h4 style={{color:"red",textAlign:"right"}}>Rank-3</h4>
                                <h6 style={{textAlign:"center",margin:0,padding:0}}>{data[2].name}</h6>
                                <h6 style={{textAlign:"center",color:"red",margin:0,padding:0}}>{data[2].scoreRaw}<span> Score</span></h6>
                            </div>
                            </Col>
                        )}
                    </Row>
                </div>   

                <div style={{padding:"40px",marginLeft:"6%"}}>
                    <h3>Other Participant:</h3>

                    <Table columns={columns} dataSource={dataSource} />
                </div>

                </div>
            )}
        </div>
    )
}

}