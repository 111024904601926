import {
    apiUrl
} from "util/config";
import {
    message
} from 'antd'
import $ from 'jquery'
var Actions = {

    saveReviews: (text, trainingId, userId, image, rating) => {
        return new Promise((resolve, reject) => {

            var settings = {
                async: true,
                crossDomain: true,
                url: apiUrl + "api/reviews",
                method: "POST",
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    // fngprt: m.cookie.get("fngprt")
                },
                data: {
                    text,
                    trainingId,
                    userId,
                    image,
                    rating
                },
                success: function (response) {
                    if (response.error) {
                        message.error(response.userMessage);

                    } else {
                        var user = JSON.parse(localStorage.getItem('user'))
                        user.organisation = response.data
                        localStorage.setItem('user', JSON.stringify(user))
                        resolve(response);
                    }
                },
                error: function (error) {

                    if (error.status == 401) {
                        message.error("Your session has been expired. Please relogin.");
                        localStorage.clear();window.location.reload();
                    } else {
                        message.error("Some error has occurred.");

                    }
                }
            };

            $.ajax(settings).done();
        });
    },
    getReviews: (query) => {
        return new Promise((resolve, reject) => {

            var settings = {
                async: true,
                crossDomain: true,
                url: apiUrl + "api/reviews?trainingId=" + query,
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    // fngprt: m.cookie.get("fngprt")
                },
                success: function (response) {
                    if (response.error) {
                        message.error(response.userMessage);

                    } else {
                        resolve(response);
                    }
                },
                error: function (error) {

                    if (error.status == 401) {
                        message.error("Your session has been expired. Please relogin.");
                        localStorage.clear();window.location.reload();
                    } else {
                        message.error("Some error has occurred.");

                    }
                }
            };

            $.ajax(settings).done();
        });
    }
}


export default Actions