import { Row, Col, List, Card} from "antd";
import React from "react";
 
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts'; //react-fusioncharts
// import style from "../../../extensions/charts/recharts/radial/Components/style";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
 

const TrainingReports = (info) => { 

  const { trainingSummary } = info
  console.log({trainingSummary})
  const dataSource = {
    chart: {
      // caption: ' ',
      // subCaption: 'In MMbbl = One Million barrels',
      // xAxisName: 'Country',
      // yAxisName: 'Reserves (MMbbl)',
      // numberSuffix: 'K',
      "showPercentValues": "1",
        "showPercentInTooltip": "0",
        "decimals": "1",
        "useDataPlotColorForLabels": "1",
        //Disable Smart Labels
        "enableSmartLabels": "0",
      theme: 'fusion'
    },
    data: [
      // { 
      //   label: "Total Trainings Assigned" ,//+ (trainingSummary.trainees || 0), 
      //   value: (trainingSummary.trainees || 0) ,
      // },
      { 
        label: "Total Passed",// + (trainingSummary.passed || 0), 
        value: (trainingSummary.passed || 0) },
      { 
        label: "Total Failed",// + (trainingSummary.failed || 0), 
        value: (trainingSummary.failed || 0) ,
        // color:"#f2726f"
      },
      { 
        label: "Trainings not Attempted",// + (trainingSummary.notAttempted || 0), 
        value: (trainingSummary.notAttempted || 0) 
      } 
    ]
  };
   
  const chartConfigs = {
    // type: 'Pie3D',

    type: 'pie2d',
    renderAt: 'chart-container',
    width: '100%',
    height: 350,
    dataFormat: 'json',
    dataSource: dataSource
};
var colView = ( trainingSummary.trainees || trainingSummary.passed || trainingSummary.failed || trainingSummary.notAttempted ) ? 12: 24;
  
  return (
    <div> 

<Row>  
         
         <Col  xl={colView} lg={colView} md={colView} sm={24} xs={24} >  
           <List
             className="demo-loadmore-list"
             // bordered
             itemLayout="horizontal" 
             size="small"
             style={{margin:'5px 20px'}}
             >
 
             <List.Item 
              actions={[  trainingSummary.trainees|| 0
              ]}
             >
             No. of trainings assigned
             </List.Item>
             <List.Item
               actions={[  
                <a 
                  key="list-loadmore-edit" 
                  style={{color: 'inherit'}} 
                  // target="_blank" 
                  // href="#"
                  // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                >
                {trainingSummary.passed|| 0}
                </a>
               ]}
             >
               No. of trainings passed
             </List.Item>
             <List.Item
               actions={[
                 <a 
                   key="list-loadmore-edit" 
                   style={{color: 'inherit'}} 
                   // target="_blank" 
                  //  href="#"
                   // href={"/campaigns/" + campaign._id + "/users?repeaters=1"}
                 >
                 {trainingSummary.failed || 0}
                 </a>
               ]}
             >
               No. of trainings failed
             </List.Item>
             <List.Item
               actions={[
                 <a 
                   key="list-loadmore-edit" 
                   style={{color: 'inherit'}} 
                  //  target="_blank"
                  //  href={"/campaigns/" + campaign._id + "/users?clicked=0"}
                 >
                 {trainingSummary.notAttempted || 0}
                 </a>
               ]}
             >
              No. of trainings not attempted
             </List.Item>
             <List.Item
               actions={[
                 <a 
                   key="list-loadmore-edit" 
                   style={{color: 'inherit'}} 
                  //  target="_blank"
                  //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                 >
                 {trainingSummary.attempted  || 0}
                 </a>
               ]}
             >
             No. of trainings attempted
             </List.Item>  
             <List.Item
               actions={[
                 <a 
                   key="list-loadmore-edit" 
                   style={{color: 'inherit'}} 
                  //  target="_blank"
                  //  href={"/campaigns/" + campaign._id + "/users?opened=1"}
                 >
                 {trainingSummary.attemptedNotSubmitted  || 0}
                 </a>
               ]}
             >
             No. of trainings attempted but not submitted
             </List.Item>  
 
             <List.Item
               actions={[
                 <span >{(trainingSummary.compliance || 0).toFixed(2) + "%"}</span>
               ]}
             >
               Compliance percentage 
             </List.Item>
           
           </List> 
           
         </Col>
         { ( trainingSummary.trainees  || trainingSummary.passed || trainingSummary.failed || trainingSummary.notAttempted ) &&
           <Col  xl={colView} lg={colView} md={colView} sm={24} xs={24}   >  
             <ReactFC style={{position: 'relative' }} {...chartConfigs} />  
           </Col> 
          }     
       </Row> 
      {/* <ReactFC {...chartConfigs} />  */}
    </div>
  );
};

export default TrainingReports;

 