import React from "react";
import { Route, Switch } from "react-router-dom";
import TraineeMain from "./traineeMain/index";
import NotFound from "./traineeMain/404";

const App = ({ match, i18n }) => {
  console.log(i18n, "i18ni18n")
  return (
    <div>
      <Switch>
        <Route path={`${match.url}trainee`} component={TraineeMain} i18n={i18n} />
        {/* <Route path={`${match.url}`} component={NotFound} /> */}
      </Switch>
    </div>
  )
};

export default App;
