import React from "react";
import { Form, Input, Spin, Row, Col, Select, Modal, DatePicker, Icon } from "antd";
import "./index.css";
import moment from "moment";
import { countryCodes } from "./data";

// function UserProfile( info) {
function RequestDemo(info) {
  const { form, getFieldDecorator, modalVisible, onSubmit, handleCancel, submitting } = info;

  const formItemLayout = {
    labelAlign: "right",
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 }
    }
  };

  const handleChange = selectedItems => {
    console.log({ selectedItems });
  };
  const handleFormSubmit = e => {
    e.preventDefault();
    if (!submitting) {
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log({ values });
          onSubmit(values);
          this.props.userTraineeUpdateInfo(values)
        }
      });
    }
  };

  const handlePreferredDate = dateTime => {
    console.log("dateTime", dateTime);
  };
  return (
    <Modal
      title="Demo Request Form"
      visible={true}
      onOk={handleFormSubmit}
      onCancel={handleCancel}
      width="55%"
      style={{ top: "3%" }}
    >
      <Form {...formItemLayout} className="request-demo">
        <Spin spinning={submitting} indicator={<Icon type="loading" style={{ fontSize: 26, color: "#038fde" }} spin />}>
          <div className="uk-modal-body" style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        pattern: /^[a-zA-Z ]/,
                        message: "Please input Name!"
                      }
                    ]
                  })(<Input placeholder="Enter Name" />)}
                </Form.Item>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please input email!"
                      },
                      {
                        type: "email",
                        message: "Please input valid email!"
                      }
                    ]
                  })(<Input placeholder="Enter Email" />)}
                </Form.Item>
                <Form.Item label="Contact">
                  {getFieldDecorator("phone", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please input contact number!"
                      },
                      {
                        validator: (rule, number, callback) => {
                          if (isNaN(number)) {
                            callback("Please input valid contact number!");
                          } else {
                            callback();
                          }
                        }
                      }
                    ]
                  })(<Input placeholder="Enter Contact Number" />)}
                </Form.Item>
                <Form.Item label="Organization">
                  {getFieldDecorator("orgName", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        pattern: /^[a-zA-Z0-9 ]/,
                        message: "Please input organization!"
                      }
                    ]
                  })(<Input placeholder="Enter Organisation Name" />)}
                </Form.Item>
                <Form.Item label="Preferred Date">
                  {getFieldDecorator("preferredDateTime", {
                    type: "object",
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          console.log(value);
                          if (value && value.isBefore(moment().add(1, "h"))) {
                            callback("Preferred date and time should be one hour later.");
                          }
                          callback();
                        }
                      }
                    ]
                  })(
                    <DatePicker
                      showTime
                      disabledDate={d => !d || d.isBefore(new Date())}
                      disabledTime={d => !d || d.isBefore(new Date())}
                      onChange={handlePreferredDate}
                      placeholder="Preferred Date and Time"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Country">
                  {getFieldDecorator("country", {
                    initialValue: countryCodes[96].name
                  })(
                    <Select showSearch placeholder="Select a Country" onChange={handleChange} style={{ width: "100%" }}>
                      {countryCodes.map(item => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </Modal>
  );
}

export default RequestDemo;
