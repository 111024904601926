import React from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Form, message, Icon } from "antd";

import { hideAuthMessage} from "appRedux/actions/Auth";
import { hideMessage, showMessage, showModal, hideModal, showLoader} from "appRedux/actions/Training";
import { requestDemo } from "appRedux/actions/Demo";
import { trialRequest } from "appRedux/actions/Trial";

// import logo from './logo.svg';
import NavbarWeb from "../../components/MainLanding/NavbarWeb";
import NavbarMobile from "../../components/MainLanding/NavbarMobile";
import BannerView from "../../components/MainLanding/BannerView";
import LandingPage from "../../components/MainLanding/LandingPage";
import DemoModal from "../../components/MainLanding/DemoModal";
import Footer from "../../components/Footer/indexLanding";

import "./../../assets/css/uikit.css";
import "./../../assets/css/main.css";
import "./../../assets/css/fontawesome.css";

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scrollpos: 300,
      visibleIcon: true,
      visible: false,
      submittingDemo: false,
      viewModel: false,
      content: "",
    };
  }

  onChange = (current) => {
    // this.setState({ current });
  };
  onClickViewModel = () => {
    this.setState({ viewModel: true });
  };
  componentWillMount() {
    message.destroy();
    this.props.hideMessage();
    this.props.hideAuthMessage();
    this.props.history.push("/signin");
  }
  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.scrollTo(0, 0);
    message.destroy();
    this.props.hideMessage();
    this.props.hideAuthMessage();
    window.addEventListener("scroll", this.handleScroll);
    const { scrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visibleIcon = scrollpos < currentScrollPos ? false : true;
    this.setState({
      visibleIcon,
    });
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    message.destroy();
    this.props.hideMessage();
    this.props.hideAuthMessage();
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { scrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visibleIcon = scrollpos < currentScrollPos ? false : true;
    this.setState({
      visibleIcon,
    });
  };
  componentDidUpdate() {
    console.log("updated ", this.props.demo);
    if (this.state.submittingDemo && this.props.demo.userMessage) {
      this.setState(
        {
          submittingDemo: false,
          viewModel: false,
        },
        () => {
          if (this.props.demo.error) {
            message.error(this.props.demo.userMessage, 5);
          } else {
            message.success(this.props.demo.userMessage, 5);
          }
        }
      );
    }
  }
  render() {
    const { form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    if (user) {
      if (user.trainee) {
        this.props.history.push("/trainee");
      } else {
        this.props.history.push("/main/dashboard/crm");
      }
    }
    const demoModelProps = {
      form: form,
      getFieldDecorator: getFieldDecorator,
      onSubmit: () => {
        this.setState({ viewModel: false });
      },
      modalVisible: this.state.viewModel,
      handleCancel: () => {
        // this.child.close();
        this.setState({ viewModel: false });
      },
    };
    const onClickProps = {
      onClickViewModel: () => {
        this.onClickViewModel();
      },
    };
    return (
      <main>
        <NavbarMobile {...onClickProps} />
        <NavbarWeb {...onClickProps} visibleIcon={this.state.visibleIcon} />
        <BannerView />
        <LandingPage {...this.props} />

        {/* { this.state.viewModel && 
              <DemoModal {...demoModelProps}/>
            }    */}
        {this.state.viewModel && <DemoModal {...demoModelProps} submitting={this.props.demo.submittingDemo} />}
        <Footer {...onClickProps} />
      </main>
    );
  }
}

const mapStateToProps = ({ auth, demo, trial, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  // var userData = user
  const { loader, alertMessage, successMessage, showMessage } = trainingInfo;
  return {
    demo,
    trial,
    loader,
    alertMessage,
    successMessage,
    showMessage,
    authUser,
    accessToken,
  };
};
export default Form.create()(
  withRouter(
    connect(
      mapStateToProps,
      {
       hideAuthMessage,
        hideMessage,
        showMessage,
        showModal,
        hideModal,
        showLoader,
        requestDemo,
        trialRequest,
      }
    )(App)
  )
);
