import React from 'react';
import { Tabs } from 'antd';
import Widget from 'components/Widget';
import TrainingItem from './TrainingItem';
import { List } from 'antd';

const TabPane = Tabs.TabPane;

class TrainingList extends React.Component {
	render() {
		const { trainingList } = this.props;
		if(!trainingList){
			return ''
		}
		const notAttemptedList = trainingList
		const attemptedList = trainingList
		// const notAttemptedList = trainingList.campaigns.filter((item) => item.attempted === false && item.campaignId.trainingId);

		// const attemptedList = trainingList.campaigns.filter((item) => item.attempted === true && item.campaignId.trainingId);
		return (
			<Widget title="Recent Campaigns" styleName="gx-card-tabs">
				<Tabs defaultActiveKey="1">
					<TabPane tab="Assigned" key="1">
						<List
							itemLayout="vertical"
							size="large"
							pagination={{
								onChange: (page) => {
									console.log(page);
								},
								pageSize: 5
							}}
							dataSource={notAttemptedList}
							renderItem={(item) => <TrainingItem key={item._id} data={item} />}
						/>
					</TabPane>
					<TabPane tab="Completed" key="2">
						<List
							itemLayout="vertical"
							size="large"
							pagination={{
								onChange: (page) => {
									console.log(page);
								},
								pageSize: 5
							}}
							dataSource={attemptedList}
							renderItem={(item) => <TrainingItem key={item._id} data={item} />}
						/>
					</TabPane>
				</Tabs>
			</Widget>
		);
	}
}

// {
//             attemptedList.map((training) =>
//               <TrainingItem key={training._id} data={training}/>)
//           }
export default TrainingList;
