import React from 'react'
// import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
// import {DropOption} from '../../../components'

 
// const { confirm } = Modal

const ViewModalReport = (campaignProps) => {
 
   
  const { modalVisible, handleCancel , graphReport} = campaignProps  
  var columns = [ 
    {
      title:  (graphReport.key).slice(0,1).toUpperCase() + (graphReport.key).slice(1, (graphReport.key).length), 
      key: 'key',
      dataIndex: 'key',
      render: (name, data) => <span>{data[graphReport.key]}</span>,
    },
    {
        title: graphReport.type != "training" ? "Mail not Opened" : "Attempted Not Finished",
        key: 'notView', 
        render: (name, data) => <span>{graphReport.type != "training" ? data.notOpened : data.attemptedNotSubmitted}</span>,
    },
    {
        title: graphReport.type != "training" ? "Mail Opened not Clicked" : "Passed",
        key: 'Passed', 
        render: (name, data) => <span>{graphReport.type != "training" ? data.openedNotClicked : data.passed}</span>,
    },

    {
        title: graphReport.type != "training" ? "Users clicked" : "Failed",
        key: 'failed', 
        render: (name, data) => <span>{graphReport.type != "training" ? data.defaulters : data.failed}</span>,
    },
    {
        title: graphReport.type != "training" ? "Repeat Defaulters" : "Not Attempted",
        key: 'notAttempted', 
        render: (name, data) => <span>{graphReport.type != "training" ? data.repeaters : data.notAttempted}</span>,
    },
    {
        title: "Compliance",
        key: 'compliance', 
        render: (name, data) => <span>{(data.compliance == "NaN" ? 100 : data.compliance.toFixed(2)) + "%"}</span>,
    }
  ]
  return (

    <Modal
    onCancel={handleCancel}
      title= {graphReport.title}
      visible={modalVisible}  
      footer={false}
    >
      <Table className="gx-table-responsive" 
        size="small"
        columns={columns}
        dataSource={graphReport.data}
        bordered={false}
        pagination={false} 
      />
    </Modal> 
  ) 
}

export default ViewModalReport
