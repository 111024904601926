import React from "react";
import { Link } from "react-router-dom";

import { IconFont } from "util/Utils";
import "./style.less";
import store from "store";
import { getSubdomain } from '../../util/Utils';
class Footer extends React.Component {
  render() {
    const { onClickViewModel, location } = this.props;
    console.log("location ", this.props);
    const fontStyle = {
      textDecoration: "none",
      color: "#ffffff",
      fontSize: "19px",
      textTransform: "uppercase",
      textAlign: "center",
      fontWeight: "600",
    };
    const fontStyle2 = {
      textDecoration: "none",
      color: "#ffffff",
      fontSize: "21px",
      textTransform: "uppercase",
      //   textAlign: "center",
      margin: "0px",
      fontWeight: "600",
    };

    let footerStyle = {};

    if (
      location &&
      (location.pathname === "/trainee/profile" ||
        location.pathname === "/trainee/settings" ||
        location.pathname === "/trainee/calendar" ||
        location.pathname === "/trainee/your/training" ||
        location.pathname === "/trainee/cart")
    ) {
      footerStyle = {
        // position: "fixed",
        bottom: 0,
        width: "100%",
      };
    }

    return (
      <div  className="footer-bottom-bg-color" >
          <div className="uk-container uk-container text-center" >  
            <div className="footer-bottom-logo" >
              <i className={("fa fa-logo-white-icon " + getSubdomain())} ></i>
              {/* <IconFont
                type="icon-logo_web_white"
                style={{
                  fontSize: "150px",  
                }}
              ></IconFont>   */}
              </div>
            {/* <h3 className=" uk-margin-remove uk-text-white"  style={{fontWeight:"500", fontSize: "1.3rem"}}>
            101C Sun Ave NE Albuquerque, New Mexico - 87109</h3>
            <p className="  uk-margin-remove uk-text-white" style={{  fontSize: "1.1rem"}}>www.ohphish.eccouncil.org | support@ohphish.com</p>
          */}
          </div>   
        </div>
        
    );
  }
}

export default Footer;
