// import { api, apiUrl } from "../../../util/config";
import $ from "jquery";
import { message } from "antd";
import { apiUrl } from "../../util/config";
import { userSignOut } from "./../../appRedux/actions/Auth";
var Actions = {
  updateTraining: trainingData => {
    message.destroy()
    console.log("trainingData---", { trainingData });
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/training/update_training";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: trainingData,
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };
      $.ajax(settings).done();
    });
  },
  fetchTrainingCategory: () => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/training/category";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };
      $.ajax(settings).done();
    });
  },
  fetchTrainingV1Info: trainingId => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/trainingV1";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: {
          trainingId: trainingId
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  fetchTrainingInfo: (
    trainingId,
    type,
    page,
    searchText,
    topicValue,
    categoryValue,
    priceValue,
    durationValue,showWeb,languageValue
  ) => {
    return new Promise((resolve, reject) => {
      message.destroy()
      if (priceValue) {
        if (priceValue.includes("Free") && priceValue.includes("Paid")) {
          priceValue = undefined;
        }
      }

      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);
      var url = apiUrl + "api/lms/training";

      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          showWeb,
          trainingId: trainingId ? trainingId : undefined,
          trainingTypeId: type === 0 ? null : type,
          page: page || 0,
          searchText:
            searchText !== "" && searchText !== "All" ? searchText : undefined,
          trainingSubType:
            topicValue && !topicValue.includes("All") ? topicValue : undefined,
          language:
            languageValue && !languageValue.includes("all") ? languageValue : undefined,
          trainingType:
            categoryValue && !categoryValue.includes(0)
              ? categoryValue
              : undefined,
          priceValue:
            priceValue && !priceValue.includes("All") ? priceValue : undefined,
          durationValue: durationValue ? durationValue : undefined
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          console.log("error.status", error);
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },

  fetchTraineeTrainingInfo: (campaignId, page, completionStatus, language) => {
    message.destroy()
    console.log("page", page);
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);
      var url = apiUrl + "api/lms/trainee/trainings";

      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          trainingId: campaignId ? campaignId : null,
          page: page || 0,
          completionStatus: completionStatus ? completionStatus : undefined,
          language:language
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  fetchBookmarks: (page, searchText) => {
    message.destroy()
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);
      var url = apiUrl + "api/mybookmarks";

      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          page: page || 0,
          searchText:
            searchText !== "" && searchText !== "All" ? searchText : undefined
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  actionBookmarks: (action, trainingId) => {
    message.destroy();
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);
      var url = apiUrl + "api/bookmarks/" + trainingId + "/" + action;

      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
          // fngprt: m.cookie.get("fngprt")
        },
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
          } else {
            message.success(response.userMessage);
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
  fetchUserEnroledNumber: (id) => {
    message.destroy()
    return new Promise((resolve, reject) => {
      var url = apiUrl + "api/getUserEnrolledNumber";

      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: {
          id
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          }
          resolve(response);
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            message.error("Some error has occurred.");
          }
        }
      };

      $.ajax(settings).done();
    });
  },
};

$.ajaxQ = (function() {
  var id = 0,
    Q = {};

  $(document).ajaxSend(function(e, jqx) {
    jqx._id = ++id;
    Q[jqx._id] = jqx;
  });
  $(document).ajaxComplete(function(e, jqx) {
    delete Q[jqx._id];
  });

  return {
    abortAll: function() {
      var r = [];
      $.each(Q, function(i, jqx) {
        r.push(jqx._id);
        jqx.abort();
      });
      return r;
    }
  };
})();

export default Actions;
