import $ from "jquery";
import { message } from "antd";
import { api, apiUrl } from "../../../util/config";
var Actions = {
  saveReviews: (text, trainingId, userId, image, rating) => {
    message.destroy();
    return new Promise((resolve, reject) => {
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/reviews",
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          text,
          trainingId,
          userId,
          image,
          rating,
        },
        success: function(response) {
          if (response.error) {
            message.error(response.userMessage);
          } else {
            var user = JSON.parse(localStorage.getItem("user"));
            user.organisation = response.data;
            localStorage.setItem("user", JSON.stringify(user));
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        },
      };

      $.ajax(settings).done();
    });
  },

  getTrainingDetails: (payload) => {
    message.destroy();
    return new Promise((resolve, reject) => {
      var queryUrl = "api/lms/trainee/trainings";
      if (payload.trainingType) {
        queryUrl = "api/lms/training";
      }
      var form = new FormData();
      // form.append("users", trainees);
      form.append("trainingId", payload.trainingId);
      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + queryUrl,
        method: "GET",
        dataType: "json",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: payload,
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
            resolve(response);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        },
      };

      $.ajax(settings).done();
    });
  },

  getMyTrainings: () => {
    message.destroy()
    return new Promise((resolve, reject) => {
      // var form = new FormData();
      // form.append("users", trainees);
      // form.append("trainingId", trainingId);
      // const userData = localStorage.getItem('user');
      //   const user = JSON.parse(userData);
      // form.append("organisationId", user.organisationId);

      var settings = {
        async: true,
        crossDomain: true,
        url: apiUrl + "api/lms/trainee/trainings",
        method: "GET",
        // processData: false,
        // contentType: false,
        // mimeType: "multipart/form-data",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {},
        success: function(response) {
          // response = JSON.parse(response);
          if (response.error) {
            message.error(response.userMessage);
            resolve(response);
          } else {
            resolve(response);
          }
        },
        error: function(error) {
          if (error.status == 401) {
            message.error("Your session has been expired. Please relogin.");
            localStorage.clear();
            window.location.reload();
          } else {
            message.error("Some error has occurred.");
          }
        },
      };

      $.ajax(settings).done();
    });
  },
};

export default Actions;
